import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import { Roles } from '@/auth/azure/Roles'
import RouterErrorHandler from '@/components/RouterErrorHandler'
import { Reparto } from '@/enums'
import InterventionCreatorRoute from '@/routes/interventions/InterventionCreator.route'
import InterventionEditorRoute from '@/routes/interventions/InterventionEditor.route'
import InterventionsPage from '@/routes/interventions/InterventionsPage'
import { RoleRoute, RoleRouteObject, RouteFunctionParams } from '@/types'
import { LoaderFunction, defer } from 'react-router-typesafe'
import { azienda } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { ODataModelResponseV4 } from '@odata2ts/odata-core'
import { HttpResponseModel } from '@odata2ts/http-client-api'

export const interventionsPageRouteLoader = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const clientId = params.clientId
  let azienda: HttpResponseModel<ODataModelResponseV4<azienda>> | undefined
  if (clientId)
    azienda = await qsAdminApi.azienda(Number(clientId)).query((builder, azienda) => {
      builder.select('id', 'nome')
    })

  const title = azienda ? `${azienda.data.nome} - Interventi` : 'Interventi'

  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.select('id', 'nome')
    builder.orderBy(azienda.nome.asc())
  })

  const getImpiegati = qsAdminApi.user().query((builder, impiegato) => {
    builder.filter(impiegato.reparto.props.id.eq(Reparto.SVILUPPO).or(impiegato.reparto.props.id.eq(Reparto.TECNICO)))
    builder.select('id', 'fullname')
    builder.orderBy(impiegato.fullname.asc())
  })

  return defer({
    title,
    aziende: await getAziende,
    impiegati: await getImpiegati,
    userInfo: await getAzureUserInformation(),
    defaultCRUDAllowedRoles: [Roles.GlobalAdministrator, Roles.Administrator, Roles.Supervisor, Roles.TechDeveloper],
  })
}) satisfies LoaderFunction

export const InterventionsPageRoute: RoleRoute = {
  path: 'interventions',
  children: [
    {
      index: true,
      element: <InterventionsPage />,
      loader: interventionsPageRouteLoader,
      allowedRoles: [Roles.Guest],
    } as RoleRouteObject,
    InterventionEditorRoute,
    InterventionCreatorRoute,
  ],
  errorElement: <RouterErrorHandler />,
  text: 'Interventi',
  icon: 'car',
  mainMenuRoute: true,
  allowedRoles: [Roles.Guest],
}
