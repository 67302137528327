import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { commessa, pacchetto_vendita, azienda } from '@/model/qsadminapi/QsAdminApiModuleModel'
import PackageSaleEditor from '@/routes/packages/sale/PackageSaleEditor'
import { RoleRouteObject, RouteFunctionParams } from '@/types'
import { redirect } from 'react-router-dom'
import { defer, LoaderFunction, ActionFunction } from 'react-router-typesafe'
import { ODataCollectionResponseV4, ODataModelResponseV4 } from '@odata2ts/odata-core'
import { ODataResponse, HttpResponseModel } from '@odata2ts/http-client-api'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import { Roles } from '@/auth/azure/Roles'
import { StatoCommessa } from '@/routes/projects/ProjectEditor.enums'

export const packageSaleEditorRouteAction = (async ({ request, params }: RouteFunctionParams) => {
  const packageSale = (await request.json()) as pacchetto_vendita
  console.log('pacchetto', packageSale)
  const qsAdminApi = useQsAdminApiManager.getState().service

  switch (request.method) {
    case 'POST': {
      packageSale.unita_restanti = packageSale.unita
      const res = await qsAdminApi.pacchetto_vendita().create(packageSale)
      console.log(res)
      return redirect(`../${res.data.id}`)
    }
    case 'PUT':
    case 'PATCH': {
      return await qsAdminApi.pacchetto_vendita(packageSale.id).update(packageSale)
    }
    default: {
      throw new Response('Method not allowed', {
        status: 405,
        statusText: 'Method not allowed',
      })
    }
  }
}) satisfies ActionFunction

export const packageSaleEditorRouteLoader = (async ({
  request,
  params,
}: RouteFunctionParams<'packageSaleId' | 'clientId'>) => {
  const packageSaleId = params.packageSaleId
  const clientId = params.clientId

  const qsAdminApi = useQsAdminApiManager.getState().service

  let azienda: HttpResponseModel<ODataModelResponseV4<azienda>> | undefined
  if (clientId)
    azienda = await qsAdminApi.azienda(Number(clientId)).query((builder, azienda) => {
      builder.select('id', 'nome')
    })
  let title = azienda ? `${azienda.data.nome} - ` : ``

  let packageSale: pacchetto_vendita
  let getCommesse: ODataResponse<ODataCollectionResponseV4<commessa>> | undefined

  if (packageSaleId) {
    //editor
    const getPackageSale = await qsAdminApi.pacchetto_vendita(Number(packageSaleId)).query((builder, pacchetto) => {
      builder.expanding('sede', (sedeBuilder, sede) => {
        sedeBuilder.expanding('azienda', (aziendaBuilder, azienda) => {
          aziendaBuilder.expanding('sedi', (sediBuilder, sedeAzienda) => {
            sediBuilder.expanding('commesse', (commesseSediBuilder, commessaSede) => {
              commesseSediBuilder.select('id', 'ded_Dis', 'titolo')
              commesseSediBuilder.orderBy(commessaSede.ded_Dis.desc())
            })
            sediBuilder.select('id', 'nome', 'commesse', 'note')
            sediBuilder.orderBy(sedeAzienda.principale.desc())
          })
          aziendaBuilder.select('id', 'nome', 'sedi')
        })
        sedeBuilder.expanding('commesse', (commesseBuilder, commessa) => {
          commesseBuilder.select('id', 'ded_Dis', 'titolo')
          commesseBuilder.orderBy(commessa.ded_Dis.desc())
        })
        sedeBuilder.select('id', 'nome', 'azienda', 'commesse', 'note')
      })
      builder.expanding('commessa', (commessaBuilder, task) => {
        commessaBuilder.select('id', 'ded_Dis', 'titolo')
      })
      builder.expanding('tipologia_unita', (tipologiaUnitaBuilder, tipologiaUnita) => {
        tipologiaUnitaBuilder.select('id', 'nome')
      })
    })
    packageSale = getPackageSale.data satisfies pacchetto_vendita
    title += `${packageSale.ded_Dis}`
    if (clientId && packageSale?.sede?.azienda && packageSale?.sede?.azienda.id !== Number(clientId)) {
      throw new Error(
        `Il pacchetto vendita ${packageSale.ded_Dis} appartiene al cliente ${packageSale?.sede?.azienda.nome} e non al cliente corrente`,
      )
    }
  } else {
    //creator
    title += 'Nuovo pacchetto'
    packageSale = {
      id: 0,
      ded_Dis: '',
      ded_RootFam: '',
      ded_SubFam: '',
      ded_Id: 0,
      consuntivo: false,
      unita: 0,
      costo_unita: 0,
      unita_restanti: 0,
      creazione: new Date().toISOString(),
      note: null,
      data_fatturazione: null,
      sede: null,
      isExhaust: false,
      isExpired: false,
    }
  }

  const getAziende = qsAdminApi.azienda().query((builder, qazienda) => {
    builder.filter(qazienda.sedi.any())
    builder.filter(clientId ? qazienda.id.eq(Number(clientId)) : null)
    builder.select('id', 'nome')
    builder.orderBy(qazienda.nome.asc())
  })

  const getTipologieDurata = qsAdminApi.tipologia_durata_pacchetto().query((builder, tipologia) => {
    builder.select('id', 'nome')
    builder.orderBy(tipologia.nome.asc())
  })

  if (packageSale.sede)
    getCommesse = qsAdminApi.commessa().query((builder, commessa) => {
      builder.filter(commessa.sede.props.id.eq(Number(packageSale.sede?.id)))
      builder.filter(
        commessa.stato.props.id
          .eq(StatoCommessa.APERTA)
          .or(packageSale.commessa ? commessa.id.eq(Number(packageSale.commessa?.id)) : null),
      )
      builder.select('id', 'ded_Dis', 'titolo')
      builder.orderBy(commessa.id.desc())
    })

  return defer({
    title,
    packageSale,
    getAziende,
    getTipologieDurata,
    getCommesse,
    getUserInfo: getAzureUserInformation(),
    defaultCRUDAllowedRoles: [Roles.GlobalAdministrator, Roles.Administrator, Roles.Supervisor],
  })
}) satisfies LoaderFunction

export const PackageSaleEditorRoute = {
  path: ':packageSaleId',
  element: <PackageSaleEditor creating={false} />,
  loader: packageSaleEditorRouteLoader,
  action: packageSaleEditorRouteAction,
  allowedRoles: [Roles.Guest],
} as RoleRouteObject
