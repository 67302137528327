import { useLoaderData, Await } from 'react-router-typesafe'
import { Form as DXForm, Tab, TabbedItem } from 'devextreme-react/form'
import { Suspense, useRef } from 'react'
import { meetingEditorRouteLoader } from '@/routes/meetings/MeetingEditor.route'
import AttachmentsManager from '@/components/file-manager/AttachmentsManager'
import MeetingEditorForm from '@/routes/meetings/MeetingEditor.form'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import DocumentCreator from '@/components/document/DocumentCreator'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { useFetcher } from 'react-router-dom'
import { FileManagerRef } from 'devextreme-react/file-manager'
import { isUserRoleAllowed } from '@/routes/utils'
import { Roles } from '@/auth/azure/Roles'
import { DocumentPermissions } from '@/enums'
import DocumentHead from '@/components/document-head/DocumentHead'

type MeetingEditorProps = {
  creating: boolean
}

function MeetingEditor(props: MeetingEditorProps) {
  const qsAdminApi = useQsAdminApiManager.getState().service
  const { client } = useQsAdminApiManager()

  const { title, getTipologiaRiunione, getQsImpiegato, meeting, getUserInfo, defaultCRUDAllowedRoles } =
    useLoaderData<typeof meetingEditorRouteLoader>()

  const { creating } = props

  const verbaleGetRef = useRef<any>(null)
  const fetcher = useFetcher()

  const fileManagerRef = useRef<FileManagerRef>(null)

  return (
    <>
      <DocumentHead title={title}></DocumentHead>
      <h2 className="content-block">{title}</h2>
      <Suspense fallback={<LoadingPanel />}>
        <Await resolve={Promise.all([getQsImpiegato, getTipologiaRiunione, getUserInfo])}>
          {([impiegati, tipologie, userInfo]) => (
            <>
              <div id="meeting-offer-container" className={'content-block dx-card responsive-paddings'}>
                <fetcher.Form>
                  <MeetingEditorForm
                    creating={creating}
                    meeting={meeting}
                    meetingTypes={tipologie.data.value}
                    employees={impiegati.data.value}
                    verbaleGetRef={verbaleGetRef}
                    isRoleAllowed={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
                    fetcher={fetcher}
                  ></MeetingEditorForm>
                </fetcher.Form>
              </div>
              {!creating && (
                <div id="allegati-activity-container" className={'content-block dx-card responsive-paddings'}>
                  <DXForm id="form-allegati">
                    <TabbedItem>
                      <Tab title="Verbale">
                        <DocumentCreator
                          title="Verbale"
                          documentUrl={meeting.mainDocumentUrl}
                          documentGetRef={verbaleGetRef}
                          action={async () => {
                            await qsAdminApi.riunione(meeting.id).CreateDocument()
                          }}
                          preliminarPermissionsAction={async () => {
                            try {
                              const response = await client.post(
                                '/api/riunione/drivepermissions',
                                {
                                  objectId: meeting.id,
                                  path: meeting.mainDocumentUrl,
                                },
                                {
                                  headers: {
                                    'Content-Type': 'application/json',
                                  },
                                },
                              )
                              return response.status === 200
                                ? (response.data as DocumentPermissions)
                                : DocumentPermissions.Deny
                            } catch (error) {
                              console.log('Errore in settaggio permessi sul documento riunione', error)
                              return DocumentPermissions.Deny
                            }
                          }}
                        />
                      </Tab>
                      <Tab title="Allegati">
                        <AttachmentsManager
                          fileManagerRef={fileManagerRef}
                          folderUrl={meeting.attachmentsFolderUrl}
                          preliminarPermissionsAction={async () => {
                            try {
                              const response = await client.post(
                                '/api/riunione/drivepermissions',
                                {
                                  objectId: meeting.id,
                                  path: meeting.attachmentsFolderUrl,
                                },
                                {
                                  headers: {
                                    'Content-Type': 'application/json',
                                  },
                                },
                              )
                              return response.status === 200
                                ? (response.data as DocumentPermissions)
                                : DocumentPermissions.Deny
                            } catch (error) {
                              console.log('Errore in settaggio permessi sulla cartella allegati riunione', error)
                              return DocumentPermissions.Deny
                            }
                          }}
                          createDirectoryAction={async (
                            rootFolderPath: string,
                            name: string,
                            conflictBehavior: string,
                          ) => {
                            return await client.post(
                              '/api/riunione/createfolder',
                              {
                                rootFolder: rootFolderPath,
                                newFolderName: name,
                                conflictBehavior,
                                objectId: meeting.id,
                              },
                              {
                                headers: {
                                  'Content-Type': 'application/json',
                                },
                              },
                            )
                          }}
                        />
                      </Tab>
                    </TabbedItem>
                  </DXForm>
                </div>
              )}
            </>
          )}
        </Await>
      </Suspense>
    </>
  )
}

export default MeetingEditor
