import { LoaderFunction } from 'react-router-typesafe'
import { RoleRouteObject } from '@/types'
import { Roles } from '@/auth/azure/Roles'
import ClientGroupsPage from '@/routes/clients/client-groups/ClientGroupsPage'

export const clientGroupsPageRouteLoader = (async () => {
  const title = 'Gruppi aziendali'
  return {
    title,
    defaultCRUDAllowedRoles: [Roles.GlobalAdministrator, Roles.Administrator, Roles.Supervisor],
  }
}) satisfies LoaderFunction

export const ClientGroupsPageRoute = {
  path: 'groups',
  loader: clientGroupsPageRouteLoader,
  element: <ClientGroupsPage />,
  allowedRoles: [Roles.GlobalAdministrator, Roles.Administrator, Roles.Supervisor],
} satisfies RoleRouteObject
