import { mealVoucherRouteLoader } from '@/routes/hours/meal-voucher/MealVoucherPage.route'
import { useScreenSize } from '@/themes/media-query'
import { Suspense, useCallback, useRef } from 'react'
import { Await, useLoaderData } from 'react-router-typesafe'
import dxDataGrid, { ExportingEvent } from 'devextreme/ui/data_grid'
import DataGrid, {
  Column,
  Editing,
  Export,
  HeaderFilter,
  Scrolling,
  StateStoring,
  DataGridRef,
  FilterRow,
  FilterPanel,
  Pager,
  CustomRule,
} from 'devextreme-react/data-grid'
import { exportDataGrid } from 'devextreme/excel_exporter'
import saveAs from 'file-saver'
import { Workbook } from 'exceljs'
import { gridTotalItemsLabel } from '@/constants'
import useTokenRefresh from '@/auth/azure/azureManager'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import notify from 'devextreme/ui/notify'
import { ClickEvent } from 'devextreme/ui/button'
import { Button, Toolbar } from 'devextreme-react'
import { Item as ToolbarItem } from 'devextreme-react/toolbar'
import DocumentHead from '@/components/document-head/DocumentHead'

const MealVoucherPage = () => {
  const { getAllEmployeesMonthTotals } = useLoaderData<typeof mealVoucherRouteLoader>()
  const { client } = useQsAdminApiManager()
  const currentScreenSize = useScreenSize()
  const title = 'Invio report buoni pasto'
  const getGridHeight = useCallback(() => {
    let h = '79vh'
    if (currentScreenSize.isMedium) {
      h = '75vh'
    } else if (currentScreenSize.isSmall) {
      h = '120vh'
    } else if (currentScreenSize.isXSmall) {
      h = '75vh'
    }
    return h
  }, [currentScreenSize])
  const gridRef = useRef<DataGridRef>(null)
  const onExporting = (e: ExportingEvent) => {
    ExportDataGridToExcel(e.component)
  }

  const ExportDataGridToExcel = (component: dxDataGrid) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('MealVoucher')
    exportDataGrid({ component, worksheet }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'MealVoucher.xlsx')
      })
    })
  }

  return (
    <>
      <DocumentHead title={title}></DocumentHead>
      <Suspense fallback={<LoadingPanel />}>
        <Await resolve={getAllEmployeesMonthTotals}>
          {(allEmployeesMonthTotals) => (
            <>
              <h2 className={'content-block'}>{title}</h2>
              <div className={'content-block'}>
                <Toolbar>
                  <ToolbarItem location="after" locateInMenu="never">
                    <Button
                      text="Invia"
                      icon="email"
                      useSubmitBehavior={false}
                      width={'150px'}
                      onClick={async (e: ClickEvent) => {
                        gridRef?.current?.instance().beginCustomLoading('Invio report buoni pasto in corso....')
                        try {
                          const response = await client.post(
                            '/api/attivitalavorativa/SendTotalMealVoucherReportAllEmployees',
                            allEmployeesMonthTotals.data,
                            {
                              headers: {
                                'Content-Type': 'application/json',
                              },
                            },
                          )
                          notify(`Invio report buoni pasto avvenuto correttamente.`, 'success', 3000)
                          return true
                        } catch (error) {
                          console.log('Errore invio report buoni pasto:', error)
                          notify(`Errore invio report buoni pasto: ${error}`, 'error', 3000)
                          return false
                        } finally {
                          gridRef?.current?.instance().endCustomLoading()
                        }
                      }}
                    />
                  </ToolbarItem>
                </Toolbar>
                <DataGrid
                  id={'meal-voucher-datagrid'}
                  height={getGridHeight}
                  dataSource={allEmployeesMonthTotals.data.allUsersTotals}
                  noDataText="Nessun impiegato trovato"
                  className={'dx-card wide-card'}
                  showBorders={false}
                  showColumnLines={true}
                  wordWrapEnabled={false}
                  showRowLines={true}
                  rowAlternationEnabled={true}
                  allowColumnResizing={currentScreenSize.isLarge || currentScreenSize.isMedium}
                  allowColumnReordering={currentScreenSize.isLarge || currentScreenSize.isMedium}
                  width="100%"
                  onExporting={onExporting}
                  ref={gridRef}
                  columnHidingEnabled={
                    currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium
                  }
                >
                  <FilterRow visible={true} />
                  <FilterPanel visible={true} />
                  <HeaderFilter visible={true} />
                  <Export enabled={true} />
                  <Scrolling mode="virtual" />
                  <Pager
                    visible={true}
                    allowedPageSizes={'auto'}
                    displayMode={'compact'}
                    showPageSizeSelector={false}
                    showInfo={true}
                    showNavigationButtons={false}
                    infoText={gridTotalItemsLabel}
                  />
                  <StateStoring
                    enabled={true}
                    type="localStorage"
                    storageKey={'meal-voucher-datagrid'}
                    savingTimeout={50}
                  />
                  <Editing
                    mode="cell"
                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={true}
                    selectTextOnEditStart={true}
                    startEditAction={'click'}
                  />
                  <Column
                    allowEditing={false}
                    dataField={'user.fullname'}
                    caption="IMPIEGATO"
                    dataType="string"
                    defaultSortOrder="asc"
                    hidingPriority={
                      currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium
                        ? 3
                        : undefined
                    }
                  ></Column>
                  <Column
                    allowEditing={false}
                    dataField={'user.email'}
                    caption="EMAIL"
                    dataType="string"
                    hidingPriority={
                      currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium
                        ? 2
                        : undefined
                    }
                  ></Column>
                  <Column
                    dataField={'totals.TotalMealVoucher'}
                    caption="TOTALE BUONI PASTO"
                    dataType="number"
                    hidingPriority={
                      currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium
                        ? 1
                        : undefined
                    }
                  >
                    <CustomRule
                      validationCallback={(options: {
                        column: Record<string, any>
                        data: Record<string, any>
                        formItem: Record<string, any>
                        rule: Record<string, any>
                        validator: Record<string, any>
                        value: string | number
                      }) => {
                        return typeof options.value === 'number' ? options.value >= 0 : false
                      }}
                      message="Totale buoni pasto deve essere >= 0"
                    ></CustomRule>
                  </Column>
                </DataGrid>
              </div>
            </>
          )}
        </Await>
      </Suspense>
    </>
  )
}

export default MealVoucherPage
