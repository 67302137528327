import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import useTokenRefresh, { AzureUserInfo } from '@/auth/azure/azureManager'
import { Roles } from '@/auth/azure/Roles'
import DateColumn from '@/components/date-column/DateColumn'
import { contatto_aziendale, intervento, task, user } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { isUserRoleAllowed } from '@/routes/utils'
import { useScreenSize } from '@/themes/media-query'
import { DataGrid } from 'devextreme-react'
import {
  Column,
  Paging,
  Button as GridButton,
  StateStoring,
  DataGridTypes,
  Export,
  Toolbar,
  Item,
  DataGridRef,
} from 'devextreme-react/cjs/data-grid'
import DataSource from 'devextreme/data/data_source'
import ODataStore from 'devextreme/data/odata/store'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { ClickEvent } from 'devextreme/ui/button'
import { ColumnButtonClickEvent, ExportingEvent, RowDblClickEvent } from 'devextreme/ui/data_grid'
import notify from 'devextreme/ui/notify'
import { Workbook } from 'exceljs'
import saveAs from 'file-saver'
import { useRef } from 'react'
import { Link } from 'react-router-dom'

const TaskEditorFormInterventionsGrid = (props: { task: task; userInfo: AzureUserInfo | undefined }) => {
  const { task, userInfo } = props
  const currentScreenSize = useScreenSize()
  const gridRef = useRef<DataGridRef>(null)
  const token = useTokenRefresh()

  const computeCustRifValue = (rowData: intervento) => {
    let custRifs = ''
    rowData.riferimenti_cliente?.map((custRif: contatto_aziendale) => {
      custRifs = `${custRifs + (custRifs.length > 0 ? ' ' : '')}${custRif.fullname}`
    })
    return custRifs.trim()
  }

  const cellRiferimentiRender = (cellData: DataGridTypes.ColumnCellTemplateData) => {
    return (
      <>
        {cellData.data.riferimenti_cliente?.map((contatto: contatto_aziendale, index: number) => {
          return (
            <div className={`dx-tag-content custom-tag-padding`} key={`ctc-${index}`}>
              <span>{contatto.fullname}</span>
            </div>
          )
        })}
      </>
    )
  }

  const computeTechniciansValue = (rowData: intervento) => {
    let techs = ''
    rowData.impiegati?.map((tech: user) => {
      techs = `${techs + (techs.length > 0 ? ' - ' : '')}${tech.fullname}`
    })
    return techs.trim()
  }

  const ExportDataGridToExcel = (component: any) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('TaskInterventionsGrid')
    exportDataGrid({ component, worksheet }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'TaskInterventions.xlsx')
      })
    })
  }

  const onExporting = (e: ExportingEvent) => {
    ExportDataGridToExcel(e.component)
  }

  const interventionsTaskDataSource = new DataSource({
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/intervento`,
      key: 'id',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    filter: [['task.id', task.id]],
    expand: ['impiegati', 'riferimenti_cliente'],
  })

  return (
    <>
      <DataGrid
        id={'task-interventions-datagrid'}
        dataSource={interventionsTaskDataSource}
        ref={gridRef}
        noDataText="Nessun intervento associato al task"
        keyExpr={'id'}
        showBorders={true}
        showColumnLines={true}
        focusedRowEnabled={true}
        columnHidingEnabled={currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium}
        allowColumnResizing={true}
        rowAlternationEnabled={true}
        wordWrapEnabled={false}
        repaintChangesOnly={true}
        width="100%"
        onRowDblClick={(e: RowDblClickEvent<any, any>) => {
          if (e.rowType === 'data') window.open(`/interventions/${e.data.id}`, '_blank')
        }}
        onExporting={onExporting}
      >
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey={'task-interventions-datagrid'}
          savingTimeout={50}
        />
        <Paging defaultPageSize={8} />
        <Export enabled={true} />
        <Column
          type="buttons"
          width={currentScreenSize.isXSmall || currentScreenSize.isSmall ? '10%' : '2%'}
          alignment="left"
        >
          <GridButton
            hint="Details"
            icon="search"
            onClick={(e: ColumnButtonClickEvent) => {
              window.open(`/interventions/${e.row?.data.id}`, '_blank')
            }}
          />
        </Column>
        <Column
          dataField={'ded_Dis'}
          cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
            const data: intervento = cellData.row.data
            return isUserRoleAllowed(userInfo?.roles, [Roles.Guest]) ? (
              <>
                <Link to={`/interventions/${data.id}`}>{data.ded_Dis}</Link>
              </>
            ) : (
              <>{data.ded_Dis}</>
            )
          }}
          caption="CODICE"
        ></Column>
        {DateColumn({
          dataField: 'data',
          caption: 'DATA',
          format: 'dd/MM/yyyy',
        })}
        <Column
          dataField={'impiegati'}
          caption="TECNICI"
          calculateCellValue={computeTechniciansValue}
          allowSorting={false}
        ></Column>
        <Column
          dataField={'riferimenti_cliente'}
          caption="RIFERIMENTI"
          cellRender={cellRiferimentiRender}
          calculateCellValue={computeCustRifValue}
          allowSorting={false}
        ></Column>
        <Column dataField={'durata_intervento'} dataType="number" caption="DURATA"></Column>
        <Toolbar>
          <Item
            location="before"
            locateInMenu="never"
            showText="inMenu"
            widget="dxButton"
            options={{
              hint: 'Refresh',
              text: 'Refresh',
              icon: 'refresh',
              onClick: (e: ClickEvent) => {
                gridRef.current?.instance().refresh()
              },
              stylingMode: 'text',
            }}
          ></Item>
          <Item
            name="exportButton"
            locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
            showText="inMenu"
            options={{ hint: 'Esporta dati in excel', text: 'Esporta dati in excel' }}
          />
        </Toolbar>
      </DataGrid>
    </>
  )
}

export default TaskEditorFormInterventionsGrid
