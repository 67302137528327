import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { ordine } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { ordersPageRouteLoader } from '@/routes/orders/OrdersPage.route'
import ODataStore from 'devextreme/data/odata/store'
import notify from 'devextreme/ui/notify'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useLoaderData } from 'react-router-typesafe'
import {
  Column,
  ColumnChooser,
  DataGrid,
  Editing,
  Export,
  FilterRow,
  HeaderFilter,
  Item,
  Scrolling,
  SearchPanel,
  Selection,
  StateStoring,
  Toolbar,
  DataGridTypes,
  DataGridRef,
  Sorting,
  Search,
  FilterPanel,
  Pager,
  Button as GridButton,
  MasterDetail,
} from 'devextreme-react/data-grid'
import { Accordion, Item as AccordionItem } from 'devextreme-react/accordion'
import { ClickEvent } from 'devextreme/ui/button'
import { useScreenSize } from '@/themes/media-query'
import { CellPreparedEvent, ColumnButtonClickEvent, RowDblClickEvent } from 'devextreme/ui/data_grid'
import { GridCellColor } from '@/enums'
import { confirm } from 'devextreme/ui/dialog'
import { ContentReadyEvent } from 'devextreme/ui/accordion'
import {
  createDateFilterItemDescriptor,
  FormFilterItemDescriptorType,
} from '@/components/filter-form/GenericFilterForm.types'
import GenericFilterForm from '@/components/filter-form/GenericFilterForm'
import useTokenRefresh from '@/auth/azure/azureManager'
import DateColumn from '@/components/date-column/DateColumn'
import { columnSourceFactory, isUserRoleAllowed } from '@/routes/utils'
import { Roles } from '@/auth/azure/Roles'
import { gridTotalItemsLabel } from '@/constants'
import OrderBriefDetail from '@/routes/orders/OrderBriefDetail'
import { StatoOrdine } from '@/routes/orders/order.types'
import DocumentHead from '@/components/document-head/DocumentHead'

const OrdersPage = () => {
  const { title, stati, aziende, userInfo, defaultCRUDAllowedRoles } = useLoaderData<typeof ordersPageRouteLoader>()
  const token = useTokenRefresh()
  const { clientId } = useParams()
  const navigate = useNavigate()

  const { service } = useQsAdminApiManager()

  const gridRef = useRef<DataGridRef>(null)
  const currentScreenSize = useScreenSize()
  const getGridHeight = useCallback(() => {
    let h = '79vh'
    if (currentScreenSize.isMedium) {
      h = '75vh'
    } else if (currentScreenSize.isSmall) {
      h = '120vh'
    } else if (currentScreenSize.isXSmall) {
      h = '75vh'
    }
    return h
  }, [currentScreenSize])

  const getFiltersConfiguration = useCallback((): FormFilterItemDescriptorType[] => {
    const fltConf: FormFilterItemDescriptorType[] = [
      {
        fieldName: 'azienda',
        placeHolder: 'Azienda',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        values: aziende.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['commessa/sede/azienda/id', this.currentValue]]
        },
      },
      {
        fieldName: 'stato',
        placeHolder: 'Stato',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        defaultValue: StatoOrdine['DA EVADERE'],
        values: stati.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['stato/id', this.currentValue]]
        },
      },
      createDateFilterItemDescriptor('creazione', 'Data', 'Ultimo mese'),
    ]
    console.log('GENFLT - GETCONF :', fltConf)
    return fltConf
  }, [aziende, stati])

  const initialFilterConfiguration = useMemo(() => getFiltersConfiguration(), [getFiltersConfiguration])
  const [filterConfiguration, setFilterConfiguration] = useState(initialFilterConfiguration)
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false)
  const getCurrentFilter = useCallback((): any[] => {
    let applied: boolean = false
    const currentFlts = []
    for (const flt of filterConfiguration) {
      const fltValue = flt.composeFilterItem()
      if (fltValue !== null) {
        currentFlts.push(fltValue)
      }
      if (!applied && flt.currentValue !== flt.defaultValue) applied = true
    }
    setFiltersApplied(applied)
    console.log('GENFLT - CURRENT:', currentFlts)
    return currentFlts
  }, [filterConfiguration])

  const [currentFilter, setCurrentFilter] = useState<any[]>(
    clientId ? [['commessa/sede/azienda/id', Number(clientId)]] : [],
  )

  const ordersDataSource = {
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/ordine`,
      key: 'id',
      keyType: 'Int32',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    expand: [
      'offerta',
      'quota',
      'commessa($expand=sede($expand=azienda($expand=agente($expand=commerciale_qs),rivenditore)))',
      'stato',
      'tasks',
      'personalizzazioni',
    ],
    filter: currentFilter?.length > 0 ? currentFilter : null,
    requireTotalCount: true,
  }

  const onCellPrepared = (e: CellPreparedEvent) => {
    if (e.rowType === 'data' && e.column.dataField === 'stato.nome' && e.value) {
      switch (e.value) {
        case 'EVASO': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.EMERALD}`

          break
        }
        case 'DA EVADERE': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.SALMON}`

          break
        }
        case 'ANNULLATO': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.ORANGE}`
          break
        }
      }
    }
  }

  const onDeleteClick = (e: ClickEvent) => {
    const rowkey = gridRef.current?.instance().option('focusedRowKey')
    console.log('id', rowkey)
    gridRef.current
      ?.instance()
      .byKey(rowkey)
      .then((rowdata: ordine) => {
        const result = confirm(`<i>Vuoi davvero eliminare l'ordine <b>${rowdata.id}</b>?</i>`, 'Eliminazione Ordine')
        result.then((dialogResult) => {
          if (dialogResult === false) return
          service
            .ordine(rowdata.id)
            .delete()
            .then(() => {
              // Aggiornamento griglia
              gridRef.current?.instance().refresh()

              notify(
                {
                  message: `Ordine '${rowdata['ded_Dis']}' del cliente ${
                    rowdata['commessa']?.sede?.azienda?.nome ?? 'Non definito'
                  } eliminato con successo`,
                  type: 'success',
                  displayTime: 5000,
                },
                {
                  position: 'bottom center',
                  direction: 'up-push',
                },
              )
            })
            .catch((error: unknown) => {
              console.log('error', error)
              notify(
                {
                  message: `Errore eliminazione ordine: ${error}`,
                  type: 'error',
                  displayTime: 5000,
                },
                {
                  position: 'bottom center',
                  direction: 'up-push',
                },
              )
            })
        })
      })
  }
  const onEvadiClick = (e: ClickEvent) => {
    const rowkey = gridRef.current?.instance().option('focusedRowKey')
    if (rowkey)
      gridRef.current
        ?.instance()
        .byKey(rowkey)
        .then((rowdata: ordine) => {
          if (rowdata.stato?.id === StatoOrdine['DA EVADERE']) {
            const result = confirm(`<i>Vuoi davvero evadere l'ordine <b>${rowdata.ded_Dis}</b>?</i>`, 'Evasione Ordine')
            result.then((dialogResult) => {
              if (dialogResult === false) return
              gridRef?.current?.instance().beginCustomLoading('Evasione ordine in corso....')
              service
                .ordine(rowdata.id)
                .ProcessingOrder()
                .then(() => {
                  notify({
                    message: `Ordine '${rowdata['id']}' del cliente ${
                      rowdata['commessa']?.sede?.azienda?.nome ?? 'Non definito'
                    } evaso con successo`,
                    type: 'success',
                    displayTime: 3000,
                  })
                })
                .finally(() => {
                  gridRef?.current?.instance().endCustomLoading()
                  gridRef?.current?.instance().refresh()
                })
            })
          } else
            notify(`L'ordine deve essere nello stato 'DA EVADERE'. Stato: '${rowdata.stato?.nome}'`, 'warning', 3000)
        })
    else notify(`Selezionare un ordine da evadere`, 'warning', 3000)
  }
  const onAnnullaClick = (e: ClickEvent) => {
    const rowkey = gridRef.current?.instance().option('focusedRowKey')
    if (rowkey)
      gridRef.current
        ?.instance()
        .byKey(rowkey)
        .then((rowdata: ordine) => {
          if (rowdata.stato?.id === StatoOrdine['ANNULLATO']) {
            notify(`L'ordine non può essere annullato. Stato: '${rowdata.stato?.nome}'`, 'warning', 3000)
          } else {
            const result = confirm(
              `<i>Vuoi davvero annullare l'ordine <b>${rowdata.ded_Dis}</b>?</i>`,
              'Annulamento Ordine',
            )
            result.then((dialogResult) => {
              if (dialogResult === false) return
              gridRef?.current?.instance().beginCustomLoading('Annulamento ordine in corso....')
              service
                .ordine(rowdata.id)
                .CancelOrder()
                .then(() => {
                  notify({
                    message: `Ordine '${rowdata['id']}' del cliente ${
                      rowdata['commessa']?.sede?.azienda?.nome ?? 'Non definito'
                    } annullato con successo`,
                    type: 'success',
                    displayTime: 3000,
                  })
                })
                .finally(() => {
                  gridRef?.current?.instance().endCustomLoading()
                  gridRef?.current?.instance().refresh()
                })
            })
          }
        })
    else
      notify({
        message: `Selezionare un ordine da annullare`,
        type: 'warning',
        displayTime: 3000,
      })
  }

  useEffect(() => {
    getCurrentFilter()
  }, [])

  return (
    <>
      <DocumentHead title={title}></DocumentHead>
      {!clientId && <h2 className={'content-block'}>{title}</h2>}
      <div className={clientId ? '' : 'content-block'}>
        {!clientId && (
          <div className="accordion-generic-filter-form">
            <Accordion
              className={filtersApplied ? 'with-filter' : ''}
              collapsible={true}
              onContentReady={(e: ContentReadyEvent<any, any>) => {
                e.component?.collapseItem(0)
              }}
            >
              <AccordionItem icon="filter" title={'Filtri'}>
                <GenericFilterForm
                  FormItemDescriptors={filterConfiguration}
                  resetFilterClicked={function (e: ClickEvent): void {
                    setFilterConfiguration(getFiltersConfiguration())
                  }}
                  filterValueChanged={function (): void {
                    const currentFlts = getCurrentFilter()
                    console.log('GENFLT - VALUE CHNG', currentFlts)
                    if (JSON.stringify(currentFlts) !== JSON.stringify(currentFilter)) {
                      console.log('GENFLT - SETTING FILT', currentFlts)
                      setCurrentFilter(currentFlts)
                    }
                  }}
                />
              </AccordionItem>
            </Accordion>
          </div>
        )}
        <DataGrid
          id={clientId ? 'client-orders-datagrid' : 'orders-datagrid'}
          height={getGridHeight}
          dataSource={ordersDataSource}
          className={'dx-card wide-card'}
          showBorders={false}
          showColumnLines={true}
          wordWrapEnabled={false}
          showRowLines={true}
          focusedRowEnabled={true}
          rowAlternationEnabled={true}
          allowColumnResizing={currentScreenSize.isLarge || currentScreenSize.isMedium}
          allowColumnReordering={currentScreenSize.isLarge || currentScreenSize.isMedium}
          width="100%"
          columnHidingEnabled={currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium}
          onCellPrepared={onCellPrepared}
          //   onExporting={onExporting}
          ref={gridRef}
          noDataText="Nessun ordine trovato"
          remoteOperations={{
            filtering: true,
            grouping: false,
            groupPaging: false,
            paging: true,
            sorting: true,
            summary: true,
          }}
          grouping={{
            // Fondamentale per il raggruppamento veloce!!!
            autoExpandAll: true,
          }}
          groupPanel={{
            visible: currentScreenSize.isLarge || currentScreenSize.isMedium,
            emptyPanelText: 'Trascina qui una colonna per raggruppare',
          }}
          onRowDblClick={(e: RowDblClickEvent<any, any>) => {
            if (e.rowType === 'data') navigate(`${e.data.id}`)
          }}
        >
          <Pager
            visible={true}
            allowedPageSizes={'auto'}
            displayMode={'compact'}
            showPageSizeSelector={false}
            showInfo={true}
            showNavigationButtons={false}
            infoText={gridTotalItemsLabel}
          />
          <FilterRow visible={true} />
          <Sorting mode="multiple"></Sorting>
          <SearchPanel visible={true} width={currentScreenSize.isXSmall ? 200 : 250} />
          <Export enabled={true} />
          <FilterPanel visible={true} />
          <Editing allowAdding={true} />
          <HeaderFilter visible={true} />
          <Selection mode="single" />
          <ColumnChooser enabled={true} />
          <Scrolling mode="virtual" />
          <StateStoring
            enabled={true}
            type="localStorage"
            storageKey={clientId ? 'client-orders-datagrid' : 'orders-datagrid'}
            savingTimeout={50}
          />
          <MasterDetail enabled={true} component={OrderBriefDetail} />,
          <Column
            visible={currentScreenSize.isXSmall || currentScreenSize.isSmall}
            type="buttons"
            width={currentScreenSize.isXSmall || currentScreenSize.isSmall ? '10%' : '2%'}
            alignment="left"
            showInColumnChooser={false}
          >
            <GridButton
              hint="Details"
              icon="search"
              onClick={(e: ColumnButtonClickEvent) => {
                navigate(`${e.row?.data.id}`)
              }}
            ></GridButton>
          </Column>
          <Column
            dataField={'id'}
            caption="ID"
            visible={false}
            dataType="number"
            defaultSortOrder="desc"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 1 : undefined
            }
          >
            {!clientId && <HeaderFilter dataSource={columnSourceFactory(token, 'ordine', 'id', true)}></HeaderFilter>}
          </Column>
          <Column
            dataField="ded_Dis"
            cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
              const data: ordine = cellData.row.data
              return isUserRoleAllowed(userInfo?.roles, [Roles.Guest]) ? (
                <>
                  <Link to={`/orders/${data.id}`}>{data.ded_Dis}</Link>
                </>
              ) : (
                <>{data.ded_Dis}</>
              )
            }}
            visible={true}
            caption="CODICE"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 14 : undefined
            }
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'ordine', 'ded_Dis', true)}>
                <Search enabled={true} searchExpr={'ded_Dis'} />
              </HeaderFilter>
            )}
          </Column>
          <Column
            dataField="stato.nome"
            caption="STATO"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 12 : undefined
            }
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'ordine', 'stato.nome')}>
                <Search enabled={true} searchExpr={'stato.nome'} />
              </HeaderFilter>
            )}
          </Column>
          <Column
            dataField="commessa.sede.azienda.nome"
            cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
              const data: ordine = cellData.row.data
              return isUserRoleAllowed(userInfo?.roles, [Roles.Guest]) ? (
                <>
                  <Link to={`/clients/${data.commessa?.sede?.azienda?.id}`}>{data.commessa?.sede?.azienda?.nome}</Link>
                </>
              ) : (
                <>{data.commessa?.sede?.azienda?.nome}</>
              )
            }}
            caption="AZIENDA"
            visible={clientId === undefined}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 15 : undefined
            }
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'azienda', 'nome')}>
                <Search enabled={true} searchExpr={'nome'} />
              </HeaderFilter>
            )}
          </Column>
          <Column
            dataField="commessa.sede.nome"
            caption="SEDE"
            allowHeaderFiltering={false}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 9 : undefined
            }
          />
          <Column
            dataField="commessa.sede.azienda.agente.commerciale_qs.fullname"
            caption="AGENTE"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 8 : undefined
            }
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'agente', 'commerciale_qs.fullname')}>
                <Search enabled={true} searchExpr={'commerciale_qs.fullname'} />
              </HeaderFilter>
            )}
          </Column>
          <Column dataField={'commessa.sede.azienda.rivenditore.nome'} caption={'RIVENDITORE'} visible={false}>
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'Azienda', 'rivenditore.nome')}>
                <Search enabled={true} searchExpr={'rivenditore.nome'} />
              </HeaderFilter>
            )}
          </Column>
          {DateColumn({
            dataField: 'creazione',
            caption: 'DATA',
            format: 'dd/MM/yyyy',
            hidingPriority:
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 13 : undefined,
          })}
          <Column
            dataField="importo"
            dataType="number"
            caption="IMPORTO"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 10 : undefined
            }
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'ordine', 'importo', true)}></HeaderFilter>
            )}
          </Column>
          {DateColumn({
            dataField: 'evasione',
            caption: 'DATA EVASIONE',
            format: 'dd/MM/yyyy',

            hidingPriority:
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 11 : undefined,
          })}
          <Column
            dataField="offerta.ded_Dis"
            cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
              const data: ordine = cellData.row.data
              return isUserRoleAllowed(userInfo?.roles, [...defaultCRUDAllowedRoles, Roles.Marketing]) ? (
                <>
                  <Link to={`/offers/${data.offerta?.id}`}>{data.offerta?.ded_Dis}</Link>
                </>
              ) : (
                <>{data.offerta?.ded_Dis}</>
              )
            }}
            caption="OFFERTA"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 7 : undefined
            }
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'ordine', 'offerta.ded_Dis', true)}>
                <Search enabled={true} searchExpr={'offerta.ded_Dis'} />
              </HeaderFilter>
            )}
          </Column>
          <Column
            dataField="quota.number"
            cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
              const data: ordine = cellData.row.data
              return isUserRoleAllowed(userInfo?.roles, [...defaultCRUDAllowedRoles, Roles.Marketing]) ? (
                <>
                  <Link to={`/quotes/${data.quota?.id}`}>{data.quota?.number}</Link>
                </>
              ) : (
                <>{data.quota?.number}</>
              )
            }}
            caption="QUOTA"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 6 : undefined
            }
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'ordine', 'quota.number')}>
                <Search enabled={true} searchExpr={'quota.number'} />
              </HeaderFilter>
            )}
          </Column>
          <Column
            dataField="commessa.ded_Dis"
            cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
              const data: ordine = cellData.row.data
              return isUserRoleAllowed(userInfo?.roles, [
                ...defaultCRUDAllowedRoles,
                Roles.Marketing,
                Roles.TechDeveloper,
              ]) ? (
                <>
                  <Link to={`/projects/${data.commessa?.id}`}>{data.commessa?.ded_Dis}</Link>
                </>
              ) : (
                <>{data.commessa?.ded_Dis}</>
              )
            }}
            caption="COMMESSA"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 5 : undefined
            }
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'ordine', 'commessa.ded_Dis')}>
                <Search enabled={true} searchExpr={'commessa.ded_Dis'} />
              </HeaderFilter>
            )}
          </Column>
          <Column
            dataField="attivitaTecnicaRichiesta"
            caption="ATTIVITA' RICHIESTA"
            allowHeaderFiltering={false}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 4 : undefined
            }
          ></Column>
          <Column
            dataField="hasTasks"
            dataType="boolean"
            caption="TASK"
            allowHeaderFiltering={false}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 3 : undefined
            }
          ></Column>
          <Toolbar>
            <Item
              location="before"
              locateInMenu="never"
              showText="inMenu"
              widget="dxButton"
              options={{
                hint: 'Refresh',
                text: 'Refresh',
                icon: 'refresh',
                onClick: (e: ClickEvent) => {
                  gridRef.current?.instance().refresh()
                },
                stylingMode: 'text',
              }}
            ></Item>
            <Item name="groupPanel" />
            <Item
              visible={isUserRoleAllowed(userInfo?.roles, [
                Roles.GlobalAdministrator,
                Roles.Administrator,
                Roles.Supervisor,
              ])}
              locateInMenu={
                currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium
                  ? 'always'
                  : 'auto'
              }
              widget="dxButton"
              options={{
                hint: 'Ordine Consuntivo',
                text: 'Ordine Consuntivo',
                icon: 'plus',
                onClick: (e: ClickEvent) => {
                  navigate({
                    pathname: 'new',
                    search: `?isConsuntivo=${true}`,
                  })
                },
                stylingMode: 'contained',
                type: 'success',
              }}
            ></Item>
            <Item
              visible={isUserRoleAllowed(userInfo?.roles, [Roles.GlobalAdministrator, Roles.ExternalSales])}
              locateInMenu={
                currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium
                  ? 'always'
                  : 'auto'
              }
              widget="dxButton"
              options={{
                hint: 'Ordine Rivenditore',
                text: 'Ordine Rivenditore',
                icon: 'plus',
                onClick: (e: ClickEvent) => {
                  navigate(`newresellerorder`)
                },
                stylingMode: 'contained',
                type: 'default',
              }}
            ></Item>
            <Item
              visible={isUserRoleAllowed(userInfo?.roles, [Roles.GlobalAdministrator, Roles.Administrator])}
              locateInMenu={
                currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium
                  ? 'always'
                  : 'auto'
              }
              showText="inMenu"
              widget="dxButton"
              options={{
                hint: 'Evadi ordine',
                text: 'Evadi ordine',
                icon: 'check',
                onClick: onEvadiClick,
                stylingMode: 'text',
              }}
            ></Item>
            <Item
              visible={isUserRoleAllowed(userInfo?.roles, [Roles.GlobalAdministrator, Roles.Administrator])}
              locateInMenu={
                currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium
                  ? 'always'
                  : 'auto'
              }
              showText="inMenu"
              widget="dxButton"
              options={{
                hint: 'Annulla ordine',
                text: 'Annulla ordine',
                icon: 'remove',
                onClick: onAnnullaClick,
                stylingMode: 'text',
              }}
            ></Item>
            <Item
              visible={isUserRoleAllowed(userInfo?.roles, [
                Roles.GlobalAdministrator,
                Roles.Administrator,
                Roles.Supervisor,
                Roles.ExternalSales,
              ])}
              locateInMenu={
                currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium
                  ? 'always'
                  : 'auto'
              }
              showText="inMenu"
              widget="dxButton"
              options={{
                hint: 'Elimina ordine',
                text: 'Elimina ordine',
                icon: 'trash',
                onClick: onDeleteClick,
                stylingMode: 'text',
              }}
            ></Item>
            <Item
              name="columnChooserButton"
              locateInMenu={
                currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium
                  ? 'always'
                  : 'auto'
              }
              showText="inMenu"
              options={{ hint: 'Scelta colonne', text: 'Scelta colonne' }}
            />
            <Item
              name="exportButton"
              locateInMenu={
                currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium
                  ? 'always'
                  : 'auto'
              }
              showText="inMenu"
              options={{ hint: 'Esporta dati in excel', text: 'Esporta dati in excel' }}
            />
            <Item name="searchPanel" />
          </Toolbar>
        </DataGrid>
      </div>
    </>
  )
}

export default OrdersPage
