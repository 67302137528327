import { LoaderFunction } from 'react-router-typesafe'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { RoleRouteObject, RouteFunctionParams } from '@/types'
import { Roles } from '@/auth/azure/Roles'
import ContactsUnifier from '@/routes/contacts/ContactsUnifier'

export const ContactsUnifierLoader = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  const clientId = params.clientId

  const clients = await useQsAdminApiManager
    .getState()
    .service.azienda()
    .query((builder, azienda) => {
      builder.filter(clientId ? azienda.id.eq(Number(clientId)) : null)
      builder.filter(azienda.sedi.any())
      builder.orderBy(azienda.nome.asc())
      builder.select('id', 'nome')
    })

  const title =
    clients.data.value.length === 1 ? `${clients.data.value[0].nome} - Unifica contatti` : 'Unifica contatti'
  return {
    title,
    clients,
  }
}) satisfies LoaderFunction

export const ContactsUnifierRoute = {
  path: 'unifier',
  loader: ContactsUnifierLoader,
  element: <ContactsUnifier />,
  allowedRoles: [Roles.GlobalAdministrator, Roles.Administrator, Roles.Supervisor, Roles.Sales, Roles.ExternalSales],
} satisfies RoleRouteObject
