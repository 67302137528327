import { Form as DXForm, GroupItem, SimpleItem, ButtonItem, ButtonOptions, FormRef } from 'devextreme-react/form'
import { FormFilterItemDescriptorType, GenericFilterFormProps } from '@/components/filter-form/GenericFilterForm.types'
import { useEffect, useRef, useState } from 'react'
import { ArraySourceComposer } from '@/auth/api/connector'
import { ValueChangedEvent } from 'devextreme/ui/select_box'
import { useLocation } from 'react-router-dom'
import { ClickEvent } from 'devextreme/ui/button'

const GenericFilterForm = (props: GenericFilterFormProps) => {
  const formRef = useRef<FormRef>(null)
  const [formData, setFormData] = useState<Record<string, unknown>>({})
  const location = useLocation()

  // Function to create the data object from FormItemDescriptors
  const createFormDataObject = (formItemDescriptors: FormFilterItemDescriptorType[]): Record<string, unknown> => {
    const dataObject: Record<string, unknown> = {}
    for (const item of formItemDescriptors) {
      const localStorageValue = localStorage.getItem(`${location.pathname}-${item.fieldName}-filter`)
      if (localStorageValue !== undefined && localStorageValue !== null) {
        item.currentValue = localStorageValue === '' ? undefined : JSON.parse(localStorageValue)
      } else if (item.defaultValue !== undefined && item.defaultValue !== null) item.currentValue = item.defaultValue
      dataObject[item.fieldName] = item.values.find(
        (itemValue: any) => itemValue[item.valueKeyName] === item.currentValue,
      )
    }
    return dataObject
  }

  useEffect(() => {
    const dataObject = createFormDataObject(props.FormItemDescriptors)
    setFormData(dataObject)
    console.log('GENFLT - FORM DATA', dataObject)
  }, [props.FormItemDescriptors])

  return (
    <>
      <DXForm id="filter-form" ref={formRef} formData={formData}>
        <GroupItem colCount={5} colSpan={1}>
          {props.FormItemDescriptors.map((itemDescriptor) => (
            <SimpleItem
              key={itemDescriptor.fieldName}
              name={itemDescriptor.fieldName}
              dataField={itemDescriptor.fieldName}
              label={{ visible: false }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer(itemDescriptor.valueKeyName, itemDescriptor.values),
                placeholder: itemDescriptor.placeHolder,
                displayExpr: itemDescriptor.valueDisplayExpr,
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  hideOnOutsideClick: true,
                },
                onValueChanged: (e: ValueChangedEvent) => {
                  console.log(e)
                  console.log('GENFLT - VALUE NEWFLT INNER CHANGED', e.value, e.previousValue)
                  if (e.previousValue === e.value) return
                  itemDescriptor.currentValue = e.value ? e.value[itemDescriptor.valueKeyName] : undefined
                  console.log('GENFLT - VALUE NEWFLT RECOMPUTED', itemDescriptor.currentValue)
                  localStorage.setItem(
                    `${location.pathname}-${itemDescriptor.fieldName}-filter`,
                    itemDescriptor.currentValue !== undefined && itemDescriptor.currentValue !== null
                      ? JSON.stringify(itemDescriptor.currentValue)
                      : '',
                  )
                  props.filterValueChanged()
                },
                clearButtonText: 'Clear',
                showClearButton: true,
              }}
            ></SimpleItem>
          ))}
        </GroupItem>
        <GroupItem cssClass="buttons-group" colCount={1}>
          <ButtonItem name="btn-resetFilter">
            <ButtonOptions
              text="reset"
              icon="revert"
              useSubmitBehavior={false}
              width={'150px'}
              onClick={(e: ClickEvent) => {
                for (const itemDescriptor of props.FormItemDescriptors) {
                  localStorage.removeItem(`${location.pathname}-${itemDescriptor.fieldName}-filter`)
                }
                props.resetFilterClicked(e)
              }}
            />
          </ButtonItem>
        </GroupItem>
      </DXForm>
    </>
  )
}

export default GenericFilterForm
