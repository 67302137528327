import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { SoftwareAdskCreatorRoute, SoftwareAdskEditorRoute } from '@/routes/software/adsk/SoftwareAdskEditor.route'
import SoftwareAdskGrid from '@/routes/software/adsk/SoftwareAdskGrid'
import { LoaderFunction } from 'react-router-dom'
import { RoleRouteObject, RouteFunctionParams } from '@/types'
import { Roles } from '@/auth/azure/Roles'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import { azienda } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { ODataModelResponseV4 } from '@odata2ts/odata-core'
import { HttpResponseModel } from '@odata2ts/http-client-api'

export const SoftwareAdskGridLoader = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const clientId = params.clientId
  let azienda: HttpResponseModel<ODataModelResponseV4<azienda>> | undefined
  if (clientId)
    azienda = await qsAdminApi.azienda(Number(clientId)).query((builder, azienda) => {
      builder.select('id', 'nome')
    })

  const title = azienda ? `${azienda.data.nome} - Software Adsk` : 'Software Adsk'

  const getAziende = qsAdminApi.azienda().query((builder, qazienda) => {
    builder.orderBy(qazienda.nome.asc())
    builder.select('id', 'nome')
  })

  return {
    title,
    aziendaList: await getAziende,
    userInfo: await getAzureUserInformation(),
  }
}) satisfies LoaderFunction

export const SoftwareAdskRoute = {
  path: 'autodesk',
  children: [
    {
      index: true,
      loader: SoftwareAdskGridLoader,
      element: <SoftwareAdskGrid />,
      allowedRoles: [Roles.Guest],
    } as RoleRouteObject,
    SoftwareAdskCreatorRoute,
    SoftwareAdskEditorRoute,
  ],

  icon: 'codeblock',
  text: 'Software Adsk',
  allowedRoles: [Roles.Guest],
} as RoleRouteObject
