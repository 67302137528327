import { DataGridTypes } from 'devextreme-react/cjs/data-grid'
import { Form as DXForm, GroupItem, SimpleItem, Tab, TabbedItem, TabPanelOptions } from 'devextreme-react/form'
import type { task } from '@/model/qsadminapi/QsAdminApiModuleModel'
import DocumentList from '@/components/document/DocumentList'
import { DocumentPermissions } from '@/enums'
import { useRef } from 'react'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'

const TaskBriefDetail = (props: DataGridTypes.MasterDetailTemplateData) => {
  const task: task = props.data.data
  const documentListRef = useRef<any>(null)
  const { client } = useQsAdminApiManager()

  return (
    <div className={'dx-card responsive-paddings'}>
      <DXForm id={'task_brief_detail_form'} formData={task} labelLocation={'top'} readOnly={true}>
        <GroupItem colCount={5} colSpan={5}>
          <GroupItem colCount={1} colSpan={5}>
            <TabbedItem>
              <TabPanelOptions selectedIndex={1} />
              <Tab title="Allegati">
                <DocumentList
                  title="Allegati"
                  folderPath={task.attachmentsFolderUrl}
                  documentListGetRef={documentListRef}
                  preliminarPermissionsAction={async () => {
                    try {
                      const response = await client.post(
                        '/api/task/drivepermissions',
                        {
                          objectId: task?.id,
                          path: task?.attachmentsFolderUrl,
                        },
                        {
                          headers: {
                            'Content-Type': 'application/json',
                          },
                        },
                      )
                      return response.status === 200 ? (response.data as DocumentPermissions) : DocumentPermissions.Deny
                    } catch (error) {
                      console.log('Errore in settaggio permessi sulla cartella allegati task', error)
                      return DocumentPermissions.Deny
                    }
                  }}
                  readOnly={true}
                />
              </Tab>
              <Tab title="Note Ordine">
                <SimpleItem
                  dataField={'ordine.note'}
                  editorType={'dxTextArea'}
                  label={{ visible: false }}
                  editorOptions={{ height: 200 }}
                />
              </Tab>
              <Tab title="Note">
                <SimpleItem
                  dataField={'note'}
                  editorType={'dxHtmlEditor'}
                  label={{ visible: false }}
                  editorOptions={{
                    stylingMode: 'filled',
                    readOnly: true,
                    valueType: 'html',
                  }}
                ></SimpleItem>
              </Tab>
              <Tab title="Note Completamento">
                <SimpleItem
                  dataField={'note_completamento'}
                  editorType={'dxHtmlEditor'}
                  label={{ visible: false }}
                  editorOptions={{
                    stylingMode: 'filled',
                    readOnly: true,
                    valueType: 'html',
                  }}
                ></SimpleItem>
              </Tab>
              <Tab title="Note Cliente">
                <SimpleItem
                  dataField={'sede.azienda.note'}
                  editorType={'dxHtmlEditor'}
                  label={{ visible: false }}
                  editorOptions={{
                    stylingMode: 'filled',
                    readOnly: true,
                    valueType: 'html',
                  }}
                ></SimpleItem>
              </Tab>
            </TabbedItem>
          </GroupItem>
        </GroupItem>
      </DXForm>
    </div>
  )
}

export default TaskBriefDetail
