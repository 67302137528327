import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { taskEditorRouteLoader } from '@/routes/tasks/TaskEditor.route'
import { TaskEditorProps } from '@/routes/tasks/TaskEditor.types'
import { Suspense, useRef } from 'react'
import { useFetcher, useParams } from 'react-router-dom'
import { Await, useLoaderData } from 'react-router-typesafe'
import { Form as DXForm, FormRef, GroupItem, SimpleItem } from 'devextreme-react/form'
import AttachmentsManager from '@/components/file-manager/AttachmentsManager'
import TaskEditorForm from '@/routes/tasks/TaskEditor.form'
import { FileManagerRef } from 'devextreme-react/file-manager'
import { ordine, punto_fatturazione_ordine } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { isUserRoleAllowed } from '@/routes/utils'
import { DocumentPermissions } from '@/enums'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import DocumentHead from '@/components/document-head/DocumentHead'

const TaskEditor = (props: TaskEditorProps) => {
  const { creating } = props
  const {
    title,
    task,
    getAziende,
    getStati,
    getQsImpiegati,
    getOrdini,
    getPuntiFatturazione,
    userInfo,
    defaultCRUDAllowedRoles,
  } = useLoaderData<typeof taskEditorRouteLoader>()
  const { clientId } = useParams()
  const fetcher = useFetcher()

  const fileManagerRef = useRef<FileManagerRef>(null)
  const formDetailsRef = useRef<FormRef>(null)
  const { client } = useQsAdminApiManager()
  return (
    <>
      <DocumentHead title={title}></DocumentHead>
      <Suspense fallback={<LoadingPanel />}>
        <Await resolve={Promise.all([getStati, getAziende, getQsImpiegati, getOrdini, getPuntiFatturazione])}>
          {([stati, aziende, impiegati, ordini, puntiFatturazione]) => (
            <>
              <h2 className={clientId ? '' : 'content-block'}>{title}</h2>
              <div
                id="editor-task-container"
                className={clientId ? 'dx-card responsive-paddings' : 'content-block dx-card responsive-paddings'}
              >
                <fetcher.Form>
                  <TaskEditorForm
                    creating={creating}
                    task={task}
                    stati={stati.data.value}
                    aziende={aziende.data.value}
                    impiegati={impiegati.data.value}
                    ordini={ordini?.data.value}
                    puntiFatturazione={puntiFatturazione?.data.value}
                    isRoleAllowed={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
                    userInfo={userInfo}
                    fetcher={fetcher}
                    onOrderChanged={(newValue: ordine | undefined | null) => {
                      formDetailsRef.current?.instance().updateData('ordine.note', newValue?.note)
                    }}
                    onPuntoFatturazioneChanged={(newValue: punto_fatturazione_ordine | undefined | null) => {
                      formDetailsRef.current?.instance().updateData('punto_fatturazione.note', newValue?.note)
                    }}
                  />
                </fetcher.Form>
              </div>
              <div
                id="allegati-task-container"
                className={clientId ? 'dx-card responsive-paddings' : 'content-block dx-card responsive-paddings'}
              >
                <DXForm id="form-allegati" ref={formDetailsRef}>
                  <GroupItem colCount={creating ? 1 : 2}>
                    <GroupItem colCount={2}>
                      <GroupItem caption="Note Ordine">
                        <SimpleItem
                          dataField={'ordine.note'}
                          editorType={'dxTextArea'}
                          label={{ visible: false }}
                          editorOptions={{
                            value: task.ordine?.note,
                            readOnly: true,
                            height: '400',
                          }}
                        ></SimpleItem>
                      </GroupItem>
                      <GroupItem caption="Note Punto di Fatturazione">
                        <SimpleItem
                          dataField={'punto_fatturazione.note'}
                          editorType={'dxTextArea'}
                          label={{ visible: false }}
                          editorOptions={{
                            value: task.punto_fatturazione?.note,
                            readOnly: true,
                            height: '400',
                          }}
                        ></SimpleItem>
                      </GroupItem>
                    </GroupItem>
                    {!creating && (
                      <GroupItem caption="Allegati">
                        <AttachmentsManager
                          fileManagerRef={fileManagerRef}
                          folderUrl={task?.attachmentsFolderUrl}
                          preliminarPermissionsAction={async () => {
                            try {
                              const response = await client.post(
                                '/api/task/drivepermissions',
                                {
                                  objectId: task.id,
                                  path: task.attachmentsFolderUrl,
                                },
                                {
                                  headers: {
                                    'Content-Type': 'application/json',
                                  },
                                },
                              )
                              return response.status === 200
                                ? (response.data as DocumentPermissions)
                                : DocumentPermissions.Deny
                            } catch (error) {
                              console.log('Errore in settaggio permessi sulla cartella allegati task', error)
                              return DocumentPermissions.Deny
                            }
                          }}
                          createDirectoryAction={async (
                            rootFolderPath: string,
                            name: string,
                            conflictBehavior: string,
                          ) => {
                            return await client.post(
                              '/api/task/createfolder',
                              {
                                rootFolder: rootFolderPath,
                                newFolderName: name,
                                conflictBehavior,
                                objectId: task.id,
                              },
                              {
                                headers: {
                                  'Content-Type': 'application/json',
                                },
                              },
                            )
                          }}
                        />
                      </GroupItem>
                    )}
                  </GroupItem>
                </DXForm>
              </div>
            </>
          )}
        </Await>
      </Suspense>
    </>
  )
}

export default TaskEditor
