import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { offerta, ordine, azienda, commessa } from '@/model/qsadminapi/QsAdminApiModuleModel'
import OrderEditor from '@/routes/orders/OrderEditor'
import { RoleRouteObject, RouteFunctionParams } from '@/types'
import { redirect } from 'react-router-dom'
import { LoaderFunction, ActionFunction, defer } from 'react-router-typesafe'
import { ODataModelResponseV4, ODataCollectionResponseV4 } from '@odata2ts/odata-core'
import { HttpResponseModel, ODataResponse } from '@odata2ts/http-client-api'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import { Roles } from '@/auth/azure/Roles'
import { StatoOrdine } from '@/routes/orders/order.types'
import { StatoOfferta } from '@/routes/offers/OfferEditor.enums'
import { StatoCommessa } from '@/routes/projects/ProjectEditor.enums'

export const orderEditorRouteLoader = (async ({ request, params }: RouteFunctionParams<'orderId' | 'clientId'>) => {
  const orderId = params.orderId
  const clientId = params.clientId
  const offerId = new URL(request.url).searchParams.get('offerId')
  const isConsuntivo = new URL(request.url).searchParams.get('isConsuntivo') === 'true'

  const qsAdminApi = useQsAdminApiManager.getState().service

  let getOfferte: ODataResponse<ODataCollectionResponseV4<offerta>> | undefined
  let getCommesse: ODataResponse<ODataCollectionResponseV4<commessa>> | undefined

  let azienda: HttpResponseModel<ODataModelResponseV4<azienda>> | undefined
  let title = azienda ? `${azienda.data.nome} - ` : ``
  let order: ordine

  const userInfo = await getAzureUserInformation()
  const user = await qsAdminApi.user().query((builder, user) => {
    builder.select('id', 'fullname', 'email', 'azienda')
    builder.expanding('azienda', (riferimentiBuilder, azienda) => {
      riferimentiBuilder.select('id', 'nome')
    })
    builder.filter(user.email.eq(userInfo?.email ?? ''))
  })

  if (clientId)
    azienda = await qsAdminApi.azienda(Number(clientId)).query((builder, azienda) => {
      builder.select('id', 'nome')
    })

  if (orderId) {
    //editor
    const getOrder = await qsAdminApi.ordine(Number(orderId)).query((builder, ordine) => {
      builder.expanding('stato', (statoBuilder, stato) => {
        statoBuilder.select('id', 'nome')
      })
      builder.expand('offerta')
      builder.expand('quota')
      builder.expanding('commessa', (commessaBuilder, commessa) => {
        commessaBuilder.expanding('sede', (sedeCommessaBuilder, sedeCommessa) => {
          sedeCommessaBuilder.expanding('azienda', (aziendaSedeCommessaBuilder, aziendaSede) => {
            aziendaSedeCommessaBuilder.expanding('agente', (agenteBuilder, agente) => {
              agenteBuilder.expanding('commerciale_qs', (commercialeBuilder, commerciale) => {
                commercialeBuilder.select('id', 'fullname')
              })
              agenteBuilder.select('id', 'commerciale_qs')
            })
            aziendaSedeCommessaBuilder.expanding('rivenditore', (rivenditoreBuilder, rivenditore) => {
              rivenditoreBuilder.select('id', 'nome')
            })
            aziendaSedeCommessaBuilder.select('id', 'nome', 'agente', 'rivenditore')
          })
          sedeCommessaBuilder.select('id', 'nome', 'azienda', 'note')
        })
        commessaBuilder.select('id', 'ded_Dis', 'titolo', 'sede')
      })
    })
    order = getOrder.data satisfies ordine
    title += `${order.ded_Dis}`
    if (clientId && order?.commessa?.sede?.azienda && order?.commessa?.sede?.azienda.id !== Number(clientId)) {
      throw new Error(
        `L'ordine ${order.ded_Dis} appartiene al cliente ${order?.commessa?.sede?.azienda.nome} e non al cliente corrente`,
      )
    }
  } else {
    //creator
    title += `Nuovo ordine`
    let getOffer: HttpResponseModel<ODataModelResponseV4<offerta>> | undefined
    if (offerId) {
      //creator a partire da offerta
      getOffer = await qsAdminApi.offerta(Number(offerId)).query((builder, offer) => {
        builder.expanding('commessa', (commessaBuilder, commessa) => {
          commessaBuilder.expanding('sede', (sedeBuilder, sede) => {
            sedeBuilder.expanding('azienda', (aziendaBuilder, azienda) => {
              aziendaBuilder.select('id', 'nome', 'agente', 'rivenditore')
              aziendaBuilder.expanding('agente', (agenteBuilder, agente) => {
                agenteBuilder.expanding('commerciale_qs', (commercialeBuilder, commerciale) => {
                  commercialeBuilder.select('id', 'fullname')
                })
                agenteBuilder.select('id', 'commerciale_qs')
              })
              aziendaBuilder.expanding('rivenditore', (rivenditoreBuilder, rivenditore) => {
                rivenditoreBuilder.select('id', 'nome')
              })
            })
            sedeBuilder.select('id', 'nome', 'azienda', 'note')
          })
          commessaBuilder.select('id', 'ded_Dis', 'titolo', 'sede')
        })
      })
    }
    const getStatoDefault = await qsAdminApi
      .stato_ordine(isConsuntivo || offerId ? StatoOrdine['DA EVADERE'] : StatoOrdine['PROVVISORIO'])
      .query((builder, stato) => {
        builder.select('id', 'nome')
      })
    order = {
      id: 0,
      ded_Dis: '',
      ded_RootFam: '',
      ded_SubFam: '',
      ded_Id: 0,
      creazione: new Date().toISOString(),
      evasione: null,
      importo: 0,
      note: null,
      note_evasione: null,
      anno_rif: new Date().getFullYear(),
      stato: getStatoDefault.data,
      offerta: getOffer?.data,
      quota: null,
      commessa: getOffer?.data.commessa,
      tasks: [],
      attachmentsFolderUrl: '',
      mainDocumentUrl: '',
      attivitaTecnicaRichiesta: getOffer ? getOffer.data.attivitaTecnicaRichiesta : false,
      hasTasks: false,
      consuntivo: isConsuntivo,
    }
  }

  if (order.commessa?.sede) {
    getOfferte = qsAdminApi.offerta().query((builder, offerta) => {
      builder.filter(offerta.sede.props.id.eq(Number(order.commessa?.sede?.id)))
      builder.filter(
        offerta.stato.props.id
          .eq(StatoOfferta.APERTA)
          .or(order.offerta ? offerta.id.eq(Number(order.offerta?.id)) : null),
      )
      builder.select('id', 'ded_Dis')
      builder.orderBy(offerta.ded_Dis.desc())
    })
    getCommesse = qsAdminApi.commessa().query((builder, commessa) => {
      builder.filter(commessa.sede.props.id.eq(Number(order.commessa?.sede?.id)))
      builder.filter(
        commessa.stato.props.id
          .eq(StatoCommessa.APERTA)
          .or(order.commessa ? commessa.id.eq(Number(order.commessa?.id)) : null),
      )
      builder.select('id', 'ded_Dis', 'titolo')
      builder.orderBy(commessa.ded_Dis.desc())
    })
  }

  const getStati = qsAdminApi.stato_ordine().query((builder, stato) => {
    builder.select('id', 'nome')
    builder.orderBy(stato.nome.asc())
  })

  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.filter(azienda.sedi.any())
    builder.filter(clientId ? azienda.id.eq(Number(clientId)) : null)
    if (userInfo?.roles?.hasRole(Roles.Sales))
      builder.filter(
        azienda.agente.props.commerciale_qs.props.id
          .eq(Number(user.data.value[0].id))
          .or(order.commessa?.sede ? azienda.id.eq(Number(order.commessa.sede.azienda?.id)) : null),
      )
    if (userInfo?.roles?.hasRole(Roles.ExternalSales))
      builder.filter(
        azienda.rivenditore.props.id
          .eq(Number(user.data.value[0].azienda?.id))
          .or(order.commessa?.sede ? azienda.id.eq(Number(order.commessa.sede.azienda?.id)) : null),
      )
    builder.orderBy(azienda.nome.asc())
    builder.select('id', 'nome')
  })

  return defer({
    title,
    order,
    getStati,
    getAziende,
    getOfferte,
    getCommesse,
    userInfo,
    defaultCRUDAllowedRoles: [
      Roles.GlobalAdministrator,
      Roles.Administrator,
      Roles.Supervisor,
      Roles.Sales,
      Roles.ExternalSales,
    ],
  })
}) satisfies LoaderFunction

export const orderEditorRouteAction = (async ({ request }: RouteFunctionParams) => {
  switch (request.method) {
    case 'POST': {
      const orderFormData = await request.formData()
      console.log('orderFormData', orderFormData)
      const client = useQsAdminApiManager.getState().client
      const res = await client.post('/api/ordine/create', orderFormData)
      console.log('ORDER RES', res)
      return redirect(`./../${res.data.id}`)
    }
    case 'PUT':
    case 'PATCH': {
      const order = (await request.json()) as ordine
      const qsAdminApi = useQsAdminApiManager.getState().service
      return await qsAdminApi.ordine(order.id).update(order)
    }
    default: {
      throw new Response('Method not allowed', {
        status: 405,
        statusText: 'Method not allowed',
      })
    }
  }
}) satisfies ActionFunction

export const restoreOrderAction = (async ({ request, params }: RouteFunctionParams) => {
  const order = (await request.json()) as ordine
  const qsAdminApi = useQsAdminApiManager.getState().service
  return await qsAdminApi.ordine(order.id).RestoreOrder()
}) satisfies ActionFunction

const OrderEditorRoute = {
  path: ':orderId',
  element: <OrderEditor creating={false} isReseller={false} />,
  children: [
    {
      path: 'restore',
      action: restoreOrderAction,
      allowedRoles: [Roles.GlobalAdministrator, Roles.Administrator],
    } as RoleRouteObject,
  ],
  loader: orderEditorRouteLoader,
  action: orderEditorRouteAction,
  allowedRoles: [Roles.Guest],
} as RoleRouteObject

export default OrderEditorRoute
