import { useCallback, useState } from 'react'
import {
  Column,
  DataGrid,
  type DataGridTypes,
  Editing,
  Lookup,
  Paging,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid'
import type { InitNewRowEvent, SavedEvent } from 'devextreme/ui/data_grid'
import { TagBox, type TagBoxTypes } from 'devextreme-react/tag-box'
import { AxiosError } from 'axios'
import type { AxiosResponse } from 'axios'
import notify from 'devextreme/ui/notify'
import { Button } from 'devextreme-react/button'
import { useLoaderData } from 'react-router-typesafe'
import { supportQuotePageRouteLoader } from '@/routes/support/SupportQuotePage.route'
import { ArraySourceComposer } from '@/auth/api/connector'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { ad_product_catalog } from '@/model/qsadminapi/QsAdminApiModuleModel'
import DocumentHead from '@/components/document-head/DocumentHead'

type suppQuoteSourceType = {
  qs: qsNewSuppQuoteSourceType[]
  adsk: adskNewSuppQuoteSourceType[]
  platform: pltNewSuppQuoteSourceType[]
}

type qsNewSuppQuoteSourceType = {
  id?: number
  applicazioni?: string[]
  postazioni?: number
  net: boolean
}

type adskNewSuppQuoteSourceType = {
  id?: number
  nome?: string
  postazioni?: number
}

type pltNewSuppQuoteSourceType = {
  id?: number
  piattaforma?: string
  postazioni?: number
}

type supportQuoteResultType = {
  qs: {
    total: number
    missingPrices: string[]
    missingProducts: string[]
  }
  adsk: {
    total: number
    missingPrices: string[]
    missingProducts: string[]
  }
  platform: {
    total: number
    missingPrices: string[]
    missingProducts: string[]
  }
  total: number
}

export default function SupportQuotePage() {
  const { licenseQsApps, adskApps, platformQsApps } = useLoaderData<typeof supportQuotePageRouteLoader>()
  const [suppQuoteSource] = useState<suppQuoteSourceType>({
    qs: [],
    adsk: [],
    platform: [],
  })
  const [qsNewSuppQuoteSource] = useState<qsNewSuppQuoteSourceType[]>(suppQuoteSource?.qs)
  const [adskNewSuppQuoteSource] = useState<adskNewSuppQuoteSourceType[]>(suppQuoteSource?.adsk)
  const [platformNewSuppQuoteSource] = useState<pltNewSuppQuoteSourceType[]>(suppQuoteSource?.platform)
  const [supportQuoteResult, setSupportQuoteResult] = useState<supportQuoteResultType>({
    qs: { total: 0, missingPrices: [], missingProducts: [] },
    adsk: { total: 0, missingPrices: [], missingProducts: [] },
    platform: { total: 0, missingPrices: [], missingProducts: [] },
    total: 0,
  })
  const title = 'Nuovo Preventivo Assistenza Telefonica'

  const cellQsAppTemplate = useCallback(
    (container: { innerHTML: string }, options: any) => {
      const noBreakSpace = '\u00A0'
      let productValues = ''
      options.value?.forEach((element: string) => {
        if (supportQuoteResult.qs.missingPrices.includes(element)) {
          productValues += `<span style="background-color:yellow">${element}</span>`
        } else if (supportQuoteResult.qs.missingProducts.includes(element)) {
          productValues += `<span style="background-color:red">${element}</span>`
        } else {
          productValues += element
        }

        productValues += ' '
      })
      container.innerHTML = productValues.trim() || noBreakSpace
    },
    [supportQuoteResult.qs.missingPrices, supportQuoteResult.qs.missingProducts],
  )

  const cellAutodeskTemplate = useCallback(
    (container: { innerHTML: string }, options: any) => {
      const noBreakSpace = '\u00A0'

      let productValue = ''

      if (supportQuoteResult.adsk.missingPrices.includes(options.value)) {
        productValue = `<span style="background-color:yellow">${options.value}</span>`
      } else if (supportQuoteResult.adsk.missingProducts.includes(options.value)) {
        productValue = `<span style="background-color:red">${options.value}</span>`
      } else {
        productValue = options.value
      }

      container.innerHTML = productValue || noBreakSpace
    },
    [supportQuoteResult.adsk.missingPrices, supportQuoteResult.adsk.missingProducts],
  )

  const cellPlatformTemplate = useCallback(
    (container: { innerHTML: string }, options: any) => {
      const noBreakSpace = '\u00A0'

      let productValue = ''

      if (supportQuoteResult.platform.missingPrices.includes(options.value)) {
        productValue = `<span style="background-color:yellow">${options.value}</span>`
      } else if (supportQuoteResult.platform.missingProducts.includes(options.value)) {
        productValue = `<span style="background-color:red">${options.value}</span>`
      } else {
        productValue = options.value
      }

      container.innerHTML = productValue || noBreakSpace
    },
    [supportQuoteResult.platform.missingPrices, supportQuoteResult.platform.missingProducts],
  )

  const calculateQsTotal = (options: DataGridTypes.CustomSummaryInfo) => {
    if (options.summaryProcess !== 'finalize') return
    options.totalValue = supportQuoteResult.qs.total
  }
  const calculateAdskTotal = (options: DataGridTypes.CustomSummaryInfo) => {
    if (options.summaryProcess !== 'finalize') return
    options.totalValue = supportQuoteResult.adsk.total
  }
  const calculatePlatformTotal = (options: DataGridTypes.CustomSummaryInfo) => {
    if (options.summaryProcess !== 'finalize') return
    options.totalValue = supportQuoteResult.platform.total
  }
  const { client } = useQsAdminApiManager()
  const QuoteGridSaved = (e: SavedEvent) => {
    const body = JSON.stringify(suppQuoteSource)
    console.log('BD', body)
    client
      .post(`/api/supportquote/getnewsupportquote`, body, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response: AxiosResponse) => {
        console.log('support cost result', response.data)
        setSupportQuoteResult(response.data)
        notify(`Preventivo assistenza telefonica aggiornato con successo`, 'success', 2000)
      })
      .catch((error: AxiosError) => {
        notify(`Errore in fase di calcolo preventivo assistenza telefonica. Dettagli : ${error}`, 'error', 2000)
        console.log(`Errore in fase di calcolo preventivo assistenza telefonica. Dettagli : ${error}`)
      })
  }

  return (
    <>
      <DocumentHead title={title}></DocumentHead>
      <h2 className={'content-block'}>{title}</h2>

      <div className={'content-block'}>
        <h3>Qs Informatica</h3>
        <hr />
        <DataGrid
          id={`sw-qsapps-grid-newsuppquote`}
          dataSource={qsNewSuppQuoteSource}
          keyExpr={'id'}
          showBorders={true}
          showColumnLines={true}
          columnHidingEnabled={false}
          allowColumnReordering={true}
          allowColumnResizing={true}
          rowAlternationEnabled={true}
          wordWrapEnabled={true}
          repaintChangesOnly={true}
          width="100%"
          onSaved={QuoteGridSaved}
          onInitNewRow={(e: InitNewRowEvent) => {
            e.data.applicazioni = []
            e.data.postazioni = 1
            e.data.net = false
          }}
        >
          <Paging defaultPageSize={8} />
          <Editing
            mode="batch"
            allowUpdating={true}
            allowAdding={true}
            allowDeleting={true}
            selectTextOnEditStart={true}
            startEditAction={'dblClick'}
          />
          <Column dataField={'id'} width={'3%'} visible={false} />,
          <Column
            dataField={'applicazioni'}
            caption={'APPLICAZIONI'}
            width={'50%'}
            cellTemplate={cellQsAppTemplate}
            editCellComponent={(props: any) => (
              <TagBox
                dataSource={ArraySourceComposer('id', licenseQsApps.data.value)}
                defaultValue={props.data.value ?? ''}
                valueExpr="nome"
                displayExpr="nome"
                showSelectionControls={true}
                inputAttr={{ 'aria-label': 'Name' }}
                applyValueMode="useButtons"
                searchEnabled={true}
                searchExpr={['nome']}
                onValueChanged={(e: TagBoxTypes.ValueChangedEvent) => {
                  props.data.setValue(e.value)
                }}
              />
            )}
          >
            <Lookup dataSource={licenseQsApps.data.value} displayExpr="nome" valueExpr="nome" />
          </Column>
          <Column dataField={'postazioni'} caption={'POSTI DI LAVORO'} width={'25%'} dataType={'number'} />
          <Column dataField={'net'} caption={'LICENZA DI RETE'} width={'25%'} dataType={'boolean'} />
          <Summary calculateCustomSummary={calculateQsTotal}>
            <TotalItem
              name="QsTotalQuote"
              summaryType="custom"
              displayFormat="Totale : € {0}"
              showInColumn="applicazioni"
            />
          </Summary>
        </DataGrid>
        <h3>Autodesk</h3>
        <hr />
        <DataGrid
          id={`sw-autodesk-grid-newsuppquote`}
          dataSource={adskNewSuppQuoteSource}
          keyExpr={'id'}
          showBorders={true}
          showColumnLines={true}
          columnHidingEnabled={false}
          allowColumnReordering={true}
          allowColumnResizing={true}
          rowAlternationEnabled={true}
          wordWrapEnabled={false}
          repaintChangesOnly={true}
          width="100%"
          onSaved={QuoteGridSaved}
          onInitNewRow={(e: InitNewRowEvent) => {
            e.data.nome = ''
            e.data.postazioni = 1
          }}
        >
          <Paging defaultPageSize={8} />
          <Editing
            mode="batch"
            allowUpdating={true}
            allowAdding={true}
            allowDeleting={true}
            selectTextOnEditStart={true}
            startEditAction={'dblClick'}
          />
          <Column dataField={'id'} width={'3%'} visible={false} />,
          <Column dataField={'nome'} caption={'PRODOTTO'} cellTemplate={cellAutodeskTemplate} width={'50%'}>
            <Lookup
              dataSource={[
                ...new Map(
                  adskApps.data.value.map((product: ad_product_catalog) => [product['offeringName'], product]),
                ).values(),
              ]}
              displayExpr="offeringName"
              valueExpr="offeringName"
            />
          </Column>
          <Column dataField={'postazioni'} caption={'POSTI DI LAVORO'} width={'25%'} dataType={'number'} />
          <Summary calculateCustomSummary={calculateAdskTotal}>
            <TotalItem
              name="AutodeskTotalQuote"
              summaryType="custom"
              displayFormat="Totale : € {0}"
              showInColumn="nome"
            />
          </Summary>
        </DataGrid>
        <h3>Piattaforme</h3>
        <hr />
        <DataGrid
          id={`sw-platform-grid-newsuppquote`}
          dataSource={platformNewSuppQuoteSource}
          keyExpr={'id'}
          showBorders={true}
          showColumnLines={true}
          columnHidingEnabled={false}
          allowColumnReordering={true}
          allowColumnResizing={true}
          rowAlternationEnabled={true}
          wordWrapEnabled={false}
          repaintChangesOnly={true}
          width="100%"
          onSaved={QuoteGridSaved}
          onInitNewRow={(e: InitNewRowEvent) => {
            e.data.piattaforma = ''
            e.data.postazioni = 1
          }}
        >
          <Paging defaultPageSize={8} />
          <Editing
            mode="batch"
            allowUpdating={true}
            allowAdding={true}
            allowDeleting={true}
            selectTextOnEditStart={true}
            startEditAction={'dblClick'}
          />
          <Column dataField={'id'} width={'3%'} visible={false} />,
          <Column dataField={'piattaforma'} caption={'PIATTAFORMA'} cellTemplate={cellPlatformTemplate} width={'50%'}>
            <Lookup dataSource={platformQsApps.data.value} displayExpr="nome" valueExpr="nome" />
          </Column>
          <Column dataField={'postazioni'} caption={'POSTI DI LAVORO'} width={'25%'} dataType={'number'} />
          <Summary calculateCustomSummary={calculatePlatformTotal}>
            <TotalItem
              name="PlatformTotalQuote"
              summaryType="custom"
              displayFormat="Totale : € {0}"
              showInColumn="piattaforma"
            />
          </Summary>
        </DataGrid>
        <h3>Totale</h3>
        <hr />
        <div style={{ float: 'right' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              icon="copy"
              stylingMode={'text'}
              onClick={() => {
                navigator.clipboard.writeText(supportQuoteResult.total.toString()).then(
                  () => {
                    notify(`Preventivo assistenza telefonica copiato con successo`, 'success', 2000)
                  },
                  (error: any) => {
                    console.log('Errore copia preventivo assistenza telefonica', error)
                    notify(`Errore copia preventivo assistenza telefonica`, 'error', 2000)
                  },
                )
              }}
            ></Button>
            <h3 style={{ backgroundColor: 'lightgreen' }}>€ {supportQuoteResult.total}</h3>
          </div>
        </div>
      </div>
    </>
  )
}
