import { useScreenSize } from '@/themes/media-query'
import { Button, DataGrid, Popup } from 'devextreme-react'
import { Accordion, Item as AccordionItem } from 'devextreme-react/accordion'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useFetcher, useNavigate, useParams } from 'react-router-dom'
import { useLoaderData } from 'react-router-typesafe'
import {
  Column,
  ColumnChooser,
  Editing,
  Export,
  FilterRow,
  HeaderFilter,
  Item,
  Scrolling,
  SearchPanel,
  Selection,
  StateStoring,
  Toolbar,
  DataGridTypes,
  MasterDetail,
  DataGridRef,
  Sorting,
  Search,
  FilterPanel,
  Pager,
  Button as GridButton,
} from 'devextreme-react/data-grid'
import { CellPreparedEvent, ColumnButtonClickEvent, ExportingEvent, RowDblClickEvent } from 'devextreme/ui/data_grid'
import ODataStore from 'devextreme/data/odata/store'
import notify from 'devextreme/ui/notify'
import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import { assistenza, stato_ticket, ticket, user } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { GridCellColor } from '@/enums'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import saveAs from 'file-saver'
import { ClickEvent } from 'devextreme/ui/button'
import { ContentReadyEvent } from 'devextreme/ui/accordion'
import {
  createDateFilterItemDescriptor,
  FormFilterItemDescriptorType,
} from '@/components/filter-form/GenericFilterForm.types'
import GenericFilterForm from '@/components/filter-form/GenericFilterForm'
import { activeState, ticketsPageRouteLoader } from '@/routes/tickets/TicketsPage.route'
import { StatoTicket } from '@/routes/tickets/TicketEditor.enums'
import useTokenRefresh, { useAzureManager } from '@/auth/azure/azureManager'
import { confirm } from 'devextreme/ui/dialog'
import TicketBriefDetail from '@/routes/tickets/TicketBriefDetail'
import DateColumn from '@/components/date-column/DateColumn'
import { columnSourceFactory, isUserRoleAllowed } from '@/routes/utils'
import { Roles } from '@/auth/azure/Roles'
import AssignSelector from '@/routes/tickets/AssignSelector'
import { PopupRef } from 'devextreme-react/cjs/popup'
import { gridTotalItemsLabel } from '@/constants'
import Rating from '@/components/Rating/Rating'
import { PhoneNumber } from '@/components/PhoneNumber/PhoneNumber'
import DocumentHead from '@/components/document-head/DocumentHead'

const TicketsPage = () => {
  const token = useTokenRefresh()
  const { title, stati, impiegati, userInfo, defaultCRUDAllowedRoles } = useLoaderData<typeof ticketsPageRouteLoader>()
  const fetcher = useFetcher()

  const navigate = useNavigate()
  const gridRef = useRef<DataGridRef>(null)
  const { clientId } = useParams()
  const currentScreenSize = useScreenSize()
  const getGridHeight = useCallback(() => {
    let h = '79vh'
    if (currentScreenSize.isMedium) {
      h = '75vh'
    } else if (currentScreenSize.isSmall) {
      h = '120vh'
    } else if (currentScreenSize.isXSmall) {
      h = '75vh'
    }
    return h
  }, [currentScreenSize])

  const popupAssignRef = useRef<PopupRef>(null)
  const [assignToData, setAssignToData] = useState<{
    formData: {
      technician: user | null | undefined
    }
    onSubmitEditor: (
      e: any,
      params: {
        ticketId: string | null | undefined
        userId: number | null | undefined
      },
    ) => Promise<void>
    tecniciList: (user | null | undefined)[]
    ticket: ticket | null | undefined
  }>()

  const getFiltersConfiguration = useCallback((): FormFilterItemDescriptorType[] => {
    const fltConf: FormFilterItemDescriptorType[] = [
      {
        fieldName: 'stato',
        placeHolder: 'Stato',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        values: stati,
        defaultValue: stati.find((stato: stato_ticket) => stato.nome === 'ATTIVO')?.id,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return this.currentValue === activeState.id
            ? [['stato/id', StatoTicket.APERTO], 'or', ['stato/id', StatoTicket.ASSEGNATO]]
            : [['stato/id', this.currentValue]]
        },
      },
      {
        fieldName: 'assegnato',
        placeHolder: 'Utente Assegnato',
        valueKeyName: 'id',
        valueDisplayExpr: 'fullname',
        values: impiegati.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['assegnato/id', this.currentValue]]
        },
      },
      createDateFilterItemDescriptor('apertura', 'Data Apertura'),
    ]
    console.log('GENFLT - GETCONF :', fltConf)
    return fltConf
  }, [stati, impiegati.data.value])

  const initialFilterConfiguration = useMemo(() => getFiltersConfiguration(), [getFiltersConfiguration])
  const [filterConfiguration, setFilterConfiguration] = useState(initialFilterConfiguration)
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false)
  const getCurrentFilter = useCallback((): any[] => {
    let applied: boolean = false
    const currentFlts = []
    for (const flt of filterConfiguration) {
      const fltValue = flt.composeFilterItem()
      if (fltValue !== null) {
        currentFlts.push(fltValue)
      }
      if (!applied && flt.currentValue !== flt.defaultValue) applied = true
    }
    setFiltersApplied(applied)
    console.log('GENFLT - CURRENT:', currentFlts)
    return currentFlts
  }, [filterConfiguration])

  const [currentFilter, setCurrentFilter] = useState<any[]>(clientId ? [['sede/azienda/id', Number(clientId)]] : [])

  const ticketsDataSource = {
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/ticket`,
      key: 'id',
      keyType: 'Int32',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    expand: [
      'sede($expand=assistenze($expand=telefonica,maintenance),azienda($expand=agente($expand=commerciale_qs),rivenditore))',
      'tipologia_ticket',
      'assegnato',
      'tipologia_problema',
      'argomento_problema',
      'stato',
      'piattaforma',
      'applicazione',
      'creatore',
      'incaricato',
      'eventi($expand=utente, tipologia)',
      'note_ticket($expand=utente,emails,ticket($expand=assegnato,stato))',
    ],
    filter: currentFilter.length > 0 ? currentFilter : null,
    requireTotalCount: true,
  }

  const ExportDataGridToExcel = (component: any) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Tickets')
    exportDataGrid({ component, worksheet }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Tickets.xlsx')
      })
    })
  }

  const onExporting = (e: ExportingEvent) => {
    ExportDataGridToExcel(e.component)
  }

  const cellPrepared = (e: CellPreparedEvent) => {
    if (e.rowType === 'data' && e.value) {
      if (e.column.dataField === 'assegnato.fullname' && e.value === userInfo?.name)
        e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.LILAC}`

      if (e.column.dataField === 'incaricato.fullname')
        e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.ORANGE}`

      if (e.column.name === 'info_telefonica' || e.column.name === 'info_maintenance') {
        if (e.value === 'ATTIVA') {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.EMERALD}`
        } else if (e.value === 'SCADUTA') {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.SALMON}`
        } else {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.YELLOW}`
        }
      }

      if (e.column.dataField === 'ded_Dis') {
        switch (e.row.data.leg_n_chiamate) {
          case 0: {
            e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.YELLOW}`
            break
          }
          case 1: {
            e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.ORANGE}`
            break
          }
          case 2: {
            e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.SALMON}`
            break
          }
          default: {
            e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: white; background-color: ${GridCellColor.RED}`
            break
          }
        }
      }
    }
    if (e.rowType === 'data' && !e.value && e.column.dataField === 'assegnato.fullname')
      e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.LIGHTGRREN}`
  }

  const computeTelefonicaValue = (rowData: ticket) => {
    let telefonica = ''
    rowData.sede?.assistenze?.map((ass: assistenza) => (telefonica = `${telefonica + ass.telefonica?.nome} `))
    return telefonica.trim()
  }

  const computeMaintenanceValue = (rowData: ticket) => {
    let maintenance = ''
    rowData.sede?.assistenze?.map((ass: assistenza) => (maintenance = `${maintenance + ass.maintenance?.nome} `))
    return maintenance.trim()
  }

  const cellRenderUrgente = (cellData: DataGridTypes.ColumnCellTemplateData) => {
    return cellData.value ? <Button icon="errorcircle" stylingMode="text" /> : <></>
  }
  const cellRenderNotificaChiusura = (cellData: DataGridTypes.ColumnCellTemplateData) => {
    return cellData.value && cellData.value === true ? <Button icon="bell" stylingMode="text" /> : <></>
  }

  const cellRenderRating = (cellData: DataGridTypes.ColumnCellTemplateData) => {
    return cellData.value && cellData.value > 0 ? <Rating value={cellData.value} /> : <></>
  }

  useEffect(() => {
    getCurrentFilter()
  }, [])

  return (
    <>
      <DocumentHead title={title}></DocumentHead>
      {!clientId && <h2 className={'content-block'}>{title}</h2>}
      <div className={clientId ? '' : 'content-block'}>
        {!clientId && (
          <div className="accordion-generic-filter-form">
            <Accordion
              className={filtersApplied ? 'with-filter' : ''}
              collapsible={true}
              onContentReady={(e: ContentReadyEvent<any, any>) => {
                e.component?.collapseItem(0)
              }}
            >
              <AccordionItem icon="filter" title={'Filtri'}>
                <GenericFilterForm
                  FormItemDescriptors={filterConfiguration}
                  resetFilterClicked={function (e: ClickEvent): void {
                    setFilterConfiguration(getFiltersConfiguration())
                  }}
                  filterValueChanged={function (): void {
                    const currentFlts = getCurrentFilter()
                    console.log('GENFLT - VALUE CHNG', currentFlts)
                    if (JSON.stringify(currentFlts) !== JSON.stringify(currentFilter)) {
                      console.log('GENFLT - SETTING FILT', currentFlts)
                      setCurrentFilter(currentFlts)
                    }
                  }}
                />
              </AccordionItem>
            </Accordion>
          </div>
        )}
        <DataGrid
          id={clientId ? 'client-tickets-datagrid' : 'tickets-datagrid'}
          height={getGridHeight}
          dataSource={ticketsDataSource}
          noDataText="Nessun ticket trovato"
          className={'dx-card wide-card'}
          showBorders={false}
          showColumnLines={true}
          wordWrapEnabled={false}
          showRowLines={true}
          focusedRowEnabled={true}
          rowAlternationEnabled={true}
          allowColumnResizing={currentScreenSize.isLarge || currentScreenSize.isMedium}
          allowColumnReordering={currentScreenSize.isLarge || currentScreenSize.isMedium}
          width="100%"
          onExporting={onExporting}
          ref={gridRef}
          onCellPrepared={cellPrepared}
          remoteOperations={{
            filtering: true,
            grouping: false,
            groupPaging: false,
            paging: true,
            sorting: true,
            summary: true,
          }}
          grouping={{
            // Fondamentale per il raggruppamento veloce!!!
            autoExpandAll: true,
          }}
          groupPanel={{
            visible: currentScreenSize.isLarge || currentScreenSize.isMedium,
            emptyPanelText: 'Trascina qui una colonna per raggruppare',
          }}
          onRowDblClick={(e: RowDblClickEvent<any, any>) => {
            if (e.rowType === 'data') navigate(`${e.data.id}`)
          }}
          onContextMenuPreparing={(e: DataGridTypes.ContextMenuPreparingEvent<any, any>) => {
            console.log('e', e)
            if (e.row?.rowType === 'data') {
              const rowTicket: ticket = e.row.data
              if (!e.items) e.items = []
              e.items.push(
                {
                  text: isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles) ? 'Edita' : 'Visualizza',
                  icon: isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles) ? 'edit' : 'eyeopen',
                  visible: true,
                  onItemClick: () => {
                    navigate(`${e.row?.data.id}`)
                  },
                },
                {
                  text: 'Prendi in carico',
                  icon: 'cart',
                  visible:
                    isUserRoleAllowed(userInfo?.roles, [Roles.GlobalAdministrator, Roles.TechDeveloper]) &&
                    rowTicket?.stato?.id !== StatoTicket.COMPLETATO &&
                    rowTicket?.stato?.id !== StatoTicket.ELIMINATO &&
                    !rowTicket?.incaricato &&
                    (!rowTicket.assegnato || rowTicket.assegnato.email === userInfo?.email),
                  onItemClick: () => {
                    if (
                      (e.row?.data.incaricato && e.row?.data.incaricato.fullname !== userInfo?.name) ||
                      (e.row?.data.assegnato && e.row?.data.assegnato.fullname !== userInfo?.name)
                    ) {
                      const result = confirm(
                        e.row?.data.incaricato && e.row?.data.incaricato.fullname !== userInfo?.name
                          ? `<i>Il ticket<b>${e.row?.data.ded_Dis}</b> è in carico all'utente ${e.row?.data.incaricato.fullname}. Si desidera procedere ugualmente?</i>`
                          : `<i>Il ticket<b>${e.row?.data.ded_Dis}</b> è assegnato all'utente ${e.row?.data.assegnato.fullname}. Si desidera procedere ugualmente?</i>`,
                        'Presa in Carico Ticket',
                      )
                      result.then((dialogResult) => {
                        if (dialogResult === false) return false
                        fetcher.submit(JSON.stringify(e.row?.data), {
                          method: 'put',
                          action: `/tickets/${e.row?.data.id}/takecharge`,
                          encType: 'application/json',
                        })
                      })
                    } else
                      fetcher.submit(JSON.stringify(e.row?.data), {
                        method: 'put',
                        action: `/tickets/${e.row?.data.id}/takecharge`,
                        encType: 'application/json',
                      })
                  },
                },
                {
                  text: 'Assegna a me',
                  icon: 'user',
                  visible:
                    isUserRoleAllowed(userInfo?.roles, [Roles.GlobalAdministrator, Roles.TechDeveloper]) &&
                    rowTicket?.stato?.id !== StatoTicket.COMPLETATO &&
                    rowTicket?.stato?.id !== StatoTicket.ELIMINATO &&
                    !rowTicket.assegnato,
                  onItemClick: () => {
                    if (e.row?.data.assegnato && e.row?.data.assegnato.fullname !== userInfo?.name) {
                      const result = confirm(
                        `<i>Il ticket<b>${e.row?.data.ded_Dis}</b> è assegnato all'utente ${e.row?.data.assegnato.fullname}. Si desidera procedere ugualmente?</i>`,
                        'Assegnazione Ticket',
                      )
                      result.then((dialogResult) => {
                        if (dialogResult === false) return false
                        fetcher.submit(
                          JSON.stringify({
                            ticketId: e.row?.data.ded_Dis,
                            userId: impiegati.data.value.find((employee) => employee.email === userInfo?.email)?.id,
                          }),
                          {
                            method: 'put',
                            action: `/tickets/${e.row?.data.id}/assign`,
                            encType: 'application/json',
                          },
                        )
                      })
                    } else
                      fetcher.submit(
                        JSON.stringify({
                          ticketId: e.row?.data.ded_Dis,
                          userId: impiegati.data.value.find((employee) => employee.email === userInfo?.email)?.id,
                        }),
                        {
                          method: 'put',
                          action: `/tickets/${e.row?.data.id}/assign`,
                          encType: 'application/json',
                        },
                      )
                  },
                },
                {
                  text: 'Assegna a',
                  icon: 'user',
                  visible:
                    isUserRoleAllowed(userInfo?.roles, [Roles.GlobalAdministrator, Roles.Supervisor]) &&
                    rowTicket?.stato?.id !== StatoTicket.COMPLETATO &&
                    rowTicket?.stato?.id !== StatoTicket.ELIMINATO &&
                    !rowTicket.assegnato,
                  onItemClick: () => {
                    if (e.row?.data.assegnato && e.row?.data.assegnato.fullname !== userInfo?.name) {
                      const result = confirm(
                        `<i>Il ticket<b>${e.row?.data.ded_Dis}</b> è assegnato all'utente ${e.row?.data.assegnato.fullname}. Si desidera procedere ugualmente?</i>`,
                        'Assegnazione Ticket',
                      )
                      result.then((dialogResult) => {
                        if (dialogResult === false) return false
                        const tecnici: (user | null | undefined)[] = []
                        tecnici.push(...impiegati.data.value.filter((tec) => tec.attivo))
                        setAssignToData({
                          ticket: e.row?.data,
                          tecniciList: tecnici,
                          formData: {
                            technician: null,
                          },
                          onSubmitEditor: async (
                            e: any,
                            params: {
                              ticketId: string | null | undefined
                              userId: number | null | undefined
                            },
                          ) => {
                            e.preventDefault()
                            try {
                              fetcher?.submit(JSON.stringify(params), {
                                method: 'post',
                                action: `/tickets/${e.row?.data.id}/assign`,
                                encType: 'application/json',
                              })
                            } catch (error: unknown) {
                              notify(
                                `Errore assegnazione ticket '${e.row?.data?.ded_Dis}'. Dettagli : ${error}`,
                                'error',
                                2000,
                              )
                            } finally {
                              popupAssignRef.current?.instance().hide()
                            }
                          },
                        })

                        popupAssignRef.current?.instance().show()
                      })
                    } else {
                      const tecnici: (user | null | undefined)[] = []
                      tecnici.push(...impiegati.data.value.filter((tec) => tec.attivo))
                      setAssignToData({
                        ticket: e.row?.data,
                        tecniciList: tecnici,
                        formData: {
                          technician: null,
                        },
                        onSubmitEditor: async (
                          e: any,
                          params: {
                            ticketId: string | null | undefined
                            userId: number | null | undefined
                          },
                        ) => {
                          e.preventDefault()
                          try {
                            fetcher?.submit(JSON.stringify(params), {
                              method: 'post',
                              action: `/tickets/${e.row?.data.id}/assign`,
                              encType: 'application/json',
                            })
                          } catch (error: unknown) {
                            notify(
                              `Errore assegnazione ticket '${e.row?.data?.ded_Dis}'. Dettagli : ${error}`,
                              'error',
                              2000,
                            )
                          } finally {
                            popupAssignRef.current?.instance().hide()
                          }
                        },
                      })

                      popupAssignRef.current?.instance().show()
                    }
                  },
                },
                {
                  text: 'Rimuovi assegnazione',
                  icon: 'arrowback',
                  visible:
                    isUserRoleAllowed(userInfo?.roles, [Roles.GlobalAdministrator, Roles.TechDeveloper]) &&
                    rowTicket?.stato?.id !== StatoTicket.COMPLETATO &&
                    rowTicket?.stato?.id !== StatoTicket.ELIMINATO &&
                    rowTicket.assegnato,
                  onItemClick: () => {
                    if (e.row?.data.assegnato && e.row?.data.assegnato.fullname !== userInfo?.name) {
                      const result = confirm(
                        `<i>Il ticket<b>${e.row?.data.ded_Dis}</b> è assegnato all'utente ${e.row?.data.assegnato.fullname}. Si desidera procedere ugualmente?</i>`,
                        'Rimozione Assegnazione Ticket',
                      )
                      result.then((dialogResult) => {
                        if (dialogResult === false) return
                        fetcher.submit(JSON.stringify(e.row?.data), {
                          method: 'put',
                          action: `/tickets/${e.row?.data?.id}/removeassignee`,
                          encType: 'application/json',
                        })
                      })
                    } else
                      fetcher.submit(JSON.stringify(e.row?.data), {
                        method: 'put',
                        action: `/tickets/${e.row?.data?.id}/removeassignee`,
                        encType: 'application/json',
                      })
                  },
                },
                {
                  text: 'Rilascia',
                  icon: 'arrowback',
                  visible:
                    isUserRoleAllowed(userInfo?.roles, [Roles.GlobalAdministrator, Roles.TechDeveloper]) &&
                    rowTicket?.stato?.id !== StatoTicket.COMPLETATO &&
                    rowTicket?.stato?.id !== StatoTicket.ELIMINATO &&
                    rowTicket.incaricato &&
                    rowTicket.incaricato.email === userInfo?.email,
                  onItemClick: () => {
                    if (e.row?.data.incaricato.fullname === userInfo?.name) {
                      fetcher.submit(JSON.stringify(e.row?.data), {
                        method: 'put',
                        action: `/tickets/${e.row?.data?.id}/release`,
                        encType: 'application/json',
                      })
                    } else {
                      const result = confirm(
                        `<i>Il ticket<b>${e.row?.data.ded_Dis}</b> è in carico all'utente ${e.row?.data.incaricato.fullname}. Si desidera procedere ugualmente?</i>`,
                        'Rilascio Ticket',
                      )
                      result.then((dialogResult) => {
                        if (dialogResult === false) return
                        fetcher.submit(JSON.stringify(e.row?.data), {
                          method: 'put',
                          action: `/tickets/${e.row?.data?.id}/release`,
                          encType: 'application/json',
                        })
                      })
                    }
                  },
                },
                {
                  text: 'Riapri',
                  icon: 'revert',
                  visible:
                    isUserRoleAllowed(userInfo?.roles, [Roles.GlobalAdministrator, Roles.TechDeveloper]) &&
                    rowTicket?.stato?.id === StatoTicket.COMPLETATO,
                  onItemClick: () => {
                    if (e.row?.data.assegnato.fullname === userInfo?.name) {
                      const result = confirm(
                        `<i>Si è sicuri di procedere con la riapertura del ticket <b>${e.row?.data.ded_Dis}</b>?</i>`,
                        'Riapertura Ticket',
                      )
                      result.then((dialogResult) => {
                        if (dialogResult === false) return
                        fetcher.submit(JSON.stringify(e.row?.data), {
                          method: 'put',
                          action: `/tickets/${e.row?.data?.id}/reopen`,
                          encType: 'application/json',
                        })
                      })
                    } else {
                      const result = confirm(
                        `<i>Il ticket<b>${e.row?.data.ded_Dis}</b> è assegnato all'utente ${e.row?.data.assegnato.fullname}. Si è sicuri di procedere ugualmente con la riapertura del ticket?</i>`,
                        'Riapertura Ticket',
                      )
                      result.then((dialogResult) => {
                        if (dialogResult === false) return
                        fetcher.submit(JSON.stringify(e.row?.data), {
                          method: 'put',
                          action: `/tickets/${e.row?.data?.id}/reopen`,
                          encType: 'application/json',
                        })
                      })
                    }
                  },
                },
                {
                  text: 'Elimina',
                  icon: 'close',
                  visible:
                    isUserRoleAllowed(userInfo?.roles, [Roles.GlobalAdministrator, Roles.TechDeveloper]) &&
                    rowTicket?.stato?.id !== StatoTicket.COMPLETATO &&
                    rowTicket?.stato?.id !== StatoTicket.ELIMINATO &&
                    rowTicket?.assegnato &&
                    rowTicket?.assegnato.email === userInfo?.email,
                  onItemClick: () => {
                    const result = confirm(
                      `<i>Vuoi davvero eliminare il ticket <b>${e.row?.data?.ded_Dis}</b>?</i>`,
                      'Eliminazione Ticket',
                    )
                    result.then((dialogResult) => {
                      if (dialogResult === false) return
                      fetcher.submit(JSON.stringify(e.row?.data), {
                        method: 'put',
                        action: `/tickets/${e.row?.data?.id}/cancel`,
                        encType: 'application/json',
                      })
                    })
                  },
                },
                {
                  text: 'Invia notifica chiusura',
                  icon: 'bell',
                  visible:
                    isUserRoleAllowed(userInfo?.roles, [Roles.GlobalAdministrator, Roles.TechDeveloper]) &&
                    rowTicket?.stato?.id !== StatoTicket.COMPLETATO &&
                    rowTicket?.stato?.id !== StatoTicket.ELIMINATO &&
                    rowTicket?.assegnato &&
                    rowTicket?.assegnato.email === userInfo?.email,
                  onItemClick: () => {
                    if (!e.row?.data.leg_email_contatto)
                      notify("Email riferimento assente: impossibile eseguire l' azione.", 'warning', 3000)
                    else if (e.row?.data.sede) {
                      const result = confirm(
                        `<i>Vuoi davvero inviare la notifica di chiusura del ticket <b>${e.row?.data?.ded_Dis}</b>?</i>`,
                        'Invio Notifica Chiusura Ticket',
                      )
                      result.then((dialogResult) => {
                        if (dialogResult === false) return
                        fetcher.submit(JSON.stringify(e.row?.data), {
                          method: 'put',
                          action: `/tickets/${e.row?.data?.id}/sendclosurenotice`,
                          encType: 'application/json',
                        })
                      })
                    } else notify("Sede assente: impossibile eseguire l' azione.", 'warning', 3000)
                  },
                },
                {
                  text: 'Invia notifica contatto',
                  icon: 'bell',
                  visible:
                    isUserRoleAllowed(userInfo?.roles, [Roles.GlobalAdministrator, Roles.TechDeveloper]) &&
                    rowTicket?.stato?.id !== StatoTicket.COMPLETATO &&
                    rowTicket?.stato?.id !== StatoTicket.ELIMINATO &&
                    rowTicket?.assegnato &&
                    rowTicket?.assegnato.email === userInfo?.email,
                  onItemClick: () => {
                    if (!e.row?.data.leg_email_contatto)
                      notify("Email riferimento assente: impossibile eseguire l' azione.", 'warning', 3000)
                    else if (e.row?.data.sede) {
                      const result = confirm(
                        `<i>Vuoi davvero inviare la notifica di contatto del ticket <b>${e.row?.data?.ded_Dis}</b>?</i>`,
                        'Invio Notifica Contatto Cliente',
                      )
                      result.then((dialogResult) => {
                        if (dialogResult === false) return
                        fetcher.submit(JSON.stringify(e.row?.data), {
                          method: 'put',
                          action: `/tickets/${e.row?.data?.id}/sendcontactnotice`,
                          encType: 'application/json',
                        })
                      })
                    } else notify("Sede assente: impossibile eseguire l' azione.", 'warning', 3000)
                  },
                },
                {
                  text: 'Incrementa chiamate',
                  icon: 'arrowup',
                  visible: isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles),
                  onItemClick: () => {
                    fetcher.submit(JSON.stringify(e.row?.data), {
                      method: 'put',
                      action: `/tickets/${e.row?.data?.id}/incrementcallnumber`,
                      encType: 'application/json',
                    })
                  },
                },
                {
                  text: 'Imposta urgente',
                  icon: 'errorcircle',
                  visible: isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles),
                  onItemClick: () => {
                    fetcher.submit(JSON.stringify(e.row?.data), {
                      method: 'put',
                      action: `/tickets/${e.row?.data?.id}/seturgent`,
                      encType: 'application/json',
                    })
                  },
                },
                {
                  text: 'Imposta attesa',
                  icon: 'clock',
                  visible:
                    isUserRoleAllowed(userInfo?.roles, [Roles.GlobalAdministrator, Roles.TechDeveloper]) &&
                    rowTicket?.stato?.id !== StatoTicket.COMPLETATO &&
                    rowTicket?.stato?.id !== StatoTicket.ELIMINATO &&
                    !rowTicket.attesa &&
                    rowTicket?.assegnato &&
                    rowTicket?.assegnato.email === userInfo?.email,
                  onItemClick: () => {
                    fetcher.submit(JSON.stringify(e.row?.data), {
                      method: 'put',
                      action: `/tickets/${e.row?.data?.id}/setwait`,
                      encType: 'application/json',
                    })
                  },
                },
                {
                  text: 'Pulisci attesa',
                  icon: 'clock',
                  visible:
                    isUserRoleAllowed(userInfo?.roles, [Roles.GlobalAdministrator, Roles.TechDeveloper]) &&
                    rowTicket?.stato?.id !== StatoTicket.COMPLETATO &&
                    rowTicket?.stato?.id !== StatoTicket.ELIMINATO &&
                    rowTicket.attesa &&
                    rowTicket?.assegnato &&
                    rowTicket?.assegnato.email === userInfo?.email,
                  onItemClick: () => {
                    fetcher.submit(JSON.stringify(e.row?.data), {
                      method: 'put',
                      action: `/tickets/${e.row?.data?.id}/clearwait`,
                      encType: 'application/json',
                    })
                  },
                },
                {
                  text: 'Invia notifica assistenza scaduta',
                  icon: 'bell',
                  visible: isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles),
                  onItemClick: () => {
                    if (!e.row?.data.leg_email_contatto)
                      notify("Email riferimento assente: impossibile eseguire l' azione.", 'warning', 3000)
                    else if (e.row?.data.sede) {
                      fetcher.submit(JSON.stringify(e.row?.data), {
                        method: 'put',
                        action: `/tickets/${e.row?.data?.id}/sendsupportexpirednotice`,
                        encType: 'application/json',
                      })
                    } else notify("Sede assente: impossibile eseguire l' azione.", 'warning', 3000)
                  },
                },
                {
                  text: 'Invia notifica altro fornitore',
                  icon: 'bell',
                  visible: isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles),
                  onItemClick: () => {
                    if (!e.row?.data.leg_email_contatto)
                      notify("Email riferimento assente: impossibile eseguire l' azione.", 'warning', 3000)
                    else if (e.row?.data.sede) {
                      fetcher.submit(JSON.stringify(e.row?.data), {
                        method: 'put',
                        action: `/tickets/${e.row?.data?.id}/sendothervendornotice`,
                        encType: 'application/json',
                      })
                    } else notify("Sede assente: impossibile eseguire l' azione.", 'warning', 3000)
                  },
                },
              )
            }
          }}
          columnHidingEnabled={currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium}
        >
          <Pager
            visible={true}
            allowedPageSizes={'auto'}
            displayMode={'compact'}
            showPageSizeSelector={false}
            showInfo={true}
            showNavigationButtons={false}
            infoText={gridTotalItemsLabel}
          />
          <FilterRow visible={true} />
          <Sorting mode="multiple"></Sorting>
          <SearchPanel visible={true} width={currentScreenSize.isXSmall ? 180 : 250} />
          <Export enabled={true} />
          <FilterPanel visible={true} />
          <Editing allowAdding={true} />
          <HeaderFilter visible={true} />
          <Selection mode="single" />
          <ColumnChooser enabled={true} />
          <MasterDetail enabled={true} component={TicketBriefDetail} />,
          <Scrolling mode="virtual" />
          <StateStoring
            enabled={true}
            type="localStorage"
            storageKey={clientId ? 'client-tickets-datagrid' : 'tickets-datagrid'}
            savingTimeout={50}
          />
          <Column
            visible={currentScreenSize.isXSmall || currentScreenSize.isSmall}
            type="buttons"
            width={currentScreenSize.isXSmall || currentScreenSize.isSmall ? '10%' : '2%'}
            alignment="left"
            showInColumnChooser={false}
          >
            <GridButton
              hint="Details"
              icon="search"
              onClick={(e: ColumnButtonClickEvent) => {
                navigate(`${e.row?.data.id}`)
              }}
            ></GridButton>
          </Column>
          <Column
            dataField={'id'}
            width={'5%'}
            caption="ID"
            defaultSortOrder="desc"
            allowSearch={false}
            visible={false}
            dataType="number"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 1 : undefined
            }
          >
            {!clientId && <HeaderFilter dataSource={columnSourceFactory(token, 'ticket', 'id', true)}></HeaderFilter>}
          </Column>
          <Column
            dataField={'contattato'}
            caption="C"
            minWidth={35}
            width={'auto'}
            allowHeaderFiltering={false}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 12 : undefined
            }
          ></Column>
          <Column
            dataField={'attesa'}
            caption="W"
            minWidth={35}
            width={'auto'}
            allowHeaderFiltering={false}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 11 : undefined
            }
          />
          <Column
            dataField={'urgente'}
            caption={'U'}
            cellRender={cellRenderUrgente}
            allowHeaderFiltering={false}
            minWidth={40}
            width={'auto'}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 10 : undefined
            }
          />
          <Column
            dataField={'notifica_chiusura'}
            caption="N"
            cellRender={cellRenderNotificaChiusura}
            allowHeaderFiltering={false}
            minWidth={40}
            width={'auto'}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 9 : undefined
            }
          />
          <Column
            dataField={'rating'}
            dataType="number"
            caption={'RATING'}
            cellRender={cellRenderRating}
            minWidth={70}
            width={70}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 8 : undefined
            }
          >
            {!clientId && (
              <HeaderFilter
                dataSource={[
                  {
                    text: '0',
                    value: 0,
                  },
                  {
                    text: '1',
                    value: 1,
                  },
                  {
                    text: '2',
                    value: 2,
                  },
                  {
                    text: '3',
                    value: 3,
                  },
                  {
                    text: '4',
                    value: 4,
                  },
                  {
                    text: '5',
                    value: 5,
                  },
                ]}
              ></HeaderFilter>
            )}
          </Column>
          <Column
            dataField={'ded_Dis'}
            cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
              const data: ticket = cellData.row.data
              return isUserRoleAllowed(userInfo?.roles, [Roles.Guest]) ? (
                <>
                  <div className="customLink">
                    <Link to={`/tickets/${data.id}`}>{data.ded_Dis}</Link>
                  </div>
                </>
              ) : (
                <>{data.ded_Dis}</>
              )
            }}
            caption={'TICKET ID'}
            allowSearch={false}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 23 : undefined
            }
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'ticket', 'ded_Dis', true)}>
                <Search enabled={true} searchExpr={'ded_Dis'} />
              </HeaderFilter>
            )}
          </Column>
          <Column
            dataField={'creatore.fullname'}
            caption={'CREATORE'}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 4 : undefined
            }
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'ticket', 'creatore.fullname')}>
                <Search enabled={true} searchExpr={'creatore.fullname'} />
              </HeaderFilter>
            )}
          </Column>
          <Column
            dataField={'sede.azienda.nome'}
            cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
              const data: ticket = cellData.row.data
              return isUserRoleAllowed(userInfo?.roles, [Roles.Guest]) ? (
                <>
                  <Link to={`/clients/${data.sede?.azienda?.id}`}>{data.sede?.azienda?.nome}</Link>
                </>
              ) : (
                <>{data.sede?.azienda?.nome}</>
              )
            }}
            caption={'AZIENDA'}
            visible={clientId === undefined}
            hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 24 : undefined}
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'azienda', 'nome')}>
                <Search enabled={true} searchExpr={'nome'} />
              </HeaderFilter>
            )}
          </Column>
          <Column
            dataField={'sede.nome'}
            caption={'SEDE'}
            allowHeaderFiltering={false}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 17 : undefined
            }
          />
          <Column
            dataField={'sede.azienda.agente.commerciale_qs.fullname'}
            caption={'AGENTE'}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 16 : undefined
            }
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'agente', 'commerciale_qs.fullname')}>
                <Search enabled={true} searchExpr={'commerciale_qs.fullname'} />
              </HeaderFilter>
            )}
          </Column>
          <Column dataField={'sede.azienda.rivenditore.nome'} caption={'RIVENDITORE'} visible={false}>
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'Azienda', 'rivenditore.nome')}>
                <Search enabled={true} searchExpr={'rivenditore.nome'} />
              </HeaderFilter>
            )}
          </Column>
          <Column
            dataField={'leg_contatto'}
            caption={'CONTATTO'}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 15 : undefined
            }
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'ticket', 'leg_contatto')}>
                <Search enabled={true} searchExpr={'leg_contatto'} />
              </HeaderFilter>
            )}
          </Column>
          <Column
            dataField={'leg_email_contatto'}
            caption="EMAIL"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 14 : undefined
            }
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'ticket', 'leg_email_contatto')}>
                <Search enabled={true} searchExpr={'leg_email_contatto'} />
              </HeaderFilter>
            )}
          </Column>
          <Column
            dataField={'leg_phone_contatto'}
            cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
              return <PhoneNumber phoneNumber={cellData.value} />
            }}
            caption="TELEFONO"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 13 : undefined
            }
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'ticket', 'leg_phone_contatto')}>
                <Search enabled={true} searchExpr={'leg_phone_contatto'} />
              </HeaderFilter>
            )}
          </Column>
          {DateColumn({
            dataField: 'apertura',
            caption: 'INIZIO',
            format: 'dd/MM/yyyy HH:mm',
            width: 'auto',
            hidingPriority: currentScreenSize.isSmall || currentScreenSize.isXSmall ? 19 : undefined,
          })}
          {DateColumn({
            dataField: 'chiusura',
            caption: 'FINE',
            format: 'dd/MM/yyyy HH:mm',
            width: 'auto',
            hidingPriority: currentScreenSize.isSmall || currentScreenSize.isXSmall ? 18 : undefined,
          })}
          <Column
            dataField="tipologia_ticket.nome"
            caption="CATEGORIA"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 3 : undefined
            }
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'tipologia_ticket', 'nome')}>
                <Search enabled={true} searchExpr={'nome'} />
              </HeaderFilter>
            )}
          </Column>
          {/* <Column dataField="descrizione" caption="MOTIVO" /> */}
          <Column
            dataField="stato.nome"
            caption="STATO"
            hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 22 : undefined}
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'stato_ticket', 'nome')}>
                <Search enabled={true} searchExpr={'nome'} />
              </HeaderFilter>
            )}
          </Column>
          <Column
            dataField="assegnato.fullname"
            caption="ASSEGNATO"
            hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 21 : undefined}
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'ticket', 'assegnato.fullname')}>
                <Search enabled={true} searchExpr={'assegnato.fullname'} />
              </HeaderFilter>
            )}
          </Column>
          <Column
            dataField="incaricato.fullname"
            caption="IN CARICO DA"
            hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 20 : undefined}
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'ticket', 'incaricato.fullname')}>
                <Search enabled={true} searchExpr={'incaricato.fullname'} />
              </HeaderFilter>
            )}
          </Column>
          <Column
            dataField="durata"
            caption="DURATA"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 7 : undefined
            }
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'ticket', 'durata', true)}></HeaderFilter>
            )}
          </Column>
          <Column
            name={'info_telefonica'}
            caption={'TELEFONICA'}
            calculateCellValue={computeTelefonicaValue}
            allowHeaderFiltering={false}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 6 : undefined
            }
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'tipologia_assistenza', 'nome')}>
                <Search enabled={true} searchExpr={'nome'} />
              </HeaderFilter>
            )}
          </Column>
          <Column
            name={'info_maintenance'}
            caption={'MAINTENANCE'}
            calculateCellValue={computeMaintenanceValue}
            allowHeaderFiltering={false}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 5 : undefined
            }
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'tipologia_assistenza', 'nome')}>
                <Search enabled={true} searchExpr={'nome'} />
              </HeaderFilter>
            )}
          </Column>
          <Toolbar>
            <Item
              location="before"
              locateInMenu="never"
              showText="inMenu"
              widget="dxButton"
              options={{
                hint: 'Refresh',
                text: 'Refresh',
                icon: 'refresh',
                onClick: (e: ClickEvent) => {
                  gridRef.current?.instance().refresh()
                },
                stylingMode: 'text',
              }}
            ></Item>
            <Item name="groupPanel" />
            <Item
              visible={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              name="addRowButton"
              options={{
                onClick: () => {
                  navigate(`new`)
                },
                hint: 'Nuovo ticket',
                text: 'Nuovo ticket',
              }}
            />
            <Item
              visible={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              widget="dxButton"
              options={{
                hint: 'Storico',
                text: 'Storico',
                icon: 'eyeopen',
                onClick: (e: ClickEvent) => {
                  window.open(`/tickets/historic`, '_blank')
                },
                stylingMode: 'text',
              }}
            ></Item>
            <Item
              name="columnChooserButton"
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              options={{ hint: 'Scelta colonne', text: 'Scelta colonne' }}
            />
            <Item
              name="exportButton"
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              options={{ hint: 'Esporta dati in excel', text: 'Esporta dati in excel' }}
            />
            <Item name="searchPanel" />
          </Toolbar>
        </DataGrid>
        <Popup
          width={300}
          height={300}
          hideOnOutsideClick={true}
          showCloseButton={true}
          deferRendering={true}
          title={'Assegna a'}
          ref={popupAssignRef}
          className="assignto-popup-content"
        >
          <AssignSelector data={assignToData} />
        </Popup>
      </div>
    </>
  )
}

export default TicketsPage
