import { RoleRoute, RoleRouteObject, RouteFunctionParams } from '@/types'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import { defer, LoaderFunction } from 'react-router-typesafe'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import HoursPage from '@/routes/hours/HoursPage'
import HourEditorRoute from '@/routes/hours/HourEditor.route'
import HourCreatorRoute from '@/routes/hours/HourCreator.route'
import { Roles } from '@/auth/azure/Roles'
import RouterErrorHandler from '@/components/RouterErrorHandler'
import { isUserRoleAllowed } from '@/routes/utils'
import { MealVoucherPageRoute } from '@/routes/hours/meal-voucher/MealVoucherPage.route'

export const hoursPageRouteLoader = (async () => {
  const qsAdminApi = useQsAdminApiManager.getState().service
  const userInfo = await getAzureUserInformation()

  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.select('id', 'nome')
    builder.orderBy(azienda.nome.asc())
  })

  const getImpiegati = qsAdminApi.user().query((builder, impiegato) => {
    builder.select('id', 'fullname', 'email')
    builder.filter(
      isUserRoleAllowed(userInfo?.roles, [Roles.GlobalAdministrator, Roles.Administrator, Roles.Supervisor])
        ? null
        : impiegato.email.eq(userInfo?.email ?? ''),
    )
    builder.orderBy(impiegato.fullname.asc())
  })

  return defer({
    title: 'Ore',
    aziende: await getAziende,
    impiegati: await getImpiegati,
    userInfo,
    defaultCRUDAllowedRoles: [
      Roles.GlobalAdministrator,
      Roles.Administrator,
      Roles.Supervisor,
      Roles.Sales,
      Roles.Marketing,
      Roles.TechDeveloper,
    ],
  })
}) satisfies LoaderFunction

export const HoursPageRoute: RoleRoute = {
  path: 'hours',
  children: [
    {
      index: true,
      element: <HoursPage />,
      loader: hoursPageRouteLoader,
      allowedRoles: [
        Roles.GlobalAdministrator,
        Roles.Administrator,
        Roles.Marketing,
        Roles.TechDeveloper,
        Roles.Sales,
        Roles.Supervisor,
      ],
    } as RoleRouteObject,
    HourCreatorRoute,
    HourEditorRoute,
    MealVoucherPageRoute,
  ],
  errorElement: <RouterErrorHandler />,
  text: 'Ore',
  icon: 'clock',
  mainMenuRoute: true,
  allowedRoles: [
    Roles.GlobalAdministrator,
    Roles.Administrator,
    Roles.Marketing,
    Roles.TechDeveloper,
    Roles.Sales,
    Roles.Supervisor,
  ],
}
