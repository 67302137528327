type RequestType = {
  url: string
  async: boolean
  method: string
  timeout: number
  params: any
  payload: any
  headers: any
}
export const ODataStoreRequestConfiguration =
  (token: string | undefined, timeout?: number) => (request: RequestType) => {
    // const token = useCognitoManager.getState().getAPIToken()
    // console.log('received token for config:', token)
    request.async = true
    request.headers = {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json;odata.metadata=none',
    }
    if (timeout) {
      request.timeout = timeout
    }
    return request
  }
