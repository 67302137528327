import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import useTokenRefresh, { AzureUserInfo } from '@/auth/azure/azureManager'
import { Roles } from '@/auth/azure/Roles'
import DateColumn from '@/components/date-column/DateColumn'
import { attivita_lavorativa, task } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { computeTempo } from '@/routes/hours/utils'
import { isUserRoleAllowed } from '@/routes/utils'
import { useScreenSize } from '@/themes/media-query'
import { DataGrid } from 'devextreme-react'
import {
  Column,
  Paging,
  Button as GridButton,
  StateStoring,
  DataGridTypes,
  Export,
  Toolbar,
  Item,
  DataGridRef,
} from 'devextreme-react/cjs/data-grid'
import DataSource from 'devextreme/data/data_source'
import ODataStore from 'devextreme/data/odata/store'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { ClickEvent } from 'devextreme/ui/button'
import { ColumnButtonClickEvent, ExportingEvent, RowDblClickEvent } from 'devextreme/ui/data_grid'
import notify from 'devextreme/ui/notify'
import { Workbook } from 'exceljs'
import saveAs from 'file-saver'
import { useRef } from 'react'
import { Link } from 'react-router-dom'

const TaskEditorFormHoursGrid = (props: { task: task; userInfo: AzureUserInfo | undefined }) => {
  const token = useTokenRefresh()
  const { task, userInfo } = props
  const currentScreenSize = useScreenSize()
  const gridRef = useRef<DataGridRef>(null)
  const computeTempoUfficioValue = (rowData: attivita_lavorativa) => {
    return computeTempo(rowData.tempo_ufficio)
  }

  const computeTempoClienteValue = (rowData: attivita_lavorativa) => {
    return computeTempo(rowData.tempo_cliente)
  }
  const ExportDataGridToExcel = (component: any) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('TaskHours')
    exportDataGrid({ component, worksheet }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'TaskHours.xlsx')
      })
    })
  }

  const onExporting = (e: ExportingEvent) => {
    ExportDataGridToExcel(e.component)
  }

  const hoursTaskDataSource = new DataSource({
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/attivita_lavorativa`,
      key: 'id',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    filter: [['task.id', task.id]],
    expand: ['centro_costo', 'piattaforma', 'intervento', 'impiegato'],
  })

  return (
    <>
      <DataGrid
        id={'task-hours-datagrid'}
        dataSource={hoursTaskDataSource}
        ref={gridRef}
        noDataText="Nessun' attività lavorativa associata al task"
        keyExpr={'id'}
        showBorders={true}
        showColumnLines={true}
        focusedRowEnabled={true}
        columnHidingEnabled={currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium}
        allowColumnResizing={true}
        rowAlternationEnabled={true}
        wordWrapEnabled={false}
        repaintChangesOnly={true}
        width="100%"
        onRowDblClick={(e: RowDblClickEvent<any, any>) => {
          if (e.rowType === 'data') window.open(`/hours/${e.data.id}`, '_blank')
        }}
        onExporting={onExporting}
      >
        <StateStoring enabled={true} type="localStorage" storageKey={'task-hours-datagrid'} savingTimeout={50} />
        <Paging defaultPageSize={8} />
        <Export enabled={true} />
        <Column
          type="buttons"
          width={currentScreenSize.isXSmall || currentScreenSize.isSmall ? '10%' : '2%'}
          alignment="left"
        >
          <GridButton
            hint="Details"
            icon="search"
            onClick={(e: ColumnButtonClickEvent) => {
              window.open(`/hours/${e.row?.data.id}`, '_blank')
            }}
          />
        </Column>
        {DateColumn({
          dataField: 'data',
          caption: 'DATA',
          width: 'auto',
          format: 'dd/MM/yyyy',
        })}
        <Column dataField={'impiegato.fullname'} caption="IMPIEGATO"></Column>
        <Column dataField={'centro_costo.nome'} caption="CENTRO DI COSTO"></Column>
        <Column dataField={'piattaforma.nome'} caption="PIATTAFORMA"></Column>
        <Column
          dataField={'intervento.ded_Dis'}
          cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
            const data: attivita_lavorativa = cellData.row.data
            return isUserRoleAllowed(userInfo?.roles, [Roles.Guest]) ? (
              <>
                <Link to={`/interventions/${data.intervento?.id}`}>{data.intervento?.ded_Dis}</Link>
              </>
            ) : (
              <>{data.intervento?.ded_Dis}</>
            )
          }}
          caption={'INTERVENTO'}
        ></Column>
        <Column
          name={'tempo_ufficio'}
          caption="UFFICIO"
          calculateCellValue={computeTempoUfficioValue}
          dataType="datetime"
          format={{ hour: '2-digit', minute: '2-digit', hour12: false }}
          width={'auto'}
        />
        <Column
          name={'tempo_cliente'}
          caption="CLIENTE"
          calculateCellValue={computeTempoClienteValue}
          dataType="datetime"
          format={{ hour: '2-digit', minute: '2-digit', hour12: false }}
          width={'auto'}
        />
        <Column dataField={'note'} caption="NOTE" />
        <Toolbar>
          <Item
            location="before"
            locateInMenu="never"
            showText="inMenu"
            widget="dxButton"
            options={{
              hint: 'Refresh',
              text: 'Refresh',
              icon: 'refresh',
              onClick: (e: ClickEvent) => {
                gridRef.current?.instance().refresh()
              },
              stylingMode: 'text',
            }}
          ></Item>
          <Item
            name="exportButton"
            locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
            showText="inMenu"
            options={{ hint: 'Esporta dati in excel', text: 'Esporta dati in excel' }}
          />
        </Toolbar>
      </DataGrid>
    </>
  )
}

export default TaskEditorFormHoursGrid
