import DataGrid, {
  Column,
  ColumnChooser,
  ColumnChooserSearch,
  Editing,
  Export,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Item,
  MasterDetail,
  Scrolling,
  SearchPanel,
  Sorting,
  StateStoring,
  Toolbar,
  DataGridTypes,
  DataGridRef,
  Search,
  Pager,
  Button as GridButton,
} from 'devextreme-react/data-grid'
import ODataStore from 'devextreme/data/odata/store'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver'
import { useScreenSize } from '@/themes/media-query'
import notify from 'devextreme/ui/notify'
import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import { SoftwareAdskEditorForm } from './SoftwareAdskEditor.form'
import dxDataGrid, {
  CellHoverChangedEvent,
  CellPreparedEvent,
  ColumnButtonClickEvent,
  dxDataGridColumn,
  ExportingEvent,
  RowDblClickEvent,
} from 'devextreme/ui/data_grid'
import { Popover, PopoverRef } from 'devextreme-react/popover'
import { useLoaderData } from 'react-router-typesafe'
import { FormMode, GridCellColor } from '@/enums'
import { SoftwareAdskGridLoader } from '@/routes/software/adsk/SoftwareAdskGrid.route'
import { Link, useFetcher, useNavigate, useParams } from 'react-router-dom'
import { Accordion, Item as AccordionItem } from 'devextreme-react/accordion'
import { ClickEvent } from 'devextreme/ui/button'
import { ContentReadyEvent } from 'devextreme/ui/accordion'
import {
  createDateFilterItemDescriptor,
  FormFilterItemDescriptorType,
} from '@/components/filter-form/GenericFilterForm.types'
import GenericFilterForm from '@/components/filter-form/GenericFilterForm'
import useTokenRefresh from '@/auth/azure/azureManager'
import DateColumn from '@/components/date-column/DateColumn'
import { columnSourceFactory, isUserRoleAllowed } from '@/routes/utils'
import { ad_subscription } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { Roles } from '@/auth/azure/Roles'
import { confirm } from 'devextreme/ui/dialog'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { gridTotalItemsLabel } from '@/constants'
import DocumentHead from '@/components/document-head/DocumentHead'

const cellPrepared = (e: CellPreparedEvent) => {
  if (e.rowType === 'data') {
    if (e.column.dataField === 'status') {
      if (e.value) {
        switch (e.value) {
          case 'Active': {
            e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.EMERALD}`

            break
          }
          case 'Canceled': {
            e.cellElement.style.cssText = `color: white; background-color: ${GridCellColor.RED}`

            break
          }
          case 'Expired': {
            e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.SALMON}`

            break
          }
          case 'Upgraded': {
            e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.ORANGE}`

            break
          }
          case 'Unregistered': {
            e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.CORNFLOWERBLUE}`

            break
          }
          case 'Registered': {
            e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.VIOLET}`

            break
          }
          case 'Inactive': {
            e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.YELLOW}`

            break
          }
        }
      }
    } else if (e.column.dataField === 'stato_noleggio.nome' && e.value) {
      switch (e.value) {
        case 'ALTRO DEALER': {
          e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.YELLOW}`

          break
        }
        case 'ATTIVO': {
          e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.EMERALD}`

          break
        }
        case 'SCADUTO': {
          e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.SALMON}`

          break
        }
        // No default
      }
    }
  }
}

const getColumnTooltip = (col: dxDataGridColumn): string => {
  switch (col.dataField) {
    case 'tradein': {
      return 'TRADE IN'
    }
    case 'rete': {
      return 'LICENZA DI RETE'
    }
    case 'n_contratto': {
      return 'NUMERO DI CONTRATTO'
    }
    case 'postazioni': {
      return 'POSTAZIONI'
    }
    case 'altro_fornitore': {
      return 'ALTRO FORNITORE'
    }
    case 'contratto_precedente': {
      return 'CONTRATTO PRECEDENTE'
    }
    // No default
  }
  return col.caption ?? ''
}

const onExporting = (e: ExportingEvent) => {
  ExportDataGridToExcel(e.component)
}

const ExportDataGridToExcel = (component: dxDataGrid) => {
  const workbook = new Workbook()
  const worksheet = workbook.addWorksheet('ClientsGrid')
  exportDataGrid({ component, worksheet }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'AdskLicense.xlsx')
    })
  })
}

const SoftwareAdskGrid = () => {
  const currentScreenSize = useScreenSize()
  const client = useQsAdminApiManager.getState().client
  const { clientId } = useParams()
  const fetcher = useFetcher()
  const { title, aziendaList, userInfo } = useLoaderData<typeof SoftwareAdskGridLoader>()
  const token = useTokenRefresh()

  const navigate = useNavigate()
  const swAdskGridRef = useRef<DataGridRef>(null)
  const gridPopoverRef = useRef<PopoverRef>(null)

  const getFiltersConfiguration = useCallback((): FormFilterItemDescriptorType[] => {
    const fltConf: FormFilterItemDescriptorType[] = [
      {
        fieldName: 'azienda',
        placeHolder: 'Azienda',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        values: aziendaList.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['csn/azienda/id', this.currentValue]]
        },
      },
      createDateFilterItemDescriptor('startDate', 'Data Inizio'),
    ]
    console.log('GENFLT - GETCONF :', fltConf)
    return fltConf
  }, [aziendaList])

  const initialFilterConfiguration = useMemo(() => getFiltersConfiguration(), [getFiltersConfiguration])
  const [filterConfiguration, setFilterConfiguration] = useState(initialFilterConfiguration)
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false)
  const getCurrentFilter = useCallback((): any[] => {
    let applied: boolean = false
    const currentFlts = []
    for (const flt of filterConfiguration) {
      const fltValue = flt.composeFilterItem()
      if (fltValue !== null) {
        currentFlts.push(fltValue)
      }
      if (!applied && flt.currentValue !== flt.defaultValue) applied = true
    }
    setFiltersApplied(applied)
    console.log('GENFLT - CURRENT:', currentFlts)
    return currentFlts
  }, [filterConfiguration])

  const [currentFilter, setCurrentFilter] = useState<any[]>(clientId ? [['csn/azienda/id', Number(clientId)]] : [])

  const swAdskDataSource = {
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/ad_subscription`,
      key: 'id',
      keyType: 'Int32',
      version: 4,
      errorHandler: (e) => {
        console.log(e.errorDetails)
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    filter: currentFilter.length > 0 ? currentFilter : null,
    expand: ['csn($expand=azienda($expand=agente($expand=commerciale_qs),rivenditore))'],
  }

  const getGridHeight = useCallback(() => {
    let h = '72vh'
    if (currentScreenSize.isMedium) {
      h = '68vh'
    } else if (currentScreenSize.isSmall) {
      h = '120vh'
    } else if (currentScreenSize.isXSmall) {
      h = '65vh'
    }
    return h
  }, [currentScreenSize])

  const onCellHoverChanged = (e: CellHoverChangedEvent) => {
    if (e.rowType === 'header' && e.eventType === 'mouseover') {
      gridPopoverRef.current?.instance().option('contentTemplate', function (contentElement: any) {
        const a: dxDataGridColumn = e.column
        return `<div><b>${getColumnTooltip(e.column)}</b></div>`
      })
      gridPopoverRef.current?.instance().option('target', e.cellElement)
      gridPopoverRef.current?.instance().show()
    }
    if (e.eventType === 'mouseout') {
      gridPopoverRef.current?.instance().hide()
    }
  }

  const onUpdateAllSubClick = (e: ClickEvent) => {
    const result = confirm(
      `<i>Vuoi davvero aggiornare tutte le subscription da Partner Center? L'operazione potrebbe richiedere qualche minuto.</i>`,
      'Aggiornamento Subscription',
    )
    result.then((dialogResult) => {
      if (dialogResult === false) return
      swAdskGridRef?.current?.instance().beginCustomLoading('Aggiornamento stato in corso....')

      client
        .put(
          '/api/autodeskpws/subscriptions',
          {},
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        .then(() => {
          notify(`Subscription aggiornate correttamente`, 'success', 5000)
        })
        .catch((error: any) => {
          notify(`Errore aggiornamento subscription: ${error}`, 'error', 5000)
        })
        .finally(() => {
          swAdskGridRef.current?.instance().endCustomLoading()
          swAdskGridRef.current?.instance().refresh()
        })
    })
  }

  useEffect(() => {
    getCurrentFilter()
  }, [])

  return (
    <>
      <DocumentHead title={title}></DocumentHead>
      {!clientId && (
        <div className="accordion-generic-filter-form">
          <Accordion
            className={filtersApplied ? 'with-filter' : ''}
            collapsible={true}
            onContentReady={(e: ContentReadyEvent<any, any>) => {
              e.component?.collapseItem(0)
            }}
          >
            <AccordionItem icon="filter" title={'Filtri'}>
              <GenericFilterForm
                FormItemDescriptors={filterConfiguration}
                resetFilterClicked={function (e: ClickEvent): void {
                  setFilterConfiguration(getFiltersConfiguration())
                }}
                filterValueChanged={function (): void {
                  const currentFlts = getCurrentFilter()
                  console.log('GENFLT - VALUE CHNG', currentFlts)
                  if (JSON.stringify(currentFlts) !== JSON.stringify(currentFilter)) {
                    console.log('GENFLT - SETTING FILT', currentFlts)
                    setCurrentFilter(currentFlts)
                  }
                }}
              />
            </AccordionItem>
          </Accordion>
        </div>
      )}
      <DataGrid
        id={clientId ? 'client-sw-adsk-grid' : 'sw-adsk-grid'}
        height={getGridHeight}
        className={'dx-card wide-card'}
        noDataText="Nessuna licenza trovata"
        dataSource={swAdskDataSource}
        showBorders={false}
        showColumnLines={true}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnHidingEnabled={currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium}
        allowColumnResizing={currentScreenSize.isLarge || currentScreenSize.isMedium}
        allowColumnReordering={currentScreenSize.isLarge || currentScreenSize.isMedium}
        rowAlternationEnabled={true}
        wordWrapEnabled={false}
        width="100%"
        onExporting={onExporting}
        onCellPrepared={cellPrepared}
        onCellHoverChanged={onCellHoverChanged}
        ref={swAdskGridRef}
        onRowDblClick={(e: RowDblClickEvent<any, any>) => {
          if (e.rowType === 'data') navigate(`${e.data.id}`)
        }}
        remoteOperations={{
          filtering: true,
          grouping: false,
          groupPaging: false,
          paging: true,
          sorting: true,
          summary: true,
        }}
        groupPanel={{
          visible: currentScreenSize.isLarge || currentScreenSize.isMedium,
          emptyPanelText: 'Trascina qui una colonna per raggruppare',
        }}
      >
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey={clientId ? 'client-sw-adsk-grid' : 'sw-adsk-grid'}
          savingTimeout={50}
        />
        <Pager
          visible={true}
          allowedPageSizes={'auto'}
          displayMode={'compact'}
          showPageSizeSelector={false}
          showInfo={true}
          showNavigationButtons={false}
          infoText={gridTotalItemsLabel}
        />
        <Export enabled={true} />
        <HeaderFilter visible={true} />
        <GroupPanel visible={true} emptyPanelText="Trascina qui una colonna per raggruppare" />
        <Grouping autoExpandAll={true} />
        <FilterRow visible={true} />
        <FilterPanel visible={true} />
        <SearchPanel visible={true} width={currentScreenSize.isXSmall ? 180 : 250} />
        <Scrolling mode="virtual" />
        <Sorting mode="multiple" />
        <ColumnChooser enabled={true} title={'Scelta Colonne'}>
          <ColumnChooserSearch enabled={true} />
        </ColumnChooser>
        <Editing allowAdding={true} />
        <MasterDetail
          enabled={true}
          render={(props: DataGridTypes.MasterDetailTemplateData) => (
            <SoftwareAdskEditorForm mode={FormMode.View} license={props.data} aziendaList={aziendaList.data.value} />
          )}
        />
        <Column
          visible={currentScreenSize.isXSmall}
          type="buttons"
          width={currentScreenSize.isXSmall || currentScreenSize.isSmall ? '10%' : '2%'}
          alignment="left"
          showInColumnChooser={false}
        >
          <GridButton
            hint="Details"
            icon="search"
            onClick={(e: ColumnButtonClickEvent) => {
              navigate(`${e.row?.data.id}`)
            }}
          ></GridButton>
        </Column>
        <Column
          dataField={'id'}
          width={'5%'}
          caption="ID"
          visible={false}
          dataType="number"
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 1 : undefined
          }
        >
          {!clientId && (
            <HeaderFilter dataSource={columnSourceFactory(token, 'ad_subscription', 'id', true)}></HeaderFilter>
          )}
        </Column>
        <Column
          dataField={'subscriptionId'}
          caption={'SUBSCRIPTION'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 21 : undefined
          }
        >
          {!clientId && (
            <HeaderFilter dataSource={columnSourceFactory(token, 'ad_subscription', 'subscriptionId')}>
              <Search enabled={true} searchExpr={'subscriptionId'} />
            </HeaderFilter>
          )}
        </Column>
        <Column
          dataField={'serialNumber'}
          caption={'SERIALE'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 20 : undefined
          }
        >
          {!clientId && (
            <HeaderFilter dataSource={columnSourceFactory(token, 'ad_subscription', 'serialNumber')}>
              <Search enabled={true} searchExpr={'serialNumber'} />
            </HeaderFilter>
          )}
        </Column>
        <Column
          dataField={'csn.csn'}
          caption={'CSN'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 22 : undefined
          }
        >
          {!clientId && (
            <HeaderFilter dataSource={columnSourceFactory(token, 'ad_subscription', 'csn.csn')}>
              <Search enabled={true} searchExpr={'csn.csn'} />
            </HeaderFilter>
          )}
        </Column>
        <Column
          dataField={'csn.azienda.nome'}
          cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
            const data: ad_subscription = cellData.row.data
            return (
              <>
                <Link to={`/clients/${data.csn?.azienda?.id}`}>{data.csn?.azienda?.nome}</Link>
              </>
            )
          }}
          caption={'AZIENDA'}
          visible={clientId === undefined}
          defaultSortOrder="asc"
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 23 : undefined
          }
        >
          {!clientId && (
            <HeaderFilter dataSource={columnSourceFactory(token, 'azienda', 'nome')}>
              <Search enabled={true} searchExpr={'nome'} />
            </HeaderFilter>
          )}
        </Column>
        <Column
          dataField={'csn.azienda.agente.commerciale_qs.fullname'}
          caption={'AGENTE'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 19 : undefined
          }
        >
          {!clientId && (
            <HeaderFilter dataSource={columnSourceFactory(token, 'agente', 'commerciale_qs.fullname')}>
              <Search enabled={true} searchExpr={'commerciale_qs.fullname'} />
            </HeaderFilter>
          )}
        </Column>
        <Column
          dataField={'csn.azienda.rivenditore.nome'}
          caption={'RIVENDITORE'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 18 : undefined
          }
        >
          {!clientId && (
            <HeaderFilter dataSource={columnSourceFactory(token, 'Azienda', 'rivenditore.nome')}>
              <Search enabled={true} searchExpr={'rivenditore.nome'} />
            </HeaderFilter>
          )}
        </Column>
        <Column
          dataField={'quantity'}
          dataType={'number'}
          caption={'POSTAZIONI'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 17 : undefined
          }
        >
          {!clientId && (
            <HeaderFilter dataSource={columnSourceFactory(token, 'ad_subscription', 'quantity', true)}></HeaderFilter>
          )}
        </Column>
        <Column
          dataField={'status'}
          caption={'STATO'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 16 : undefined
          }
        >
          {!clientId && (
            <HeaderFilter dataSource={columnSourceFactory(token, 'ad_subscription', 'status')}>
              <Search enabled={true} searchExpr={'status'} />
            </HeaderFilter>
          )}
        </Column>
        {DateColumn({
          dataField: 'startDate',
          caption: 'INIZIO',
          format: 'dd/MM/yyyy',
          hidingPriority:
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 15 : undefined,
        })}
        {DateColumn({
          dataField: 'endDate',
          caption: 'FINE',
          format: 'dd/MM/yyyy',
          hidingPriority:
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 14 : undefined,
        })}
        {DateColumn({
          dataField: 'extensionDate',
          caption: 'ESTENSIONE',
          format: 'dd/MM/yyyy',
          visible: false,
          hidingPriority:
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 13 : undefined,
        })}
        <Column
          dataField={'productName'}
          caption={'PRODOTTO'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 12 : undefined
          }
        >
          {!clientId && (
            <HeaderFilter dataSource={columnSourceFactory(token, 'ad_subscription', 'productName')}>
              <Search enabled={true} searchExpr={'productName'} />
            </HeaderFilter>
          )}
        </Column>
        <Column
          dataField={'productCode'}
          caption={'CODICE PRODOTTO'}
          visible={false}
          allowHeaderFiltering={false}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 11 : undefined
          }
        />
        <Column
          dataField={'licenseType'}
          caption={'LICENZA'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 10 : undefined
          }
        >
          {!clientId && (
            <HeaderFilter dataSource={columnSourceFactory(token, 'ad_subscription', 'licenseType')}>
              <Search enabled={true} searchExpr={'licenseType'} />
            </HeaderFilter>
          )}
        </Column>
        <Column
          dataField={'autoRenew'}
          caption={'AUTO RINNOVO'}
          dataType={'boolean'}
          allowHeaderFiltering={false}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 9 : undefined
          }
        />
        <Column
          dataField={'term'}
          caption={'TERM'}
          visible={false}
          allowHeaderFiltering={false}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 8 : undefined
          }
        />
        <Column
          dataField={'opportunity'}
          caption={'OPPORTUNITY'}
          visible={false}
          allowHeaderFiltering={false}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 7 : undefined
          }
        />
        <Column
          dataField={'release'}
          caption={'VERSIONE'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 6 : undefined
          }
        >
          {!clientId && (
            <HeaderFilter dataSource={columnSourceFactory(token, 'ad_subscription', 'release')}>
              <Search enabled={true} searchExpr={'release'} />
            </HeaderFilter>
          )}
        </Column>
        <Column
          dataField={'switchType'}
          caption={'SWITCHTYPE'}
          visible={true}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 5 : undefined
          }
        >
          {!clientId && (
            <HeaderFilter dataSource={columnSourceFactory(token, 'ad_subscription', 'switchType')}>
              <Search enabled={true} searchExpr={'switchType'} />
            </HeaderFilter>
          )}
        </Column>
        <Column
          dataField={'behavior'}
          caption={'COMPORTAMENTO'}
          visible={false}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 4 : undefined
          }
        >
          {!clientId && (
            <HeaderFilter dataSource={columnSourceFactory(token, 'ad_subscription', 'behavior')}>
              <Search enabled={true} searchExpr={'behavior'} />
            </HeaderFilter>
          )}
        </Column>
        <Column
          dataField={'contract'}
          caption={'CONTRATTO'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 3 : undefined
          }
        >
          {!clientId && (
            <HeaderFilter dataSource={columnSourceFactory(token, 'ad_subscription', 'contract')}>
              <Search enabled={true} searchExpr={'contract'} />
            </HeaderFilter>
          )}
        </Column>
        <Column
          dataField={'note'}
          caption={'NOTE'}
          allowHeaderFiltering={false}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 2 : undefined
          }
        />
        <Toolbar>
          <Item
            location="before"
            locateInMenu="never"
            showText="inMenu"
            widget="dxButton"
            options={{
              hint: 'Refresh',
              text: 'Refresh',
              icon: 'refresh',
              onClick: (e: ClickEvent) => {
                swAdskGridRef.current?.instance().refresh()
              },
              stylingMode: 'text',
            }}
          ></Item>
          <Item name="groupPanel" />
          <Item
            visible={isUserRoleAllowed(userInfo?.roles, [
              Roles.Sales,
              Roles.ExternalSales,
              Roles.Administrator,
              Roles.GlobalAdministrator,
            ])}
            locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
            showText="inMenu"
            widget="dxButton"
            options={{
              hint: 'Ricarica subscription',
              text: 'Ricarica subscription',
              icon: 'pulldown',
              onClick: onUpdateAllSubClick,
              stylingMode: 'text',
            }}
          ></Item>
          <Item
            name="columnChooserButton"
            locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
            showText="inMenu"
            options={{ hint: 'Scelta colonne', text: 'Scelta colonne' }}
          />
          <Item
            name="exportButton"
            locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
            showText="inMenu"
            options={{ hint: 'Esporta dati in excel', text: 'Esporta dati in excel' }}
          />
          <Item name="searchPanel" />
        </Toolbar>
      </DataGrid>
      <Popover position="bottom" ref={gridPopoverRef} />
    </>
  )
}

export default SoftwareAdskGrid
