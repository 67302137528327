import { Suspense } from 'react'
import 'devextreme-react/lookup'
import 'devextreme-react/text-area'
import { useLoaderData, Await } from 'react-router-typesafe'
import { clientEditorRouteLoader } from '@/routes/clients/client/ClientEditor.route'
import ClientEditorForm from '@/routes/clients/client/ClientEditor.form'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { useFetcher } from 'react-router-dom'
import { isUserRoleAllowed } from '@/routes/utils'
import { Roles } from '@/auth/azure/Roles'
import DocumentHead from '@/components/document-head/DocumentHead'

const ClientEditor = (props: { creating: boolean }) => {
  const {
    clientData,
    clientId,
    defaultCRUDAllowedRoles,
    getUserInfo,
    agentHistoryGraphData,
    getGruppoAziendale,
    getRivenditore,
    getTipologiaRapporto,
    getAgente,
    getTipologiaPagamento,
    getContattiAziendali,
  } = useLoaderData<typeof clientEditorRouteLoader>()

  const fetcher = useFetcher()
  const title = clientData.data.nome ? `${clientData.data.nome} - Dettagli` : 'Nuovo cliente'

  return (
    <>
      <DocumentHead title={title}></DocumentHead>
      <Suspense fallback={<LoadingPanel />}>
        <Await
          resolve={Promise.all([
            getGruppoAziendale,
            getRivenditore,
            getTipologiaRapporto,
            getAgente,
            getTipologiaPagamento,
            getContattiAziendali,
            getUserInfo,
          ])}
        >
          {([gruppiAziendali, rivenditori, tipiRapporto, agenti, tipiPagamento, contattiAziendali, userInfo]) => (
            <>
              <fetcher.Form>
                <ClientEditorForm
                  creating={props.creating}
                  agentHistoryGraph={agentHistoryGraphData}
                  gruppiAziendali={gruppiAziendali.data.value}
                  rivenditori={rivenditori.data.value}
                  tipiRapporto={tipiRapporto.data.value}
                  agenti={agenti.data.value}
                  tipiPagamento={tipiPagamento.data.value}
                  client={clientData.data}
                  clientId={clientId}
                  contattiAziendali={contattiAziendali?.data.value}
                  isRoleAllowed={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
                  userInfo={userInfo}
                  fetcher={fetcher}
                />
              </fetcher.Form>
            </>
          )}
        </Await>
      </Suspense>
    </>
  )
}

export default ClientEditor
