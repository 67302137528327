import { titlePageInitialLabel } from '@/constants'
import { Helmet } from 'react-helmet'

const DocumentHead = (props: { title?: string }) => {
  return (
    <>
      <Helmet>
        <title>
          {titlePageInitialLabel}
          {props.title ? ` - ${props.title}` : ``}
        </title>
      </Helmet>
    </>
  )
}

export default DocumentHead
