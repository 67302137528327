import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

type PhoneNumberProps = {
  showCountryCode?: boolean
  phoneNumber: string
  countryCode?: string
}

export const PhoneNumber = (props: PhoneNumberProps) => {
  const [countryCode, setCountryCode] = useState<string | undefined>()
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>()

  useEffect(() => {
    if (props.showCountryCode) {
      setCountryCode(props.countryCode)
    }
    setPhoneNumber(props.phoneNumber)
  }, [props.countryCode, props.phoneNumber, props.showCountryCode])

  return (
    <a href={`tel:${props.phoneNumber}`}>
      {props.showCountryCode && countryCode && <span>{countryCode}</span>}
      <span>{phoneNumber}</span>
    </a>
  )
}
