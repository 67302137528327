import {
  QueryObject,
  QStringPath,
  QId,
  QStringParam,
  QGuidPath,
  QGuidParam,
  QEnumPath,
  QEntityPath,
  QEntityCollectionPath,
  QNumberPath,
  QBinaryPath,
  QNumberParam,
  QBooleanPath,
  QDateTimeOffsetPath,
  QAction,
} from '@odata2ts/odata-query-objects'
import type {
  DashboardViewItemDescriptorId,
  NonPersistentLiteObjectId,
  ActionContainerDescriptorId,
  DashboardNameId,
  NonPersistentBaseObjectId,
  PostponeTimeId,
  SecuredActionDescriptorId,
  BaseObjectId,
  userId,
  repartoId,
  centro_costo_visibilityId,
  centro_costoId,
  attivita_lavorativaId,
  piattaforma_attivita_lavorativaId,
  causale_centro_costoId,
  taskId,
  ordineId,
  stato_ordineId,
  offertaId,
  stato_offertaId,
  commessaId,
  stato_commessaId,
  sedeId,
  aziendaId,
  agenteId,
  storico_agenteId,
  gruppo_aziendaleId,
  tipologia_rapporto_aziendaId,
  tipologia_pagamentoId,
  nome_notoId,
  software_concorrenteId,
  concorrente_software_featureId,
  documento_aziendaId,
  documentoId,
  documento_analisiId,
  analisiId,
  stato_analisiId,
  stato_condivisioneId,
  pacchetto_venditaId,
  tipologia_durata_pacchettoId,
  pacchetto_utilizzoId,
  interventoId,
  fatturazione_interventoId,
  contatto_aziendaleId,
  rapportoId,
  email_aziendaleId,
  entita_aziendaleId,
  ad_quoteId,
  ad_csnId,
  ad_subscriptionId,
  ad_quote_historyId,
  attivita_commId,
  tipologia_attivita_commId,
  stato_att_commId,
  eventoId,
  promemoriaId,
  partecipante_eventoId,
  documento_contattoId,
  tipologia_documentoId,
  documento_taskId,
  documento_riunioneId,
  riunioneId,
  tipologia_riunioneId,
  documento_ticketId,
  ticketId,
  tipologia_ticketId,
  tipologia_problemaId,
  argomento_problemaId,
  qs_supporto_applicazioneId,
  piattaforma_ticketId,
  stato_ticketId,
  nota_ticketId,
  ticket_emailId,
  evento_ticketId,
  tipologia_evento_ticketId,
  documento_ordineId,
  documento_offertaId,
  documento_commessaId,
  documento_sedeId,
  est_licenzaId,
  est_softwareId,
  est_piano_mantenimentoId,
  est_stato_noleggioId,
  assistenzaId,
  tipologia_assistenzaId,
  qs_licenzaId,
  qs_versioneId,
  qs_storico_licenzaId,
  stato_qs_licenzaId,
  qs_licenza_applicazioneId,
  tipologia_qs_licenzaId,
  qs_applicazioneId,
  template_offertaId,
  personalizzazioneId,
  punto_fatturazione_ordineId,
  stato_punto_fatturazioneId,
  stato_taskId,
  info_impiegatoId,
  autoId,
  badgeId,
  telefonataId,
  ad_product_catalogId,
  ad_promotionId,
  constantsId,
} from './QsAdminApiModuleModel'
import {
  ViewItemVisibility,
  ActionsToolbarVisibility,
  SecurityPermissionPolicy,
  SecurityPermissionState,
} from './QsAdminApiModuleModel'

export class QDashboardViewItemDescriptor extends QueryObject {
  public readonly ViewId = new QStringPath(this.withPrefix('ViewId'))
}

export const qDashboardViewItemDescriptor = new QDashboardViewItemDescriptor()

export class QDashboardViewItemDescriptorId extends QId<DashboardViewItemDescriptorId> {
  private readonly params = [new QStringParam('ViewId')]

  getParams() {
    return this.params
  }
}

export class QNonPersistentLiteObject extends QueryObject {
  public readonly Oid = new QGuidPath(this.withPrefix('Oid'))
}

export const qNonPersistentLiteObject = new QNonPersistentLiteObject()

export class QNonPersistentLiteObjectId extends QId<NonPersistentLiteObjectId> {
  private readonly params = [new QGuidParam('Oid')]

  getParams() {
    return this.params
  }
}

export class QDashboardOrganizationItem extends QNonPersistentLiteObject {
  public readonly Visibility = new QEnumPath(this.withPrefix('Visibility'), ViewItemVisibility)
}

export const qDashboardOrganizationItem = new QDashboardOrganizationItem()

export class QDashboardOrganizationItem_1OfIModelDashboardViewItem extends QDashboardOrganizationItem {}

export const qDashboardOrganizationItem_1OfIModelDashboardViewItem =
  new QDashboardOrganizationItem_1OfIModelDashboardViewItem()

export class QViewDashboardOrganizationItem extends QDashboardOrganizationItem_1OfIModelDashboardViewItem {
  public readonly ObjectType = new QEntityPath(this.withPrefix('ObjectType'), () => QType)
  public readonly Criteria = new QStringPath(this.withPrefix('Criteria'))
  public readonly ActionsToolbarVisibility = new QEnumPath(
    this.withPrefix('ActionsToolbarVisibility'),
    ActionsToolbarVisibility,
  )
  public readonly AvailableViews = new QEntityCollectionPath(
    this.withPrefix('AvailableViews'),
    () => QDashboardViewItemDescriptor,
  )
  public readonly ViewDescriptor = new QEntityPath(
    this.withPrefix('ViewDescriptor'),
    () => QDashboardViewItemDescriptor,
  )
}

export const qViewDashboardOrganizationItem = new QViewDashboardOrganizationItem()

export class QDashboardOrganizationItem_1OfIModelStaticText extends QDashboardOrganizationItem {}

export const qDashboardOrganizationItem_1OfIModelStaticText = new QDashboardOrganizationItem_1OfIModelStaticText()

export class QStaticTextDashboardOrganizationItem extends QDashboardOrganizationItem_1OfIModelStaticText {
  public readonly Text = new QStringPath(this.withPrefix('Text'))
}

export const qStaticTextDashboardOrganizationItem = new QStaticTextDashboardOrganizationItem()

export class QDashboardOrganizationItem_1OfIModelStaticImage extends QDashboardOrganizationItem {}

export const qDashboardOrganizationItem_1OfIModelStaticImage = new QDashboardOrganizationItem_1OfIModelStaticImage()

export class QStaticImageDashboardOrganizationItem extends QDashboardOrganizationItem_1OfIModelStaticImage {
  public readonly ImageName = new QStringPath(this.withPrefix('ImageName'))
  public readonly SvgImageWidth = new QNumberPath(this.withPrefix('SvgImageWidth'))
  public readonly SvgImageHeight = new QNumberPath(this.withPrefix('SvgImageHeight'))
  public readonly Preview = new QBinaryPath(this.withPrefix('Preview'))
}

export const qStaticImageDashboardOrganizationItem = new QStaticImageDashboardOrganizationItem()

export class QDashboardOrganizationItem_1OfIModelActionContainerViewItem extends QDashboardOrganizationItem {}

export const qDashboardOrganizationItem_1OfIModelActionContainerViewItem =
  new QDashboardOrganizationItem_1OfIModelActionContainerViewItem()

export class QActionContainerDashboardOrganizationItem extends QDashboardOrganizationItem_1OfIModelActionContainerViewItem {
  public readonly ActionContainer = new QEntityPath(
    this.withPrefix('ActionContainer'),
    () => QActionContainerDescriptor,
  )
  public readonly ActionContainers = new QEntityCollectionPath(
    this.withPrefix('ActionContainers'),
    () => QActionContainerDescriptor,
  )
}

export const qActionContainerDashboardOrganizationItem = new QActionContainerDashboardOrganizationItem()

export class QActionContainerDescriptor extends QueryObject {
  public readonly ActionContainerId = new QStringPath(this.withPrefix('ActionContainerId'))
}

export const qActionContainerDescriptor = new QActionContainerDescriptor()

export class QActionContainerDescriptorId extends QId<ActionContainerDescriptorId> {
  private readonly params = [new QStringParam('ActionContainerId')]

  getParams() {
    return this.params
  }
}

export class QDashboardName extends QueryObject {
  public readonly ID = new QNumberPath(this.withPrefix('ID'))
}

export const qDashboardName = new QDashboardName()

export class QDashboardNameId extends QId<DashboardNameId> {
  private readonly params = [new QNumberParam('ID')]

  getParams() {
    return this.params
  }
}

export class QNonPersistentBaseObject extends QueryObject {
  public readonly Oid = new QGuidPath(this.withPrefix('Oid'))
}

export const qNonPersistentBaseObject = new QNonPersistentBaseObject()

export class QNonPersistentBaseObjectId extends QId<NonPersistentBaseObjectId> {
  private readonly params = [new QGuidParam('Oid')]

  getParams() {
    return this.params
  }
}

export class QPostponeTime extends QueryObject {
  public readonly ID = new QStringPath(this.withPrefix('ID'))
}

export const qPostponeTime = new QPostponeTime()

export class QPostponeTimeId extends QId<PostponeTimeId> {
  private readonly params = [new QStringParam('ID')]

  getParams() {
    return this.params
  }
}

export class QProceedMember extends QNonPersistentBaseObject {
  public readonly Name = new QStringPath(this.withPrefix('Name'))
  public readonly Caption = new QStringPath(this.withPrefix('Caption'))
}

export const qProceedMember = new QProceedMember()

export class QSecuredActionDescriptor extends QueryObject {
  public readonly Id = new QStringPath(this.withPrefix('Id'))
  public readonly Image = new QBinaryPath(this.withPrefix('Image'))
}

export const qSecuredActionDescriptor = new QSecuredActionDescriptor()

export class QSecuredActionDescriptorId extends QId<SecuredActionDescriptorId> {
  private readonly params = [new QStringParam('Id')]

  getParams() {
    return this.params
  }
}

export class QBaseObject extends QueryObject {
  public readonly Oid = new QGuidPath(this.withPrefix('Oid'))
}

export const qBaseObject = new QBaseObject()

export class QBaseObjectId extends QId<BaseObjectId> {
  private readonly params = [new QGuidParam('Oid')]

  getParams() {
    return this.params
  }
}

export class QPermissionPolicyUser extends QBaseObject {
  public readonly ChangePasswordOnFirstLogon = new QBooleanPath(this.withPrefix('ChangePasswordOnFirstLogon'))
  public readonly UserName = new QStringPath(this.withPrefix('UserName'))
  public readonly IsActive = new QBooleanPath(this.withPrefix('IsActive'))
  public readonly Roles = new QEntityCollectionPath(this.withPrefix('Roles'), () => QPermissionPolicyRole)
}

export const qPermissionPolicyUser = new QPermissionPolicyUser()

export class QPermissionPolicyRoleBase extends QBaseObject {
  public readonly Name = new QStringPath(this.withPrefix('Name'))
  public readonly IsAdministrative = new QBooleanPath(this.withPrefix('IsAdministrative'))
  public readonly CanEditModel = new QBooleanPath(this.withPrefix('CanEditModel'))
  public readonly PermissionPolicy = new QEnumPath(this.withPrefix('PermissionPolicy'), SecurityPermissionPolicy)
  public readonly TypePermissions = new QEntityCollectionPath(
    this.withPrefix('TypePermissions'),
    () => QPermissionPolicyTypePermissionObject,
  )
  public readonly NavigationPermissions = new QEntityCollectionPath(
    this.withPrefix('NavigationPermissions'),
    () => QPermissionPolicyNavigationPermissionObject,
  )
  public readonly ActionPermissions = new QEntityCollectionPath(
    this.withPrefix('ActionPermissions'),
    () => QPermissionPolicyActionPermissionObject,
  )
}

export const qPermissionPolicyRoleBase = new QPermissionPolicyRoleBase()

export class QPermissionPolicyRole extends QPermissionPolicyRoleBase {
  public readonly Users = new QEntityCollectionPath(this.withPrefix('Users'), () => QPermissionPolicyUser)
}

export const qPermissionPolicyRole = new QPermissionPolicyRole()

export class QPermissionPolicyTypePermissionObject extends QBaseObject {
  public readonly TargetTypeFullName = new QStringPath(this.withPrefix('TargetTypeFullName'))
  public readonly ReadState = new QEnumPath(this.withPrefix('ReadState'), SecurityPermissionState)
  public readonly WriteState = new QEnumPath(this.withPrefix('WriteState'), SecurityPermissionState)
  public readonly CreateState = new QEnumPath(this.withPrefix('CreateState'), SecurityPermissionState)
  public readonly DeleteState = new QEnumPath(this.withPrefix('DeleteState'), SecurityPermissionState)
  public readonly NavigateState = new QEnumPath(this.withPrefix('NavigateState'), SecurityPermissionState)
  public readonly Role = new QEntityPath(this.withPrefix('Role'), () => QPermissionPolicyRoleBase)
  public readonly MemberPermissions = new QEntityCollectionPath(
    this.withPrefix('MemberPermissions'),
    () => QPermissionPolicyMemberPermissionsObject,
  )
  public readonly ObjectPermissions = new QEntityCollectionPath(
    this.withPrefix('ObjectPermissions'),
    () => QPermissionPolicyObjectPermissionsObject,
  )
}

export const qPermissionPolicyTypePermissionObject = new QPermissionPolicyTypePermissionObject()

export class QPermissionPolicyMemberPermissionsObject extends QBaseObject {
  public readonly Members = new QStringPath(this.withPrefix('Members'))
  public readonly ReadState = new QEnumPath(this.withPrefix('ReadState'), SecurityPermissionState)
  public readonly WriteState = new QEnumPath(this.withPrefix('WriteState'), SecurityPermissionState)
  public readonly Criteria = new QStringPath(this.withPrefix('Criteria'))
  public readonly TypePermissionObject = new QEntityPath(
    this.withPrefix('TypePermissionObject'),
    () => QPermissionPolicyTypePermissionObject,
  )
}

export const qPermissionPolicyMemberPermissionsObject = new QPermissionPolicyMemberPermissionsObject()

export class QPermissionPolicyObjectPermissionsObject extends QBaseObject {
  public readonly Criteria = new QStringPath(this.withPrefix('Criteria'))
  public readonly ReadState = new QEnumPath(this.withPrefix('ReadState'), SecurityPermissionState)
  public readonly WriteState = new QEnumPath(this.withPrefix('WriteState'), SecurityPermissionState)
  public readonly DeleteState = new QEnumPath(this.withPrefix('DeleteState'), SecurityPermissionState)
  public readonly NavigateState = new QEnumPath(this.withPrefix('NavigateState'), SecurityPermissionState)
  public readonly TypePermissionObject = new QEntityPath(
    this.withPrefix('TypePermissionObject'),
    () => QPermissionPolicyTypePermissionObject,
  )
}

export const qPermissionPolicyObjectPermissionsObject = new QPermissionPolicyObjectPermissionsObject()

export class QPermissionPolicyNavigationPermissionObject extends QBaseObject {
  public readonly ItemPath = new QStringPath(this.withPrefix('ItemPath'))
  public readonly NavigateState = new QEnumPath(this.withPrefix('NavigateState'), SecurityPermissionState)
  public readonly Role = new QEntityPath(this.withPrefix('Role'), () => QPermissionPolicyRoleBase)
}

export const qPermissionPolicyNavigationPermissionObject = new QPermissionPolicyNavigationPermissionObject()

export class QPermissionPolicyActionPermissionObject extends QBaseObject {
  public readonly ActionId = new QStringPath(this.withPrefix('ActionId'))
  public readonly Image = new QBinaryPath(this.withPrefix('Image'))
  public readonly ActionDescriptor = new QEntityPath(
    this.withPrefix('ActionDescriptor'),
    () => QSecuredActionDescriptor,
  )
  public readonly SecuredActionDescriptors = new QEntityCollectionPath(
    this.withPrefix('SecuredActionDescriptors'),
    () => QSecuredActionDescriptor,
  )
  public readonly Role = new QEntityPath(this.withPrefix('Role'), () => QPermissionPolicyRoleBase)
}

export const qPermissionPolicyActionPermissionObject = new QPermissionPolicyActionPermissionObject()

export class QApplicationUser extends QPermissionPolicyUser {
  public readonly userData = new QEntityPath(this.withPrefix('userData'), () => Quser)
  public readonly LoginInfo = new QEntityCollectionPath(this.withPrefix('LoginInfo'), () => QApplicationUserLoginInfo)
}

export const qApplicationUser = new QApplicationUser()

export class Quser extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly fullname = new QStringPath(this.withPrefix('fullname'))
  public readonly cognome = new QStringPath(this.withPrefix('cognome'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly email = new QStringPath(this.withPrefix('email'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly attivo = new QBooleanPath(this.withPrefix('attivo'))
  public readonly contractor = new QBooleanPath(this.withPrefix('contractor'))
  public readonly email_ad = new QStringPath(this.withPrefix('email_ad'))
  public readonly applicationUser = new QEntityPath(this.withPrefix('applicationUser'), () => QApplicationUser)
  public readonly reparto = new QEntityPath(this.withPrefix('reparto'), () => Qreparto)
  public readonly info = new QEntityPath(this.withPrefix('info'), () => Qinfo_impiegato)
  public readonly azienda = new QEntityPath(this.withPrefix('azienda'), () => Qazienda)
  public readonly interventi = new QEntityCollectionPath(this.withPrefix('interventi'), () => Qintervento)
  public readonly riunioni_assistite = new QEntityCollectionPath(this.withPrefix('riunioni_assistite'), () => Qriunione)
  public readonly tasks_tecnico = new QEntityCollectionPath(this.withPrefix('tasks_tecnico'), () => Qtask)
  public readonly attivita_commerciali = new QEntityCollectionPath(
    this.withPrefix('attivita_commerciali'),
    () => Qattivita_comm,
  )
  public readonly eventi = new QEntityCollectionPath(this.withPrefix('eventi'), () => Qevento)
  public readonly riunioni_create = new QEntityCollectionPath(this.withPrefix('riunioni_create'), () => Qriunione)
  public readonly tasks_create = new QEntityCollectionPath(this.withPrefix('tasks_create'), () => Qtask)
  public readonly tasks_responsabile_tecnico = new QEntityCollectionPath(
    this.withPrefix('tasks_responsabile_tecnico'),
    () => Qtask,
  )
  public readonly telefonate_create = new QEntityCollectionPath(this.withPrefix('telefonate_create'), () => Qtelefonata)
  public readonly telefonate_ricevute = new QEntityCollectionPath(
    this.withPrefix('telefonate_ricevute'),
    () => Qtelefonata,
  )
  public readonly tickets = new QEntityCollectionPath(this.withPrefix('tickets'), () => Qticket)
  public readonly eventi_assistiti = new QEntityCollectionPath(
    this.withPrefix('eventi_assistiti'),
    () => Qpartecipante_evento,
  )
  public readonly offerte = new QEntityCollectionPath(this.withPrefix('offerte'), () => Qofferta)
  public readonly attivita_lavorative = new QEntityCollectionPath(
    this.withPrefix('attivita_lavorative'),
    () => Qattivita_lavorativa,
  )
  public readonly commesse_create = new QEntityCollectionPath(this.withPrefix('commesse_create'), () => Qcommessa)
  public readonly agenti = new QEntityCollectionPath(this.withPrefix('agenti'), () => Qagente)
}

export const quser = new Quser()

export class QuserId extends QId<userId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qreparto extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly descrizione = new QStringPath(this.withPrefix('descrizione'))
  public readonly impiegati = new QEntityCollectionPath(this.withPrefix('impiegati'), () => Quser)
  public readonly centri_costo = new QEntityCollectionPath(
    this.withPrefix('centri_costo'),
    () => Qcentro_costo_visibility,
  )
}

export const qreparto = new Qreparto()

export class QrepartoId extends QId<repartoId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qcentro_costo_visibility extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly centro_costo = new QEntityPath(this.withPrefix('centro_costo'), () => Qcentro_costo)
  public readonly reparto = new QEntityPath(this.withPrefix('reparto'), () => Qreparto)
}

export const qcentro_costo_visibility = new Qcentro_costo_visibility()

export class Qcentro_costo_visibilityId extends QId<centro_costo_visibilityId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qcentro_costo extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly attivita_lavorative = new QEntityCollectionPath(
    this.withPrefix('attivita_lavorative'),
    () => Qattivita_lavorativa,
  )
  public readonly causali_centro_costo = new QEntityCollectionPath(
    this.withPrefix('causali_centro_costo'),
    () => Qcausale_centro_costo,
  )
  public readonly visibility = new QEntityCollectionPath(this.withPrefix('visibility'), () => Qcentro_costo_visibility)
}

export const qcentro_costo = new Qcentro_costo()

export class Qcentro_costoId extends QId<centro_costoId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qattivita_lavorativa extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly data = new QDateTimeOffsetPath(this.withPrefix('data'))
  public readonly ded_RootFam = new QStringPath(this.withPrefix('ded_RootFam'))
  public readonly ded_Dis = new QStringPath(this.withPrefix('ded_Dis'))
  public readonly ded_Id = new QNumberPath(this.withPrefix('ded_Id'))
  public readonly tempo_cliente = new QStringPath(this.withPrefix('tempo_cliente'))
  public readonly tempo_trasferta = new QStringPath(this.withPrefix('tempo_trasferta'))
  public readonly tempo_ufficio = new QStringPath(this.withPrefix('tempo_ufficio'))
  public readonly spese_trasferta = new QNumberPath(this.withPrefix('spese_trasferta'))
  public readonly spese_vitto = new QNumberPath(this.withPrefix('spese_vitto'))
  public readonly spese_alloggio = new QNumberPath(this.withPrefix('spese_alloggio'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly impiegato = new QEntityPath(this.withPrefix('impiegato'), () => Quser)
  public readonly centro_costo = new QEntityPath(this.withPrefix('centro_costo'), () => Qcentro_costo)
  public readonly piattaforma = new QEntityPath(this.withPrefix('piattaforma'), () => Qpiattaforma_attivita_lavorativa)
  public readonly causale = new QEntityPath(this.withPrefix('causale'), () => Qcausale_centro_costo)
  public readonly task = new QEntityPath(this.withPrefix('task'), () => Qtask)
  public readonly intervento = new QEntityPath(this.withPrefix('intervento'), () => Qintervento)
  public readonly sede = new QEntityPath(this.withPrefix('sede'), () => Qsede)
}

export const qattivita_lavorativa = new Qattivita_lavorativa()

export class Qattivita_lavorativaId extends QId<attivita_lavorativaId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qpiattaforma_attivita_lavorativa extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly attivita_lavorative = new QEntityCollectionPath(
    this.withPrefix('attivita_lavorative'),
    () => Qattivita_lavorativa,
  )
}

export const qpiattaforma_attivita_lavorativa = new Qpiattaforma_attivita_lavorativa()

export class Qpiattaforma_attivita_lavorativaId extends QId<piattaforma_attivita_lavorativaId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qcausale_centro_costo extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly centro_costo = new QEntityPath(this.withPrefix('centro_costo'), () => Qcentro_costo)
  public readonly attivita_lavorative = new QEntityCollectionPath(
    this.withPrefix('attivita_lavorative'),
    () => Qattivita_lavorativa,
  )
}

export const qcausale_centro_costo = new Qcausale_centro_costo()

export class Qcausale_centro_costoId extends QId<causale_centro_costoId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qtask extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly ded_RootFam = new QStringPath(this.withPrefix('ded_RootFam'))
  public readonly ded_SubFam = new QStringPath(this.withPrefix('ded_SubFam'))
  public readonly ded_Dis = new QStringPath(this.withPrefix('ded_Dis'))
  public readonly attachmentsFolderUrl = new QStringPath(this.withPrefix('attachmentsFolderUrl'))
  public readonly ded_Id = new QNumberPath(this.withPrefix('ded_Id'))
  public readonly consegna_prevista = new QDateTimeOffsetPath(this.withPrefix('consegna_prevista'))
  public readonly apertura = new QDateTimeOffsetPath(this.withPrefix('apertura'))
  public readonly concluso = new QBooleanPath(this.withPrefix('concluso'))
  public readonly chiusura = new QDateTimeOffsetPath(this.withPrefix('chiusura'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly note_completamento = new QStringPath(this.withPrefix('note_completamento'))
  public readonly ultima_modifica = new QDateTimeOffsetPath(this.withPrefix('ultima_modifica'))
  public readonly avviso_completamento = new QBooleanPath(this.withPrefix('avviso_completamento'))
  public readonly appuntamento_outlook = new QStringPath(this.withPrefix('appuntamento_outlook'))
  public readonly anno_rif = new QNumberPath(this.withPrefix('anno_rif'))
  public readonly resp_tecnico = new QEntityPath(this.withPrefix('resp_tecnico'), () => Quser)
  public readonly ordine = new QEntityPath(this.withPrefix('ordine'), () => Qordine)
  public readonly commessa = new QEntityPath(this.withPrefix('commessa'), () => Qcommessa)
  public readonly proprietario = new QEntityPath(this.withPrefix('proprietario'), () => Quser)
  public readonly stato = new QEntityPath(this.withPrefix('stato'), () => Qstato_task)
  public readonly sede = new QEntityPath(this.withPrefix('sede'), () => Qsede)
  public readonly punto_fatturazione = new QEntityPath(
    this.withPrefix('punto_fatturazione'),
    () => Qpunto_fatturazione_ordine,
  )
  public readonly tecnici = new QEntityCollectionPath(this.withPrefix('tecnici'), () => Quser)
  public readonly attivita_lavorative = new QEntityCollectionPath(
    this.withPrefix('attivita_lavorative'),
    () => Qattivita_lavorativa,
  )
  public readonly eventi = new QEntityCollectionPath(this.withPrefix('eventi'), () => Qevento)
  public readonly interventi = new QEntityCollectionPath(this.withPrefix('interventi'), () => Qintervento)
  public readonly personalizzazioni = new QEntityCollectionPath(
    this.withPrefix('personalizzazioni'),
    () => Qpersonalizzazione,
  )
  public readonly documenti = new QEntityCollectionPath(this.withPrefix('documenti'), () => Qdocumento_task)
  public readonly pacchetti_venduti = new QEntityCollectionPath(
    this.withPrefix('pacchetti_venduti'),
    () => Qpacchetto_vendita,
  )
}

export const qtask = new Qtask()

export class QtaskId extends QId<taskId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qordine extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly ded_RootFam = new QStringPath(this.withPrefix('ded_RootFam'))
  public readonly ded_SubFam = new QStringPath(this.withPrefix('ded_SubFam'))
  public readonly ded_Dis = new QStringPath(this.withPrefix('ded_Dis'))
  public readonly mainDocumentUrl = new QStringPath(this.withPrefix('mainDocumentUrl'))
  public readonly attachmentsFolderUrl = new QStringPath(this.withPrefix('attachmentsFolderUrl'))
  public readonly hasTasks = new QBooleanPath(this.withPrefix('hasTasks'))
  public readonly ded_Id = new QNumberPath(this.withPrefix('ded_Id'))
  public readonly creazione = new QDateTimeOffsetPath(this.withPrefix('creazione'))
  public readonly evasione = new QDateTimeOffsetPath(this.withPrefix('evasione'))
  public readonly importo = new QNumberPath(this.withPrefix('importo'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly note_evasione = new QStringPath(this.withPrefix('note_evasione'))
  public readonly anno_rif = new QNumberPath(this.withPrefix('anno_rif'))
  public readonly attivitaTecnicaRichiesta = new QBooleanPath(this.withPrefix('attivitaTecnicaRichiesta'))
  public readonly consuntivo = new QBooleanPath(this.withPrefix('consuntivo'))
  public readonly stato = new QEntityPath(this.withPrefix('stato'), () => Qstato_ordine)
  public readonly offerta = new QEntityPath(this.withPrefix('offerta'), () => Qofferta)
  public readonly quota = new QEntityPath(this.withPrefix('quota'), () => Qad_quote)
  public readonly condivisione = new QEntityPath(this.withPrefix('condivisione'), () => Qstato_condivisione)
  public readonly commessa = new QEntityPath(this.withPrefix('commessa'), () => Qcommessa)
  public readonly tasks = new QEntityCollectionPath(this.withPrefix('tasks'), () => Qtask)
  public readonly personalizzazioni = new QEntityCollectionPath(
    this.withPrefix('personalizzazioni'),
    () => Qpersonalizzazione,
  )
  public readonly documenti = new QEntityCollectionPath(this.withPrefix('documenti'), () => Qdocumento_ordine)
  public readonly punti_fatturazione_ordine = new QEntityCollectionPath(
    this.withPrefix('punti_fatturazione_ordine'),
    () => Qpunto_fatturazione_ordine,
  )
}

export const qordine = new Qordine()

export class QordineId extends QId<ordineId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class ordine_QProcessingOrder extends QAction {
  private readonly params: [] = []

  constructor() {
    super('ProcessingOrder')
  }

  getParams() {
    return this.params
  }
}

export class ordine_QRestoreOrder extends QAction {
  private readonly params: [] = []

  constructor() {
    super('RestoreOrder')
  }

  getParams() {
    return this.params
  }
}

export class ordine_QCancelOrder extends QAction {
  private readonly params: [] = []

  constructor() {
    super('CancelOrder')
  }

  getParams() {
    return this.params
  }
}

export class Qstato_ordine extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly ordini = new QEntityCollectionPath(this.withPrefix('ordini'), () => Qordine)
}

export const qstato_ordine = new Qstato_ordine()

export class Qstato_ordineId extends QId<stato_ordineId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qofferta extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly ded_RootFam = new QStringPath(this.withPrefix('ded_RootFam'))
  public readonly ded_SubFam = new QStringPath(this.withPrefix('ded_SubFam'))
  public readonly ded_Rev = new QStringPath(this.withPrefix('ded_Rev'))
  public readonly ded_Dis = new QStringPath(this.withPrefix('ded_Dis'))
  public readonly mainDocumentUrl = new QStringPath(this.withPrefix('mainDocumentUrl'))
  public readonly attachmentsFolderUrl = new QStringPath(this.withPrefix('attachmentsFolderUrl'))
  public readonly ded_Id = new QNumberPath(this.withPrefix('ded_Id'))
  public readonly revisione = new QNumberPath(this.withPrefix('revisione'))
  public readonly data_creazione = new QDateTimeOffsetPath(this.withPrefix('data_creazione'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly anno_rif = new QNumberPath(this.withPrefix('anno_rif'))
  public readonly data_invio = new QDateTimeOffsetPath(this.withPrefix('data_invio'))
  public readonly creazione_automatica = new QStringPath(this.withPrefix('creazione_automatica'))
  public readonly attivitaTecnicaRichiesta = new QBooleanPath(this.withPrefix('attivitaTecnicaRichiesta'))
  public readonly stato = new QEntityPath(this.withPrefix('stato'), () => Qstato_offerta)
  public readonly autore = new QEntityPath(this.withPrefix('autore'), () => Quser)
  public readonly commessa = new QEntityPath(this.withPrefix('commessa'), () => Qcommessa)
  public readonly analisi = new QEntityPath(this.withPrefix('analisi'), () => Qanalisi)
  public readonly tipologia_pagamento = new QEntityPath(
    this.withPrefix('tipologia_pagamento'),
    () => Qtipologia_pagamento,
  )
  public readonly sede = new QEntityPath(this.withPrefix('sede'), () => Qsede)
  public readonly condivisione = new QEntityPath(this.withPrefix('condivisione'), () => Qstato_condivisione)
  public readonly template = new QEntityPath(this.withPrefix('template'), () => Qtemplate_offerta)
  public readonly riferimenti = new QEntityCollectionPath(this.withPrefix('riferimenti'), () => Qcontatto_aziendale)
  public readonly riferimenti_entita = new QEntityCollectionPath(
    this.withPrefix('riferimenti_entita'),
    () => Qentita_aziendale,
  )
  public readonly documenti = new QEntityCollectionPath(this.withPrefix('documenti'), () => Qdocumento_offerta)
  public readonly ordini = new QEntityCollectionPath(this.withPrefix('ordini'), () => Qordine)
}

export const qofferta = new Qofferta()

export class QoffertaId extends QId<offertaId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class offerta_QCreateDocument extends QAction {
  private readonly params: [] = []

  constructor() {
    super('CreateDocument')
  }

  getParams() {
    return this.params
  }
}

export class Qstato_offerta extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly offerte = new QEntityCollectionPath(this.withPrefix('offerte'), () => Qofferta)
}

export const qstato_offerta = new Qstato_offerta()

export class Qstato_offertaId extends QId<stato_offertaId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qcommessa extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly ded_RootFam = new QStringPath(this.withPrefix('ded_RootFam'))
  public readonly ded_SubFam = new QStringPath(this.withPrefix('ded_SubFam'))
  public readonly ded_Dis = new QStringPath(this.withPrefix('ded_Dis'))
  public readonly attachmentsFolderUrl = new QStringPath(this.withPrefix('attachmentsFolderUrl'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly inizio = new QDateTimeOffsetPath(this.withPrefix('inizio'))
  public readonly fine = new QDateTimeOffsetPath(this.withPrefix('fine'))
  public readonly anno_rif = new QNumberPath(this.withPrefix('anno_rif'))
  public readonly titolo = new QStringPath(this.withPrefix('titolo'))
  public readonly creazione = new QDateTimeOffsetPath(this.withPrefix('creazione'))
  public readonly legacy = new QBooleanPath(this.withPrefix('legacy'))
  public readonly stato = new QEntityPath(this.withPrefix('stato'), () => Qstato_commessa)
  public readonly sede = new QEntityPath(this.withPrefix('sede'), () => Qsede)
  public readonly autore = new QEntityPath(this.withPrefix('autore'), () => Quser)
  public readonly condivisione = new QEntityPath(this.withPrefix('condivisione'), () => Qstato_condivisione)
  public readonly attivita_commerciali = new QEntityCollectionPath(
    this.withPrefix('attivita_commerciali'),
    () => Qattivita_comm,
  )
  public readonly pacchetti_vendita = new QEntityCollectionPath(
    this.withPrefix('pacchetti_vendita'),
    () => Qpacchetto_vendita,
  )
  public readonly tasks = new QEntityCollectionPath(this.withPrefix('tasks'), () => Qtask)
  public readonly offerte = new QEntityCollectionPath(this.withPrefix('offerte'), () => Qofferta)
  public readonly documenti = new QEntityCollectionPath(this.withPrefix('documenti'), () => Qdocumento_commessa)
  public readonly ad_quotes = new QEntityCollectionPath(this.withPrefix('ad_quotes'), () => Qad_quote)
  public readonly ordini = new QEntityCollectionPath(this.withPrefix('ordini'), () => Qordine)
}

export const qcommessa = new Qcommessa()

export class QcommessaId extends QId<commessaId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qstato_commessa extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly commesse = new QEntityCollectionPath(this.withPrefix('commesse'), () => Qcommessa)
}

export const qstato_commessa = new Qstato_commessa()

export class Qstato_commessaId extends QId<stato_commessaId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qsede extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly principale = new QBooleanPath(this.withPrefix('principale'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly email = new QStringPath(this.withPrefix('email'))
  public readonly email_pec = new QStringPath(this.withPrefix('email_pec'))
  public readonly telefono = new QStringPath(this.withPrefix('telefono'))
  public readonly fax = new QStringPath(this.withPrefix('fax'))
  public readonly leg_srvpath = new QStringPath(this.withPrefix('leg_srvpath'))
  public readonly leg_email_rin_ad = new QStringPath(this.withPrefix('leg_email_rin_ad'))
  public readonly leg_rif_ad = new QStringPath(this.withPrefix('leg_rif_ad'))
  public readonly strada = new QStringPath(this.withPrefix('strada'))
  public readonly citta = new QStringPath(this.withPrefix('citta'))
  public readonly provincia = new QStringPath(this.withPrefix('provincia'))
  public readonly cap = new QStringPath(this.withPrefix('cap'))
  public readonly stato = new QStringPath(this.withPrefix('stato'))
  public readonly archived = new QBooleanPath(this.withPrefix('archived'))
  public readonly azienda = new QEntityPath(this.withPrefix('azienda'), () => Qazienda)
  public readonly resp_tecnico = new QEntityPath(this.withPrefix('resp_tecnico'), () => Quser)
  public readonly pacchetti_vendita = new QEntityCollectionPath(
    this.withPrefix('pacchetti_vendita'),
    () => Qpacchetto_vendita,
  )
  public readonly attivita_commerciali = new QEntityCollectionPath(
    this.withPrefix('attivita_commerciali'),
    () => Qattivita_comm,
  )
  public readonly contatti_aziendali = new QEntityCollectionPath(
    this.withPrefix('contatti_aziendali'),
    () => Qcontatto_aziendale,
  )
  public readonly tickets = new QEntityCollectionPath(this.withPrefix('tickets'), () => Qticket)
  public readonly attivita_lavorative = new QEntityCollectionPath(
    this.withPrefix('attivita_lavorative'),
    () => Qattivita_lavorativa,
  )
  public readonly interventi = new QEntityCollectionPath(this.withPrefix('interventi'), () => Qintervento)
  public readonly commesse = new QEntityCollectionPath(this.withPrefix('commesse'), () => Qcommessa)
  public readonly analisi = new QEntityCollectionPath(this.withPrefix('analisi'), () => Qanalisi)
  public readonly licenze_esterne = new QEntityCollectionPath(this.withPrefix('licenze_esterne'), () => Qest_licenza)
  public readonly assistenze = new QEntityCollectionPath(this.withPrefix('assistenze'), () => Qassistenza)
  public readonly licenze_qs = new QEntityCollectionPath(this.withPrefix('licenze_qs'), () => Qqs_licenza)
  public readonly offerte = new QEntityCollectionPath(this.withPrefix('offerte'), () => Qofferta)
  public readonly documenti = new QEntityCollectionPath(this.withPrefix('documenti'), () => Qdocumento_sede)
  public readonly entita_aziendali = new QEntityCollectionPath(
    this.withPrefix('entita_aziendali'),
    () => Qentita_aziendale,
  )
  public readonly storico_licenza = new QEntityCollectionPath(
    this.withPrefix('storico_licenza'),
    () => Qqs_storico_licenza,
  )
  public readonly tasks = new QEntityCollectionPath(this.withPrefix('tasks'), () => Qtask)
}

export const qsede = new Qsede()

export class QsedeId extends QId<sedeId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qazienda extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly ded_RootFam = new QStringPath(this.withPrefix('ded_RootFam'))
  public readonly ded_Dis = new QStringPath(this.withPrefix('ded_Dis'))
  public readonly ded_Id = new QNumberPath(this.withPrefix('ded_Id'))
  public readonly email = new QStringPath(this.withPrefix('email'))
  public readonly email_pec = new QStringPath(this.withPrefix('email_pec'))
  public readonly rapp_inizio = new QDateTimeOffsetPath(this.withPrefix('rapp_inizio'))
  public readonly p_iva = new QStringPath(this.withPrefix('p_iva'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly contattabile = new QBooleanPath(this.withPrefix('contattabile'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly blacklist = new QBooleanPath(this.withPrefix('blacklist'))
  public readonly leg_origine_rapp = new QStringPath(this.withPrefix('leg_origine_rapp'))
  public readonly note_fatturazione = new QStringPath(this.withPrefix('note_fatturazione'))
  public readonly is_rivenditore = new QBooleanPath(this.withPrefix('is_rivenditore'))
  public readonly archived = new QBooleanPath(this.withPrefix('archived'))
  public readonly agente = new QEntityPath(this.withPrefix('agente'), () => Qagente)
  public readonly sede_principale = new QEntityPath(this.withPrefix('sede_principale'), () => Qsede)
  public readonly gruppo_aziendale = new QEntityPath(this.withPrefix('gruppo_aziendale'), () => Qgruppo_aziendale)
  public readonly tipologia_rapporto = new QEntityPath(
    this.withPrefix('tipologia_rapporto'),
    () => Qtipologia_rapporto_azienda,
  )
  public readonly tipologia_pagamento = new QEntityPath(
    this.withPrefix('tipologia_pagamento'),
    () => Qtipologia_pagamento,
  )
  public readonly rivenditore = new QEntityPath(this.withPrefix('rivenditore'), () => Qazienda)
  public readonly nomi_noti = new QEntityCollectionPath(this.withPrefix('nomi_noti'), () => Qnome_noto)
  public readonly sedi = new QEntityCollectionPath(this.withPrefix('sedi'), () => Qsede)
  public readonly software_concorrenti = new QEntityCollectionPath(
    this.withPrefix('software_concorrenti'),
    () => Qsoftware_concorrente,
  )
  public readonly documenti = new QEntityCollectionPath(this.withPrefix('documenti'), () => Qdocumento_azienda)
  public readonly rivendite = new QEntityCollectionPath(this.withPrefix('rivendite'), () => Qazienda)
  public readonly ad_csns = new QEntityCollectionPath(this.withPrefix('ad_csns'), () => Qad_csn)
  public readonly gruppi_rappresentati = new QEntityCollectionPath(
    this.withPrefix('gruppi_rappresentati'),
    () => Qgruppo_aziendale,
  )
  public readonly ad_quotes = new QEntityCollectionPath(this.withPrefix('ad_quotes'), () => Qad_quote)
  public readonly storico_agenti = new QEntityCollectionPath(this.withPrefix('storico_agenti'), () => Qstorico_agente)
}

export const qazienda = new Qazienda()

export class QaziendaId extends QId<aziendaId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qagente extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly attivo = new QBooleanPath(this.withPrefix('attivo'))
  public readonly commerciale_qs = new QEntityPath(this.withPrefix('commerciale_qs'), () => Quser)
  public readonly storico_agente = new QEntityCollectionPath(this.withPrefix('storico_agente'), () => Qstorico_agente)
}

export const qagente = new Qagente()

export class QagenteId extends QId<agenteId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qstorico_agente extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly data = new QDateTimeOffsetPath(this.withPrefix('data'))
  public readonly agente = new QEntityPath(this.withPrefix('agente'), () => Qagente)
  public readonly azienda = new QEntityPath(this.withPrefix('azienda'), () => Qazienda)
}

export const qstorico_agente = new Qstorico_agente()

export class Qstorico_agenteId extends QId<storico_agenteId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qgruppo_aziendale extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly capogruppo = new QEntityPath(this.withPrefix('capogruppo'), () => Qazienda)
  public readonly aziende = new QEntityCollectionPath(this.withPrefix('aziende'), () => Qazienda)
}

export const qgruppo_aziendale = new Qgruppo_aziendale()

export class Qgruppo_aziendaleId extends QId<gruppo_aziendaleId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qtipologia_rapporto_azienda extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly aziende = new QEntityCollectionPath(this.withPrefix('aziende'), () => Qazienda)
}

export const qtipologia_rapporto_azienda = new Qtipologia_rapporto_azienda()

export class Qtipologia_rapporto_aziendaId extends QId<tipologia_rapporto_aziendaId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qtipologia_pagamento extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly aziende = new QEntityCollectionPath(this.withPrefix('aziende'), () => Qazienda)
  public readonly offerte = new QEntityCollectionPath(this.withPrefix('offerte'), () => Qofferta)
}

export const qtipologia_pagamento = new Qtipologia_pagamento()

export class Qtipologia_pagamentoId extends QId<tipologia_pagamentoId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qnome_noto extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly azienda = new QEntityPath(this.withPrefix('azienda'), () => Qazienda)
}

export const qnome_noto = new Qnome_noto()

export class Qnome_notoId extends QId<nome_notoId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qsoftware_concorrente extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly concorrente = new QEntityPath(this.withPrefix('concorrente'), () => Qazienda)
  public readonly features = new QEntityCollectionPath(this.withPrefix('features'), () => Qconcorrente_software_feature)
}

export const qsoftware_concorrente = new Qsoftware_concorrente()

export class Qsoftware_concorrenteId extends QId<software_concorrenteId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qconcorrente_software_feature extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly software = new QEntityPath(this.withPrefix('software'), () => Qsoftware_concorrente)
}

export const qconcorrente_software_feature = new Qconcorrente_software_feature()

export class Qconcorrente_software_featureId extends QId<concorrente_software_featureId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qdocumento_azienda extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly azienda = new QEntityPath(this.withPrefix('azienda'), () => Qazienda)
  public readonly documento = new QEntityPath(this.withPrefix('documento'), () => Qdocumento)
  public readonly tipologia = new QEntityPath(this.withPrefix('tipologia'), () => Qtipologia_documento)
}

export const qdocumento_azienda = new Qdocumento_azienda()

export class Qdocumento_aziendaId extends QId<documento_aziendaId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qdocumento extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly estensione = new QStringPath(this.withPrefix('estensione'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly path = new QStringPath(this.withPrefix('path'))
  public readonly creazione = new QDateTimeOffsetPath(this.withPrefix('creazione'))
  public readonly ded_Docid = new QStringPath(this.withPrefix('ded_Docid'))
  public readonly analisi = new QEntityCollectionPath(this.withPrefix('analisi'), () => Qdocumento_analisi)
  public readonly tasks = new QEntityCollectionPath(this.withPrefix('tasks'), () => Qdocumento_task)
  public readonly riunioni = new QEntityCollectionPath(this.withPrefix('riunioni'), () => Qdocumento_riunione)
  public readonly tickets = new QEntityCollectionPath(this.withPrefix('tickets'), () => Qdocumento_ticket)
  public readonly aziende = new QEntityCollectionPath(this.withPrefix('aziende'), () => Qdocumento_azienda)
  public readonly contatti = new QEntityCollectionPath(this.withPrefix('contatti'), () => Qdocumento_contatto)
  public readonly ordini = new QEntityCollectionPath(this.withPrefix('ordini'), () => Qdocumento_ordine)
  public readonly offerte = new QEntityCollectionPath(this.withPrefix('offerte'), () => Qdocumento_offerta)
  public readonly commesse = new QEntityCollectionPath(this.withPrefix('commesse'), () => Qdocumento_commessa)
  public readonly sedi = new QEntityCollectionPath(this.withPrefix('sedi'), () => Qdocumento_sede)
}

export const qdocumento = new Qdocumento()

export class QdocumentoId extends QId<documentoId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qdocumento_analisi extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly documento = new QEntityPath(this.withPrefix('documento'), () => Qdocumento)
  public readonly analisi = new QEntityPath(this.withPrefix('analisi'), () => Qanalisi)
  public readonly tipologia = new QEntityPath(this.withPrefix('tipologia'), () => Qtipologia_documento)
}

export const qdocumento_analisi = new Qdocumento_analisi()

export class Qdocumento_analisiId extends QId<documento_analisiId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qanalisi extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly ded_RootFam = new QStringPath(this.withPrefix('ded_RootFam'))
  public readonly ded_SubFam = new QStringPath(this.withPrefix('ded_SubFam'))
  public readonly ded_Rev = new QStringPath(this.withPrefix('ded_Rev'))
  public readonly ded_Dis = new QStringPath(this.withPrefix('ded_Dis'))
  public readonly mainDocumentUrl = new QStringPath(this.withPrefix('mainDocumentUrl'))
  public readonly attachmentsFolderUrl = new QStringPath(this.withPrefix('attachmentsFolderUrl'))
  public readonly ded_Id = new QNumberPath(this.withPrefix('ded_Id'))
  public readonly revisione = new QNumberPath(this.withPrefix('revisione'))
  public readonly oggetto = new QStringPath(this.withPrefix('oggetto'))
  public readonly creazione = new QDateTimeOffsetPath(this.withPrefix('creazione'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly anno_rif = new QNumberPath(this.withPrefix('anno_rif'))
  public readonly stato = new QEntityPath(this.withPrefix('stato'), () => Qstato_analisi)
  public readonly creatore = new QEntityPath(this.withPrefix('creatore'), () => Quser)
  public readonly sede = new QEntityPath(this.withPrefix('sede'), () => Qsede)
  public readonly condivisione = new QEntityPath(this.withPrefix('condivisione'), () => Qstato_condivisione)
  public readonly riferimenti_entita = new QEntityCollectionPath(
    this.withPrefix('riferimenti_entita'),
    () => Qentita_aziendale,
  )
  public readonly riferimenti = new QEntityCollectionPath(this.withPrefix('riferimenti'), () => Qcontatto_aziendale)
  public readonly documenti_analisi = new QEntityCollectionPath(
    this.withPrefix('documenti_analisi'),
    () => Qdocumento_analisi,
  )
  public readonly offerte = new QEntityCollectionPath(this.withPrefix('offerte'), () => Qofferta)
}

export const qanalisi = new Qanalisi()

export class QanalisiId extends QId<analisiId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class analisi_QCreateDocument extends QAction {
  private readonly params: [] = []

  constructor() {
    super('CreateDocument')
  }

  getParams() {
    return this.params
  }
}

export class Qstato_analisi extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly analisi = new QEntityCollectionPath(this.withPrefix('analisi'), () => Qanalisi)
}

export const qstato_analisi = new Qstato_analisi()

export class Qstato_analisiId extends QId<stato_analisiId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qstato_condivisione extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly offerte = new QEntityCollectionPath(this.withPrefix('offerte'), () => Qofferta)
  public readonly analisi = new QEntityCollectionPath(this.withPrefix('analisi'), () => Qanalisi)
  public readonly commesse = new QEntityCollectionPath(this.withPrefix('commesse'), () => Qcommessa)
  public readonly pacchetti_vendita = new QEntityCollectionPath(
    this.withPrefix('pacchetti_vendita'),
    () => Qpacchetto_vendita,
  )
  public readonly ordini = new QEntityCollectionPath(this.withPrefix('ordini'), () => Qordine)
}

export const qstato_condivisione = new Qstato_condivisione()

export class Qstato_condivisioneId extends QId<stato_condivisioneId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qpacchetto_vendita extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly ded_RootFam = new QStringPath(this.withPrefix('ded_RootFam'))
  public readonly ded_SubFam = new QStringPath(this.withPrefix('ded_SubFam'))
  public readonly ded_Dis = new QStringPath(this.withPrefix('ded_Dis'))
  public readonly isExhaust = new QBooleanPath(this.withPrefix('isExhaust'))
  public readonly isExpired = new QBooleanPath(this.withPrefix('isExpired'))
  public readonly ded_Id = new QNumberPath(this.withPrefix('ded_Id'))
  public readonly consuntivo = new QBooleanPath(this.withPrefix('consuntivo'))
  public readonly unita = new QNumberPath(this.withPrefix('unita'))
  public readonly costo_unita = new QNumberPath(this.withPrefix('costo_unita'))
  public readonly unita_restanti = new QNumberPath(this.withPrefix('unita_restanti'))
  public readonly creazione = new QDateTimeOffsetPath(this.withPrefix('creazione'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly data_fatturazione = new QDateTimeOffsetPath(this.withPrefix('data_fatturazione'))
  public readonly commessa = new QEntityPath(this.withPrefix('commessa'), () => Qcommessa)
  public readonly tipologia_unita = new QEntityPath(
    this.withPrefix('tipologia_unita'),
    () => Qtipologia_durata_pacchetto,
  )
  public readonly task = new QEntityPath(this.withPrefix('task'), () => Qtask)
  public readonly sede = new QEntityPath(this.withPrefix('sede'), () => Qsede)
  public readonly condivisione = new QEntityPath(this.withPrefix('condivisione'), () => Qstato_condivisione)
  public readonly utilizzi = new QEntityCollectionPath(this.withPrefix('utilizzi'), () => Qpacchetto_utilizzo)
}

export const qpacchetto_vendita = new Qpacchetto_vendita()

export class Qpacchetto_venditaId extends QId<pacchetto_venditaId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qtipologia_durata_pacchetto extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly pacchetto_venditas = new QEntityCollectionPath(
    this.withPrefix('pacchetto_venditas'),
    () => Qpacchetto_vendita,
  )
}

export const qtipologia_durata_pacchetto = new Qtipologia_durata_pacchetto()

export class Qtipologia_durata_pacchettoId extends QId<tipologia_durata_pacchettoId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qpacchetto_utilizzo extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly ded_RootFam = new QStringPath(this.withPrefix('ded_RootFam'))
  public readonly ded_SubFam = new QStringPath(this.withPrefix('ded_SubFam'))
  public readonly ded_Dis = new QStringPath(this.withPrefix('ded_Dis'))
  public readonly ded_Id = new QNumberPath(this.withPrefix('ded_Id'))
  public readonly unita_utilizzate = new QNumberPath(this.withPrefix('unita_utilizzate'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly creazione = new QDateTimeOffsetPath(this.withPrefix('creazione'))
  public readonly pacchetto_vendita = new QEntityPath(this.withPrefix('pacchetto_vendita'), () => Qpacchetto_vendita)
  public readonly intervento = new QEntityPath(this.withPrefix('intervento'), () => Qintervento)
}

export const qpacchetto_utilizzo = new Qpacchetto_utilizzo()

export class Qpacchetto_utilizzoId extends QId<pacchetto_utilizzoId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qintervento extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly ded_RootFam = new QStringPath(this.withPrefix('ded_RootFam'))
  public readonly ded_SubFam = new QStringPath(this.withPrefix('ded_SubFam'))
  public readonly ded_Dis = new QStringPath(this.withPrefix('ded_Dis'))
  public readonly durata_intervento = new QNumberPath(this.withPrefix('durata_intervento'))
  public readonly mainDocumentUrl = new QStringPath(this.withPrefix('mainDocumentUrl'))
  public readonly ded_Id = new QNumberPath(this.withPrefix('ded_Id'))
  public readonly data = new QDateTimeOffsetPath(this.withPrefix('data'))
  public readonly durata_viaggio = new QNumberPath(this.withPrefix('durata_viaggio'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly anno_rif = new QNumberPath(this.withPrefix('anno_rif'))
  public readonly mattina_inizio = new QDateTimeOffsetPath(this.withPrefix('mattina_inizio'))
  public readonly mattina_fine = new QDateTimeOffsetPath(this.withPrefix('mattina_fine'))
  public readonly pomeriggio_inizio = new QDateTimeOffsetPath(this.withPrefix('pomeriggio_inizio'))
  public readonly pomeriggio_fine = new QDateTimeOffsetPath(this.withPrefix('pomeriggio_fine'))
  public readonly remote = new QBooleanPath(this.withPrefix('remote'))
  public readonly locale = new QStringPath(this.withPrefix('locale'))
  public readonly ignorePackages = new QBooleanPath(this.withPrefix('ignorePackages'))
  public readonly task = new QEntityPath(this.withPrefix('task'), () => Qtask)
  public readonly stato_fatturazione = new QEntityPath(
    this.withPrefix('stato_fatturazione'),
    () => Qfatturazione_intervento,
  )
  public readonly sede = new QEntityPath(this.withPrefix('sede'), () => Qsede)
  public readonly impiegati = new QEntityCollectionPath(this.withPrefix('impiegati'), () => Quser)
  public readonly riferimenti_cliente = new QEntityCollectionPath(
    this.withPrefix('riferimenti_cliente'),
    () => Qcontatto_aziendale,
  )
  public readonly pacchetti_utilizzo = new QEntityCollectionPath(
    this.withPrefix('pacchetti_utilizzo'),
    () => Qpacchetto_utilizzo,
  )
  public readonly attivita_lavorative = new QEntityCollectionPath(
    this.withPrefix('attivita_lavorative'),
    () => Qattivita_lavorativa,
  )
}

export const qintervento = new Qintervento()

export class QinterventoId extends QId<interventoId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qfatturazione_intervento extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly interventi = new QEntityCollectionPath(this.withPrefix('interventi'), () => Qintervento)
}

export const qfatturazione_intervento = new Qfatturazione_intervento()

export class Qfatturazione_interventoId extends QId<fatturazione_interventoId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qcontatto_aziendale extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly fullname = new QStringPath(this.withPrefix('fullname'))
  public readonly ad_recipient = new QBooleanPath(this.withPrefix('ad_recipient'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly cognome = new QStringPath(this.withPrefix('cognome'))
  public readonly ruolo = new QStringPath(this.withPrefix('ruolo'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly titolo = new QStringPath(this.withPrefix('titolo'))
  public readonly telefono = new QStringPath(this.withPrefix('telefono'))
  public readonly attivo = new QBooleanPath(this.withPrefix('attivo'))
  public readonly preposto = new QEntityPath(this.withPrefix('preposto'), () => Qcontatto_aziendale)
  public readonly rapporto = new QEntityPath(this.withPrefix('rapporto'), () => Qrapporto)
  public readonly sede = new QEntityPath(this.withPrefix('sede'), () => Qsede)
  public readonly analisi_assegnate = new QEntityCollectionPath(this.withPrefix('analisi_assegnate'), () => Qanalisi)
  public readonly interventi = new QEntityCollectionPath(this.withPrefix('interventi'), () => Qintervento)
  public readonly offerte = new QEntityCollectionPath(this.withPrefix('offerte'), () => Qofferta)
  public readonly emails = new QEntityCollectionPath(this.withPrefix('emails'), () => Qemail_aziendale)
  public readonly entita_aziendali = new QEntityCollectionPath(
    this.withPrefix('entita_aziendali'),
    () => Qentita_aziendale,
  )
  public readonly ad_quotes_contatti_addizionali = new QEntityCollectionPath(
    this.withPrefix('ad_quotes_contatti_addizionali'),
    () => Qad_quote,
  )
  public readonly attivita_commerciali = new QEntityCollectionPath(
    this.withPrefix('attivita_commerciali'),
    () => Qattivita_comm,
  )
  public readonly staff = new QEntityCollectionPath(this.withPrefix('staff'), () => Qcontatto_aziendale)
  public readonly eventi = new QEntityCollectionPath(this.withPrefix('eventi'), () => Qpartecipante_evento)
  public readonly documenti = new QEntityCollectionPath(this.withPrefix('documenti'), () => Qdocumento_contatto)
  public readonly ad_quotes = new QEntityCollectionPath(this.withPrefix('ad_quotes'), () => Qad_quote)
  public readonly ad_csns = new QEntityCollectionPath(this.withPrefix('ad_csns'), () => Qad_csn)
}

export const qcontatto_aziendale = new Qcontatto_aziendale()

export class Qcontatto_aziendaleId extends QId<contatto_aziendaleId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qrapporto extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly impiegati = new QEntityCollectionPath(this.withPrefix('impiegati'), () => Qcontatto_aziendale)
}

export const qrapporto = new Qrapporto()

export class QrapportoId extends QId<rapportoId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qemail_aziendale extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly email = new QStringPath(this.withPrefix('email'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly rif_ad = new QBooleanPath(this.withPrefix('rif_ad'))
  public readonly contatti_aziendali = new QEntityCollectionPath(
    this.withPrefix('contatti_aziendali'),
    () => Qcontatto_aziendale,
  )
  public readonly entita_aziendali = new QEntityCollectionPath(
    this.withPrefix('entita_aziendali'),
    () => Qentita_aziendale,
  )
}

export const qemail_aziendale = new Qemail_aziendale()

export class Qemail_aziendaleId extends QId<email_aziendaleId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qentita_aziendale extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly ad_recipient = new QBooleanPath(this.withPrefix('ad_recipient'))
  public readonly telefono = new QStringPath(this.withPrefix('telefono'))
  public readonly sede = new QEntityPath(this.withPrefix('sede'), () => Qsede)
  public readonly parent = new QEntityPath(this.withPrefix('parent'), () => Qentita_aziendale)
  public readonly analisi_assegnate = new QEntityCollectionPath(this.withPrefix('analisi_assegnate'), () => Qanalisi)
  public readonly offerte = new QEntityCollectionPath(this.withPrefix('offerte'), () => Qofferta)
  public readonly emails = new QEntityCollectionPath(this.withPrefix('emails'), () => Qemail_aziendale)
  public readonly contatti_aziendali = new QEntityCollectionPath(
    this.withPrefix('contatti_aziendali'),
    () => Qcontatto_aziendale,
  )
  public readonly entita_dipendenti = new QEntityCollectionPath(
    this.withPrefix('entita_dipendenti'),
    () => Qentita_aziendale,
  )
}

export const qentita_aziendale = new Qentita_aziendale()

export class Qentita_aziendaleId extends QId<entita_aziendaleId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qad_quote extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly status = new QStringPath(this.withPrefix('status'))
  public readonly transactionId = new QStringPath(this.withPrefix('transactionId'))
  public readonly number = new QStringPath(this.withPrefix('number'))
  public readonly auto = new QBooleanPath(this.withPrefix('auto'))
  public readonly autoFinalizationDate = new QDateTimeOffsetPath(this.withPrefix('autoFinalizationDate'))
  public readonly autoExpiringDate = new QDateTimeOffsetPath(this.withPrefix('autoExpiringDate'))
  public readonly attivitaTecnicaRichiesta = new QBooleanPath(this.withPrefix('attivitaTecnicaRichiesta'))
  public readonly creationDate = new QDateTimeOffsetPath(this.withPrefix('creationDate'))
  public readonly expiringDate = new QDateTimeOffsetPath(this.withPrefix('expiringDate'))
  public readonly language = new QStringPath(this.withPrefix('language'))
  public readonly csn = new QEntityPath(this.withPrefix('csn'), () => Qad_csn)
  public readonly azienda = new QEntityPath(this.withPrefix('azienda'), () => Qazienda)
  public readonly contact = new QEntityPath(this.withPrefix('contact'), () => Qcontatto_aziendale)
  public readonly admin = new QEntityPath(this.withPrefix('admin'), () => Qcontatto_aziendale)
  public readonly commessa = new QEntityPath(this.withPrefix('commessa'), () => Qcommessa)
  public readonly author = new QEntityPath(this.withPrefix('author'), () => Quser)
  public readonly ad_subscriptions = new QEntityCollectionPath(
    this.withPrefix('ad_subscriptions'),
    () => Qad_subscription,
  )
  public readonly contatti_addizionali = new QEntityCollectionPath(
    this.withPrefix('contatti_addizionali'),
    () => Qcontatto_aziendale,
  )
  public readonly ad_quote_histories = new QEntityCollectionPath(
    this.withPrefix('ad_quote_histories'),
    () => Qad_quote_history,
  )
  public readonly ordini = new QEntityCollectionPath(this.withPrefix('ordini'), () => Qordine)
}

export const qad_quote = new Qad_quote()

export class Qad_quoteId extends QId<ad_quoteId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qad_csn extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly csn = new QStringPath(this.withPrefix('csn'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly admin = new QEntityPath(this.withPrefix('admin'), () => Qcontatto_aziendale)
  public readonly quoteContact = new QEntityPath(this.withPrefix('quoteContact'), () => Qcontatto_aziendale)
  public readonly azienda = new QEntityPath(this.withPrefix('azienda'), () => Qazienda)
  public readonly ad_subscriptions = new QEntityCollectionPath(
    this.withPrefix('ad_subscriptions'),
    () => Qad_subscription,
  )
  public readonly ad_quotes = new QEntityCollectionPath(this.withPrefix('ad_quotes'), () => Qad_quote)
}

export const qad_csn = new Qad_csn()

export class Qad_csnId extends QId<ad_csnId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qad_subscription extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly subscriptionId = new QStringPath(this.withPrefix('subscriptionId'))
  public readonly serialNumber = new QStringPath(this.withPrefix('serialNumber'))
  public readonly quantity = new QNumberPath(this.withPrefix('quantity'))
  public readonly status = new QStringPath(this.withPrefix('status'))
  public readonly startDate = new QDateTimeOffsetPath(this.withPrefix('startDate'))
  public readonly endDate = new QDateTimeOffsetPath(this.withPrefix('endDate'))
  public readonly extensionDate = new QDateTimeOffsetPath(this.withPrefix('extensionDate'))
  public readonly productName = new QStringPath(this.withPrefix('productName'))
  public readonly productCode = new QStringPath(this.withPrefix('productCode'))
  public readonly licenseType = new QStringPath(this.withPrefix('licenseType'))
  public readonly term = new QStringPath(this.withPrefix('term'))
  public readonly autoRenew = new QBooleanPath(this.withPrefix('autoRenew'))
  public readonly opportunity = new QStringPath(this.withPrefix('opportunity'))
  public readonly release = new QStringPath(this.withPrefix('release'))
  public readonly switchType = new QStringPath(this.withPrefix('switchType'))
  public readonly behavior = new QStringPath(this.withPrefix('behavior'))
  public readonly contract = new QStringPath(this.withPrefix('contract'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly csn = new QEntityPath(this.withPrefix('csn'), () => Qad_csn)
  public readonly ad_quotes = new QEntityCollectionPath(this.withPrefix('ad_quotes'), () => Qad_quote)
}

export const qad_subscription = new Qad_subscription()

export class Qad_subscriptionId extends QId<ad_subscriptionId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qad_quote_history extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly quoteStatus = new QStringPath(this.withPrefix('quoteStatus'))
  public readonly eventDate = new QDateTimeOffsetPath(this.withPrefix('eventDate'))
  public readonly message = new QStringPath(this.withPrefix('message'))
  public readonly quote = new QEntityPath(this.withPrefix('quote'), () => Qad_quote)
}

export const qad_quote_history = new Qad_quote_history()

export class Qad_quote_historyId extends QId<ad_quote_historyId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qattivita_comm extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly ded_RootFam = new QStringPath(this.withPrefix('ded_RootFam'))
  public readonly ded_Dis = new QStringPath(this.withPrefix('ded_Dis'))
  public readonly attachmentsFolderUrl = new QStringPath(this.withPrefix('attachmentsFolderUrl'))
  public readonly ded_Id = new QNumberPath(this.withPrefix('ded_Id'))
  public readonly inizio = new QDateTimeOffsetPath(this.withPrefix('inizio'))
  public readonly fine = new QDateTimeOffsetPath(this.withPrefix('fine'))
  public readonly creazione = new QDateTimeOffsetPath(this.withPrefix('creazione'))
  public readonly descrizione = new QStringPath(this.withPrefix('descrizione'))
  public readonly anno_rif = new QNumberPath(this.withPrefix('anno_rif'))
  public readonly note_follow_up = new QStringPath(this.withPrefix('note_follow_up'))
  public readonly autore = new QEntityPath(this.withPrefix('autore'), () => Quser)
  public readonly contatto_aziendale = new QEntityPath(this.withPrefix('contatto_aziendale'), () => Qcontatto_aziendale)
  public readonly tipologia = new QEntityPath(this.withPrefix('tipologia'), () => Qtipologia_attivita_comm)
  public readonly precedente = new QEntityPath(this.withPrefix('precedente'), () => Qattivita_comm)
  public readonly stato = new QEntityPath(this.withPrefix('stato'), () => Qstato_att_comm)
  public readonly commessa = new QEntityPath(this.withPrefix('commessa'), () => Qcommessa)
  public readonly sede = new QEntityPath(this.withPrefix('sede'), () => Qsede)
  public readonly attivita_commerciali = new QEntityCollectionPath(
    this.withPrefix('attivita_commerciali'),
    () => Qattivita_comm,
  )
  public readonly eventi = new QEntityCollectionPath(this.withPrefix('eventi'), () => Qevento)
}

export const qattivita_comm = new Qattivita_comm()

export class Qattivita_commId extends QId<attivita_commId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qtipologia_attivita_comm extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly attivita_commerciali = new QEntityCollectionPath(
    this.withPrefix('attivita_commerciali'),
    () => Qattivita_comm,
  )
}

export const qtipologia_attivita_comm = new Qtipologia_attivita_comm()

export class Qtipologia_attivita_commId extends QId<tipologia_attivita_commId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qstato_att_comm extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly attivita_commerciali = new QEntityCollectionPath(
    this.withPrefix('attivita_commerciali'),
    () => Qattivita_comm,
  )
}

export const qstato_att_comm = new Qstato_att_comm()

export class Qstato_att_commId extends QId<stato_att_commId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qevento extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly titolo = new QStringPath(this.withPrefix('titolo'))
  public readonly descrizione = new QStringPath(this.withPrefix('descrizione'))
  public readonly creazione = new QDateTimeOffsetPath(this.withPrefix('creazione'))
  public readonly ultimo_aggiornamento = new QDateTimeOffsetPath(this.withPrefix('ultimo_aggiornamento'))
  public readonly inizio = new QDateTimeOffsetPath(this.withPrefix('inizio'))
  public readonly fine = new QDateTimeOffsetPath(this.withPrefix('fine'))
  public readonly utente = new QEntityPath(this.withPrefix('utente'), () => Quser)
  public readonly attivita_comm = new QEntityPath(this.withPrefix('attivita_comm'), () => Qattivita_comm)
  public readonly task = new QEntityPath(this.withPrefix('task'), () => Qtask)
  public readonly promemoria = new QEntityCollectionPath(this.withPrefix('promemoria'), () => Qpromemoria)
  public readonly partecipanti = new QEntityCollectionPath(this.withPrefix('partecipanti'), () => Qpartecipante_evento)
}

export const qevento = new Qevento()

export class QeventoId extends QId<eventoId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qpromemoria extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly letto = new QBooleanPath(this.withPrefix('letto'))
  public readonly lettura = new QDateTimeOffsetPath(this.withPrefix('lettura'))
  public readonly evento = new QEntityPath(this.withPrefix('evento'), () => Qevento)
}

export const qpromemoria = new Qpromemoria()

export class QpromemoriaId extends QId<promemoriaId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qpartecipante_evento extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly partecipante_qs = new QEntityPath(this.withPrefix('partecipante_qs'), () => Quser)
  public readonly partecipante_est = new QEntityPath(this.withPrefix('partecipante_est'), () => Qcontatto_aziendale)
  public readonly evento = new QEntityPath(this.withPrefix('evento'), () => Qevento)
}

export const qpartecipante_evento = new Qpartecipante_evento()

export class Qpartecipante_eventoId extends QId<partecipante_eventoId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qdocumento_contatto extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly contatto = new QEntityPath(this.withPrefix('contatto'), () => Qcontatto_aziendale)
  public readonly documento = new QEntityPath(this.withPrefix('documento'), () => Qdocumento)
  public readonly tipologia = new QEntityPath(this.withPrefix('tipologia'), () => Qtipologia_documento)
}

export const qdocumento_contatto = new Qdocumento_contatto()

export class Qdocumento_contattoId extends QId<documento_contattoId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qtipologia_documento extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly analisi = new QEntityCollectionPath(this.withPrefix('analisi'), () => Qdocumento_analisi)
  public readonly tasks = new QEntityCollectionPath(this.withPrefix('tasks'), () => Qdocumento_task)
  public readonly riunioni = new QEntityCollectionPath(this.withPrefix('riunioni'), () => Qdocumento_riunione)
  public readonly tickets = new QEntityCollectionPath(this.withPrefix('tickets'), () => Qdocumento_ticket)
  public readonly aziende = new QEntityCollectionPath(this.withPrefix('aziende'), () => Qdocumento_azienda)
  public readonly contatti = new QEntityCollectionPath(this.withPrefix('contatti'), () => Qdocumento_contatto)
  public readonly ordini = new QEntityCollectionPath(this.withPrefix('ordini'), () => Qdocumento_ordine)
  public readonly offerte = new QEntityCollectionPath(this.withPrefix('offerte'), () => Qdocumento_offerta)
  public readonly commesse = new QEntityCollectionPath(this.withPrefix('commesse'), () => Qdocumento_commessa)
  public readonly sedi = new QEntityCollectionPath(this.withPrefix('sedi'), () => Qdocumento_sede)
}

export const qtipologia_documento = new Qtipologia_documento()

export class Qtipologia_documentoId extends QId<tipologia_documentoId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qdocumento_task extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly task = new QEntityPath(this.withPrefix('task'), () => Qtask)
  public readonly documento = new QEntityPath(this.withPrefix('documento'), () => Qdocumento)
  public readonly tipologia = new QEntityPath(this.withPrefix('tipologia'), () => Qtipologia_documento)
}

export const qdocumento_task = new Qdocumento_task()

export class Qdocumento_taskId extends QId<documento_taskId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qdocumento_riunione extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly riunione = new QEntityPath(this.withPrefix('riunione'), () => Qriunione)
  public readonly documento = new QEntityPath(this.withPrefix('documento'), () => Qdocumento)
  public readonly tipologia = new QEntityPath(this.withPrefix('tipologia'), () => Qtipologia_documento)
}

export const qdocumento_riunione = new Qdocumento_riunione()

export class Qdocumento_riunioneId extends QId<documento_riunioneId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qriunione extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly ded_RootFam = new QStringPath(this.withPrefix('ded_RootFam'))
  public readonly ded_SubFam = new QStringPath(this.withPrefix('ded_SubFam'))
  public readonly ded_Rev = new QStringPath(this.withPrefix('ded_Rev'))
  public readonly ded_Dis = new QStringPath(this.withPrefix('ded_Dis'))
  public readonly mainDocumentUrl = new QStringPath(this.withPrefix('mainDocumentUrl'))
  public readonly attachmentsFolderUrl = new QStringPath(this.withPrefix('attachmentsFolderUrl'))
  public readonly ded_Id = new QNumberPath(this.withPrefix('ded_Id'))
  public readonly revisione = new QNumberPath(this.withPrefix('revisione'))
  public readonly data = new QDateTimeOffsetPath(this.withPrefix('data'))
  public readonly creazione = new QDateTimeOffsetPath(this.withPrefix('creazione'))
  public readonly argomento = new QStringPath(this.withPrefix('argomento'))
  public readonly anno_rif = new QNumberPath(this.withPrefix('anno_rif'))
  public readonly tipologia = new QEntityPath(this.withPrefix('tipologia'), () => Qtipologia_riunione)
  public readonly autore = new QEntityPath(this.withPrefix('autore'), () => Quser)
  public readonly impiegati = new QEntityCollectionPath(this.withPrefix('impiegati'), () => Quser)
  public readonly documenti = new QEntityCollectionPath(this.withPrefix('documenti'), () => Qdocumento_riunione)
}

export const qriunione = new Qriunione()

export class QriunioneId extends QId<riunioneId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class riunione_QCreateDocument extends QAction {
  private readonly params: [] = []

  constructor() {
    super('CreateDocument')
  }

  getParams() {
    return this.params
  }
}

export class Qtipologia_riunione extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly riunioni = new QEntityCollectionPath(this.withPrefix('riunioni'), () => Qriunione)
}

export const qtipologia_riunione = new Qtipologia_riunione()

export class Qtipologia_riunioneId extends QId<tipologia_riunioneId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qdocumento_ticket extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly ticket = new QEntityPath(this.withPrefix('ticket'), () => Qticket)
  public readonly documento = new QEntityPath(this.withPrefix('documento'), () => Qdocumento)
  public readonly tipologia = new QEntityPath(this.withPrefix('tipologia'), () => Qtipologia_documento)
}

export const qdocumento_ticket = new Qdocumento_ticket()

export class Qdocumento_ticketId extends QId<documento_ticketId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qticket extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly ded_RootFam = new QStringPath(this.withPrefix('ded_RootFam'))
  public readonly ded_Dis = new QStringPath(this.withPrefix('ded_Dis'))
  public readonly attachmentsFolderUrl = new QStringPath(this.withPrefix('attachmentsFolderUrl'))
  public readonly ded_Id = new QNumberPath(this.withPrefix('ded_Id'))
  public readonly contattato = new QBooleanPath(this.withPrefix('contattato'))
  public readonly attesa = new QBooleanPath(this.withPrefix('attesa'))
  public readonly urgente = new QBooleanPath(this.withPrefix('urgente'))
  public readonly notifica_chiusura = new QBooleanPath(this.withPrefix('notifica_chiusura'))
  public readonly rating = new QNumberPath(this.withPrefix('rating'))
  public readonly rating_note = new QStringPath(this.withPrefix('rating_note'))
  public readonly apertura = new QDateTimeOffsetPath(this.withPrefix('apertura'))
  public readonly chiusura = new QDateTimeOffsetPath(this.withPrefix('chiusura'))
  public readonly descrizione = new QStringPath(this.withPrefix('descrizione'))
  public readonly soluzione = new QStringPath(this.withPrefix('soluzione'))
  public readonly leg_tag1 = new QStringPath(this.withPrefix('leg_tag1'))
  public readonly leg_tag2 = new QStringPath(this.withPrefix('leg_tag2'))
  public readonly durata = new QNumberPath(this.withPrefix('durata'))
  public readonly leg_contatto = new QStringPath(this.withPrefix('leg_contatto'))
  public readonly leg_n_chiamate = new QNumberPath(this.withPrefix('leg_n_chiamate'))
  public readonly leg_phone_contatto = new QStringPath(this.withPrefix('leg_phone_contatto'))
  public readonly leg_email_contatto = new QStringPath(this.withPrefix('leg_email_contatto'))
  public readonly tipologia_ticket = new QEntityPath(this.withPrefix('tipologia_ticket'), () => Qtipologia_ticket)
  public readonly assegnato = new QEntityPath(this.withPrefix('assegnato'), () => Quser)
  public readonly tipologia_problema = new QEntityPath(this.withPrefix('tipologia_problema'), () => Qtipologia_problema)
  public readonly argomento_problema = new QEntityPath(this.withPrefix('argomento_problema'), () => Qargomento_problema)
  public readonly stato = new QEntityPath(this.withPrefix('stato'), () => Qstato_ticket)
  public readonly piattaforma = new QEntityPath(this.withPrefix('piattaforma'), () => Qpiattaforma_ticket)
  public readonly applicazione = new QEntityPath(this.withPrefix('applicazione'), () => Qqs_supporto_applicazione)
  public readonly creatore = new QEntityPath(this.withPrefix('creatore'), () => Quser)
  public readonly incaricato = new QEntityPath(this.withPrefix('incaricato'), () => Quser)
  public readonly sede = new QEntityPath(this.withPrefix('sede'), () => Qsede)
  public readonly documenti = new QEntityCollectionPath(this.withPrefix('documenti'), () => Qdocumento_ticket)
  public readonly note_ticket = new QEntityCollectionPath(this.withPrefix('note_ticket'), () => Qnota_ticket)
  public readonly eventi = new QEntityCollectionPath(this.withPrefix('eventi'), () => Qevento_ticket)
}

export const qticket = new Qticket()

export class QticketId extends QId<ticketId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class ticket_QSetUrgent extends QAction {
  private readonly params: [] = []

  constructor() {
    super('SetUrgent')
  }

  getParams() {
    return this.params
  }
}

export class ticket_QSetWait extends QAction {
  private readonly params: [] = []

  constructor() {
    super('SetWait')
  }

  getParams() {
    return this.params
  }
}

export class ticket_QClearWait extends QAction {
  private readonly params: [] = []

  constructor() {
    super('ClearWait')
  }

  getParams() {
    return this.params
  }
}

export class ticket_QIncrementCallNumber extends QAction {
  private readonly params: [] = []

  constructor() {
    super('IncrementCallNumber')
  }

  getParams() {
    return this.params
  }
}

export class ticket_QCancel extends QAction {
  private readonly params: [] = []

  constructor() {
    super('Cancel')
  }

  getParams() {
    return this.params
  }
}

export class ticket_QRemoveAssignee extends QAction {
  private readonly params: [] = []

  constructor() {
    super('RemoveAssignee')
  }

  getParams() {
    return this.params
  }
}

export class ticket_QTakeCharge extends QAction {
  private readonly params: [] = []

  constructor() {
    super('TakeCharge')
  }

  getParams() {
    return this.params
  }
}

export class ticket_QRelease extends QAction {
  private readonly params: [] = []

  constructor() {
    super('Release')
  }

  getParams() {
    return this.params
  }
}

export class ticket_QSendClosureNotice extends QAction {
  private readonly params: [] = []

  constructor() {
    super('SendClosureNotice')
  }

  getParams() {
    return this.params
  }
}

export class ticket_QSendContactNotice extends QAction {
  private readonly params: [] = []

  constructor() {
    super('SendContactNotice')
  }

  getParams() {
    return this.params
  }
}

export class ticket_QSendSupportExpiredNotice extends QAction {
  private readonly params: [] = []

  constructor() {
    super('SendSupportExpiredNotice')
  }

  getParams() {
    return this.params
  }
}

export class ticket_QSendOtherVendorNotice extends QAction {
  private readonly params: [] = []

  constructor() {
    super('SendOtherVendorNotice')
  }

  getParams() {
    return this.params
  }
}

export class ticket_QReopen extends QAction {
  private readonly params: [] = []

  constructor() {
    super('Reopen')
  }

  getParams() {
    return this.params
  }
}

export class Qtipologia_ticket extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly tickets = new QEntityCollectionPath(this.withPrefix('tickets'), () => Qticket)
}

export const qtipologia_ticket = new Qtipologia_ticket()

export class Qtipologia_ticketId extends QId<tipologia_ticketId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qtipologia_problema extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly tickets = new QEntityCollectionPath(this.withPrefix('tickets'), () => Qticket)
}

export const qtipologia_problema = new Qtipologia_problema()

export class Qtipologia_problemaId extends QId<tipologia_problemaId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qargomento_problema extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly ord = new QNumberPath(this.withPrefix('ord'))
  public readonly qs_applicazione = new QEntityPath(this.withPrefix('qs_applicazione'), () => Qqs_supporto_applicazione)
  public readonly piattaforma = new QEntityPath(this.withPrefix('piattaforma'), () => Qpiattaforma_ticket)
  public readonly tickets = new QEntityCollectionPath(this.withPrefix('tickets'), () => Qticket)
}

export const qargomento_problema = new Qargomento_problema()

export class Qargomento_problemaId extends QId<argomento_problemaId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qqs_supporto_applicazione extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly argomenti_problemi = new QEntityCollectionPath(
    this.withPrefix('argomenti_problemi'),
    () => Qargomento_problema,
  )
  public readonly tickets = new QEntityCollectionPath(this.withPrefix('tickets'), () => Qticket)
}

export const qqs_supporto_applicazione = new Qqs_supporto_applicazione()

export class Qqs_supporto_applicazioneId extends QId<qs_supporto_applicazioneId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qpiattaforma_ticket extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly argomenti_problemi = new QEntityCollectionPath(
    this.withPrefix('argomenti_problemi'),
    () => Qargomento_problema,
  )
  public readonly tickets = new QEntityCollectionPath(this.withPrefix('tickets'), () => Qticket)
}

export const qpiattaforma_ticket = new Qpiattaforma_ticket()

export class Qpiattaforma_ticketId extends QId<piattaforma_ticketId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qstato_ticket extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly tickets = new QEntityCollectionPath(this.withPrefix('tickets'), () => Qticket)
}

export const qstato_ticket = new Qstato_ticket()

export class Qstato_ticketId extends QId<stato_ticketId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qnota_ticket extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly data = new QDateTimeOffsetPath(this.withPrefix('data'))
  public readonly inviata = new QBooleanPath(this.withPrefix('inviata'))
  public readonly chiusura = new QBooleanPath(this.withPrefix('chiusura'))
  public readonly testo = new QStringPath(this.withPrefix('testo'))
  public readonly tag = new QStringPath(this.withPrefix('tag'))
  public readonly ticket = new QEntityPath(this.withPrefix('ticket'), () => Qticket)
  public readonly utente = new QEntityPath(this.withPrefix('utente'), () => Quser)
  public readonly emails = new QEntityCollectionPath(this.withPrefix('emails'), () => Qticket_email)
}

export const qnota_ticket = new Qnota_ticket()

export class Qnota_ticketId extends QId<nota_ticketId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qticket_email extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly messageId = new QStringPath(this.withPrefix('messageId'))
  public readonly incoming = new QBooleanPath(this.withPrefix('incoming'))
  public readonly nota_ticket = new QEntityPath(this.withPrefix('nota_ticket'), () => Qnota_ticket)
}

export const qticket_email = new Qticket_email()

export class Qticket_emailId extends QId<ticket_emailId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qevento_ticket extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly data = new QDateTimeOffsetPath(this.withPrefix('data'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly ticket = new QEntityPath(this.withPrefix('ticket'), () => Qticket)
  public readonly utente = new QEntityPath(this.withPrefix('utente'), () => Quser)
  public readonly tipologia = new QEntityPath(this.withPrefix('tipologia'), () => Qtipologia_evento_ticket)
}

export const qevento_ticket = new Qevento_ticket()

export class Qevento_ticketId extends QId<evento_ticketId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qtipologia_evento_ticket extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly eventi_ticket = new QEntityCollectionPath(this.withPrefix('eventi_ticket'), () => Qevento_ticket)
}

export const qtipologia_evento_ticket = new Qtipologia_evento_ticket()

export class Qtipologia_evento_ticketId extends QId<tipologia_evento_ticketId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qdocumento_ordine extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly ordine = new QEntityPath(this.withPrefix('ordine'), () => Qordine)
  public readonly documento = new QEntityPath(this.withPrefix('documento'), () => Qdocumento)
  public readonly tipologia = new QEntityPath(this.withPrefix('tipologia'), () => Qtipologia_documento)
}

export const qdocumento_ordine = new Qdocumento_ordine()

export class Qdocumento_ordineId extends QId<documento_ordineId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qdocumento_offerta extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly offerta = new QEntityPath(this.withPrefix('offerta'), () => Qofferta)
  public readonly documento = new QEntityPath(this.withPrefix('documento'), () => Qdocumento)
  public readonly tipologia = new QEntityPath(this.withPrefix('tipologia'), () => Qtipologia_documento)
}

export const qdocumento_offerta = new Qdocumento_offerta()

export class Qdocumento_offertaId extends QId<documento_offertaId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qdocumento_commessa extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly documento = new QEntityPath(this.withPrefix('documento'), () => Qdocumento)
  public readonly commessa = new QEntityPath(this.withPrefix('commessa'), () => Qcommessa)
  public readonly tipologia = new QEntityPath(this.withPrefix('tipologia'), () => Qtipologia_documento)
}

export const qdocumento_commessa = new Qdocumento_commessa()

export class Qdocumento_commessaId extends QId<documento_commessaId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qdocumento_sede extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly sede = new QEntityPath(this.withPrefix('sede'), () => Qsede)
  public readonly documento = new QEntityPath(this.withPrefix('documento'), () => Qdocumento)
  public readonly tipologia = new QEntityPath(this.withPrefix('tipologia'), () => Qtipologia_documento)
}

export const qdocumento_sede = new Qdocumento_sede()

export class Qdocumento_sedeId extends QId<documento_sedeId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qest_licenza extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly seriale = new QStringPath(this.withPrefix('seriale'))
  public readonly altro_fornitore = new QBooleanPath(this.withPrefix('altro_fornitore'))
  public readonly versione = new QStringPath(this.withPrefix('versione'))
  public readonly data_vendita = new QDateTimeOffsetPath(this.withPrefix('data_vendita'))
  public readonly scadenza = new QDateTimeOffsetPath(this.withPrefix('scadenza'))
  public readonly rete = new QBooleanPath(this.withPrefix('rete'))
  public readonly n_contratto = new QStringPath(this.withPrefix('n_contratto'))
  public readonly postazioni = new QNumberPath(this.withPrefix('postazioni'))
  public readonly durata = new QNumberPath(this.withPrefix('durata'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly software = new QEntityPath(this.withPrefix('software'), () => Qest_software)
  public readonly piano_mantenimento = new QEntityPath(
    this.withPrefix('piano_mantenimento'),
    () => Qest_piano_mantenimento,
  )
  public readonly stato_noleggio = new QEntityPath(this.withPrefix('stato_noleggio'), () => Qest_stato_noleggio)
  public readonly sede = new QEntityPath(this.withPrefix('sede'), () => Qsede)
}

export const qest_licenza = new Qest_licenza()

export class Qest_licenzaId extends QId<est_licenzaId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qest_software extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly licenze_est = new QEntityCollectionPath(this.withPrefix('licenze_est'), () => Qest_licenza)
}

export const qest_software = new Qest_software()

export class Qest_softwareId extends QId<est_softwareId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qest_piano_mantenimento extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly licenze_est = new QEntityCollectionPath(this.withPrefix('licenze_est'), () => Qest_licenza)
}

export const qest_piano_mantenimento = new Qest_piano_mantenimento()

export class Qest_piano_mantenimentoId extends QId<est_piano_mantenimentoId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qest_stato_noleggio extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly licenze_est = new QEntityCollectionPath(this.withPrefix('licenze_est'), () => Qest_licenza)
}

export const qest_stato_noleggio = new Qest_stato_noleggio()

export class Qest_stato_noleggioId extends QId<est_stato_noleggioId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qassistenza extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly ded_RootFam = new QStringPath(this.withPrefix('ded_RootFam'))
  public readonly ded_SubFam = new QStringPath(this.withPrefix('ded_SubFam'))
  public readonly ded_Rev = new QStringPath(this.withPrefix('ded_Rev'))
  public readonly ded_Dis = new QStringPath(this.withPrefix('ded_Dis'))
  public readonly posti = new QNumberPath(this.withPrefix('posti'))
  public readonly sospesa = new QBooleanPath(this.withPrefix('sospesa'))
  public readonly ded_Id = new QNumberPath(this.withPrefix('ded_Id'))
  public readonly importo_precedente = new QNumberPath(this.withPrefix('importo_precedente'))
  public readonly tariffa_oraria = new QNumberPath(this.withPrefix('tariffa_oraria'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly canone = new QNumberPath(this.withPrefix('canone'))
  public readonly canone_personalizzazioni = new QNumberPath(this.withPrefix('canone_personalizzazioni'))
  public readonly canone_telefonica = new QNumberPath(this.withPrefix('canone_telefonica'))
  public readonly durata = new QNumberPath(this.withPrefix('durata'))
  public readonly scadenza = new QDateTimeOffsetPath(this.withPrefix('scadenza'))
  public readonly data_fatturazione = new QDateTimeOffsetPath(this.withPrefix('data_fatturazione'))
  public readonly seconda_fatturazione = new QDateTimeOffsetPath(this.withPrefix('seconda_fatturazione'))
  public readonly data_inizio = new QDateTimeOffsetPath(this.withPrefix('data_inizio'))
  public readonly data_sospensione = new QDateTimeOffsetPath(this.withPrefix('data_sospensione'))
  public readonly fatturazione_viaggio = new QBooleanPath(this.withPrefix('fatturazione_viaggio'))
  public readonly timbro_firma = new QBooleanPath(this.withPrefix('timbro_firma'))
  public readonly disdetta_telefonica = new QDateTimeOffsetPath(this.withPrefix('disdetta_telefonica'))
  public readonly disdetta_aggiornamenti = new QDateTimeOffsetPath(this.withPrefix('disdetta_aggiornamenti'))
  public readonly revisione = new QNumberPath(this.withPrefix('revisione'))
  public readonly maintenance = new QEntityPath(this.withPrefix('maintenance'), () => Qtipologia_assistenza)
  public readonly telefonica = new QEntityPath(this.withPrefix('telefonica'), () => Qtipologia_assistenza)
  public readonly sede = new QEntityPath(this.withPrefix('sede'), () => Qsede)
  public readonly riferimento = new QEntityPath(this.withPrefix('riferimento'), () => Qassistenza)
}

export const qassistenza = new Qassistenza()

export class QassistenzaId extends QId<assistenzaId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qtipologia_assistenza extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly maintenance = new QEntityCollectionPath(this.withPrefix('maintenance'), () => Qassistenza)
  public readonly telefonica = new QEntityCollectionPath(this.withPrefix('telefonica'), () => Qassistenza)
}

export const qtipologia_assistenza = new Qtipologia_assistenza()

export class Qtipologia_assistenzaId extends QId<tipologia_assistenzaId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qqs_licenza extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly ded_RootFam = new QStringPath(this.withPrefix('ded_RootFam'))
  public readonly ded_SubFam = new QStringPath(this.withPrefix('ded_SubFam'))
  public readonly ded_Dis = new QStringPath(this.withPrefix('ded_Dis'))
  public readonly aggiornamenti = new QStringPath(this.withPrefix('aggiornamenti'))
  public readonly tipologia = new QStringPath(this.withPrefix('tipologia'))
  public readonly data_associazione = new QDateTimeOffsetPath(this.withPrefix('data_associazione'))
  public readonly ded_Id = new QNumberPath(this.withPrefix('ded_Id'))
  public readonly scadenza = new QDateTimeOffsetPath(this.withPrefix('scadenza'))
  public readonly chiave = new QStringPath(this.withPrefix('chiave'))
  public readonly id_chiave = new QStringPath(this.withPrefix('id_chiave'))
  public readonly rete = new QBooleanPath(this.withPrefix('rete'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly attenzione_speciale = new QBooleanPath(this.withPrefix('attenzione_speciale'))
  public readonly leg_note_sw_aggiunto = new QStringPath(this.withPrefix('leg_note_sw_aggiunto'))
  public readonly hardware = new QBooleanPath(this.withPrefix('hardware'))
  public readonly rete_postazioni = new QNumberPath(this.withPrefix('rete_postazioni'))
  public readonly versione_uso = new QEntityPath(this.withPrefix('versione_uso'), () => Qqs_versione)
  public readonly sede = new QEntityPath(this.withPrefix('sede'), () => Qsede)
  public readonly stato_licenza = new QEntityPath(this.withPrefix('stato_licenza'), () => Qstato_qs_licenza)
  public readonly storico = new QEntityCollectionPath(this.withPrefix('storico'), () => Qqs_storico_licenza)
  public readonly info_applicazioni = new QEntityCollectionPath(
    this.withPrefix('info_applicazioni'),
    () => Qqs_licenza_applicazione,
  )
}

export const qqs_licenza = new Qqs_licenza()

export class Qqs_licenzaId extends QId<qs_licenzaId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qqs_versione extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly major = new QNumberPath(this.withPrefix('major'))
  public readonly minor = new QNumberPath(this.withPrefix('minor'))
  public readonly sub = new QNumberPath(this.withPrefix('sub'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly licenze = new QEntityCollectionPath(this.withPrefix('licenze'), () => Qqs_licenza)
  public readonly versioni_spedite = new QEntityCollectionPath(
    this.withPrefix('versioni_spedite'),
    () => Qqs_storico_licenza,
  )
}

export const qqs_versione = new Qqs_versione()

export class Qqs_versioneId extends QId<qs_versioneId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qqs_storico_licenza extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly data = new QDateTimeOffsetPath(this.withPrefix('data'))
  public readonly licenza = new QEntityPath(this.withPrefix('licenza'), () => Qqs_licenza)
  public readonly versione = new QEntityPath(this.withPrefix('versione'), () => Qqs_versione)
  public readonly sede = new QEntityPath(this.withPrefix('sede'), () => Qsede)
}

export const qqs_storico_licenza = new Qqs_storico_licenza()

export class Qqs_storico_licenzaId extends QId<qs_storico_licenzaId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qstato_qs_licenza extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly licenze = new QEntityCollectionPath(this.withPrefix('licenze'), () => Qqs_licenza)
}

export const qstato_qs_licenza = new Qstato_qs_licenza()

export class Qstato_qs_licenzaId extends QId<stato_qs_licenzaId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qqs_licenza_applicazione extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly data_associazione = new QDateTimeOffsetPath(this.withPrefix('data_associazione'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly aggiornamenti = new QBooleanPath(this.withPrefix('aggiornamenti'))
  public readonly passaggio_noleggio = new QBooleanPath(this.withPrefix('passaggio_noleggio'))
  public readonly scadenza = new QDateTimeOffsetPath(this.withPrefix('scadenza'))
  public readonly tipologia_licenza = new QEntityPath(this.withPrefix('tipologia_licenza'), () => Qtipologia_qs_licenza)
  public readonly ultima_versione = new QEntityPath(this.withPrefix('ultima_versione'), () => Qqs_versione)
  public readonly applicazione = new QEntityPath(this.withPrefix('applicazione'), () => Qqs_applicazione)
  public readonly licenza = new QEntityPath(this.withPrefix('licenza'), () => Qqs_licenza)
}

export const qqs_licenza_applicazione = new Qqs_licenza_applicazione()

export class Qqs_licenza_applicazioneId extends QId<qs_licenza_applicazioneId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qtipologia_qs_licenza extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly qs_licenza_applicazioni = new QEntityCollectionPath(
    this.withPrefix('qs_licenza_applicazioni'),
    () => Qqs_licenza_applicazione,
  )
}

export const qtipologia_qs_licenza = new Qtipologia_qs_licenza()

export class Qtipologia_qs_licenzaId extends QId<tipologia_qs_licenzaId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qqs_applicazione extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly applicazioni_licenze = new QEntityCollectionPath(
    this.withPrefix('applicazioni_licenze'),
    () => Qqs_licenza_applicazione,
  )
}

export const qqs_applicazione = new Qqs_applicazione()

export class Qqs_applicazioneId extends QId<qs_applicazioneId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qtemplate_offerta extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly offerte = new QEntityCollectionPath(this.withPrefix('offerte'), () => Qofferta)
}

export const qtemplate_offerta = new Qtemplate_offerta()

export class Qtemplate_offertaId extends QId<template_offertaId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qpersonalizzazione extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly rev = new QNumberPath(this.withPrefix('rev'))
  public readonly data = new QDateTimeOffsetPath(this.withPrefix('data'))
  public readonly ordine = new QEntityPath(this.withPrefix('ordine'), () => Qordine)
  public readonly task = new QEntityPath(this.withPrefix('task'), () => Qtask)
}

export const qpersonalizzazione = new Qpersonalizzazione()

export class QpersonalizzazioneId extends QId<personalizzazioneId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qpunto_fatturazione_ordine extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly creazione = new QDateTimeOffsetPath(this.withPrefix('creazione'))
  public readonly ded_RootFam = new QStringPath(this.withPrefix('ded_RootFam'))
  public readonly ded_Dis = new QStringPath(this.withPrefix('ded_Dis'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly progressivo_ordine = new QNumberPath(this.withPrefix('progressivo_ordine'))
  public readonly ordine = new QEntityPath(this.withPrefix('ordine'), () => Qordine)
  public readonly stato = new QEntityPath(this.withPrefix('stato'), () => Qstato_punto_fatturazione)
  public readonly tasks = new QEntityCollectionPath(this.withPrefix('tasks'), () => Qtask)
}

export const qpunto_fatturazione_ordine = new Qpunto_fatturazione_ordine()

export class Qpunto_fatturazione_ordineId extends QId<punto_fatturazione_ordineId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qstato_punto_fatturazione extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly punti_fatturazione_ordine = new QEntityCollectionPath(
    this.withPrefix('punti_fatturazione_ordine'),
    () => Qpunto_fatturazione_ordine,
  )
}

export const qstato_punto_fatturazione = new Qstato_punto_fatturazione()

export class Qstato_punto_fatturazioneId extends QId<stato_punto_fatturazioneId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qstato_task extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly tasks = new QEntityCollectionPath(this.withPrefix('tasks'), () => Qtask)
}

export const qstato_task = new Qstato_task()

export class Qstato_taskId extends QId<stato_taskId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qinfo_impiegato extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly email_rec = new QStringPath(this.withPrefix('email_rec'))
  public readonly ore = new QNumberPath(this.withPrefix('ore'))
  public readonly cellulare = new QStringPath(this.withPrefix('cellulare'))
  public readonly interno = new QNumberPath(this.withPrefix('interno'))
  public readonly inps = new QStringPath(this.withPrefix('inps'))
  public readonly matricola = new QStringPath(this.withPrefix('matricola'))
  public readonly divisione = new QStringPath(this.withPrefix('divisione'))
  public readonly entrata_mattina = new QStringPath(this.withPrefix('entrata_mattina'))
  public readonly entrata_pomerig = new QStringPath(this.withPrefix('entrata_pomerig'))
  public readonly auto = new QEntityPath(this.withPrefix('auto'), () => Qauto)
  public readonly badge = new QEntityPath(this.withPrefix('badge'), () => Qbadge)
  public readonly users = new QEntityCollectionPath(this.withPrefix('users'), () => Quser)
}

export const qinfo_impiegato = new Qinfo_impiegato()

export class Qinfo_impiegatoId extends QId<info_impiegatoId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qauto extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly modello = new QStringPath(this.withPrefix('modello'))
  public readonly targa = new QStringPath(this.withPrefix('targa'))
}

export const qauto = new Qauto()

export class QautoId extends QId<autoId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class Qbadge extends QueryObject {
  public readonly id = new QStringPath(this.withPrefix('id'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly impiegati = new QEntityCollectionPath(this.withPrefix('impiegati'), () => Qinfo_impiegato)
}

export const qbadge = new Qbadge()

export class QbadgeId extends QId<badgeId> {
  private readonly params = [new QStringParam('id')]

  getParams() {
    return this.params
  }
}

export class Qtelefonata extends QueryObject {
  public readonly id = new QNumberPath(this.withPrefix('id'))
  public readonly contatto = new QStringPath(this.withPrefix('contatto'))
  public readonly data = new QDateTimeOffsetPath(this.withPrefix('data'))
  public readonly note = new QStringPath(this.withPrefix('note'))
  public readonly autore = new QEntityPath(this.withPrefix('autore'), () => Quser)
  public readonly destinatario = new QEntityPath(this.withPrefix('destinatario'), () => Quser)
}

export const qtelefonata = new Qtelefonata()

export class QtelefonataId extends QId<telefonataId> {
  private readonly params = [new QNumberParam('id')]

  getParams() {
    return this.params
  }
}

export class QApplicationUserLoginInfo extends QBaseObject {
  public readonly LoginProviderName = new QStringPath(this.withPrefix('LoginProviderName'))
  public readonly ProviderUserKey = new QStringPath(this.withPrefix('ProviderUserKey'))
  public readonly User = new QEntityPath(this.withPrefix('User'), () => QApplicationUser)
}

export const qApplicationUserLoginInfo = new QApplicationUserLoginInfo()

export class Qad_product_catalog extends QueryObject {
  public readonly id = new QGuidPath(this.withPrefix('id'))
  public readonly offeringName = new QStringPath(this.withPrefix('offeringName'))
  public readonly offeringCode = new QStringPath(this.withPrefix('offeringCode'))
  public readonly offeringId = new QStringPath(this.withPrefix('offeringId'))
  public readonly accessModelCode = new QStringPath(this.withPrefix('accessModelCode'))
  public readonly accessModelDescription = new QStringPath(this.withPrefix('accessModelDescription'))
  public readonly servicePlanCode = new QStringPath(this.withPrefix('servicePlanCode'))
  public readonly servicePlanDescription = new QStringPath(this.withPrefix('servicePlanDescription'))
  public readonly termCode = new QStringPath(this.withPrefix('termCode'))
  public readonly termDescription = new QStringPath(this.withPrefix('termDescription'))
}

export const qad_product_catalog = new Qad_product_catalog()

export class Qad_product_catalogId extends QId<ad_product_catalogId> {
  private readonly params = [new QGuidParam('id')]

  getParams() {
    return this.params
  }
}

export class Qad_promotion extends QueryObject {
  public readonly id = new QGuidPath(this.withPrefix('id'))
  public readonly code = new QStringPath(this.withPrefix('code'))
  public readonly name = new QStringPath(this.withPrefix('name'))
  public readonly description = new QStringPath(this.withPrefix('description'))
  public readonly startDate = new QDateTimeOffsetPath(this.withPrefix('startDate'))
  public readonly endDate = new QDateTimeOffsetPath(this.withPrefix('endDate'))
  public readonly orderAction = new QStringPath(this.withPrefix('orderAction'))
  public readonly offeringId = new QStringPath(this.withPrefix('offeringId'))
  public readonly offeringCode = new QStringPath(this.withPrefix('offeringCode'))
  public readonly intendedUsageCode = new QStringPath(this.withPrefix('intendedUsageCode'))
  public readonly termCode = new QStringPath(this.withPrefix('termCode'))
  public readonly accessModelCode = new QStringPath(this.withPrefix('accessModelCode'))
  public readonly autoApply = new QBooleanPath(this.withPrefix('autoApply'))
  public readonly quantityNeeded = new QNumberPath(this.withPrefix('quantityNeeded'))
  public readonly um = new QStringPath(this.withPrefix('um'))
  public readonly discount = new QNumberPath(this.withPrefix('discount'))
  public readonly currency = new QStringPath(this.withPrefix('currency'))
}

export const qad_promotion = new Qad_promotion()

export class Qad_promotionId extends QId<ad_promotionId> {
  private readonly params = [new QGuidParam('id')]

  getParams() {
    return this.params
  }
}

export class Qconstants extends QueryObject {
  public readonly nome = new QStringPath(this.withPrefix('nome'))
  public readonly valore = new QStringPath(this.withPrefix('valore'))
}

export const qconstants = new Qconstants()

export class QconstantsId extends QId<constantsId> {
  private readonly params = [new QStringParam('nome')]

  getParams() {
    return this.params
  }
}

export class QType extends QueryObject {
  public readonly GenericTypeArguments = new QEntityCollectionPath(this.withPrefix('GenericTypeArguments'), () => QType)
  public readonly CustomAttributes = new QEntityCollectionPath(
    this.withPrefix('CustomAttributes'),
    () => QCustomAttributeData,
  )
}

export const qType = new QType()

export class QCustomAttributeData extends QueryObject {
  public readonly ConstructorArguments = new QEntityCollectionPath(
    this.withPrefix('ConstructorArguments'),
    () => QCustomAttributeTypedArgument,
  )
  public readonly NamedArguments = new QEntityCollectionPath(
    this.withPrefix('NamedArguments'),
    () => QCustomAttributeNamedArgument,
  )
}

export const qCustomAttributeData = new QCustomAttributeData()

export class QCustomAttributeTypedArgument extends QueryObject {}

export const qCustomAttributeTypedArgument = new QCustomAttributeTypedArgument()

export class QCustomAttributeNamedArgument extends QueryObject {}

export const qCustomAttributeNamedArgument = new QCustomAttributeNamedArgument()
