import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { LoaderFunction, RouteObject } from 'react-router-dom'
import { RoleRouteObject, RouteFunctionParams } from '@/types'
import SoftwareAltrePiattaformeGrid from '@/routes/software/altre-piattaforme/SoftwareAltrePiattaformeGrid'
import {
  SoftwareAltrePiattaformeCreatorRoute,
  SoftwareAltrePiattaformeEditorRoute,
} from '@/routes/software/altre-piattaforme/SoftwareAltrePiattaformeEditor.route'
import { Roles } from '@/auth/azure/Roles'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import { azienda } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { ODataModelResponseV4 } from '@odata2ts/odata-core'
import { HttpResponseModel } from '@odata2ts/http-client-api'

export const SoftwareAltrePiattaformeGridLoader = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const clientId = params.clientId
  let azienda: HttpResponseModel<ODataModelResponseV4<azienda>> | undefined
  if (clientId)
    azienda = await qsAdminApi.azienda(Number(clientId)).query((builder, azienda) => {
      builder.select('id', 'nome')
    })

  const title = azienda ? `${azienda.data.nome} - Software Altri` : 'Software Altri'

  const getAziende = qsAdminApi.azienda().query((builder, qazienda) => {
    builder.orderBy(qazienda.nome.asc())
    builder.select('id', 'nome')
  })

  const getPlatforms = qsAdminApi.est_software().query((builder, software) => {
    builder.select('id', 'nome')
    builder.orderBy(software.nome.asc())
  })

  const getManteinancePlans = qsAdminApi.est_piano_mantenimento().query((builder, piano) => {
    builder.select('id', 'nome')
    builder.orderBy(piano.nome.asc())
  })

  const getRentStatus = qsAdminApi.est_stato_noleggio().query((builder, stato) => {
    builder.select('id', 'nome')
    builder.orderBy(stato.nome.asc())
  })

  return {
    title,
    aziendaList: await getAziende,
    altrePiattaformePlatforms: await getPlatforms,
    altrePiattaformeMaintenancePlans: await getManteinancePlans,
    altrePiattaformeRentStatus: await getRentStatus,
    userInfo: await getAzureUserInformation(),
    defaultCRUDAllowedRoles: [Roles.GlobalAdministrator, Roles.Administrator, Roles.Supervisor],
  }
}) satisfies LoaderFunction

export const SoftwareAltrePiattaformeRoute = {
  path: 'altrepiattaforme',
  children: [
    {
      index: true,
      loader: SoftwareAltrePiattaformeGridLoader,
      element: <SoftwareAltrePiattaformeGrid />,
      allowedRoles: [Roles.Guest],
    } as RoleRouteObject,
    SoftwareAltrePiattaformeCreatorRoute,
    SoftwareAltrePiattaformeEditorRoute,
  ],
  icon: 'codeblock',
  text: 'Software Altri',
  allowedRoles: [Roles.Guest],
} as RoleRouteObject
