import { RouteObject, redirect } from 'react-router-dom'
import { defer, LoaderFunction, ActionFunction } from 'react-router-typesafe'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import PersonCreator from '@/routes/contacts/person/PersonCreator'
import { RoleRouteObject, RouteFunctionParams } from '@/types'
import { contatto_aziendale, azienda } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { Roles } from '@/auth/azure/Roles'
import { getAzureUserInformation } from '@/auth/azure/azureManager'

import { ODataModelResponseV4 } from '@odata2ts/odata-core'
import { HttpResponseModel } from '@odata2ts/http-client-api'

export const personCreatorRouteLoader = (async ({ params }: RouteFunctionParams<'clientId'>) => {
  const qsAdminApi = useQsAdminApiManager.getState().service
  const clientId = params.clientId
  let azienda: HttpResponseModel<ODataModelResponseV4<azienda>> | undefined
  if (clientId)
    azienda = await qsAdminApi.azienda(Number(clientId)).query((builder, azienda) => {
      builder.select('id', 'nome')
    })
  const title = azienda ? `${azienda.data.nome} - Nuovo contatto` : 'Nuovo contatto'
  const contact: contatto_aziendale = {
    id: 0,
    nome: null,
    cognome: null,
    ruolo: null,
    note: null,
    titolo: null,
    telefono: null,
    attivo: true,
    emails: [
      {
        id: 0,
        email: null,
        note: null,
        rif_ad: false,
      },
    ],
    fullname: '',
    ad_recipient: false,
  }
  const getRapporti = qsAdminApi.rapporto().query((builder, rapporto) => {
    builder.select('id', 'nome')
    builder.orderBy(rapporto.nome.asc())
  })
  const getAziende = qsAdminApi.azienda().query((builder, qazienda) => {
    builder.orderBy(qazienda.nome.asc())
    builder.select('id', 'nome')
    builder.filter(qazienda.sedi.any())
    builder.filter(clientId ? qazienda.id.eq(Number(clientId)) : null)
  })
  return defer({
    title,
    contact,
    getRapporti,
    getAziende,
    getUserInfo: getAzureUserInformation(),
  })
}) satisfies LoaderFunction

const personCreatorRouteAction = (async ({ request }: RouteFunctionParams) => {
  const qsAdminApi = useQsAdminApiManager.getState().service
  const contact = (await request.json()) as contatto_aziendale
  const res = await qsAdminApi.contatto_aziendale().create(contact)
  return redirect(`..`)
}) satisfies ActionFunction

const PersonCreatorRoute = {
  path: 'new',
  element: <PersonCreator />,
  loader: personCreatorRouteLoader,
  action: personCreatorRouteAction,
  allowedRoles: [Roles.Guest],
} as RoleRouteObject

export default PersonCreatorRoute
