import { ArraySourceComposer } from '@/auth/api/connector'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { contatto_aziendale, email_aziendale, sede, azienda } from '@/model/qsadminapi/QsAdminApiModuleModel'
import {
  ButtonItem,
  ButtonOptions,
  Form as DxForm,
  EmailRule,
  EmptyItem,
  GroupItem,
  RequiredRule,
  SimpleItem,
  Tab,
  TabPanelOptions,
  TabbedItem,
  FormRef,
} from 'devextreme-react/form'
import DataSource from 'devextreme/data/data_source'
import { ClickEvent } from 'devextreme/ui/button'
import { useCallback, useEffect, useRef, useState } from 'react'
import { ValueChangedEvent as ValueChangedEventSelectBox } from 'devextreme/ui/select_box'
import { FieldDataChangedEvent } from 'devextreme/ui/form'
import { PersonCreatorFormProps } from '@/routes/contacts/person/PersonCreator.types'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { useParams } from 'react-router-dom'
import FormPopupClientNote from '@/components/form-popup-client-note/FormPopupClientNote'
import { PopupRef } from 'devextreme-react/cjs/popup'
import SiteSimpleItem from '@/components/site-simple-item/SiteSimpleItem'
import notify from 'devextreme/ui/notify'
import { customButton, isUserRoleAllowed } from '@/routes/utils'
import { Roles } from '@/auth/azure/Roles'

const PersonCreatorForm = (props: PersonCreatorFormProps) => {
  const { contact, rapporti, aziende, userInfo, fetcher } = props
  const { clientId } = useParams()

  const [contactEmailAddresses, setContactEmailAddresses] = useState<string[]>()
  const [newContactData, setNewContactData] = useState<contatto_aziendale>(contact)

  const [currentCompany, setCurrentCompany] = useState<azienda | null | undefined>(
    clientId ? (aziende ? aziende[0] : null) : null,
  )
  const [currentCompanyChanged, setCurrentCompanyChanged] = useState<boolean>(clientId ? true : false)
  const [sediDataSource, setSediDataSource] = useState<DataSource>(ArraySourceComposer('id', []))
  const formNewContactRef = useRef<FormRef>(null)
  const popupNoteClienteRef = useRef<PopupRef>(null)
  const [currentSite, setCurrentSite] = useState<sede | null | undefined>()

  const qsAdminApi = useQsAdminApiManager.getState().service

  const getCompanySites = useCallback(
    async (companyId: number) => {
      const sitesData = await qsAdminApi.sede().query((builder, sede) => {
        builder.filter(sede.azienda.props.id.eq(companyId))
        builder.orderBy(sede.principale.desc())
        builder.select('id', 'nome', 'note')
      })
      return sitesData.data.value
    },
    [qsAdminApi],
  )

  const onEmailsCreate = async (newContactData: contatto_aziendale): Promise<contatto_aziendale> => {
    if (newContactData.emails) {
      for (const new_email of newContactData.emails) {
        if (new_email.email) {
          const newStoredEmailObj = await qsAdminApi.email_aziendale().create(new_email)
          console.log('newStoredEmailObj', newStoredEmailObj)
          new_email.id = newStoredEmailObj.data.id
        }
      }
    }

    return newContactData
  }

  const getContactEmailAddresses = useCallback(() => {
    const addresses: string[] = []
    if (newContactData.emails) {
      for (const email_aziendale of newContactData.emails) {
        addresses.push(email_aziendale.email ?? '')
      }
    }
    return addresses
  }, [newContactData.emails])

  useEffect(() => {
    if (!currentCompanyChanged) return
    if (currentCompany) {
      getCompanySites(currentCompany?.id).then((sites: sede[]) => {
        console.log('SITES', sites)
        setSediDataSource(ArraySourceComposer('id', sites))
        formNewContactRef.current?.instance().updateData('sede', sites[0])
      })
      setCurrentCompanyChanged(false)
    }
  }, [currentCompanyChanged, currentCompany, getCompanySites])

  return (
    <>
      <LoadingPanel visible={fetcher.state !== 'idle'} />
      <DxForm
        id={`contact_person_editor_form_new`}
        key={`contact_person_editor_form_new`}
        formData={newContactData}
        ref={formNewContactRef}
        showValidationSummary={true}
        onFieldDataChanged={(e: FieldDataChangedEvent) => {
          if (
            formNewContactRef.current?.instance().option().isDirty !== null &&
            formNewContactRef.current?.instance().option().isDirty !== undefined
          ) {
            console.log('is dirty', formNewContactRef.current?.instance().option().isDirty)
            formNewContactRef.current
              .instance()
              .getButton('btn-save')
              ?.option('disabled', !formNewContactRef.current?.instance().option().isDirty)
          }
        }}
      >
        <GroupItem colCountByScreen={{ xs: 1, sm: 2, md: 2, lg: 2 }}>
          <GroupItem caption="Anagrafica" colCount={1}>
            <GroupItem colCountByScreen={{ xs: 1, sm: 1, md: 1, lg: 2 }}>
              <SimpleItem
                cssClass="accent"
                label={{ text: 'Azienda' }}
                editorType="dxSelectBox"
                editorOptions={{
                  buttons: [
                    customButton('open', (e: ClickEvent) => {
                      if (currentCompany) window.open(`/clients/${currentCompany.id}`, '_blank')
                      else notify(`Deve essere selezionata un' azienda per poterla aprire.`, 'warning', 3000)
                    }),
                    { name: 'dropDown', location: 'after' },
                  ],
                  value: currentCompany,
                  dataSource: ArraySourceComposer('id', aziende),
                  placeholder: "Seleziona l'azienda...",
                  displayExpr: 'nome',
                  searchEnabled: true,
                  readOnly: clientId ? true : false,
                  dropDownOptions: {
                    showTitle: true,
                    title: "Selezionare l'azienda",
                    hideOnOutsideClick: true,
                  },
                  onValueChanged: (e: ValueChangedEventSelectBox) => {
                    if (e.previousValue?.id === e.value?.id) return
                    console.log('COMPANY EVENT CHANGED', e.previousValue, e.value)
                    setCurrentCompany(e.value)
                    setCurrentCompanyChanged(true)
                  },
                }}
              >
                <RequiredRule message="Il nome del cliente è obbligatorio" />
              </SimpleItem>
              {SiteSimpleItem({
                dataSource: sediDataSource,
                rules: [
                  {
                    type: 'required',
                    message: `Sede obbligatoria`,
                  },
                ],
                onInfoClick: () => {
                  if (newContactData?.sede) {
                    setCurrentSite(newContactData.sede)
                    popupNoteClienteRef.current?.instance().show()
                  } else notify('Deve essere selezionata una sede per aprire le note.', 'warning', 3000)
                },
              })}
              <SimpleItem label={{ text: 'Titolo' }} dataField="titolo" />
              <SimpleItem label={{ text: 'Nome' }} dataField="nome">
                <RequiredRule message="Il nome del contatto è una informazione obbligatoria" />
              </SimpleItem>
              <SimpleItem label={{ text: 'Cognome' }} dataField="cognome" />
              <SimpleItem label={{ text: 'Ruolo' }} dataField="ruolo" />
              <SimpleItem
                label={{ text: 'Rapporto' }}
                dataField="rapporto"
                editorType="dxLookup"
                editorOptions={{
                  dataSource: ArraySourceComposer('id', rapporti),
                  placeholder: 'Seleziona il tipo di rapporto...',
                  displayExpr: 'nome',
                  searchEnabled: true,
                  dropDownCentered: true,
                  showClearButton: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare il rapporto',
                    hideOnOutsideClick: true,
                  },
                }}
              />
              <SimpleItem label={{ text: 'Telefono' }} dataField="telefono" />
              {getContactEmailAddresses()?.map((string, index) => (
                <SimpleItem
                  key={`Email${index}`}
                  label={{ text: `Email ${index + 1}` }}
                  dataField={`emails[${index}].email`}
                  editorOptions={{
                    buttons: [
                      {
                        name: 'trash',
                        location: 'after',
                        options: {
                          visible: index > 0,
                          stylingMode: 'text',
                          icon: 'trash',
                          onClick: () => {
                            newContactData.emails?.splice(index, 1)
                            setContactEmailAddresses(getContactEmailAddresses())
                            // formNewContactRef.current?.forceUpdate()
                          },
                        },
                      },
                    ],
                  }}
                >
                  <EmailRule message="L'indirizzo email non è valido" ignoreEmptyValue={true} />
                  <RequiredRule message="L'indirizzo email è una informazione obbligatoria" />
                </SimpleItem>
              ))}
              <ButtonItem name="addEmailBtn" verticalAlignment={'bottom'} colSpan={2}>
                <ButtonOptions
                  text={'Aggiungi Email'}
                  icon={'add'}
                  type="default"
                  stylingMode="text"
                  onClick={function (e: ClickEvent) {
                    const emlObj: email_aziendale = {
                      id: 0,
                      email: null,
                      note: null,
                      rif_ad: false,
                    }
                    newContactData.emails?.push(emlObj)
                    setContactEmailAddresses(getContactEmailAddresses())
                    // formNewContactRef.current?.forceUpdate()
                  }}
                />
                <EmptyItem />
                <EmptyItem />
              </ButtonItem>
            </GroupItem>
          </GroupItem>
          <GroupItem caption="Informazioni" colCount={1}>
            <TabbedItem>
              <TabPanelOptions animationEnabled={true} swipeEnabled={false} deferRendering={false} />
              <Tab title="Note">
                <SimpleItem
                  dataField={'note'}
                  editorType={'dxTextArea'}
                  label={{ visible: false }}
                  editorOptions={{
                    height: '350',
                  }}
                ></SimpleItem>
              </Tab>
              <Tab title="Impostazioni">
                <GroupItem colCount={2}>
                  <SimpleItem
                    dataField="ad_recipient"
                    label={{ text: 'Contatto addizionale quota autodesk' }}
                    editorType="dxSwitch"
                  />
                </GroupItem>
              </Tab>
            </TabbedItem>
          </GroupItem>
        </GroupItem>
        <GroupItem cssClass="last-group">
          <GroupItem cssClass="buttons-group" colCountByScreen={{ xs: 1, sm: 2, md: 2, lg: 2 }}>
            <ButtonItem name="btn-save">
              <ButtonOptions
                disabled={!formNewContactRef.current?.instance().option().isDirty}
                icon="save"
                text="Salva"
                width="150px"
                onClick={async () => {
                  const validationResult = formNewContactRef.current?.instance().validate()
                  if (!validationResult?.isValid) return
                  console.log('validation ok')
                  console.log(newContactData)
                  await onEmailsCreate(newContactData)
                  newContactData
                    ? fetcher.submit(JSON.stringify(newContactData), {
                        method: 'POST',
                        encType: 'application/json',
                      })
                    : console.error('no data tu submit')
                }}
              />
            </ButtonItem>
          </GroupItem>
        </GroupItem>
      </DxForm>
      <FormPopupClientNote
        data={currentSite}
        refPopup={popupNoteClienteRef}
        refreshDataSourceSites={() => {
          if (currentCompany) {
            getCompanySites(currentCompany?.id).then((sites: sede[]) => {
              console.log('SITES', sites)
              setSediDataSource(ArraySourceComposer('id', sites))
              formNewContactRef.current?.instance().updateData(
                'sede',
                sites.find((site: sede) => {
                  return site.id === currentSite?.id
                }),
              )
            })
          }
        }}
        isRoleAllowed={isUserRoleAllowed(userInfo?.roles, [
          Roles.TechDeveloper,
          Roles.Sales,
          Roles.GlobalAdministrator,
          Roles.ExternalSales,
          Roles.Administrator,
        ])}
      ></FormPopupClientNote>
    </>
  )
}

export default PersonCreatorForm
