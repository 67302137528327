import DocumentHead from '@/components/document-head/DocumentHead'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import InvoicingStepEditorForm from '@/routes/invoicingsteps/InvoicingStepEditor.form'
import { invoicingStepEditorRouteLoader } from '@/routes/invoicingsteps/InvoicingStepEditor.route'
import { InvoicingStepEditorProps } from '@/routes/invoicingsteps/InvoicingStepEditor.types'
import { isUserRoleAllowed } from '@/routes/utils'
import { Suspense } from 'react'
import { useFetcher } from 'react-router-dom'
import { Await, useLoaderData } from 'react-router-typesafe'

const InvoicingStepEditor = (props: InvoicingStepEditorProps) => {
  const { creating } = props
  const { title, invoicingStep, getUserInfo, getStati, getOrdini, defaultCRUDAllowedRoles } =
    useLoaderData<typeof invoicingStepEditorRouteLoader>()

  const fetcher = useFetcher()

  return (
    <>
      <DocumentHead title={title}></DocumentHead>
      <Suspense fallback={<LoadingPanel />}>
        <Await resolve={Promise.all([getUserInfo, getStati, getOrdini])}>
          {([userInfo, stati, ordini]) => (
            <>
              <h2 className={'content-block'}>{title}</h2>
              <div id="editor-invoicing-step-container" className={'content-block dx-card responsive-paddings'}>
                <fetcher.Form>
                  <InvoicingStepEditorForm
                    creating={creating}
                    fetcher={fetcher}
                    userInfo={userInfo}
                    isRoleAllowed={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
                    invoicingStep={invoicingStep}
                    stati={stati.data.value}
                    ordini={ordini.data.value}
                  />
                </fetcher.Form>
              </div>
            </>
          )}
        </Await>
      </Suspense>
    </>
  )
}

export default InvoicingStepEditor
