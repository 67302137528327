import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { useAzureManager } from '@/auth/azure/azureManager'
import { Roles } from '@/auth/azure/Roles'
import CausalsEditor from '@/routes/costcenters/causals/CausalsEditor'
import { RoleRouteObject, RouteFunctionParams } from '@/types'
import { RouteObject } from 'react-router-dom'
import { LoaderFunction, defer } from 'react-router-typesafe'

export const causalsEditorRouteLoader = (async ({ request, params }: RouteFunctionParams) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const getCentriCosto = qsAdminApi.centro_costo().query((builder, centroCosto) => {
    builder.select('id', 'nome')
    builder.orderBy(centroCosto.nome.asc())
  })

  return defer({ title: 'Editazione causali centro di costo', getCentriCosto })
}) satisfies LoaderFunction

const CausalsEditorRoute = {
  path: 'causals',
  element: <CausalsEditor />,
  loader: causalsEditorRouteLoader,
  allowedRoles: [Roles.Guest],
} as RoleRouteObject

export default CausalsEditorRoute
