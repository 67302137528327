import React from 'react'

interface GoogleMapProps {
  id: string
  apiKey: string
  placeName: string
  width?: string
  height?: string
  zoom?: number
}

const GoogleMap: React.FC<GoogleMapProps> = ({ id, apiKey, placeName, width = '100%', height = '25vh', zoom = 15 }) => {
  const mapSrc = `https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${encodeURIComponent(placeName)}&zoom=${zoom}`

  return (
    <div style={{ width, height }}>
      <iframe
        title={id}
        width={'100%'}
        height={'100%'}
        style={{ border: 0, display: 'block' }}
        src={mapSrc}
        allowFullScreen
        aria-hidden="false"
      ></iframe>
    </div>
  )
}

export default GoogleMap
