import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { Roles } from '@/auth/azure/Roles'
import RouterErrorHandler from '@/components/RouterErrorHandler'
import { user } from '@/model/qsadminapi/QsAdminApiModuleModel'
import MealVoucherPage from '@/routes/hours/meal-voucher/MealVoucherPage'
import { RoleRoute, RoleRouteObject } from '@/types'
import { AxiosResponse } from 'axios'
import { defer, LoaderFunction } from 'react-router-typesafe'

interface GetAllemployeesMonthTotalsResponse {
  firstRangeDate: string
  lastRangeDate: string
  allUsersTotals: {
    user: user
    totals: {
      ExtraTime: number
      TotalTimeHours: number
      TotalTimeMinutes: number
      TransferTime: number
      TotalMealVoucher: number
      Errors: string[]
    }
  }[]
}

export const mealVoucherRouteLoader = (async () => {
  const client = useQsAdminApiManager.getState().client
  const getAllEmployeesMonthTotals: Promise<
    AxiosResponse<GetAllemployeesMonthTotalsResponse, GetAllemployeesMonthTotalsResponse>
  > = client.get(`/api/attivitalavorativa/getallemployeesmonthtotals`, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return defer({ getAllEmployeesMonthTotals })
}) satisfies LoaderFunction

export const MealVoucherPageRoute: RoleRoute = {
  path: 'mealvoucher',
  children: [
    {
      index: true,
      element: <MealVoucherPage />,
      loader: mealVoucherRouteLoader,
      allowedRoles: [Roles.GlobalAdministrator, Roles.Administrator],
    } as RoleRouteObject,
  ],
  errorElement: <RouterErrorHandler />,
  allowedRoles: [Roles.GlobalAdministrator, Roles.Administrator],
}
