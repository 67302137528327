import { Roles } from '@/auth/azure/Roles'
import RouterErrorHandler from '@/components/RouterErrorHandler'
import InvoicingStepEditorRoute from '@/routes/invoicingsteps/InvoicingStepEditor.route'
import { RoleRoute } from '@/types'

export const InvoicingStepsPageRoute: RoleRoute = {
  path: 'invoicingsteps',
  children: [InvoicingStepEditorRoute],
  errorElement: <RouterErrorHandler />,
  allowedRoles: [Roles.GlobalAdministrator, Roles.Administrator, Roles.Supervisor],
}
