import { ButtonItem, Form, FormRef, GroupItem, RequiredRule, SimpleItem } from 'devextreme-react/form'
import { useRef } from 'react'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import notify from 'devextreme/ui/notify'
import Popup, { PopupRef } from 'devextreme-react/cjs/popup'
import { ClickEvent } from 'devextreme/ui/button'
import { LoadPanel } from 'devextreme-react'
import { LoadPanelRef } from 'devextreme-react/cjs/load-panel'
import { useScreenSize } from '@/themes/media-query'

type OutlookAppointmentsComputeProps = { popupRef: React.RefObject<PopupRef> }

export const OutlookAppointmentsCompute = (props: OutlookAppointmentsComputeProps) => {
  const { popupRef } = props
  const { client } = useQsAdminApiManager()
  const currentScreenSize = useScreenSize()
  const formRef = useRef<FormRef>(null)
  const loadPanelRef = useRef<LoadPanelRef>(null)

  const startDate = new Date()
  startDate.setHours(0)
  startDate.setMinutes(0)
  startDate.setSeconds(0)
  startDate.setMilliseconds(0)

  const endDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 5)

  const formData: { startDate: Date; endDate: Date } = {
    startDate,
    endDate,
  }

  return (
    <>
      <Popup
        width={currentScreenSize.isLarge || currentScreenSize.isMedium ? 400 : currentScreenSize.isXSmall ? 350 : 700}
        height={330}
        hideOnOutsideClick={true}
        showCloseButton={true}
        deferRendering={true}
        title="Aggiungi Appuntamenti"
        ref={popupRef}
        onHiding={() => {
          formRef.current?.instance().updateData('startDate', startDate)
          formRef.current?.instance().updateData('endDate', endDate)
        }}
      >
        <div id="outlook-appointments-compute-form-container" className={'dx-card responsive-paddings'}>
          {/* <LoadingPanel
          position={{ of: '#outlook-appointments-compute-form-container' }}
          visible={loadIndicatorVisible}
        ></LoadingPanel> */}
          <LoadPanel
            ref={loadPanelRef}
            shadingColor="rgba(0,0,0,0.4)"
            showIndicator={true}
            shading={true}
            showPane={true}
            hideOnOutsideClick={false}
            hideOnParentScroll={false}
            position={{ of: '#outlook-appointments-compute-form-container' }}
          />
          <Form
            id={`outlook-appointments-compute-form}`}
            key={`outlook-appointments-compute-form}`}
            formData={formData}
            validationGroup="outlookAppointmentsComputeValidation"
            ref={formRef}
          >
            <GroupItem colCount={1}>
              <SimpleItem
                dataField="startDate"
                editorOptions={{
                  displayFormat: 'dd/MM/yyyy',
                  type: 'date',
                  pickerType: 'calendar',
                  onValueChanged: (e: any) => {
                    const newEndDate = new Date(e.value)
                    newEndDate.setDate(newEndDate.getDate() + 5)
                    console.log('endDate', newEndDate)
                    formRef.current?.instance().updateData('endDate', newEndDate)
                  },
                }}
                editorType="dxDateBox"
                label={{ text: 'Data Inizio' }}
              >
                <RequiredRule message="Data inizio obbligatoria" />
              </SimpleItem>
              <SimpleItem
                dataField="endDate"
                editorOptions={{
                  displayFormat: 'dd/MM/yyyy',
                  type: 'date',
                  pickerType: 'calendar',
                }}
                editorType="dxDateBox"
                label={{ text: 'Data Fine' }}
              >
                <RequiredRule message="Data fine obbligatoria" />
              </SimpleItem>
            </GroupItem>
            <GroupItem cssClass="last-group">
              <GroupItem cssClass="buttons-group" colCount={1}>
                <ButtonItem
                  name="btn-compute"
                  buttonOptions={{
                    icon: 'event',
                    text: 'Crea',
                    disabled: false,
                    width: '150px',
                    onClick: async (e: ClickEvent) => {
                      const validationResult = formRef.current?.instance().validate()
                      if (!validationResult?.isValid) return
                      loadPanelRef.current?.instance().option('visible', true)
                      try {
                        const response = await client.post('api/task/createOutlookPlaceholders', formData, {
                          headers: { 'Content-Type': 'application/json' },
                        })
                        notify(`Creazione appuntamenti terminata con successo.`, 'success', 2000)
                      } catch (error: unknown) {
                        notify(`Errore creazione appuntamenti. Dettagli : ${error}`, 'error', 2000)
                      } finally {
                        loadPanelRef.current?.instance().option('visible', false)
                        popupRef.current?.instance().hide()
                        formRef.current?.instance().updateData('startDate', startDate)
                        formRef.current?.instance().updateData('endDate', endDate)
                      }
                    },
                  }}
                ></ButtonItem>
              </GroupItem>
            </GroupItem>
          </Form>
        </div>
      </Popup>
    </>
  )
}

export default OutlookAppointmentsCompute
