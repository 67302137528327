import { Toolbar, Item } from 'devextreme-react/toolbar'
import { DropDownButton } from 'devextreme-react/drop-down-button'
import { useFetcher, useRevalidator } from 'react-router-dom'
import { Await, useLoaderData } from 'react-router-typesafe'
import './SupportEditor.scss'
import { useNavigate } from 'react-router'
import { SelectionChangedEvent } from 'devextreme/ui/drop_down_button'
import { supportEditorRouteLoader } from '@/routes/support/SupportEditor.route'
import SupportEditorForm from '@/routes/support/SupportEditor.form'
import { Suspense } from 'react'
import { sede } from '@/model/qsadminapi/QsAdminApiModuleModel'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { isUserRoleAllowed } from '@/routes/utils'
import DocumentHead from '@/components/document-head/DocumentHead'

export default function SupportEditor() {
  const {
    title,
    support,
    getSupportTypes,
    getAllSecondaryContracts,
    site,
    getSites,
    getAllSupports,
    getUserInfo,
    defaultCRUDAllowedRoles,
  } = useLoaderData<typeof supportEditorRouteLoader>()

  const navigate = useNavigate()
  const revalidator = useRevalidator()
  const fetcher = useFetcher()

  return (
    <>
      <DocumentHead title={title}></DocumentHead>
      <Suspense fallback={<LoadingPanel />}>
        <Await
          resolve={Promise.all([getSupportTypes, getAllSecondaryContracts, getAllSupports, getSites, getUserInfo])}
        >
          {([supportTypeList, allSecondaryContractList, allSupportsList, sitesList, userInfo]) => (
            <>
              <div className={'dx-card responsive-paddings'}>
                <hr />
                <Toolbar>
                  <Item
                    location="after"
                    widget="dxButton"
                    options={{
                      icon: 'refresh',
                      onClick: () => revalidator.revalidate(),
                    }}
                  />
                  <Item
                    location="center"
                    locateInMenu="never"
                    render={() => (
                      <div className="dx-form-group-caption">
                        Contratto Assistenza {support.id} - {support.sede?.azienda?.nome}
                      </div>
                    )}
                  />
                  <Item location="before" locateInMenu="auto">
                    <DropDownButton
                      items={sitesList.data.value.filter(
                        (siteItem: sede) =>
                          // only showing sites that have a support contract
                          siteItem.assistenze?.length && siteItem.assistenze?.length > 0,
                      )}
                      stylingMode="text"
                      keyExpr="id"
                      selectedItemKey={site?.id}
                      displayExpr="nome"
                      dropDownOptions={{ width: 'auto' }}
                      useSelectMode={true}
                      onSelectionChanged={(e: SelectionChangedEvent) => {
                        navigate(`./../${e.item.assistenze[0].id}`)
                      }}
                    />
                  </Item>
                </Toolbar>
                <fetcher.Form>
                  <SupportEditorForm
                    support={support}
                    supportTypes={supportTypeList.data.value}
                    secondarySupports={allSecondaryContractList.data.value}
                    allSupports={allSupportsList.data.value}
                    isRoleAllowed={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
                    fetcher={fetcher}
                  />
                </fetcher.Form>
              </div>
            </>
          )}
        </Await>
      </Suspense>
    </>
  )
}
