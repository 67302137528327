import { InvoicingStepEditorFormProps } from '@/routes/invoicingsteps/InvoicingStepEditor.types'
import { ArraySourceComposer } from '@/auth/api/connector'
import {
  ButtonItem,
  ButtonOptions,
  Form as DXForm,
  EmptyItem,
  GroupItem,
  RequiredRule,
  SimpleItem,
  FormRef,
} from 'devextreme-react/form'
import { useRef, useState } from 'react'
import { useRevalidator } from 'react-router-dom'
import { FieldDataChangedEvent } from 'devextreme/ui/form'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import notify from 'devextreme/ui/notify'
import { customButton } from '@/routes/utils'
import { ClickEvent } from 'devextreme/ui/button'
import OrderEditorTasksGrid from '@/routes/orders/OrderEditor.tasks'

const InvoicingStepEditorForm = (props: InvoicingStepEditorFormProps) => {
  const { creating, invoicingStep, fetcher, isRoleAllowed, stati, ordini, userInfo } = props

  const formRef = useRef<FormRef>(null)
  const revalidator = useRevalidator()
  const [loadPanelVisible, setLoadPanelVisible] = useState(false)

  return (
    <>
      <LoadingPanel visible={!(fetcher?.state === 'idle' && revalidator.state === 'idle' && !loadPanelVisible)} />
      <DXForm
        id={'project_form'}
        formData={invoicingStep}
        labelLocation={'top'}
        colCount={1}
        showValidationSummary={true}
        onFieldDataChanged={(e: FieldDataChangedEvent) => {
          if (
            formRef.current?.instance().option().isDirty !== null &&
            formRef.current?.instance().option().isDirty !== undefined
          ) {
            console.log('is dirty', formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-save')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-cancel')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
          }
        }}
        validationGroup="projectValidation"
        ref={formRef}
        readOnly={!isRoleAllowed}
      >
        <GroupItem colCount={5}>
          <GroupItem colCount={2} colSpan={2}>
            <SimpleItem
              dataField="creazione"
              editorOptions={{
                displayFormat: 'dd/MM/yyyy',
                readOnly: true,
              }}
              editorType="dxDateBox"
              label={{ text: 'Data Creazione' }}
            >
              <RequiredRule message="Data creazione obbligatoria" />
            </SimpleItem>
            <SimpleItem
              dataField="ordine"
              label={{ text: 'Ordine' }}
              editorType="dxSelectBox"
              editorOptions={{
                buttons: [
                  customButton(
                    'open',
                    (e: ClickEvent) => {
                      if (invoicingStep.ordine) window.open(`/orders/${invoicingStep.ordine.id}`, '_blank')
                      else notify(`Deve essere selezionato un ordine per poterlo aprire.`, 'warning', 3000)
                    },
                    !isRoleAllowed,
                  ),
                  { name: 'dropDown', location: 'after' },
                ],
                dataSource: ArraySourceComposer('id', ordini),
                placeholder: 'Seleziona ordine...',
                displayExpr: 'ded_Dis',
                searchEnabled: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare ordine',
                  hideOnOutsideClick: true,
                },
                readOnly: true,
              }}
            >
              <RequiredRule message="Ordine obbligatorio"></RequiredRule>
            </SimpleItem>
            <SimpleItem
              dataField="stato"
              label={{ text: 'Stato' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', stati),
                placeholder: 'Seleziona lo stato...',
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare lo stato',
                  hideOnOutsideClick: true,
                },
                readOnly: creating,
              }}
            >
              <RequiredRule message="Stato obbligatorio"></RequiredRule>
            </SimpleItem>
          </GroupItem>
          <GroupItem colSpan={3}>
            <SimpleItem dataField={'note'} editorType={'dxTextArea'} editorOptions={{ height: '110' }}>
              <RequiredRule message="Note obbligatorie" />
            </SimpleItem>
          </GroupItem>
        </GroupItem>
        <GroupItem visible={isRoleAllowed} cssClass="last-group">
          <GroupItem cssClass="buttons-group" colCount={creating ? 1 : 2}>
            <ButtonItem name="btn-save">
              <ButtonOptions
                disabled={!formRef.current?.instance().option().isDirty}
                icon="save"
                text="Salva"
                width="150px"
                onClick={async () => {
                  const validationResult = formRef.current?.instance().validate()
                  if (!validationResult?.isValid) return
                  console.log('validation ok')
                  console.log('invoicingStep', invoicingStep)
                  if (invoicingStep) {
                    fetcher?.submit(JSON.stringify(invoicingStep), {
                      method: creating ? 'POST' : 'PUT',
                      encType: 'application/json',
                    })
                  } else console.error('no data tu submit')
                }}
              />
            </ButtonItem>
            {!creating && (
              <ButtonItem name="btn-cancel">
                <ButtonOptions
                  disabled={!formRef.current?.instance().option().isDirty}
                  width="150px"
                  text="Annulla"
                  icon="undo"
                  onClick={() => {
                    revalidator.revalidate()
                  }}
                />
              </ButtonItem>
            )}
          </GroupItem>
        </GroupItem>
        <GroupItem>
          <OrderEditorTasksGrid
            creating={creating}
            order={invoicingStep.ordine}
            invoicingStep={invoicingStep}
            userInfo={userInfo}
            readOnly={true}
          ></OrderEditorTasksGrid>
        </GroupItem>
      </DXForm>
    </>
  )
}

export default InvoicingStepEditorForm
