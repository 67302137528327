import { ArraySourceComposer } from '@/auth/api/connector'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { AzureUserInfo, getAzureUserInformation } from '@/auth/azure/azureManager'
import { azienda, commessa, sede, stato_commessa, user } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { StatoCommessa } from '@/routes/projects/ProjectEditor.enums'
import { useScreenSize } from '@/themes/media-query'
import { Popup } from 'devextreme-react'
import { PopupRef } from 'devextreme-react/cjs/popup'
import {
  ButtonItem,
  ButtonOptions,
  Form as DXForm,
  FormRef,
  GroupItem,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form'
import { ClickEvent } from 'devextreme/ui/button'
import notify from 'devextreme/ui/notify'
import { memo, useCallback, useEffect, useRef, useState } from 'react'

const FormPopupProject = (props: {
  popupRef: React.RefObject<PopupRef>
  sede?: sede | null
  azienda?: azienda | null
  sediList?: sede[]
  refreshCommessa?: () => void
}) => {
  const currentScreenSize = useScreenSize()
  const formRef = useRef<FormRef>(null)
  const { service } = useQsAdminApiManager()
  const [statiCommessa, setStatiCommessa] = useState<stato_commessa[]>()
  const [newProjectFormData, setNewProjectFormData] = useState<commessa>()

  const getStatoCommessaAperta = useCallback(async () => {
    const aperta = await service.stato_commessa(StatoCommessa.APERTA).query((builder, stato) => {
      builder.select('id', 'nome')
    })
    return aperta.data
  }, [service])
  const getUser = useCallback(
    async (email: string) => {
      const autore = await service.user().query((builder, user) => {
        builder.select('id', 'fullname')
        builder.filter(user.email.eq(email))
      })
      return autore.data.value[0]
    },
    [service],
  )

  const today = new Date()

  useEffect(() => {
    const newProject: commessa = {
      id: 0,
      note: null,
      fine: null,
      titolo: null,
      creazione: today.toISOString(),
      anno_rif: today.getFullYear(),
      inizio: today.toISOString(),
      sede: props.sede ?? props.sediList?.[0],
      attachmentsFolderUrl: '',
      legacy: false,
      ded_RootFam: '',
      ded_SubFam: '',
      ded_Dis: '',
    }
    getAzureUserInformation().then((result: AzureUserInfo | undefined) => {
      getUser(result?.email ?? '').then((result: user) => {
        console.log('user', result)
        newProject.autore = result
      })
    })
    getStatoCommessaAperta().then((result) => {
      console.log('stato commessa', result)
      newProject.stato = result
      setStatiCommessa([result])
      setNewProjectFormData(newProject)
    })
  }, [])

  useEffect(() => {
    console.log('sede aggiornamento', props.sede)
    console.log('sedi aggiornamento', props.sediList)
    formRef.current?.instance().updateData('sede', props.sede ?? props.sediList?.[0])
  }, [props.sede, props.sediList])

  return (
    <>
      <Popup
        ref={props.popupRef}
        dragEnabled={false}
        hideOnOutsideClick={false}
        showCloseButton={true}
        showTitle={true}
        title={`Nuova commessa`}
        container=".dx-viewport"
        width={currentScreenSize.isLarge || currentScreenSize.isMedium ? 700 : currentScreenSize.isXSmall ? 350 : 700}
        height={currentScreenSize.isSmall ? 300 : 650}
      >
        <DXForm
          id={'project_form'}
          formData={newProjectFormData}
          labelLocation={'top'}
          showValidationSummary={true}
          validationGroup="projectValidation"
          ref={formRef}
        >
          <GroupItem colCount={2}>
            <SimpleItem
              label={{ text: 'Azienda' }}
              editorType="dxLookup"
              editorOptions={{
                value: props.azienda ?? props.sede?.azienda,
                dataSource: ArraySourceComposer('id', [props.azienda ?? props.sede?.azienda]),
                displayExpr: 'nome',
                readOnly: true,
              }}
            >
              <RequiredRule message="Azienda obbligatoria"></RequiredRule>
            </SimpleItem>
            <SimpleItem
              dataField="sede"
              label={{ text: 'Sede' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', props.sede ? [props.sede] : props.sediList),
                displayExpr: 'nome',
              }}
            >
              <RequiredRule message="Sede obbligatoria"></RequiredRule>
            </SimpleItem>
          </GroupItem>
          <GroupItem colCount={2}>
            <SimpleItem
              dataField="creazione"
              editorOptions={{
                displayFormat: 'dd/MM/yyyy',
                readOnly: true,
              }}
              editorType="dxDateBox"
              label={{ text: 'Data Creazione' }}
            >
              <RequiredRule message="Data creazione obbligatoria" />
            </SimpleItem>
            <SimpleItem
              dataField="inizio"
              editorOptions={{
                displayFormat: 'dd/MM/yyyy',
                pickerType: 'rollers',
              }}
              editorType="dxDateBox"
              label={{ text: 'Data Inizio' }}
            >
              <RequiredRule message="Data inizio obbligatoria" />
            </SimpleItem>
          </GroupItem>
          <GroupItem colCount={2}>
            <SimpleItem
              colSpan={1}
              dataField="stato"
              label={{ text: 'Stato' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', statiCommessa),
                displayExpr: 'nome',
                readOnly: true,
              }}
            >
              <RequiredRule message="Stato obbligatorio"></RequiredRule>
            </SimpleItem>
          </GroupItem>
          <GroupItem colCount={1}>
            <SimpleItem dataField={'titolo'} editorType={'dxTextArea'}>
              <RequiredRule message="Titolo obbligatorio" />
            </SimpleItem>
          </GroupItem>
          <GroupItem cssClass="last-group">
            <GroupItem cssClass="buttons-group" colCount={1}>
              <ButtonItem name="btn-save">
                <ButtonOptions
                  icon="save"
                  text="Salva"
                  width="150px"
                  onClick={(e: ClickEvent) => {
                    const validationResult = formRef.current?.instance().validate()
                    if (!validationResult?.isValid) return
                    if (newProjectFormData)
                      service
                        .commessa()
                        .create(newProjectFormData)
                        .then((res) => {
                          formRef.current?.instance().updateData('titolo', '')
                          if (props.refreshCommessa) props.refreshCommessa()
                        })
                        .catch((error) => {
                          notify(`Errore creazione commessa: ${error}`, 'error', 3000)
                        })
                  }}
                />
              </ButtonItem>
            </GroupItem>
          </GroupItem>
        </DXForm>
      </Popup>
    </>
  )
}

const FormPopupProjectMemoed = memo(FormPopupProject, (oldProps, newProps) => {
  const newSede = newProps.sede ?? newProps.sediList?.[0]
  const oldSede = oldProps.sede ?? oldProps.sediList?.[0]
  return oldSede?.id === newSede?.id
})

export default FormPopupProjectMemoed
