import { Suspense, useRef } from 'react'
import { Await, useLoaderData } from 'react-router-typesafe'
import { ProjectEditorProps } from '@/routes/projects/ProjectEditor.types'
import { projectEditorRouteLoader } from '@/routes/projects/ProjectEditor.route'
import ProjectEditorForm from '@/routes/projects/ProjectEditor.form'
import { Form as DXForm, Tab, TabbedItem } from 'devextreme-react/form'
import AttachmentsManager from '@/components/file-manager/AttachmentsManager'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { useFetcher, useParams } from 'react-router-dom'
import { FileManagerRef } from 'devextreme-react/file-manager'
import { isUserRoleAllowed } from '@/routes/utils'
import { DocumentPermissions } from '@/enums'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { Roles } from '@/auth/azure/Roles'
import DocumentHead from '@/components/document-head/DocumentHead'

const ProjectEditor = (props: ProjectEditorProps) => {
  const { creating } = props
  const { title, project, getAziende, getStati, getQsImpiegato, user, userInfo, defaultCRUDAllowedRoles } =
    useLoaderData<typeof projectEditorRouteLoader>()

  const { clientId } = useParams()

  const fetcher = useFetcher()
  const fileManagerRef = useRef<FileManagerRef>(null)
  const { client } = useQsAdminApiManager()

  return (
    <>
      <DocumentHead title={title}></DocumentHead>
      <Suspense fallback={<LoadingPanel />}>
        <Await resolve={Promise.all([getStati, getAziende, getQsImpiegato])}>
          {([stati, aziende, impiegati]) => (
            <>
              <h2 className={clientId ? '' : 'content-block'}>{title}</h2>
              <div
                id="editor-project-container"
                className={clientId ? 'dx-card responsive-paddings' : 'content-block dx-card responsive-paddings'}
              >
                <fetcher.Form>
                  <ProjectEditorForm
                    creating={creating}
                    project={project}
                    stati={stati.data.value}
                    aziende={aziende.data.value}
                    impiegati={impiegati.data.value}
                    isRoleAllowed={
                      creating
                        ? isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)
                        : isUserRoleAllowed(userInfo?.roles, [
                            Roles.GlobalAdministrator,
                            Roles.Administrator,
                            Roles.Supervisor,
                          ]) ||
                          (userInfo?.roles?.hasRole(Roles.Sales) &&
                            project.sede?.azienda?.agente?.commerciale_qs?.fullname === userInfo.name) ||
                          (userInfo?.roles?.hasRole(Roles.ExternalSales) &&
                            project.sede?.azienda?.rivenditore?.id === user.data.value[0].azienda?.id)
                    }
                    userInfo={userInfo}
                    fetcher={fetcher}
                  ></ProjectEditorForm>
                </fetcher.Form>
              </div>
              {!creating && (
                <div
                  id="allegati-project-container"
                  className={clientId ? 'dx-card responsive-paddings' : 'content-block dx-card responsive-paddings'}
                >
                  <DXForm id="form-allegati">
                    <TabbedItem>
                      <Tab title="Allegati">
                        <AttachmentsManager
                          fileManagerRef={fileManagerRef}
                          folderUrl={project?.attachmentsFolderUrl}
                          preliminarPermissionsAction={async () => {
                            try {
                              const response = await client.post(
                                '/api/commessa/drivepermissions',
                                {
                                  objectId: project.id,
                                  path: project.attachmentsFolderUrl,
                                },
                                {
                                  headers: {
                                    'Content-Type': 'application/json',
                                  },
                                },
                              )
                              return response.status === 200
                                ? (response.data as DocumentPermissions)
                                : DocumentPermissions.Deny
                            } catch (error) {
                              console.log('Errore in settaggio permessi sulla cartella allegati commessa', error)
                              return DocumentPermissions.Deny
                            }
                          }}
                          createDirectoryAction={async (
                            rootFolderPath: string,
                            name: string,
                            conflictBehavior: string,
                          ) => {
                            return await client.post(
                              '/api/commessa/createfolder',
                              {
                                rootFolder: rootFolderPath,
                                newFolderName: name,
                                conflictBehavior,
                                objectId: project.id,
                              },
                              {
                                headers: {
                                  'Content-Type': 'application/json',
                                },
                              },
                            )
                          }}
                        />
                      </Tab>
                    </TabbedItem>
                  </DXForm>
                </div>
              )}
            </>
          )}
        </Await>
      </Suspense>
    </>
  )
}

export default ProjectEditor
