import ODataStore from 'devextreme/data/odata/store'
import {
  DataGrid,
  Column,
  ColumnChooser,
  ColumnChooserSearch,
  Editing,
  Export,
  FilterRow,
  HeaderFilter,
  Item,
  MasterDetail,
  Scrolling,
  Search,
  SearchPanel,
  Sorting,
  StateStoring,
  Toolbar,
  DataGridRef,
  FilterPanel,
  Pager,
  Button as GridButton,
} from 'devextreme-react/data-grid'
import { useLocation, useNavigate } from 'react-router'
import { confirm } from 'devextreme/ui/dialog'
import type { ColumnButtonClickEvent, ExportingEvent, RowDblClickEvent } from 'devextreme/ui/data_grid'
import { ClientBriefDetail } from './client/ClientBriefDetail'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver'
import { type azienda } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { useScreenSize } from '@/themes/media-query'
import notify from 'devextreme/ui/notify'
import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import { EdmLiteral } from 'devextreme/data/odata/utils'
import { useLoaderData } from 'react-router-typesafe'
import { clientsPageRouteLoader } from '@/routes/clients/ClientsPage.route'
import { Accordion, Item as AccordionItem } from 'devextreme-react/accordion'
import { ClickEvent } from 'devextreme/ui/button'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { ContentReadyEvent } from 'devextreme/ui/accordion'
import { TipologiaRapporto } from '@/routes/clients/client/ClientEditor.enums'
import {
  createDateFilterItemDescriptor,
  FormFilterItemDescriptorType,
} from '@/components/filter-form/GenericFilterForm.types'
import GenericFilterForm from '@/components/filter-form/GenericFilterForm'
import useTokenRefresh from '@/auth/azure/azureManager'
import DateColumn from '@/components/date-column/DateColumn'
import { columnSourceFactory, isUserRoleAllowed } from '@/routes/utils'
import { gridTotalItemsLabel } from '@/constants'
import DocumentHead from '@/components/document-head/DocumentHead'
import { Roles } from '@/auth/azure/Roles'

const computeOldReference = (rowData: azienda) => {
  let nomi_noti_cmb = ''
  rowData.nomi_noti?.map((nome_noto) => (nomi_noti_cmb = `${nomi_noti_cmb + nome_noto.nome}`))
  return nomi_noti_cmb
}

const calculateOldRefFilterExpression = (
  filterValue: any,
  selectedFilterOperation: string | null,
  target: string,
): [(string | boolean | EdmLiteral)[]] => {
  console.log('FLT', filterValue, selectedFilterOperation, target)

  if (target === 'filterBuilder' || target === 'filterRow') {
    if (selectedFilterOperation === '=' || selectedFilterOperation === '<>') {
      return [[new EdmLiteral(`nomi_noti/any(a: a/nome eq '${filterValue}')`), '=', selectedFilterOperation === '=']]
    } else if (
      selectedFilterOperation === 'contains' ||
      selectedFilterOperation === 'startswith' ||
      selectedFilterOperation === 'endswith'
    ) {
      return [[new EdmLiteral(`nomi_noti/any(a: ${selectedFilterOperation}(a/nome, '${filterValue}'))`), '=', true]]
    } else {
      // caso notcontains
      return [[new EdmLiteral(`nomi_noti/any(a: contains(a/nome, '${filterValue}'))`), '=', false]]
    }
  } else if (target === 'search') {
    return [[new EdmLiteral(`nomi_noti/any(a: contains(a/nome, '${filterValue}'))`), '=', true]]
  }

  return filterValue
}

const ExportDataGridToExcel = (component: any) => {
  const workbook = new Workbook()
  const worksheet = workbook.addWorksheet('ClientsGrid')
  exportDataGrid({ component, worksheet }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Customers.xlsx')
    })
  })
}

const onExporting = (e: ExportingEvent) => {
  ExportDataGridToExcel(e.component)
}

export default function ClientsPage() {
  const { service } = useQsAdminApiManager()
  const navigate = useNavigate()
  const dataGridRef = useRef<DataGridRef>(null)
  const currentScreenSize = useScreenSize()
  const { tipologie_rapporto, userInfo, defaultCRUDAllowedRoles } = useLoaderData<typeof clientsPageRouteLoader>()
  const token = useTokenRefresh()
  const title = 'Clienti'

  const getGridHeight = useCallback(() => {
    let h = '79vh'
    if (currentScreenSize.isMedium) {
      h = '75vh'
    } else if (currentScreenSize.isSmall) {
      h = '120vh'
    } else if (currentScreenSize.isXSmall) {
      h = '75vh'
    }
    return h
  }, [currentScreenSize])

  const deleteAzienda = async (clientId: number) => {
    return await service.azienda(clientId).delete()
  }

  const [filtersApplied, setFiltersApplied] = useState<boolean>(false)
  const getFiltersConfiguration = useCallback((): FormFilterItemDescriptorType[] => {
    const fltConf: FormFilterItemDescriptorType[] = [
      {
        fieldName: 'tipologia_rapporto',
        placeHolder: 'Tipologia Rapporto',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        defaultValue: TipologiaRapporto.CLIENTE,
        values: tipologie_rapporto.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['tipologia_rapporto/id', this.currentValue]]
        },
      },
      createDateFilterItemDescriptor('rapp_inizio', 'Data Rapporto Inizio'),
    ]
    console.log('GENFLT - GETCONF :', fltConf)
    return fltConf
  }, [tipologie_rapporto])

  const initialFilterConfiguration = useMemo(() => getFiltersConfiguration(), [getFiltersConfiguration])
  const [filterConfiguration, setFilterConfiguration] = useState(initialFilterConfiguration)

  const getCurrentFilter = useCallback((): any[] => {
    let applied: boolean = false
    const currentFlts = []
    for (const flt of filterConfiguration) {
      const fltValue = flt.composeFilterItem()
      if (fltValue !== null) {
        currentFlts.push(fltValue)
      }
      if (!applied && flt.currentValue !== flt.defaultValue) applied = true
    }
    setFiltersApplied(applied)
    console.log('GENFLT - CURRENT:', currentFlts)
    return currentFlts
  }, [filterConfiguration])

  const [currentFilter, setCurrentFilter] = useState<any[]>([])
  const clientDataSource = {
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/Azienda`,
      key: 'id',
      keyType: 'Int32',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    expand: [
      'agente($expand=commerciale_qs)',
      'tipologia_rapporto',
      'nomi_noti',
      'sedi',
      'tipologia_pagamento',
      'gruppo_aziendale',
      'rivenditore',
    ],
    filter: currentFilter?.length > 0 ? currentFilter : null,
    requireTotalCount: true,
  }

  useEffect(() => {
    getCurrentFilter()
  }, [])

  return (
    <>
      <DocumentHead title={title}></DocumentHead>
      <h2 className={'content-block'}>{title}</h2>
      <div className={'content-block'}>
        <div className="accordion-generic-filter-form">
          <Accordion
            className={filtersApplied ? 'with-filter' : ''}
            collapsible={true}
            onContentReady={(e: ContentReadyEvent<any, any>) => {
              e.component?.collapseItem(0)
            }}
          >
            <AccordionItem icon="filter" title={'Filtri'}>
              <GenericFilterForm
                FormItemDescriptors={filterConfiguration}
                resetFilterClicked={function (e: ClickEvent): void {
                  setFilterConfiguration(getFiltersConfiguration())
                }}
                filterValueChanged={function (): void {
                  const currentFlts = getCurrentFilter()
                  console.log('GENFLT - VALUE CHNG', currentFlts)
                  if (JSON.stringify(currentFlts) !== JSON.stringify(currentFilter)) {
                    console.log('GENFLT - SETTING FILT', currentFlts)
                    setCurrentFilter(currentFlts)
                  }
                }}
              />
            </AccordionItem>
          </Accordion>
        </div>
        <DataGrid
          id="company-grid"
          height={getGridHeight}
          className={'dx-card wide-card'}
          dataSource={clientDataSource}
          noDataText="Nessuna azienda trovata"
          remoteOperations={{
            filtering: true,
            grouping: false,
            groupPaging: false,
            paging: true,
            sorting: true,
            summary: true,
          }}
          showBorders={false}
          showColumnLines={true}
          focusedRowEnabled={true}
          defaultFocusedRowIndex={0}
          columnHidingEnabled={currentScreenSize.isSmall || currentScreenSize.isXSmall}
          allowColumnResizing={currentScreenSize.isLarge || currentScreenSize.isMedium}
          allowColumnReordering={currentScreenSize.isLarge || currentScreenSize.isMedium}
          rowAlternationEnabled={true}
          wordWrapEnabled={false}
          onExporting={onExporting}
          ref={dataGridRef}
          grouping={{
            // Fondamentale per il raggruppamento veloce!!!
            autoExpandAll: true,
          }}
          groupPanel={{
            visible: currentScreenSize.isLarge || currentScreenSize.isMedium,
            emptyPanelText: 'Trascina qui una colonna per raggruppare',
          }}
          onRowDblClick={(e: RowDblClickEvent<any, any>) => {
            if (e.rowType === 'data') navigate(`${e.data.id}`)
          }}
        >
          <Pager
            visible={true}
            allowedPageSizes={'auto'}
            displayMode={'compact'}
            showPageSizeSelector={false}
            showInfo={true}
            showNavigationButtons={false}
            infoText={gridTotalItemsLabel}
          />
          <StateStoring enabled={true} type="localStorage" storageKey="company-grid" savingTimeout={50} />
          {/* <Summary>
            <TotalItem showInColumn="nome" summaryType="count" />
          </Summary> */}
          <Export enabled={true} />
          <Editing allowAdding={true} />
          <HeaderFilter visible={true} />
          <FilterPanel visible={true} />
          <FilterRow visible={true} />
          <SearchPanel visible={true} width={currentScreenSize.isXSmall ? 200 : 250} />
          <Scrolling mode="virtual" />
          <Sorting mode="multiple" />
          <ColumnChooser enabled={true} title={'Scelta Colonne'}>
            <ColumnChooserSearch enabled={true} />
          </ColumnChooser>
          <Column
            visible={false}
            type="buttons"
            width={currentScreenSize.isXSmall ? '10%' : currentScreenSize.isSmall ? '7%' : '2%'}
            alignment="left"
            showInColumnChooser={false}
          >
            <GridButton
              hint="Details"
              icon="search"
              onClick={(e: ColumnButtonClickEvent) => {
                navigate(`${e.row?.data.id}`)
              }}
            ></GridButton>
          </Column>
          <Column
            dataField={'id'}
            width={'5%'}
            caption="ID"
            visible={false}
            dataType="number"
            hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 1 : undefined}
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'Azienda', 'id', true)}>
              <Search enabled={true} searchExpr={'id'} />
            </HeaderFilter>
          </Column>
          {DateColumn({
            dataField: 'rapp_inizio',
            width: currentScreenSize.isSmall ? '15%' : '6%',
            caption: 'DATA',
            format: 'dd/MM/yyyy',
            hidingPriority: currentScreenSize.isSmall || currentScreenSize.isXSmall ? 16 : undefined,
          })}
          <Column
            dataField={'nome'}
            caption={'NOME'}
            width={currentScreenSize.isLarge ? '50%' : '20%'}
            defaultSortOrder="asc"
            hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 17 : undefined}
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'Azienda', 'nome')}>
              <Search enabled={true} searchExpr={'nome'} />
            </HeaderFilter>
          </Column>
          <Column
            dataField={'p_iva'}
            width={'7%'}
            caption={'P.IVA'}
            hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 15 : undefined}
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'Azienda', 'p_iva')}>
              <Search enabled={true} searchExpr={'p_iva'} />
            </HeaderFilter>
          </Column>
          <Column
            dataField={'agente.commerciale_qs.fullname'}
            width={'8%'}
            caption={'AGENTE'}
            hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 14 : undefined}
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'agente', 'commerciale_qs.fullname')}>
              <Search enabled={true} searchExpr={'commerciale_qs.fullname'} />
            </HeaderFilter>
          </Column>
          <Column
            dataField={'rivenditore.nome'}
            width={'15%'}
            caption={'RIVENDITORE'}
            hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 13 : undefined}
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'Azienda', 'rivenditore.nome')}>
              <Search enabled={true} searchExpr={'rivenditore.nome'} />
            </HeaderFilter>
          </Column>
          <Column
            dataField={'tipologia_rapporto.nome'}
            width={'10%'}
            caption={'TIPOLOGIA'}
            hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 12 : undefined}
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'azienda', 'tipologia_rapporto.nome')}>
              <Search enabled={true} searchExpr={'tipologia_rapporto.nome'} />
            </HeaderFilter>
          </Column>
          <Column
            name={'old_ref_computed'}
            width={'5%'}
            caption={'VECCHIO RIFERIMENTO'}
            calculateCellValue={computeOldReference}
            calculateFilterExpression={calculateOldRefFilterExpression}
            allowFiltering={true}
            allowHeaderFiltering={true}
            visible={false}
            hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 11 : undefined}
          >
            {/*<HeaderFilter*/}
            {/*  dataSource={columnSourceFactory('old_ref_computed')} // todo*/}
            {/*/>*/}
          </Column>
          <Column
            dataField={'gruppo_aziendale.nome'}
            caption={'GRUPPO'}
            visible={false}
            width={'10%'}
            allowHeaderFiltering={true}
            hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 10 : undefined}
          />
          <Column
            dataField={'note'}
            caption={'NOTE'}
            visible={false}
            allowHeaderFiltering={false}
            hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 9 : undefined}
          />
          <Column
            dataField={'contattabile'}
            caption={'CC'}
            dataType="boolean"
            visible={false}
            width={'3%'}
            allowHeaderFiltering={false}
            hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 8 : undefined}
          />
          <Column
            dataField={'blacklist'}
            caption={'BL'}
            dataType="boolean"
            visible={false}
            width={'3%'}
            allowHeaderFiltering={false}
            hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 7 : undefined}
          />
          <Column
            dataField={'is_rivenditore'}
            caption={'RV'}
            dataType="boolean"
            visible={false}
            width={'3%'}
            allowHeaderFiltering={false}
            hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 6 : undefined}
          />
          <Column
            dataField={'tipologia_pagamento.nome'}
            caption={'PAGAMENTO'}
            visible={false}
            width={'7%'}
            allowHeaderFiltering={true}
            hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 5 : undefined}
          />
          <Column
            dataField={'leg_origine_rapp'}
            caption={'SEGNALATO'}
            visible={false}
            width={'6%'}
            allowHeaderFiltering={true}
            hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 4 : undefined}
          />
          <Column
            dataField={'email'}
            caption={'EMAIL'}
            visible={false}
            width={'10%'}
            allowHeaderFiltering={true}
            hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 3 : undefined}
          />
          <Column
            dataField={'email_pec'}
            caption={'PEC'}
            visible={false}
            width={'10%'}
            allowHeaderFiltering={true}
            hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 2 : undefined}
          />
          <MasterDetail enabled={true} component={ClientBriefDetail} />,
          <Toolbar>
            <Item
              location="before"
              locateInMenu="never"
              showText="inMenu"
              widget="dxButton"
              options={{
                hint: 'Refresh',
                text: 'Refresh',
                icon: 'refresh',
                onClick: (e: ClickEvent) => {
                  dataGridRef.current?.instance().refresh()
                },
                stylingMode: 'text',
              }}
            ></Item>
            <Item name="groupPanel" />
            <Item
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              name="addRowButton"
              visible={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
              options={{
                onClick: () => {
                  navigate('new')
                },
                hint: 'Nuovo cliente',
                text: 'Nuovo cliente',
              }}
            />
            <Item
              visible={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              widget="dxButton"
              options={{
                hint: 'Elimina cliente',
                text: 'Elimina cliente',
                icon: 'trash',
                onClick: () => {
                  const rowkey = dataGridRef.current?.instance().option('focusedRowKey')
                  dataGridRef.current
                    ?.instance()
                    .byKey(rowkey)
                    .then((rowdata: any) => {
                      console.log(`eliminazione client ${rowdata['nome']}`)
                      const result = confirm(
                        `<i>Vuoi davvero eliminare il cliente <b>${rowdata['nome']}</b>?</i>`,
                        'Eliminazione cliente',
                      )
                      result.then((dialogResult) => {
                        if (dialogResult === false) return

                        deleteAzienda(rowdata.id)
                          .then((res: any) => {
                            // Aggiornamento griglia
                            dataGridRef.current?.instance().refresh()

                            notify(
                              {
                                message: `Cliente ${rowdata['nome']} eliminato con successo`,
                                type: 'success',
                                displayTime: 5000,
                              },
                              {
                                position: 'bottom center',
                                direction: 'up-push',
                              },
                            )
                          })
                          .catch((error: any) => {
                            console.log('erro', error)
                            notify(
                              {
                                message: `Errore eliminazione cliente: ${error}`,
                                type: 'error',
                                displayTime: 5000,
                              },
                              {
                                position: 'bottom center',
                                direction: 'up-push',
                              },
                            )
                          })
                      })
                    })
                },
                stylingMode: 'text',
              }}
            ></Item>
            <Item
              visible={isUserRoleAllowed(userInfo?.roles, [
                Roles.GlobalAdministrator,
                Roles.Administrator,
                Roles.Supervisor,
              ])}
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              widget="dxButton"
              options={{
                hint: 'Gruppi aziendali',
                text: 'Gruppi aziendali',
                icon: 'group',
                onClick: (e: ClickEvent) => {
                  window.open('clients/groups', '_blank')
                },
                stylingMode: 'text',
              }}
            ></Item>
            <Item
              name="columnChooserButton"
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              options={{ hint: 'Scelta colonne', text: 'Scelta colonne' }}
            />
            <Item
              name="exportButton"
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              options={{ hint: 'Esporta dati in excel', text: 'Esporta dati in excel' }}
            />
            <Item name="searchPanel" />
          </Toolbar>
        </DataGrid>
      </div>
    </>
  )
}
