import { contactsSubRoutes } from '@/routes/contacts/ContactsPage.route'
import TabbedSelector from '@/components/TabbedSelector'
import { useParams } from 'react-router-dom'
import DocumentHead from '@/components/document-head/DocumentHead'

export default function ContactsPage() {
  const { clientId } = useParams()
  return (
    <>
      {!clientId && <h2 className={'content-block'}>Contatti</h2>}
      <div className={clientId ? '' : 'content-block'}>
        <TabbedSelector tabs={contactsSubRoutes} />
      </div>
    </>
  )
}
