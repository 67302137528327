import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { attivita_comm, commessa, azienda } from '@/model/qsadminapi/QsAdminApiModuleModel'
import ActivityEditor from '@/routes/activities/ActivityEditor'
import { RoleRouteObject, RouteFunctionParams } from '@/types'
import { redirect } from 'react-router-dom'
import { LoaderFunction, ActionFunction, defer } from 'react-router-typesafe'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import { ODataCollectionResponseV4, ODataModelResponseV4 } from '@odata2ts/odata-core'
import { ODataResponse, HttpResponseModel } from '@odata2ts/http-client-api'
import { Roles } from '@/auth/azure/Roles'
import { StatoCommessa } from '@/routes/projects/ProjectEditor.enums'

export const activityEditorRouteLoader = (async ({
  request,
  params,
}: RouteFunctionParams<'activityId' | 'clientId'>) => {
  const activityId = params.activityId
  const clientId = params.clientId
  const precId = new URL(request.url).searchParams.get('prec')
  const projectId = new URL(request.url).searchParams.get('projectId')

  const qsAdminApi = useQsAdminApiManager.getState().service
  const userInfo = await getAzureUserInformation()
  const user = await qsAdminApi.user().query((builder, impiegato) => {
    builder.filter(impiegato.email.eq(userInfo?.email ?? ''))
    builder.expanding('azienda', (aziendaBuilder, azienda) => {
      aziendaBuilder.select('id', 'nome')
    })
    builder.select('id', 'fullname', 'azienda')
  })

  let azienda: HttpResponseModel<ODataModelResponseV4<azienda>> | undefined
  if (clientId)
    azienda = await qsAdminApi.azienda(Number(clientId)).query((builder, azienda) => {
      builder.select('id', 'nome')
    })
  let title = azienda ? `${azienda.data.nome} - ` : ``

  let activity: attivita_comm
  let getCommesse: ODataResponse<ODataCollectionResponseV4<commessa>> | undefined
  const today = new Date().toISOString()
  const fullYear = new Date().getFullYear()
  if (activityId) {
    //editor
    const activityData = await qsAdminApi.attivita_comm(+activityId).query((builder, attivita) => {
      builder.expanding('sede', (sedeBuilder, sede) => {
        sedeBuilder.expanding('azienda', (aziendaSedeBuilder, azienda) => {
          aziendaSedeBuilder.expanding('sedi', (sediAziendaSedeBuilder, sedeAzienda) => {
            sediAziendaSedeBuilder.select('id', 'nome', 'note')
            // sediAziendaSedeBuilder.orderBy(sedeAzienda.principale.desc()) quando sede non è presente su attività, esplode l'ordinamento
          })
          aziendaSedeBuilder.expanding('agente', (agenteBuilder, agente) => {
            agenteBuilder.expanding('commerciale_qs', (commercialeQsBuilder, commercialeQs) => {
              commercialeQsBuilder.select('id', 'fullname')
            })
            agenteBuilder.select('id', 'commerciale_qs')
          })
          aziendaSedeBuilder.expanding('rivenditore', (rivenditoreBuilder, rivenditore) => {
            rivenditoreBuilder.select('id', 'nome')
          })
          aziendaSedeBuilder.select('id', 'nome', 'sedi', 'agente', 'rivenditore')
        })
        sedeBuilder.select('id', 'nome', 'azienda', 'note')
      })
      builder.expanding('autore', (autoreBuilder, autore) => {
        autoreBuilder.select('id', 'fullname')
      })
      builder.expanding('contatto_aziendale', (contattoBuilder, contatto) => {
        contattoBuilder.select('id', 'fullname')
      })
      builder.expanding('tipologia', (tipologiaBuilder, tipologia) => {
        tipologiaBuilder.select('id', 'nome')
      })
      builder.expanding('stato', (statoBuilder, stato) => {
        statoBuilder.select('id', 'nome')
      })
      builder.expanding('precedente', (precedenteBuilder, precedeteActivity) => {
        precedenteBuilder.select('id')
      })
      builder.expanding('commessa', (commessaBuilder, commessa) => {
        commessaBuilder.select('id', 'titolo', 'ded_Dis')
      })
    })
    activity = activityData.data satisfies attivita_comm
    title += `${activity.ded_Dis}`
    if (clientId && activity?.sede?.azienda && activity?.sede?.azienda.id !== Number(clientId)) {
      throw new Error(
        `L'attività ${activity.ded_Dis} appartiene al cliente ${activity?.sede?.azienda.nome} e non al cliente corrente`,
      )
    }
  } else {
    //creator
    let getActivity: HttpResponseModel<ODataModelResponseV4<attivita_comm>> | undefined
    let getProject: HttpResponseModel<ODataModelResponseV4<commessa>> | undefined

    //followup
    if (precId)
      getActivity = await qsAdminApi.attivita_comm(+precId).query((builder, activity) => {
        builder.expanding('sede', (sedeBuilder, sede) => {
          sedeBuilder.expanding('azienda', (aziendaSedeBuilder, azienda) => {
            aziendaSedeBuilder.expanding('sedi', (sediAziendaSedeBuilder, sedeAzienda) => {
              sediAziendaSedeBuilder.select('id', 'nome', 'note')
              // sediAziendaSedeBuilder.orderBy(sedeAzienda.principale.desc()) quando sede non è presente su attività, esplode l'ordinamento
            })
            aziendaSedeBuilder.select('id', 'nome', 'sedi')
          })
          sedeBuilder.select('id', 'nome', 'azienda', 'note')
        })
        builder.expanding('autore', (autoreBuilder, autore) => {
          autoreBuilder.select('id', 'fullname')
        })
        builder.expanding('contatto_aziendale', (contattoBuilder, contatto) => {
          contattoBuilder.select('id', 'fullname')
        })
        builder.expanding('tipologia', (tipologiaBuilder, tipologia) => {
          tipologiaBuilder.select('id', 'nome')
        })
        builder.expanding('stato', (statoBuilder, stato) => {
          statoBuilder.select('id', 'nome')
        })
        builder.expanding('precedente', (precedenteBuilder, precedeteActivity) => {
          precedenteBuilder.select('id')
        })
        builder.expanding('commessa', (commessaBuilder, commessa) => {
          commessaBuilder.select('id', 'ded_Dis', 'titolo')
        })
      })

    //attività da commessa
    if (projectId)
      //creator a partire da commessa
      getProject = await qsAdminApi.commessa(Number(projectId)).query((builder, project) => {
        builder.expanding('sede', (sedeBuilder, sede) => {
          sedeBuilder.expanding('azienda', (aziendaBuilder, azienda) => {
            aziendaBuilder.expanding('sedi', (sediBuilder, sedeAzienda) => {
              sediBuilder.select('id', 'nome', 'note')
              sediBuilder.orderBy(sedeAzienda.principale.desc())
            })
            aziendaBuilder.select('id', 'nome', 'sedi')
          })
          sedeBuilder.select('id', 'nome', 'azienda', 'note')
        })
      })

    activity = {
      id: 0,
      ded_Dis: '',
      ded_RootFam: '',
      ded_Id: null,
      attachmentsFolderUrl: '',
      inizio: today,
      fine: null,
      creazione: today,
      descrizione: null,
      note_follow_up: null,
      anno_rif: fullYear,
      precedente: getActivity?.data ?? null,
      sede: getActivity?.data.sede ?? getProject?.data.sede ?? null,
      contatto_aziendale: getActivity?.data.contatto_aziendale ?? null,
      tipologia: getActivity?.data.tipologia ?? null,
      stato: getActivity?.data.stato ?? null,
      autore: user.data.value[0],
      commessa: getActivity?.data.commessa ?? getProject?.data ?? null,
    } satisfies attivita_comm

    title += precId ? `Nuova attività: follow up di attività ${activity?.precedente?.ded_Dis}` : `Nuova attività`
  }

  if (activity.sede) {
    getCommesse = qsAdminApi.commessa().query((builder, commessa) => {
      builder.filter(commessa.sede.props.id.eq(Number(activity.sede?.id)))
      builder.filter(
        commessa.stato.props.id
          .eq(StatoCommessa.APERTA)
          .or(activity.commessa ? commessa.id.eq(Number(activity.commessa?.id)) : null),
      )
      builder.select('id', 'ded_Dis', 'titolo')
      builder.orderBy(commessa.id.desc())
    })
  }

  const getTipologie = qsAdminApi.tipologia_attivita_comm().query((builder, tipologia) => {
    builder.select('id', 'nome')
    builder.orderBy(tipologia.nome.asc())
  })

  const getStati = qsAdminApi.stato_att_comm().query((builder, stato) => {
    builder.select('id', 'nome')
    builder.orderBy(stato.nome.asc())
  })

  const getContatti = qsAdminApi.contatto_aziendale().query((builder, contatto) => {
    builder.filter(activity.sede ? contatto.sede.props.id.eq(activity.sede.id) : null)
    builder.filter(
      contatto.attivo
        .eq(true)
        .or(activity.contatto_aziendale ? contatto.id.eq(Number(activity.contatto_aziendale?.id)) : null),
    )
    builder.select('id', 'fullname')
    builder.orderBy(contatto.fullname.asc())
  })

  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.filter(azienda.sedi.any())
    builder.filter(clientId ? azienda.id.eq(Number(clientId)) : null)
    if (userInfo?.roles?.hasRole(Roles.Sales))
      builder.filter(
        azienda.agente.props.commerciale_qs.props.id
          .eq(Number(user.data.value[0].id))
          .or(activity.sede ? azienda.id.eq(Number(activity.sede.azienda?.id)) : null),
      )
    if (userInfo?.roles?.hasRole(Roles.ExternalSales))
      builder.filter(
        azienda.rivenditore.props.id
          .eq(Number(user.data.value[0].azienda?.id))
          .or(activity.sede ? azienda.id.eq(Number(activity.sede.azienda?.id)) : null),
      )
    builder.orderBy(azienda.nome.asc())
    builder.select('id', 'nome')
  })

  return defer({
    title,
    activity,
    getCommesse,
    getTipologie,
    getStati,
    getContatti,
    getAziende,
    user,
    userInfo,
    defaultCRUDAllowedRoles: [
      Roles.GlobalAdministrator,
      Roles.Administrator,
      Roles.Sales,
      Roles.ExternalSales,
      Roles.Supervisor,
    ],
  })
}) satisfies LoaderFunction

export const activityEditorRouteAction = (async ({ request, params }: RouteFunctionParams) => {
  const activity = (await request.json()) as attivita_comm
  const qsAdminApi = useQsAdminApiManager.getState().service
  console.log('activity', activity)

  switch (request.method) {
    case 'POST': {
      const res = await qsAdminApi.attivita_comm().create(activity)
      console.log(res)
      return redirect(`../${res.data.id}`)
    }
    case 'PUT': {
      return await qsAdminApi.attivita_comm(activity.id).update(activity)
    }
    case 'PATCH': {
      return await qsAdminApi.attivita_comm(activity.id).patch(activity)
    }
    default: {
      throw new Response('Method not allowed', {
        status: 405,
        statusText: 'Method not allowed',
      })
    }
  }
}) satisfies ActionFunction

const ActivityEditorRoute = {
  path: ':activityId',
  element: <ActivityEditor creating={false} />,
  loader: activityEditorRouteLoader,
  action: activityEditorRouteAction,
  allowedRoles: [
    Roles.Sales,
    Roles.Administrator,
    Roles.GlobalAdministrator,
    Roles.Marketing,
    Roles.Supervisor,
    Roles.ExternalSales,
  ],
} as RoleRouteObject

export default ActivityEditorRoute
