import { ButtonItem, Form, FormRef, GroupItem, RequiredRule, SimpleItem } from 'devextreme-react/form'
import { useRef } from 'react'
import { ArraySourceComposer } from '@/auth/api/connector'
import { task, user } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { LoadPanel, NumberBox, Popup } from 'devextreme-react'
import { ValueChangedEvent as ValueChangedEventNumberBox } from 'devextreme/ui/number_box'
import { ValueChangedEvent as ValueChangedEventSlider } from 'devextreme/ui/slider'
import { NumberBoxRef } from 'devextreme-react/cjs/number-box'
import { useScreenSize } from '@/themes/media-query'
import { PopupRef } from 'devextreme-react/cjs/popup'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import notify from 'devextreme/ui/notify'
import { LoadPanelRef } from 'devextreme-react/cjs/load-panel'
import { ClickEvent } from 'devextreme/ui/button'
import { DataGridRef } from 'devextreme-react/cjs/data-grid'
export type OutlookAppointmentOnSubmitEditorParams = {
  users: (user | null | undefined)[] | undefined
  date: Date | undefined
  duration: number | undefined
  task: task | null | undefined
  remote: boolean | undefined
  provisional: boolean | undefined
  notes?: string | undefined
}
export type OutlookAppointmentSelectorProps = {
  task: task | undefined
  popupRef: React.RefObject<PopupRef>
  appointmentData?: any
  appointmentGridRef?: React.RefObject<DataGridRef>
}

export const OutlookAppointmentSelector = (props: OutlookAppointmentSelectorProps) => {
  const { popupRef, task, appointmentData, appointmentGridRef } = props
  const formRef = useRef<FormRef>(null)
  const durataNumberBoxRef = useRef<NumberBoxRef>(null)
  const currentScreenSize = useScreenSize()
  const { client } = useQsAdminApiManager()
  const loadPanelRef = useRef<LoadPanelRef>(null)

  const dateTaskAppointment = task?.consegna_prevista ? new Date(task.consegna_prevista) : new Date()
  dateTaskAppointment.setHours(8)
  dateTaskAppointment.setMinutes(30)
  dateTaskAppointment.setSeconds(0)
  dateTaskAppointment.setMilliseconds(0)

  const tecnici: (user | null | undefined)[] = []
  tecnici.push(task?.resp_tecnico ?? null)
  task?.tecnici?.map((tecnico: user) => {
    tecnici.push(tecnico ?? null)
  })

  const formData: {
    data: Date
    durata: number
    utenti: (user | null | undefined)[]
    remoto: boolean
    provvisorio: boolean
    note: string
  } = {
    data: appointmentData ? new Date(appointmentData.StartDate) : dateTaskAppointment,
    durata: appointmentData ? appointmentData.Duration : 9,
    utenti: appointmentData
      ? [
          {
            id: appointmentData.User.id,
            fullname: appointmentData.User.fullName,
            email: appointmentData.User.email,
            cognome: null,
            nome: null,
            note: null,
            attivo: false,
            contractor: false,
            email_ad: null,
          },
        ]
      : tecnici,
    remoto: appointmentData ? appointmentData.Remote : false,
    provvisorio: appointmentData ? appointmentData.Provisional : false,
    note: appointmentData ? appointmentData.Notes : '',
  }

  return (
    <>
      <Popup
        width={currentScreenSize.isLarge || currentScreenSize.isMedium ? 700 : currentScreenSize.isXSmall ? 350 : 700}
        height={currentScreenSize.isSmall ? 320 : currentScreenSize.isXSmall ? 720 : 810}
        hideOnOutsideClick={true}
        title="Selezionare Data Appuntamento"
        showCloseButton={true}
        deferRendering={true}
        ref={popupRef}
        onHiding={() => {
          formRef.current?.instance().updateData('data', dateTaskAppointment)
          formRef.current?.instance().updateData('durata', 9)
          formRef.current?.instance().updateData('utenti', tecnici)
          formRef.current?.instance().updateData('remoto', false)
          formRef.current?.instance().updateData('provvisorio', false)
          formRef.current?.instance().updateData('note', '')
        }}
      >
        <div id="outlook-appointment-selector-form-container" className={'dx-card responsive-paddings'}>
          {/* <LoadingPanel
            position={{ of: '#outlook-appointment-selector-form-container' }}
            visible={loadIndicatorVisible}
          ></LoadingPanel> */}
          <LoadPanel
            ref={loadPanelRef}
            shadingColor="rgba(0,0,0,0.4)"
            showIndicator={true}
            shading={true}
            showPane={true}
            hideOnOutsideClick={false}
            hideOnParentScroll={false}
            position={{ of: '#outlook-appointment-selector-form-container' }}
          />

          <Form
            id={`outlook-appointment-selector-form}`}
            key={`outlook-appointment-selector-form}`}
            formData={formData}
            ref={formRef}
            validationGroup="outlookAppointmentSelectorValidation"
          >
            <GroupItem colCount={1}>
              <SimpleItem
                dataField="utenti"
                editorType="dxTagBox"
                label={{ text: 'Utenti' }}
                editorOptions={{
                  dataSource: ArraySourceComposer('id', tecnici),
                  displayExpr: 'fullname',
                  placeholder: 'Seleziona utenti...',
                  applyValueMode: 'instantly',
                  showSelectionControls: true,
                }}
              >
                <RequiredRule message="Almeno un utente obbligatorio" />
              </SimpleItem>
              <SimpleItem
                dataField="data"
                editorOptions={{
                  displayFormat: 'dd/MM/yyyy HH:mm',
                  type: 'datetime',
                  pickerType: 'calendar',
                }}
                editorType="dxDateBox"
                label={{ text: 'Data' }}
              >
                <RequiredRule message="Data obbligatoria" />
              </SimpleItem>
              <GroupItem colCount={2}>
                <SimpleItem
                  dataField="durata"
                  editorType="dxSlider"
                  label={{ text: 'Durata' }}
                  editorOptions={{
                    max: 10,
                    min: 1,
                    step: 1,
                    onValueChanged: (e: ValueChangedEventSlider) => {
                      durataNumberBoxRef.current?.instance().option('value', e.value)
                    },
                  }}
                >
                  <RequiredRule message="Durata obbligatoria" />
                </SimpleItem>
                <SimpleItem label={{ text: 'Valore' }}>
                  <NumberBox
                    ref={durataNumberBoxRef}
                    max={10}
                    min={1}
                    step={1}
                    defaultValue={formData?.durata}
                    showSpinButtons={true}
                    onValueChanged={(e: ValueChangedEventNumberBox) => {
                      formRef.current?.instance().updateData('durata', e.value)
                    }}
                  ></NumberBox>
                </SimpleItem>
              </GroupItem>
            </GroupItem>
            <GroupItem colCount={1}>
              {/* <SimpleItem dataField={'note'} editorType={'dxTextArea'}></SimpleItem> */}
              <SimpleItem
                colSpan={1}
                dataField="note"
                label={{ text: 'Note' }}
                editorType={'dxHtmlEditor'}
                editorOptions={{
                  stylingMode: 'filled',
                  valueType: 'html',
                  // toolbar: toolbarHtmlEditor(currentScreenSize),
                  mediaResizing: {
                    enabled: true,
                  },
                  imageUpload: {
                    tabs: ['file', 'url'],
                    fileUploadMode: 'base64',
                  },
                  height: '300',
                }}
              ></SimpleItem>
            </GroupItem>
            <GroupItem colCount={2}>
              <SimpleItem dataField="remoto" label={{ text: 'Remoto' }} editorType="dxSwitch"></SimpleItem>
              <SimpleItem dataField="provvisorio" label={{ text: 'Provvisorio' }} editorType="dxSwitch"></SimpleItem>
            </GroupItem>
            <GroupItem cssClass="last-group">
              <GroupItem cssClass="buttons-group" colCount={1}>
                <ButtonItem
                  name="btn-compute"
                  buttonOptions={{
                    icon: 'group',
                    text: 'Crea',
                    disabled: false,
                    width: '150px',
                    onClick: async (e: ClickEvent) => {
                      const validationResult = formRef.current?.instance().validate()
                      if (!validationResult?.isValid) return
                      loadPanelRef.current?.instance().option('visible', true)
                      try {
                        const response = await client.post(
                          'api/task/addOutlookAppointment',
                          {
                            users: formData.utenti,
                            date: formData.data,
                            duration: formData.durata,
                            task,
                            remote: formData.remoto,
                            provisional: formData.provvisorio,
                            notes: formData.note,
                          },
                          {
                            headers: { 'Content-Type': 'application/json' },
                          },
                        )
                        notify(`Creazione appuntamento terminata con successo.`, 'success', 2000)
                        if (appointmentGridRef) appointmentGridRef.current?.instance().refresh()
                      } catch (error: unknown) {
                        notify(
                          `Errore creazione appuntamento per il task ${task?.ded_Dis}. Dettagli : ${error}`,
                          'error',
                          2000,
                        )
                      } finally {
                        loadPanelRef.current?.instance().option('visible', false)
                        popupRef.current?.instance().hide()
                        formRef.current?.instance().updateData('data', dateTaskAppointment)
                        formRef.current?.instance().updateData('durata', 9)
                        formRef.current?.instance().updateData('utenti', tecnici)
                        formRef.current?.instance().updateData('remoto', false)
                        formRef.current?.instance().updateData('provvisorio', false)
                        formRef.current?.instance().updateData('note', '')
                      }
                    },
                  }}
                ></ButtonItem>
              </GroupItem>
            </GroupItem>
          </Form>
        </div>
      </Popup>
    </>
  )
}

export default OutlookAppointmentSelector
