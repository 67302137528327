import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { intervento, pacchetto_utilizzo, pacchetto_vendita, azienda } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { RoleRouteObject, RouteFunctionParams } from '@/types'
import { redirect } from 'react-router-dom'
import { defer, LoaderFunction, ActionFunction } from 'react-router-typesafe'
import { ODataCollectionResponseV4, ODataModelResponseV4 } from '@odata2ts/odata-core'
import { ODataResponse, HttpResponseModel } from '@odata2ts/http-client-api'
import PackageUsageEditor from '@/routes/packages/usage/PackageUsageEditor'
import { Roles } from '@/auth/azure/Roles'
import { getAzureUserInformation } from '@/auth/azure/azureManager'

export const packageUsageEditorRouteAction = (async ({ request, params }: RouteFunctionParams) => {
  const packageUsage = (await request.json()) as pacchetto_utilizzo
  console.log('pacchetto', packageUsage)
  const qsAdminApi = useQsAdminApiManager.getState().service

  switch (request.method) {
    case 'POST': {
      const res = await qsAdminApi.pacchetto_utilizzo().create(packageUsage)
      console.log(res)
      return redirect(`../${res.data.id}`)
    }
    case 'PUT':
    case 'PATCH': {
      return await qsAdminApi.pacchetto_utilizzo(packageUsage.id).update(packageUsage)
    }
    default: {
      throw new Response('Method not allowed', {
        status: 405,
        statusText: 'Method not allowed',
      })
    }
  }
}) satisfies ActionFunction

export const packageUsageEditorRouteLoader = (async ({
  request,
  params,
}: RouteFunctionParams<'packageUsageId' | 'clientId'>) => {
  const packageUsageId = params.packageUsageId
  const clientId = params.clientId

  const qsAdminApi = useQsAdminApiManager.getState().service

  let azienda: HttpResponseModel<ODataModelResponseV4<azienda>> | undefined
  let getInterventi: ODataResponse<ODataCollectionResponseV4<intervento>> | undefined
  let getPacchettiVendita: ODataResponse<ODataCollectionResponseV4<pacchetto_vendita>> | undefined
  let title = azienda ? `${azienda.data.nome} - ` : ``
  let packageUsage: pacchetto_utilizzo

  if (clientId)
    azienda = await qsAdminApi.azienda(Number(clientId)).query((builder, azienda) => {
      builder.select('id', 'nome')
    })

  if (packageUsageId) {
    //editor
    const getPackageUsage = await qsAdminApi.pacchetto_utilizzo(Number(packageUsageId)).query((builder, pacchetto) => {
      builder.expanding('pacchetto_vendita', (pacchettoVenditaBuilder, pacchettoVendita) => {
        pacchettoVenditaBuilder.expanding('sede', (sedePacchettoVenditaBuilder, sedePacchettoVendita) => {
          sedePacchettoVenditaBuilder.expanding(
            'azienda',
            (aziendaSedePacchettoVenditaBuilder, aziendaSedePacchettoVendita) => {
              aziendaSedePacchettoVenditaBuilder.expanding('sedi', (sediBuilder, sedeSedi) => {
                sediBuilder.select('id', 'nome', 'note')
              })
              aziendaSedePacchettoVenditaBuilder.select('id', 'nome', 'sedi')
            },
          )
          sedePacchettoVenditaBuilder.select('id', 'nome', 'azienda', 'note')
        })
        pacchettoVenditaBuilder.expanding('tipologia_unita', (tipologia_unitaBuilder, tipologia) => {
          tipologia_unitaBuilder.select('id', 'nome')
        })
        pacchettoVenditaBuilder.select('id', 'ded_Dis', 'sede', 'tipologia_unita')
      })
      builder.expanding('intervento', (interventoBuilder, intervento) => {
        interventoBuilder.expanding('sede', (sedeInterventoBuilder, sedeIntervento) => {
          sedeInterventoBuilder.expanding('azienda', (aziendaSedeInterventoBuilder, aziendaSedeIntervento) => {
            aziendaSedeInterventoBuilder.expanding('sedi', (sediBuilder, sedeSedi) => {
              sediBuilder.select('id', 'nome', 'note')
            })
            aziendaSedeInterventoBuilder.select('id', 'nome', 'sedi')
          })
          sedeInterventoBuilder.select('id', 'nome', 'azienda', 'note')
        })
        interventoBuilder.expanding('task', (taskBuilder, task) => {
          taskBuilder.expanding('commessa', (commessaBuilder, commessa) => {
            commessaBuilder.select('id', 'titolo', 'ded_Dis')
          })
          taskBuilder.select('id', 'ded_Dis', 'commessa')
        })
        interventoBuilder.expanding('impiegati', (impiegatiBuilder, impiegato) => {
          impiegatiBuilder.select('id', 'fullname')
        })
        interventoBuilder.expanding('riferimenti_cliente', (riferimentiBuilder, riferimento) => {
          riferimentiBuilder.select('id', 'fullname')
        })
        interventoBuilder.expanding('stato_fatturazione', (statoFatturazioneBuilder, statoFatturazione) => {
          statoFatturazioneBuilder.select('id', 'nome')
        })
      })
    })
    packageUsage = getPackageUsage.data satisfies pacchetto_utilizzo
    title += `${packageUsage.ded_Dis}`
    if (
      clientId &&
      packageUsage.intervento?.sede?.azienda &&
      packageUsage?.intervento?.sede?.azienda.id !== Number(clientId)
    ) {
      throw new Error(
        `Il pacchetto utilizzo ${packageUsage.ded_Dis} appartiene al cliente ${packageUsage?.intervento?.sede?.azienda.nome} e non al cliente corrente`,
      )
    }
  } else {
    //creator
    title += `Nuovo pacchetto`
    packageUsage = {
      id: 0,
      ded_Dis: '',
      ded_RootFam: '',
      ded_SubFam: '',
      ded_Id: 0,
      unita_utilizzate: 0,
      note: null,
      creazione: new Date().toISOString(),
      pacchetto_vendita: null,
      intervento: null,
    }
  }

  if (packageUsage.pacchetto_vendita || packageUsage.intervento) {
    getInterventi = qsAdminApi.intervento().query((builder, intervento) => {
      builder.filter(
        intervento.pacchetti_utilizzo
          .any()
          .not()
          .or(packageUsage.intervento ? intervento.id.eq(packageUsage.intervento.id) : null),
      )
      builder.filter(
        intervento.sede.props.id.eq(
          packageUsage.pacchetto_vendita
            ? Number(packageUsage.pacchetto_vendita.sede?.id)
            : packageUsage.intervento
              ? Number(packageUsage.intervento.sede?.id)
              : null,
        ),
      )
      builder.expanding('sede', (sedeInterventoBuilder, sedeIntervento) => {
        sedeInterventoBuilder.expanding('azienda', (aziendaSedeInterventoBuilder, aziendaSedeIntervento) => {
          aziendaSedeInterventoBuilder.expanding('sedi', (sediBuilder, sedeSedi) => {
            sediBuilder.select('id', 'nome', 'note')
          })
          aziendaSedeInterventoBuilder.select('id', 'nome', 'sedi')
        })
        sedeInterventoBuilder.select('id', 'nome', 'azienda', 'note')
      })
      builder.expanding('task', (taskBuilder, task) => {
        taskBuilder.expanding('commessa', (commessaBuilder, commessa) => {
          commessaBuilder.select('id', 'titolo', 'ded_Dis')
        })
        taskBuilder.select('id', 'ded_Dis', 'commessa')
      })
      builder.expanding('impiegati', (impiegatiBuilder, impiegato) => {
        impiegatiBuilder.select('id', 'fullname')
      })
      builder.expanding('riferimenti_cliente', (riferimentiBuilder, riferimento) => {
        riferimentiBuilder.select('id', 'fullname')
      })
      builder.expanding('stato_fatturazione', (statoFatturazioneBuilder, statoFatturazione) => {
        statoFatturazioneBuilder.select('id', 'nome')
      })
      builder.orderBy(intervento.id.desc())
    })

    getPacchettiVendita = qsAdminApi.pacchetto_vendita().query((builder, pacchetto) => {
      builder.filter(
        pacchetto.sede.props.id.eq(
          packageUsage.pacchetto_vendita
            ? Number(packageUsage.pacchetto_vendita.sede?.id)
            : packageUsage.intervento
              ? Number(packageUsage.intervento.sede?.id)
              : null,
        ),
      )
      builder.expanding('sede', (sedePacchettoVenditaBuilder, sedePacchettoVendita) => {
        sedePacchettoVenditaBuilder.expanding(
          'azienda',
          (aziendaSedePacchettoVenditaBuilder, aziendaSedePacchettoVendita) => {
            aziendaSedePacchettoVenditaBuilder.expanding('sedi', (sediBuilder, sedeSedi) => {
              sediBuilder.select('id', 'nome', 'note')
            })
            aziendaSedePacchettoVenditaBuilder.select('id', 'nome', 'sedi')
          },
        )
        sedePacchettoVenditaBuilder.select('id', 'nome', 'azienda', 'note')
      })
      builder.expanding('tipologia_unita', (tipologia_unitaBuilder, tipologia) => {
        tipologia_unitaBuilder.select('id', 'nome')
      })
      builder.select('id', 'ded_Dis', 'sede', 'tipologia_unita')
      builder.orderBy(pacchetto.id.desc())
    })
  }

  const getAziende = qsAdminApi.azienda().query((builder, qazienda) => {
    builder.filter(qazienda.sedi.any())
    builder.filter(clientId ? qazienda.id.eq(Number(clientId)) : null)
    builder.select('id', 'nome')
    builder.orderBy(qazienda.nome.asc())
  })

  const getBillingStates = qsAdminApi.fatturazione_intervento().query((builder, fatturazione_intervento) => {
    builder.select('id', 'nome')
    builder.orderBy(fatturazione_intervento.nome.asc())
  })

  return defer({
    title,
    packageUsage,
    getAziende,
    getInterventi,
    getPacchettiVendita,
    getBillingStates,
    getUserInfo: getAzureUserInformation(),
    defaultCRUDAllowedRoles: [Roles.GlobalAdministrator, Roles.Administrator, Roles.Supervisor],
  })
}) satisfies LoaderFunction

export const PackageUsageEditorRoute = {
  path: ':packageUsageId',
  element: <PackageUsageEditor creating={false} />,
  loader: packageUsageEditorRouteLoader,
  action: packageUsageEditorRouteAction,
  allowedRoles: [Roles.Guest],
} as RoleRouteObject
