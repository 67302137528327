import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import {
  ButtonItem,
  ButtonOptions,
  Form as DXForm,
  EmptyItem,
  GroupItem,
  RequiredRule,
  SimpleItem,
  FormRef,
} from 'devextreme-react/form'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams, useRevalidator } from 'react-router-dom'
import { FieldDataChangedEvent } from 'devextreme/ui/form'
import { ArraySourceComposer } from '@/auth/api/connector'
import { azienda, commessa, sede } from '@/model/qsadminapi/QsAdminApiModuleModel'
import DataSource from 'devextreme/data/data_source'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { PackageSaleEditorFormProps } from '@/routes/packages/sale/PackageSaleEditor.types'
import ProjectSimpleItem from '@/components/project-simple-item/ProjectSimpleItem'
import notify from 'devextreme/ui/notify'
import FormPopupProject from '@/components/form-popup-project/FormPopupProject'
import { StatoCommessa } from '@/routes/projects/ProjectEditor.enums'
import SiteSimpleItem from '@/components/site-simple-item/SiteSimpleItem'
import { ValueChangedEvent as ValueChangedEventSelectBox } from 'devextreme/ui/select_box'
import { PopupRef } from 'devextreme-react/cjs/popup'
import FormPopupClientNote from '@/components/form-popup-client-note/FormPopupClientNote'
import { customButton, isUserRoleAllowed } from '@/routes/utils'
import { ClickEvent } from 'devextreme/ui/button'
import { Roles } from '@/auth/azure/Roles'

const PackageSaleEditorForm = (props: PackageSaleEditorFormProps) => {
  const { pacchetto, aziende, tipologieDurata, creating, commesse, isRoleAllowed, userInfo, fetcher } = props
  const { clientId } = useParams()

  const { service } = useQsAdminApiManager()

  const formRef = useRef<FormRef>(null)
  const revalidator = useRevalidator()

  const popupNoteClienteRef = useRef<PopupRef>(null)
  const [currentCompanyChanged, setCurrentCompanyChanged] = useState<boolean>(clientId && creating ? true : false)
  const [currentCompany, setCurrentCompany] = useState<azienda | null | undefined>(
    clientId
      ? aziende
        ? aziende[0]
        : undefined
      : pacchetto && 'sede' in pacchetto
        ? pacchetto?.sede?.azienda
        : undefined,
  )
  const [currentSite, setCurrentSite] = useState<sede | null | undefined>()
  const [currentSiteChanged, setCurrentSiteChanged] = useState<boolean>(false)
  const [sediDataSource, setSediDataSource] = useState<DataSource>(
    ArraySourceComposer('id', pacchetto && 'sede' in pacchetto ? pacchetto?.sede?.azienda?.sedi : []),
  )
  const [commesseDataSource, setCommesseDataSource] = useState<DataSource>(ArraySourceComposer('id', commesse))

  //commessa form popup
  const popupRefProject = useRef<PopupRef>(null)

  const getCompanySites = useCallback(
    async (companyId: number) => {
      const sitesData = await service.sede().query((builder, sede) => {
        builder.filter(sede.azienda.props.id.eq(companyId))
        builder.orderBy(sede.principale.desc())
        builder.expanding('azienda', (aziendaSedeBuilder, azienda) => {
          aziendaSedeBuilder.expanding('sedi', (sediAziendaSedeBuilder, sedeAzienda) => {
            sediAziendaSedeBuilder.select('id', 'nome', 'note')
            sediAziendaSedeBuilder.orderBy(sedeAzienda.principale.desc())
          })
          aziendaSedeBuilder.select('id', 'nome', 'sedi')
        })
        builder.select('id', 'nome', 'azienda', 'note')
      })
      return sitesData.data.value
    },
    [service],
  )

  const getSiteProjects = useCallback(
    async (siteId: number) => {
      const projectsData = await service.commessa().query((builder, commessa) => {
        builder.filter(commessa.sede.props.id.eq(siteId)).filter(commessa.stato.props.id.eq(StatoCommessa.APERTA))
        builder.orderBy(commessa.id.desc())
        builder.select('id', 'ded_Dis', 'titolo')
      })
      return projectsData.data.value
    },
    [service, creating],
  )

  useEffect(() => {
    if (!currentCompanyChanged) return
    if (currentCompany) {
      getCompanySites(currentCompany.id).then((sites: sede[]) => {
        console.log('SITES', sites)
        setSediDataSource(ArraySourceComposer('id', sites))
        formRef.current?.instance().updateData('sede', sites[0])
        setCurrentSiteChanged(true)
      })
      setCurrentCompanyChanged(false)
    }
  }, [currentCompanyChanged, currentCompany, getCompanySites])

  useEffect(() => {
    if (!currentSiteChanged) return
    console.log('SITE EFFECT', pacchetto.sede)
    if (pacchetto.sede) {
      getSiteProjects(pacchetto.sede.id).then((commesse: commessa[]) => {
        console.log('commesse', commesse)
        setCommesseDataSource(ArraySourceComposer('id', commesse))
        formRef.current?.instance().updateData('commessa', null)
      })
      setCurrentSiteChanged(false)
    }
  }, [currentSiteChanged, pacchetto.sede, getSiteProjects])

  return (
    <>
      <LoadingPanel visible={!(fetcher?.state === 'idle' && revalidator.state === 'idle')} />
      <DXForm
        id={'package_sale_form'}
        formData={pacchetto}
        labelLocation={'top'}
        colCount={1}
        showValidationSummary={true}
        onFieldDataChanged={(e: FieldDataChangedEvent) => {
          if (
            formRef.current?.instance().option().isDirty !== null &&
            formRef.current?.instance().option().isDirty !== undefined
          ) {
            console.log('is dirty', formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-save')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-cancel')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
          }
        }}
        validationGroup="packageSaleValidation"
        ref={formRef}
        readOnly={!isRoleAllowed}
      >
        <GroupItem colCount={5}>
          <GroupItem colCount={2} colSpan={2}>
            <SimpleItem
              label={{ text: 'Azienda' }}
              editorType="dxSelectBox"
              editorOptions={{
                buttons: [
                  customButton('open', (e: ClickEvent) => {
                    if (currentCompany) window.open(`/clients/${currentCompany.id}`, '_blank')
                    else notify(`Deve essere selezionata un' azienda per poterla aprire.`, 'warning', 3000)
                  }),
                  { name: 'dropDown', location: 'after' },
                ],
                value: currentCompany,
                dataSource: ArraySourceComposer('id', aziende),
                placeholder: "Seleziona l'azienda...",
                displayExpr: 'nome',
                searchEnabled: true,
                readOnly: !isRoleAllowed || (clientId ? true : false),
                dropDownOptions: {
                  showTitle: true,
                  title: "Selezionare l'azienda",
                  hideOnOutsideClick: true,
                },
                onValueChanged: (e: ValueChangedEventSelectBox) => {
                  if (e.previousValue?.id === e.value?.id) return
                  console.log('COMPANY EVENT CHANGED', e.previousValue, e.value)
                  if (e.value) {
                    setCurrentCompany(e.value)
                    setCurrentCompanyChanged(true)
                  }
                },
              }}
            >
              <RequiredRule message="Azienda obbligatoria"></RequiredRule>
            </SimpleItem>
            {SiteSimpleItem({
              dataSource: sediDataSource,
              rules: [
                {
                  type: 'required',
                  message: `Sede obbligatoria`,
                },
              ],
              readOnly: !isRoleAllowed,
              onValueChanged: (e: ValueChangedEventSelectBox) => {
                if (e.previousValue?.id === e.value?.id) return
                console.log('SITE EVENT CHANGED', e.previousValue, e.value)
                if (e.value) setCurrentSiteChanged(true)
              },
              onInfoClick: () => {
                if (pacchetto.sede) {
                  setCurrentSite(pacchetto.sede)
                  popupNoteClienteRef.current?.instance().show()
                } else notify('Deve essere selezionata una sede per aprire le note.', 'warning', 3000)
              },
            })}
            <SimpleItem
              dataField="creazione"
              editorOptions={{
                displayFormat: 'dd/MM/yyyy',
                readOnly: true,
              }}
              editorType="dxDateBox"
              label={{ text: 'Data Creazione' }}
            >
              <RequiredRule message="Data creazione obbligatoria" />
            </SimpleItem>
            <SimpleItem
              dataField="data_fatturazione"
              editorOptions={{
                displayFormat: 'dd/MM/yyyy',
              }}
              editorType="dxDateBox"
              label={{ text: 'Data Fattura' }}
            ></SimpleItem>
            {ProjectSimpleItem({
              onAddClick: () => {
                if (pacchetto.sede) {
                  popupRefProject.current?.instance().show()
                } else notify(`Deve essere selezionata un' azienda per poter creare una commessa.`, 'warning', 3000)
              },
              onFolderClick: () => {
                if (pacchetto.commessa) window.open(`/projects/${pacchetto?.commessa?.id}`, '_blank')
                else notify(`Deve essere selezionata una commessa per poterla aprire.`, 'warning', 3000)
              },
              dataSource: commesseDataSource,
              readOnly: !isRoleAllowed,
            })}
            <EmptyItem></EmptyItem>
            <SimpleItem
              dataField="tipologia_unita"
              label={{ text: 'Tipologia durata' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', tipologieDurata),
                placeholder: 'Seleziona tipologia...',
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare tipologia',
                  hideOnOutsideClick: true,
                },
              }}
            >
              <RequiredRule message="Tipologia durata obbligatoria" />
            </SimpleItem>
            <SimpleItem
              dataField="unita"
              editorType="dxNumberBox"
              label={{ text: 'Durata' }}
              editorOptions={{
                showSpinButtons: true,
                min: 0,
                step: 0.5,
              }}
            >
              <RequiredRule message="Durata obbligatoria"></RequiredRule>
            </SimpleItem>
            <SimpleItem
              dataField="costo_unita"
              editorType="dxNumberBox"
              label={{ text: 'Costo Unitario' }}
              editorOptions={{
                showSpinButtons: true,
                min: 0,
              }}
            >
              <RequiredRule message="Costo Unitario obbligatoria"></RequiredRule>
            </SimpleItem>
            <SimpleItem dataField="consuntivo" label={{ text: 'A consuntivo' }} editorType="dxSwitch" />
          </GroupItem>
          <GroupItem colSpan={3}>
            <SimpleItem
              dataField={'note'}
              editorType={'dxTextArea'}
              label={{ visible: true }}
              editorOptions={{
                height: '350',
              }}
            >
              <RequiredRule message="Note obbligatorio"></RequiredRule>
            </SimpleItem>
          </GroupItem>
        </GroupItem>
        <GroupItem visible={isRoleAllowed} cssClass="last-group">
          <GroupItem cssClass="buttons-group" colCount={creating ? 1 : 2}>
            <ButtonItem name="btn-save">
              <ButtonOptions
                disabled={!formRef.current?.instance().option().isDirty}
                icon="save"
                text="Salva"
                width="150px"
                onClick={async () => {
                  const validationResult = formRef.current?.instance().validate()
                  if (!validationResult?.isValid) return
                  console.log('validation ok')
                  console.log('pacchetto', pacchetto)
                  if (pacchetto) {
                    fetcher?.submit(JSON.stringify(pacchetto), {
                      method: creating ? 'POST' : 'PUT',
                      encType: 'application/json',
                    })
                  } else console.error('no data tu submit')
                }}
              />
            </ButtonItem>
            {!creating && (
              <ButtonItem name="btn-cancel">
                <ButtonOptions
                  disabled={!formRef.current?.instance().option().isDirty}
                  width="150px"
                  text="Annulla"
                  icon="undo"
                  onClick={() => {
                    revalidator.revalidate()
                  }}
                />
              </ButtonItem>
            )}
          </GroupItem>
        </GroupItem>
      </DXForm>
      <FormPopupProject
        popupRef={popupRefProject}
        sede={pacchetto.sede}
        refreshCommessa={async () => {
          getSiteProjects(Number(pacchetto.sede?.id)).then((res: commessa[]) => {
            setCommesseDataSource(ArraySourceComposer('id', res))
            formRef.current?.instance().updateData('commessa', res[0])
            popupRefProject.current?.instance().hide()
          })
        }}
      ></FormPopupProject>
      <FormPopupClientNote
        data={currentSite}
        refPopup={popupNoteClienteRef}
        refreshDataSourceSites={() => {
          if (currentCompany) {
            getCompanySites(currentCompany?.id).then((sites: sede[]) => {
              console.log('SITES', sites)
              setSediDataSource(ArraySourceComposer('id', sites))
              formRef.current?.instance().updateData(
                'sede',
                sites.find((site: sede) => {
                  return site.id === currentSite?.id
                }),
              )
            })
          }
        }}
        isRoleAllowed={isUserRoleAllowed(userInfo?.roles, [
          Roles.TechDeveloper,
          Roles.Sales,
          Roles.GlobalAdministrator,
          Roles.ExternalSales,
          Roles.Administrator,
        ])}
      ></FormPopupClientNote>
    </>
  )
}

export default PackageSaleEditorForm
