import {
  Column,
  ColumnChooser,
  DataGrid,
  Editing,
  Export,
  FilterRow,
  HeaderFilter,
  Item,
  Scrolling,
  SearchPanel,
  Selection,
  StateStoring,
  Toolbar,
  DataGridTypes,
  DataGridRef,
  Sorting,
  Search,
  FilterPanel,
  Pager,
  Button as GridButton,
} from 'devextreme-react/data-grid'
import ODataStore from 'devextreme/data/odata/store'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver'
import { useScreenSize } from '@/themes/media-query'
import notify from 'devextreme/ui/notify'
import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import dxDataGrid, {
  ColumnButtonClickEvent,
  ExportingEvent,
  RowDblClickEvent,
  RowPreparedEvent,
} from 'devextreme/ui/data_grid'
import { useLoaderData } from 'react-router-typesafe'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { GridCellColor } from '@/enums'
import { pacchetto_utilizzo } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { packagesUsageGridRouteLoader } from '@/routes/packages/usage/PackagesUsageGrid.route'
import { ClickEvent } from 'devextreme/ui/button'
import { confirm } from 'devextreme/ui/dialog'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { Accordion, Item as AccordionItem } from 'devextreme-react/accordion'
import { ContentReadyEvent } from 'devextreme/ui/accordion'
import { FormFilterItemDescriptorType } from '@/components/filter-form/GenericFilterForm.types'
import GenericFilterForm from '@/components/filter-form/GenericFilterForm'
import { AxiosError, AxiosResponse } from 'axios'
import useTokenRefresh from '@/auth/azure/azureManager'
import DateColumn from '@/components/date-column/DateColumn'
import { columnSourceFactory, isUserRoleAllowed } from '@/routes/utils'
import { Roles } from '@/auth/azure/Roles'
import { gridTotalItemsLabel } from '@/constants'
import DocumentHead from '@/components/document-head/DocumentHead'

const PackagesUsageGrid = () => {
  const { title, aziende, userInfo, defaultCRUDAllowedRoles } = useLoaderData<typeof packagesUsageGridRouteLoader>()
  const token = useTokenRefresh()
  const { clientId } = useParams()
  const { service, client } = useQsAdminApiManager()
  const gridRef = useRef<DataGridRef>(null)
  const currentScreenSize = useScreenSize()
  const navigate = useNavigate()

  const getGridHeight = useCallback(() => {
    let h = '72vh'
    if (currentScreenSize.isMedium) {
      h = '68vh'
    } else if (currentScreenSize.isSmall) {
      h = '120vh'
    } else if (currentScreenSize.isXSmall) {
      h = '65vh'
    }
    return h
  }, [currentScreenSize])

  const getFiltersConfiguration = useCallback((): FormFilterItemDescriptorType[] => {
    const fltConf: FormFilterItemDescriptorType[] = [
      {
        fieldName: 'azienda',
        placeHolder: 'Azienda',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        values: aziende.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [
            [
              ['pacchetto_vendita/sede/azienda/id', this.currentValue],
              'or',
              ['intervento/sede/azienda/id', this.currentValue],
            ],
          ]
        },
      },
    ]
    console.log('GENFLT - GETCONF :', fltConf)
    return fltConf
  }, [aziende])

  const initialFilterConfiguration = useMemo(() => getFiltersConfiguration(), [getFiltersConfiguration])
  const [filterConfiguration, setFilterConfiguration] = useState(initialFilterConfiguration)
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false)
  const getCurrentFilter = useCallback((): any[] => {
    let applied: boolean = false
    const currentFlts = []
    for (const flt of filterConfiguration) {
      const fltValue = flt.composeFilterItem()
      if (fltValue !== null) {
        currentFlts.push(fltValue)
      }
      if (!applied && flt.currentValue !== flt.defaultValue) applied = true
    }
    setFiltersApplied(applied)
    console.log('GENFLT - CURRENT:', currentFlts)
    return currentFlts
  }, [filterConfiguration])

  const [currentFilter, setCurrentFilter] = useState<any[]>(
    clientId
      ? [
          [
            ['pacchetto_vendita/sede/azienda/id', Number(clientId)],
            'or',
            ['intervento/sede/azienda/id', Number(clientId)],
          ],
        ]
      : [],
  )

  const packagesUsageDataSource = {
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/pacchetto_utilizzo`,
      key: 'id',
      keyType: 'Int32',
      version: 4,
      errorHandler: (e) => {
        console.log(e.errorDetails)
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    filter: currentFilter.length > 0 ? currentFilter : null,
    expand: [
      'pacchetto_vendita($expand=tipologia_unita,sede($expand=azienda))',
      'intervento($expand=task,sede($expand=azienda))',
    ],
  }

  const onRowPrepared = (e: RowPreparedEvent) => {
    if (e.rowType === 'data') {
      if (!e.data.pacchetto_vendita)
        e.rowElement.style.cssText = `${e.rowElement.style.cssText}color: black;background-color: ${GridCellColor.SKYBLUE}`
      else if (e.data.pacchetto_vendita && e.data.unita_utilizzate === 0)
        e.rowElement.style.cssText = `${e.rowElement.style.cssText}color: black;background-color: ${GridCellColor.YELLOW}`
    }
  }
  const onExporting = (e: ExportingEvent) => {
    ExportDataGridToExcel(e.component)
  }

  const onDeleteClick = (e: ClickEvent) => {
    const rowkey = gridRef.current?.instance().option('focusedRowKey')
    console.log('id', rowkey)
    gridRef.current
      ?.instance()
      .byKey(rowkey)
      .then((rowdata: pacchetto_utilizzo) => {
        const result = confirm(
          `<i>Vuoi davvero eliminare il pacchetto <b>${rowdata.ded_Dis}</b>?</i>`,
          'Eliminazione pacchetto',
        )
        result.then((dialogResult) => {
          if (dialogResult === false) return
          service
            .pacchetto_utilizzo(rowdata.id)
            .delete()
            .then(() => {
              // Aggiornamento griglia
              gridRef.current?.instance().refresh()

              notify(
                {
                  message: `Pacchetto '${rowdata['ded_Dis']}'  eliminato con successo`,
                  type: 'success',
                  displayTime: 5000,
                },
                {
                  position: 'bottom center',
                  direction: 'up-push',
                },
              )
            })
            .catch((error: unknown) => {
              notify(
                {
                  message: `Errore eliminazione pacchetto: ${error}`,
                  type: 'error',
                  displayTime: 5000,
                },
                {
                  position: 'bottom center',
                  direction: 'up-push',
                },
              )
            })
        })
      })
  }

  const ExportDataGridToExcel = (component: dxDataGrid) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Pacchetti utilizzo')
    exportDataGrid({ component, worksheet }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'PacchettiUtilizzo.xlsx')
      })
    })
  }

  const handleInterventionImportClick = (e: ClickEvent) => {
    gridRef?.current?.instance().beginCustomLoading('Importazione interventi in corso....')
    client
      .post('/api/pacchettoutilizzo/interventionimport')
      .then((response: AxiosResponse) => {
        notify('Importazione degli interventi effettuata con successo', 'success', 2000)
      })
      .catch((error: AxiosError) => {
        notify(`Errore importazione interventi. Dettagli : ${error}`, 'error', 2000)
      })
      .finally(() => {
        gridRef?.current?.instance().endCustomLoading()
      })
  }

  useEffect(() => {
    getCurrentFilter()
  }, [])

  return (
    <>
      <DocumentHead title={title}></DocumentHead>
      {!clientId && (
        <div className="accordion-generic-filter-form">
          <Accordion
            className={filtersApplied ? 'with-filter' : ''}
            collapsible={true}
            onContentReady={(e: ContentReadyEvent<any, any>) => {
              e.component?.collapseItem(0)
            }}
          >
            <AccordionItem icon="filter" title={'Filtri'}>
              <GenericFilterForm
                FormItemDescriptors={filterConfiguration}
                resetFilterClicked={function (e: ClickEvent): void {
                  setFilterConfiguration(getFiltersConfiguration())
                }}
                filterValueChanged={function (): void {
                  const currentFlts = getCurrentFilter()
                  console.log('GENFLT - VALUE CHNG', currentFlts)
                  if (JSON.stringify(currentFlts) !== JSON.stringify(currentFilter)) {
                    console.log('GENFLT - SETTING FILT', currentFlts)
                    setCurrentFilter(currentFlts)
                  }
                }}
              />
            </AccordionItem>
          </Accordion>
        </div>
      )}
      <DataGrid
        id={clientId ? 'client-packages-usage-datagrid' : 'packages-usage-datagrid'}
        height={getGridHeight}
        dataSource={packagesUsageDataSource}
        className={'grid dx-card wide-card'}
        showBorders={false}
        showColumnLines={true}
        wordWrapEnabled={false}
        showRowLines={true}
        focusedRowEnabled={true}
        rowAlternationEnabled={false}
        allowColumnResizing={currentScreenSize.isLarge || currentScreenSize.isMedium}
        allowColumnReordering={currentScreenSize.isLarge || currentScreenSize.isMedium}
        width="100%"
        onExporting={onExporting}
        onRowPrepared={onRowPrepared}
        ref={gridRef}
        columnHidingEnabled={currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium}
        noDataText="Nessun pacchetto trovato"
        remoteOperations={{
          filtering: true,
          grouping: false,
          groupPaging: false,
          paging: true,
          sorting: true,
          summary: true,
        }}
        grouping={{
          // Fondamentale per il raggruppamento veloce!!!
          autoExpandAll: true,
        }}
        groupPanel={{
          visible: currentScreenSize.isLarge || currentScreenSize.isMedium,
          emptyPanelText: 'Trascina qui una colonna per raggruppare',
        }}
        onRowDblClick={(e: RowDblClickEvent<any, any>) => {
          if (e.rowType === 'data') navigate(`${e.data.id}`)
        }}
      >
        <Pager
          visible={true}
          allowedPageSizes={'auto'}
          displayMode={'compact'}
          showPageSizeSelector={false}
          showInfo={true}
          showNavigationButtons={false}
          infoText={gridTotalItemsLabel}
        />
        <FilterRow visible={true} />
        <Sorting mode="multiple"></Sorting>
        <SearchPanel visible={true} width={currentScreenSize.isXSmall ? 200 : 250} />
        <Export enabled={true} />
        <FilterPanel visible={true} />
        <Editing allowAdding={true} />
        <HeaderFilter visible={true} />
        <Selection mode="single" />
        <ColumnChooser enabled={true} />
        <Scrolling mode="virtual" />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey={clientId ? 'client-packages-usage-datagrid' : 'packages-usage-datagrid'}
          savingTimeout={50}
        />
        <Column
          visible={currentScreenSize.isXSmall || currentScreenSize.isSmall}
          type="buttons"
          width={currentScreenSize.isXSmall || currentScreenSize.isSmall ? '10%' : '2%'}
          alignment="left"
          showInColumnChooser={false}
        >
          <GridButton
            hint="Details"
            icon="search"
            onClick={(e: ColumnButtonClickEvent) => {
              navigate(`${e.row?.data.id}`)
            }}
          ></GridButton>
        </Column>
        <Column
          dataField={'id'}
          width={'5%'}
          caption="ID"
          visible={false}
          dataType="number"
          defaultSortOrder="desc"
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 1 : undefined
          }
        >
          {!clientId && (
            <HeaderFilter dataSource={columnSourceFactory(token, 'pacchetto_utilizzo', 'id', true)}></HeaderFilter>
          )}
        </Column>
        <Column
          dataField={'ded_Dis'}
          cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
            const data: pacchetto_utilizzo = cellData.row.data
            return isUserRoleAllowed(userInfo?.roles, [Roles.Guest]) ? (
              <>
                <div
                  className={
                    !data.pacchetto_vendita || (data.pacchetto_vendita && data.unita_utilizzate === 0)
                      ? `customLink`
                      : ''
                  }
                >
                  <Link to={`/packages/usage/${data.id}`}>{data.ded_Dis}</Link>
                </div>
              </>
            ) : (
              <>{data.ded_Dis}</>
            )
          }}
          caption={'CODICE'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 11 : undefined
          }
        >
          {!clientId && (
            <HeaderFilter dataSource={columnSourceFactory(token, 'pacchetto_utilizzo', 'ded_Dis', true)}>
              <Search enabled={true} searchExpr={'ded_Dis'} />
            </HeaderFilter>
          )}
        </Column>
        <Column
          dataField={'intervento.sede.azienda.nome'}
          cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
            const data: pacchetto_utilizzo = cellData.row.data
            return isUserRoleAllowed(userInfo?.roles, [Roles.Guest]) ? (
              <>
                <div
                  className={
                    !data.pacchetto_vendita || (data.pacchetto_vendita && data.unita_utilizzate === 0)
                      ? `customLink`
                      : ''
                  }
                >
                  <Link to={`/clients/${data.intervento?.sede?.azienda?.id}`}>
                    {data.intervento?.sede?.azienda?.nome}
                  </Link>
                </div>
              </>
            ) : (
              <>{data.intervento?.sede?.azienda?.nome}</>
            )
          }}
          caption={'AZIENDA'}
          visible={clientId === undefined}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 12 : undefined
          }
        >
          {!clientId && (
            <HeaderFilter dataSource={columnSourceFactory(token, 'azienda', 'nome')}>
              <Search enabled={true} searchExpr={'nome'} />
            </HeaderFilter>
          )}
        </Column>
        <Column
          dataField={'intervento.sede.nome'}
          caption={'SEDE'}
          allowHeaderFiltering={false}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 10 : undefined
          }
        />
        <Column
          dataField={'intervento.task.ded_Dis'}
          cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
            const data: pacchetto_utilizzo = cellData.row.data
            return isUserRoleAllowed(userInfo?.roles, [Roles.Guest]) ? (
              <>
                <div
                  className={
                    !data.pacchetto_vendita || (data.pacchetto_vendita && data.unita_utilizzate === 0)
                      ? `customLink`
                      : ''
                  }
                >
                  <Link to={`/tasks/${data.intervento?.task?.id}`}>{data.intervento?.task?.ded_Dis}</Link>
                </div>
              </>
            ) : (
              <>{data.intervento?.task?.ded_Dis}</>
            )
          }}
          caption={'TASK'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 9 : undefined
          }
        >
          {!clientId && (
            <HeaderFilter dataSource={columnSourceFactory(token, 'task', 'ded_Dis', true)}>
              <Search enabled={true} searchExpr={'ded_Dis'} />
            </HeaderFilter>
          )}
        </Column>
        <Column
          dataField={'pacchetto_vendita.ded_Dis'}
          cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
            const data: pacchetto_utilizzo = cellData.row.data
            return isUserRoleAllowed(userInfo?.roles, [Roles.Guest]) ? (
              <>
                <div
                  className={
                    !data.pacchetto_vendita || (data.pacchetto_vendita && data.unita_utilizzate === 0)
                      ? `customLink`
                      : ''
                  }
                >
                  <Link to={`/packages/sale/${data.pacchetto_vendita?.id}`}>{data.pacchetto_vendita?.ded_Dis}</Link>
                </div>
              </>
            ) : (
              <>{data.pacchetto_vendita?.ded_Dis}</>
            )
          }}
          caption={'PACCHETTO VENDUTO'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 7 : undefined
          }
        >
          {!clientId && (
            <HeaderFilter dataSource={columnSourceFactory(token, 'pacchetto_vendita', 'ded_Dis', true)}>
              <Search enabled={true} searchExpr={'ded_Dis'} />
            </HeaderFilter>
          )}
        </Column>
        <Column
          dataField={'intervento.ded_Dis'}
          cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
            const data: pacchetto_utilizzo = cellData.row.data
            return isUserRoleAllowed(userInfo?.roles, [Roles.Guest]) ? (
              <>
                <div
                  className={
                    !data.pacchetto_vendita || (data.pacchetto_vendita && data.unita_utilizzate === 0)
                      ? `customLink`
                      : ''
                  }
                >
                  <Link to={`/interventions/${data.intervento?.id}`}>{data.intervento?.ded_Dis}</Link>
                </div>
              </>
            ) : (
              <>{data.intervento?.ded_Dis}</>
            )
          }}
          caption={'INTERVENTO'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 8 : undefined
          }
        >
          {!clientId && (
            <HeaderFilter dataSource={columnSourceFactory(token, 'intervento', 'ded_Dis')}>
              <Search enabled={true} searchExpr={'ded_Dis'} />
            </HeaderFilter>
          )}
        </Column>
        <Column
          dataField={'pacchetto_vendita.tipologia_unita.nome'}
          caption={'TIPOLOGIA UNITA'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 6 : undefined
          }
        >
          {!clientId && (
            <HeaderFilter dataSource={columnSourceFactory(token, 'tipologia_durata_pacchetto', 'nome')}>
              <Search enabled={true} searchExpr={'nome'} />
            </HeaderFilter>
          )}
        </Column>
        <Column
          dataField={'unita_utilizzate'}
          caption={'UNITA'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 5 : undefined
          }
        >
          {!clientId && (
            <HeaderFilter
              dataSource={columnSourceFactory(token, 'pacchetto_utilizzo', 'unita_utilizzate', true)}
            ></HeaderFilter>
          )}
        </Column>
        <Column
          dataField={'note'}
          caption={'NOTE'}
          allowHeaderFiltering={false}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 4 : undefined
          }
        />
        {DateColumn({
          dataField: 'intervento.data',
          caption: 'DATA INTERVENTO',
          format: 'dd/MM/yyyy',
          hidingPriority:
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 3 : undefined,
        })}
        <Toolbar>
          <Item
            location="before"
            locateInMenu="never"
            showText="inMenu"
            widget="dxButton"
            options={{
              hint: 'Refresh',
              text: 'Refresh',
              icon: 'refresh',
              onClick: (e: ClickEvent) => {
                gridRef.current?.instance().refresh()
              },
              stylingMode: 'text',
            }}
          ></Item>
          <Item name="groupPanel" />
          <Item
            visible={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
            locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
            showText="inMenu"
            name="addRowButton"
            options={{
              onClick: () => {
                navigate(`new`)
              },
              hint: 'Nuovo pacchetto',
              text: 'Nuovo pacchetto',
            }}
          />
          <Item
            visible={isUserRoleAllowed(userInfo?.roles, [Roles.GlobalAdministrator, Roles.Administrator])}
            locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
            showText="inMenu"
            widget="dxButton"
            options={{
              hint: 'Importa Interventi',
              text: 'Importa Interventi',
              icon: 'upload',
              onClick: handleInterventionImportClick,
              stylingMode: 'text',
            }}
          ></Item>
          <Item
            visible={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
            locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
            showText="inMenu"
            widget="dxButton"
            options={{
              hint: 'Elimina pacchetto',
              text: 'Elimina pacchetto',
              icon: 'trash',
              onClick: onDeleteClick,
              stylingMode: 'text',
            }}
          ></Item>
          <Item
            name="columnChooserButton"
            locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
            showText="inMenu"
            options={{ hint: 'Scelta colonne', text: 'Scelta colonne' }}
          />
          <Item
            name="exportButton"
            locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
            showText="inMenu"
            options={{ hint: 'Esporta dati in excel', text: 'Esporta dati in excel' }}
          />
          <Item name="searchPanel" />
        </Toolbar>
      </DataGrid>
    </>
  )
}

export default PackagesUsageGrid
