import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import OrderEditorForm from '@/routes/orders/OrderEditor.form'
import { orderEditorRouteLoader } from '@/routes/orders/OrderEditor.route'
import { OrderEditorProps } from '@/routes/orders/OrderEditor.types'
import { Suspense, useRef } from 'react'
import { useFetcher, useParams } from 'react-router-dom'
import { Await, useLoaderData } from 'react-router-typesafe'
import { Form as DXForm, Tab, TabbedItem } from 'devextreme-react/form'
import AttachmentsManager from '@/components/file-manager/AttachmentsManager'
import DocumentUploader from '@/components/document/DocumentUploader'
import OrderEditorTasksGrid from '@/routes/orders/OrderEditor.tasks'
import { FileManagerRef } from 'devextreme-react/file-manager'
import { isUserRoleAllowed } from '@/routes/utils'
import { Roles } from '@/auth/azure/Roles'
import { DocumentPermissions } from '@/enums'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import './OrderEditor.scss'
import DocumentHead from '@/components/document-head/DocumentHead'
import OrderEditorInvoicingStepsGrid from '@/routes/orders/OrderEditor.invoicingsteps'

const OrderEditor = (props: OrderEditorProps) => {
  const { creating, isReseller } = props
  const { title, order, getStati, getAziende, getOfferte, getCommesse, userInfo, defaultCRUDAllowedRoles } =
    useLoaderData<typeof orderEditorRouteLoader>()

  const { clientId } = useParams()
  const { client } = useQsAdminApiManager()

  const fetcher = useFetcher()
  const documentoGetRef = useRef<any>(null)
  const fileManagerRef = useRef<FileManagerRef>(null)

  return (
    <>
      <DocumentHead title={title}></DocumentHead>
      <h2 className={clientId ? '' : 'content-block'}>{title}</h2>
      <Suspense fallback={<LoadingPanel />}>
        <Await resolve={Promise.all([getStati, getAziende, getOfferte, getCommesse])}>
          {([stati, aziende, offerte, commesse]) => (
            <>
              <div
                id="editor-order-container"
                className={clientId ? 'dx-card responsive-paddings' : 'content-block dx-card responsive-paddings'}
              >
                <fetcher.Form>
                  <OrderEditorForm
                    creating={creating}
                    isReseller={isReseller}
                    order={order}
                    stati={stati.data.value}
                    aziende={aziende.data.value}
                    offerte={offerte?.data.value}
                    commesse={commesse?.data.value}
                    isRoleAllowed={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
                    userInfo={userInfo}
                    fetcher={fetcher}
                  />
                </fetcher.Form>
                {!creating && (
                  <div id="info-order-container">
                    <DXForm id="form-info">
                      <TabbedItem>
                        <Tab title="Punti di fatturazione">
                          <OrderEditorInvoicingStepsGrid
                            order={order}
                            creating={creating}
                            userInfo={userInfo}
                            readOnly={
                              !isUserRoleAllowed(userInfo?.roles, [
                                Roles.GlobalAdministrator,
                                Roles.Administrator,
                                Roles.Supervisor,
                              ])
                            }
                            showTitle={false}
                          ></OrderEditorInvoicingStepsGrid>
                        </Tab>
                        <Tab title="Task">
                          <OrderEditorTasksGrid
                            order={order}
                            creating={creating}
                            userInfo={userInfo}
                            readOnly={
                              !isUserRoleAllowed(userInfo?.roles, [
                                Roles.GlobalAdministrator,
                                Roles.Administrator,
                                Roles.Supervisor,
                              ])
                            }
                            showTitle={false}
                          ></OrderEditorTasksGrid>
                        </Tab>
                      </TabbedItem>
                    </DXForm>
                  </div>
                )}
              </div>
              {!creating && (
                <div
                  id="allegati-order-container"
                  className={clientId ? 'dx-card responsive-paddings' : 'content-block dx-card responsive-paddings'}
                >
                  <DXForm id="form-allegati">
                    <TabbedItem>
                      <Tab title="Documento">
                        <DocumentUploader
                          paramName={'orderfile'}
                          allowedExtensions={['.pdf']}
                          fileUrl={order.mainDocumentUrl}
                          uploadUrl={`${import.meta.env.VITE_QSADMINAPI_HOST}/api/ordine/upload?orderId=${order.id}`}
                          documentGetRef={documentoGetRef}
                          preliminarPermissionsAction={async () => {
                            try {
                              const response = await client.post(
                                '/api/ordine/drivepermissions',
                                {
                                  objectId: order.id,
                                  path: order.mainDocumentUrl,
                                },
                                {
                                  headers: {
                                    'Content-Type': 'application/json',
                                  },
                                },
                              )
                              return response.status === 200
                                ? (response.data as DocumentPermissions)
                                : DocumentPermissions.Deny
                            } catch (error) {
                              console.log('Errore in settaggio permessi sul documento ordine', error)
                              return DocumentPermissions.Deny
                            }
                          }}
                        />
                      </Tab>
                      <Tab title="Allegati">
                        <AttachmentsManager
                          fileManagerRef={fileManagerRef}
                          folderUrl={order.attachmentsFolderUrl}
                          preliminarPermissionsAction={async () => {
                            try {
                              const response = await client.post(
                                '/api/ordine/drivepermissions',
                                {
                                  objectId: order.id,
                                  path: order.attachmentsFolderUrl,
                                },
                                {
                                  headers: {
                                    'Content-Type': 'application/json',
                                  },
                                },
                              )
                              return response.status === 200
                                ? (response.data as DocumentPermissions)
                                : DocumentPermissions.Deny
                            } catch (error) {
                              console.log('Errore in settaggio permessi sulla cartella allegati ordine', error)
                              return DocumentPermissions.Deny
                            }
                          }}
                          createDirectoryAction={async (
                            rootFolderPath: string,
                            name: string,
                            conflictBehavior: string,
                          ) => {
                            return await client.post(
                              '/api/ordine/createfolder',
                              {
                                rootFolder: rootFolderPath,
                                newFolderName: name,
                                conflictBehavior,
                                objectId: order.id,
                              },
                              {
                                headers: {
                                  'Content-Type': 'application/json',
                                },
                              },
                            )
                          }}
                        />
                      </Tab>
                    </TabbedItem>
                  </DXForm>
                </div>
              )}
            </>
          )}
        </Await>
      </Suspense>
    </>
  )
}

export default OrderEditor
