import ODataStore from 'devextreme/data/odata/store'
import DataSource from 'devextreme/data/data_source'
import { memo, useRef, useState } from 'react'
import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import type {
  qs_applicazione,
  qs_licenza,
  qs_versione,
  tipologia_qs_licenza,
} from '@/model/qsadminapi/QsAdminApiModuleModel'
import { FormMode } from '@/enums'
import {
  Column,
  DataGrid,
  DataGridRef,
  Editing,
  Item,
  Lookup,
  Paging,
  RequiredRule,
  StateStoring,
  Toolbar,
} from 'devextreme-react/data-grid'
import type { InitNewRowEvent, SavingEvent } from 'devextreme/ui/data_grid'
import useTokenRefresh from '@/auth/azure/azureManager'
import notify from 'devextreme/ui/notify'
import { useScreenSize } from '@/themes/media-query'
import { ClickEvent } from 'devextreme/ui/button'

type SoftwareQsEditorFormAppsProps = {
  license: qs_licenza
  mode: FormMode
  qsVersions: qs_versione[]
  qsLicenseTypes: tipologia_qs_licenza[]
  qsApps: qs_applicazione[]
  readOnly?: boolean
}

export const SoftwareQsEditorFormApps = (props: SoftwareQsEditorFormAppsProps) => {
  const [currentQsLicenseData] = useState<qs_licenza>(props.license)
  const today = new Date().toISOString()
  const token = useTokenRefresh()
  const currentScreenSize = useScreenSize()
  const qsLicenseApplicationsDataSource = new DataSource({
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/qs_licenza_applicazione`,
      key: 'id',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    filter: [['licenza.id', currentQsLicenseData?.id]],
    expand: ['applicazione', 'tipologia_licenza', 'ultima_versione', 'licenza'],
  })
  const gridRef = useRef<DataGridRef>(null)

  return (
    <DataGrid
      ref={gridRef}
      id={`sw-qsapps-grid-${currentQsLicenseData?.id}`}
      dataSource={qsLicenseApplicationsDataSource}
      keyExpr={'id'}
      showBorders={true}
      showColumnLines={true}
      focusedRowEnabled={true}
      columnHidingEnabled={currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium}
      allowColumnReordering={true}
      allowColumnResizing={true}
      rowAlternationEnabled={true}
      wordWrapEnabled={false}
      repaintChangesOnly={true}
      width="100%"
      onSaving={(e: SavingEvent) => {
        console.log('SAVINGGG', e)
      }}
      onInitNewRow={(e: InitNewRowEvent) => {
        e.data.aggiornamenti = false
        e.data.passaggio_noleggio = false
        e.data.data_associazione = today
        e.data.licenza = currentQsLicenseData
      }}
    >
      <StateStoring enabled={true} type="localStorage" storageKey={'swqs-apps-datagrid'} savingTimeout={50} />
      <Paging defaultPageSize={8} />
      <Editing
        mode="batch"
        allowUpdating={props.mode !== FormMode.View && !props.readOnly}
        allowAdding={props.mode !== FormMode.View && !props.readOnly}
        allowDeleting={props.mode !== FormMode.View && !props.readOnly}
        selectTextOnEditStart={true}
        startEditAction={'click'}
        onChangesChange={(value: any) => {
          // Triggerato quando avviene una modifica. Se l'array length è vuoto significa che la modifica è stata annullata o salvata....
          //console.log('CHHHHHH', value)
          //setAppsAreDirty(value.length > 0)
        }}
      />
      <Column dataField={'id'} width={'3%'} dataType={'number'} visible={false} />,
      <Column
        dataField={'data_associazione'}
        caption={'DATA'}
        width={'12%'}
        dataType={'date'}
        format={'dd/MM/yyyy'}
        hidingPriority={
          currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 1 : undefined
        }
      />
      <Column
        dataField={'applicazione.id'}
        caption={'APPLICAZIONE'}
        hidingPriority={
          currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 7 : undefined
        }
      >
        <RequiredRule message="Applicazione obbligatoria"></RequiredRule>
        <Lookup dataSource={props.qsApps} displayExpr="nome" valueExpr="id" />
      </Column>
      <Column
        dataField={'tipologia_licenza.id'}
        caption={'TIPO'}
        hidingPriority={
          currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 5 : undefined
        }
      >
        <RequiredRule message="Tipologia obbligatoria"></RequiredRule>
        <Lookup dataSource={props.qsLicenseTypes} displayExpr="nome" valueExpr="id" />
      </Column>
      <Column
        dataField={'ultima_versione.id'}
        caption={'UV'}
        width={'5%'}
        hidingPriority={
          currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 6 : undefined
        }
      >
        <Lookup dataSource={props.qsVersions} displayExpr="major" valueExpr="id" />
      </Column>
      <Column
        dataField={'scadenza'}
        caption={'SCADENZA'}
        width={'12%'}
        dataType={'date'}
        format={'dd/MM/yyyy'}
        hidingPriority={
          currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 4 : undefined
        }
      />
      <Column
        dataField={'passaggio_noleggio'}
        caption={'SWITCH'}
        width={'10%'}
        hidingPriority={
          currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 3 : undefined
        }
      />
      <Column
        dataField={'aggiornamenti'}
        caption={'US'}
        width={'5%'}
        hidingPriority={
          currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 2 : undefined
        }
      />
      <Toolbar>
        <Item
          location="before"
          locateInMenu="never"
          showText="inMenu"
          widget="dxButton"
          options={{
            hint: 'Refresh',
            text: 'Refresh',
            icon: 'refresh',
            onClick: (e: ClickEvent) => {
              gridRef.current?.instance().refresh()
            },
            stylingMode: 'text',
          }}
        ></Item>
        <Item name="addRowButton"></Item>
        <Item name="saveButton"></Item>
        <Item name="revertButton"></Item>
      </Toolbar>
    </DataGrid>
  )
}

const SoftwareQsAppsGridMemoed = memo(SoftwareQsEditorFormApps, (oldProps, newProps) => {
  return oldProps.license.id === newProps?.license.id
})

export default SoftwareQsAppsGridMemoed
