import DocumentHead from '@/components/document-head/DocumentHead'
import { clientGroupsPageRouteLoader } from '@/routes/clients/client-groups/ClientGroupsPage.route'
import { useLoaderData } from 'react-router-typesafe'
import {
  Column,
  DataGrid,
  Editing,
  Paging,
  RequiredRule,
  Button as GridButton,
  StateStoring,
  DataGridRef,
  Toolbar,
  MasterDetail,
  Item,
} from 'devextreme-react/data-grid'
import { useCallback, useRef, useState } from 'react'
import { useScreenSize } from '@/themes/media-query'
import notify from 'devextreme/ui/notify'
import ODataStore from 'devextreme/data/odata/store'
import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import useTokenRefresh from '@/auth/azure/azureManager'
import { ClickEvent } from 'devextreme/ui/button'
import DateColumn from '@/components/date-column/DateColumn'

const ClientGroupsPage = () => {
  const { title, defaultCRUDAllowedRoles } = useLoaderData<typeof clientGroupsPageRouteLoader>()
  const currentScreenSize = useScreenSize()
  const getGridHeight = useCallback(() => {
    let h = '79vh'
    if (currentScreenSize.isMedium) {
      h = '75vh'
    } else if (currentScreenSize.isSmall) {
      h = '120vh'
    } else if (currentScreenSize.isXSmall) {
      h = '75vh'
    }
    return h
  }, [currentScreenSize])
  const dataGridRef = useRef<DataGridRef>(null)
  const [currentFilter, setCurrentFilter] = useState<any[]>([])
  const token = useTokenRefresh()
  const clientGroupsDataSource = {
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/gruppo_aziendale`,
      key: 'id',
      keyType: 'Int32',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    expand: ['capogruppo', 'aziende($expand=agente($expand=commerciale_qs), tipologia_rapporto)'],
    filter: currentFilter?.length > 0 ? currentFilter : null,
    requireTotalCount: true,
  }

  return (
    <>
      <DocumentHead title={title}></DocumentHead>
      <h2 className={'content-block'}>{title}</h2>
      <div className={'content-block'}>
        <DataGrid
          ref={dataGridRef}
          id={'company-groups-grid'}
          dataSource={clientGroupsDataSource}
          noDataText="Nessun gruppo aziendale trovato"
          keyExpr={'id'}
          showBorders={true}
          showColumnLines={true}
          focusedRowEnabled={true}
          columnHidingEnabled={currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium}
          allowColumnReordering={true}
          allowColumnResizing={true}
          rowAlternationEnabled={true}
          wordWrapEnabled={false}
          repaintChangesOnly={true}
          width="100%"
        >
          <StateStoring enabled={true} type="localStorage" storageKey={'company-groups-grid'} savingTimeout={50} />
          <Paging defaultPageSize={8} />
          <Editing
            mode="batch"
            allowAdding={true}
            allowDeleting={true}
            allowUpdating={true}
            selectTextOnEditStart={true}
            startEditAction={'click'}
          />
          <MasterDetail
            enabled={true}
            component={({ data }: { data: any }) => {
              return (
                <>
                  <div className={'dx-card responsive-paddings'}>
                    <DataGrid
                      id={`companies-client-group`}
                      dataSource={data?.data.aziende}
                      noDataText="Nessun' azienda associata al gruppo aziendale"
                      keyExpr={'id'}
                      showBorders={true}
                      showColumnLines={true}
                      focusedRowEnabled={true}
                      defaultFocusedRowIndex={0}
                      columnHidingEnabled={
                        currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium
                      }
                      allowColumnReordering={true}
                      allowColumnResizing={true}
                      rowAlternationEnabled={true}
                      wordWrapEnabled={false}
                      repaintChangesOnly={true}
                      width="100%"
                    >
                      <StateStoring
                        enabled={true}
                        type="localStorage"
                        storageKey={`companies-client-group`}
                        savingTimeout={50}
                      />
                      <Paging defaultPageSize={8} />
                      {DateColumn({
                        dataField: 'rapp_inizio',
                        width: currentScreenSize.isSmall ? '15%' : '6%',
                        caption: 'DATA',
                        format: 'dd/MM/yyyy',
                        hidingPriority: currentScreenSize.isSmall || currentScreenSize.isXSmall ? 3 : undefined,
                      })}
                      <Column
                        dataField={'nome'}
                        caption={'NOME'}
                        width={currentScreenSize.isLarge ? '50%' : '20%'}
                        defaultSortOrder="asc"
                        hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 4 : undefined}
                      ></Column>
                      <Column
                        dataField={'p_iva'}
                        width={'7%'}
                        caption={'P.IVA'}
                        hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 2 : undefined}
                      ></Column>
                      <Column
                        dataField={'agente.commerciale_qs.fullname'}
                        width={'8%'}
                        caption={'AGENTE'}
                        hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 1 : undefined}
                      ></Column>
                    </DataGrid>
                  </div>
                </>
              )
            }}
          />
          <Column dataField={'id'} caption="ID" visible={false} dataType="number"></Column>
          <Column dataField={'nome'} caption={'NOME'}>
            <RequiredRule message="Nome gruppo aziendale obbligatorio"></RequiredRule>
          </Column>
          <Column dataField={'capogruppo.nome'} caption={'CAPOGRUPPO'} editorOptions={{ readOnly: true }}></Column>
          <Column dataField={'note'} caption={'NOTE'} />
          <Column type="buttons" width={'4%'} alignment="left">
            <GridButton name="delete"></GridButton>
          </Column>
          <Toolbar>
            <Item
              location="before"
              locateInMenu="never"
              showText="inMenu"
              widget="dxButton"
              options={{
                hint: 'Refresh',
                text: 'Refresh',
                icon: 'refresh',
                onClick: (e: ClickEvent) => {
                  dataGridRef.current?.instance().refresh()
                },
                stylingMode: 'text',
              }}
            ></Item>
            <Item name="addRowButton"></Item>
            <Item name="saveButton"></Item>
            <Item name="revertButton"></Item>
          </Toolbar>
        </DataGrid>
      </div>
    </>
  )
}

export default ClientGroupsPage
