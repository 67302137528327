import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { TreeView, type TreeViewRef, TreeViewTypes } from 'devextreme-react/tree-view'
import { useScreenSize } from '@/themes/media-query'
import './SideNavigationMenu.scss'
import { off, on } from 'devextreme/events'
import { NavLink, useLocation, RouteObject, Link } from 'react-router-dom'
import {
  allAuthenticatedChildrenRoutes,
  AuthenticatedLayoutRoute,
} from '@/routes/authenticated/AuthenticatedLayout.route'
import { RoleRoute } from '@/types'
import { prop } from '@mdxeditor/editor'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import { Role } from '@/auth/azure/Roles'

interface SideNavigationMenuProps {
  selectedItemChanged: () => void
  openMenu: (e: React.PointerEvent) => void
  compactMode: boolean
  onMenuReady: (e: TreeViewTypes.ContentReadyEvent) => void
}

const getTreeSource = async (isLarge: boolean, authenticatedRoutes: RoleRoute[]) => {
  const allowedRoutes = await filterTabsByRoles(allAuthenticatedChildrenRoutes)
  return allowedRoutes.map((item) => ({
    ...item,
    expanded: isLarge,
    selected: false,
    path: item.path && !/^\//.test(item.path) ? `/${item.path}` : item.path,
  }))
}

const filterTabsByRoles = async (routes: RoleRoute[]): Promise<RoleRoute[]> => {
  const userInfo = await getAzureUserInformation()
  return routes.filter((route) => {
    return route.mainMenuRoute && route.allowedRoles?.some((role: Role) => userInfo?.roles?.hasRole(role))
  })
}

export default function SideNavigationMenu(props: React.PropsWithChildren<SideNavigationMenuProps>) {
  const { children, selectedItemChanged, openMenu, compactMode, onMenuReady } = props
  const { isLarge } = useScreenSize()
  const currentPath = useLocation()
  const treeViewRef = useRef<TreeViewRef>(null)
  const wrapperRef = useRef<HTMLDivElement>()
  const [treeSource, setTreeSource] = useState<RoleRoute[]>()

  useEffect(() => {
    const filterRoutes = async () => {
      const filteredRoutes = await getTreeSource(isLarge, allAuthenticatedChildrenRoutes)
      for (const v of filteredRoutes) {
        delete v.children
      }
      setTreeSource(filteredRoutes)
    }
    filterRoutes()
  }, [isLarge])

  const getWrapperRef = useCallback(
    (element: HTMLDivElement) => {
      const prevElement = wrapperRef.current
      if (prevElement) {
        off(prevElement, 'dxclick')
      }

      wrapperRef.current = element
      on(element, 'dxclick', (e: React.PointerEvent) => {
        openMenu(e)
      })
    },
    [openMenu],
  )

  useEffect(() => {
    if (!treeViewRef.current) {
      return
    }

    if (currentPath.pathname !== undefined) {
      // Transforming current path (ie /software/qs/...) to root path (ie /software)
      const pathSections = currentPath.pathname.split('/')
      const pathroot = `/${pathSections[1]}`

      treeViewRef.current.instance().selectItem(pathroot)
      treeViewRef.current.instance().expandItem(pathroot)
    }

    if (compactMode) {
      treeViewRef.current.instance().collapseAll()
    }
  }, [currentPath, compactMode, treeSource])

  return (
    <div className={'dx-swatch-additional side-navigation-menu'} ref={getWrapperRef}>
      {children}
      <div className={'menu-container'}>
        <TreeView
          ref={treeViewRef}
          items={treeSource}
          keyExpr={'path'}
          selectionMode={'single'}
          focusStateEnabled={false}
          selectedExpr={'selected'}
          onContentReady={onMenuReady}
          width={'100%'}
          expandEvent={'click'}
          itemRender={(itemData: RoleRoute) => (
            <>
              <NavLink
                to={itemData.path ?? '/'}
                className="dx-item-content dx-treeview-item-content"
                onClick={(event) => {
                  // Manually invoke selectedItemChanged when NavLink is clicked
                  selectedItemChanged()
                }}
              >
                <div className="nav-link-wrapper">
                  <i className={`dx-icon dx-icon-${itemData.icon} menu-icon`} />
                  <span>{itemData.text}</span>
                </div>
              </NavLink>
            </>
          )}
        />
      </div>
    </div>
  )
}
