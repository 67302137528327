import { interventionsPageRouteLoader } from '@/routes/interventions/InterventionsPage.route'
import { useScreenSize } from '@/themes/media-query'
import { DataGrid } from 'devextreme-react'
import { Accordion, Item as AccordionItem } from 'devextreme-react/accordion'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useLoaderData } from 'react-router-typesafe'
import {
  Column,
  ColumnChooser,
  Editing,
  Export,
  FilterRow,
  HeaderFilter,
  Item,
  Scrolling,
  SearchPanel,
  Selection,
  StateStoring,
  Toolbar,
  DataGridRef,
  Sorting,
  Search,
  FilterPanel,
  DataGridTypes,
  Pager,
  Button as GridButton,
  MasterDetail,
} from 'devextreme-react/data-grid'
import { CellPreparedEvent, ColumnButtonClickEvent, ExportingEvent, RowDblClickEvent } from 'devextreme/ui/data_grid'
import ODataStore from 'devextreme/data/odata/store'
import notify from 'devextreme/ui/notify'
import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import { contatto_aziendale, intervento, user } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { EdmLiteral } from 'devextreme/data/odata/utils'
import { confirm } from 'devextreme/ui/dialog'
import { GridCellColor } from '@/enums'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import saveAs from 'file-saver'
import { ClickEvent } from 'devextreme/ui/button'
import { AxiosError, AxiosResponse } from 'axios'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { ContentReadyEvent } from 'devextreme/ui/accordion'
import {
  createDateFilterItemDescriptor,
  FormFilterItemDescriptorType,
} from '@/components/filter-form/GenericFilterForm.types'
import GenericFilterForm from '@/components/filter-form/GenericFilterForm'
import useTokenRefresh from '@/auth/azure/azureManager'
import DateColumn from '@/components/date-column/DateColumn'
import { columnSourceFactory, isUserRoleAllowed } from '@/routes/utils'
import { Roles } from '@/auth/azure/Roles'
import { gridTotalItemsLabel } from '@/constants'
import InterventionBriefDetail from '@/routes/interventions/InterventionBriefDetail'
import DocumentHead from '@/components/document-head/DocumentHead'

const headerFilterTechnicians: { text: string; value: string }[] = []
const computeTechniciansValue = (rowData: intervento) => {
  let techs = ''
  rowData.impiegati?.map((tech: user) => {
    techs = `${techs + (techs.length > 0 ? ' - ' : '')}${tech.fullname}`
    const filter: { text: string; value: string } = {
      text: tech.fullname,
      value: tech.fullname,
    }
    const headerFilter = headerFilterTechnicians.find((hf: { text: string; value: string }) => {
      return hf.text === filter.text
    })
    if (!headerFilter) headerFilterTechnicians.push(filter)
  })
  return techs.trim()
}

const calculateTechniciansFilterExpression = (
  filterValue: any,
  selectedFilterOperation: string | null,
  target: string,
): string | (() => any) | Array<any> => {
  if (target === 'filterBuilder' || target === 'filterRow') {
    if (selectedFilterOperation === '=' || selectedFilterOperation === '<>') {
      return [
        [new EdmLiteral(`impiegati/any(a: a/fullname eq '${filterValue}')`), '=', selectedFilterOperation === '='],
      ]
    } else if (
      selectedFilterOperation === 'contains' ||
      selectedFilterOperation === 'startswith' ||
      selectedFilterOperation === 'endswith'
    ) {
      return [[new EdmLiteral(`impiegati/any(a: ${selectedFilterOperation}(a/fullname, '${filterValue}'))`), '=', true]]
    } else {
      // caso notcontains
      return [[new EdmLiteral(`impiegati/any(a: contains(a/fullname, '${filterValue}'))`), '=', false]]
    }
  } else if (target === 'search' || target === 'headerFilter') {
    return [[new EdmLiteral(`impiegati/any(a: contains(a/fullname, '${filterValue}'))`), '=', true]]
  }

  return filterValue
}

const headerFilterRif: { text: string | null; value: string | null }[] = []

const computeCustRifValue = (rowData: intervento) => {
  let custRifs = ''
  rowData.riferimenti_cliente?.map((custRif: contatto_aziendale) => {
    custRifs = `${custRifs + (custRifs.length > 0 ? ' ' : '')}${custRif.fullname}`
    const filter: { text: string | null; value: string | null } = {
      text: custRif.fullname,
      value: custRif.fullname,
    }
    const headerFilter = headerFilterRif.find((hf: { text: string | null; value: string | null }) => {
      return hf.text === filter.text
    })
    if (!headerFilter) headerFilterRif.push(filter)
  })
  return custRifs.trim()
}

const cellRiferimentiRender = (cellData: DataGridTypes.ColumnCellTemplateData) => {
  return (
    <>
      {cellData.data.riferimenti_cliente?.map((contatto: contatto_aziendale, index: number) => {
        return (
          <div className={`dx-tag-content custom-tag-padding`} key={`ctc-${index}`}>
            <span>{contatto.fullname}</span>
          </div>
        )
      })}
    </>
  )
}

const calculateCustRifFilterExpression = (
  filterValue: any,
  selectedFilterOperation: string | null,
  target: string,
): string | (() => any) | Array<any> => {
  if (target === 'filterBuilder' || target === 'filterRow') {
    if (selectedFilterOperation === '=' || selectedFilterOperation === '<>') {
      return [
        [
          new EdmLiteral(`riferimenti_cliente/any(a: a/fullname eq '${filterValue}')`),
          '=',
          selectedFilterOperation === '=',
        ],
      ]
    } else if (
      selectedFilterOperation === 'contains' ||
      selectedFilterOperation === 'startswith' ||
      selectedFilterOperation === 'endswith'
    ) {
      return [
        [
          new EdmLiteral(`riferimenti_cliente/any(a: ${selectedFilterOperation}(a/fullname, '${filterValue}'))`),
          '=',
          true,
        ],
      ]
    } else {
      // caso notcontains
      return [[new EdmLiteral(`riferimenti_cliente/any(a: contains(a/fullname, '${filterValue}'))`), '=', false]]
    }
  } else if (target === 'search' || target === 'headerFilter') {
    return [[new EdmLiteral(`riferimenti_cliente/any(a: contains(a/fullname, '${filterValue}'))`), '=', true]]
  }

  return filterValue
}

const cellPrepared = (e: CellPreparedEvent) => {
  if (e.column.dataField === 'stato_fatturazione.nome' && e.rowType === 'data' && e.value) {
    if (e.value === 'FATTURATO') {
      e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.EMERALD}`
    } else if (e.value === 'NON FATTURABILE') {
      e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.ORANGE}`
    } else {
      e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.SALMON}`
    }
  }
}

const ExportDataGridToExcel = (component: any) => {
  const workbook = new Workbook()
  const worksheet = workbook.addWorksheet('Interventions')
  exportDataGrid({ component, worksheet }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Interventions.xlsx')
    })
  })
}

const onExporting = (e: ExportingEvent) => {
  ExportDataGridToExcel(e.component)
}

const InterventionsPage = () => {
  const { service, client } = useQsAdminApiManager()
  const { title, aziende, impiegati, userInfo, defaultCRUDAllowedRoles } =
    useLoaderData<typeof interventionsPageRouteLoader>()
  const token = useTokenRefresh()
  const { clientId } = useParams()
  const gridRef = useRef<DataGridRef>(null)
  const navigate = useNavigate()
  const currentScreenSize = useScreenSize()
  const now: Date = new Date()
  const minDate: Date = new Date(now.getFullYear() - 2, 0, 1)
  const maxDate = now
  const getFiltersConfiguration = useCallback((): FormFilterItemDescriptorType[] => {
    const fltConf: FormFilterItemDescriptorType[] = [
      {
        fieldName: 'azienda',
        placeHolder: 'Azienda',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        values: aziende.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['sede/azienda/id', this.currentValue]]
        },
      },
      {
        fieldName: 'tecnico',
        placeHolder: 'Tecnico',
        valueKeyName: 'id',
        valueDisplayExpr: 'fullname',
        values: impiegati.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [`impiegati/any(a: a/id eq ${this.currentValue})`]
        },
      },
      createDateFilterItemDescriptor('data', 'Data Intervento', 'Ultimo mese'),
    ]
    console.log('GENFLT - GETCONF :', fltConf)
    return fltConf
  }, [aziende, impiegati])

  const handleExportPDFClick = (e: ClickEvent) => {
    const rowkey = gridRef.current?.instance().option('focusedRowKey')
    gridRef?.current?.instance().beginCustomLoading('Esportazione rapporto di intervento in corso....')
    gridRef.current
      ?.instance()
      .byKey(rowkey)
      .then(async (rowdata: intervento) => {
        console.log('analisi', rowdata)
        client
          .get('/api/intervento/exportpdf', {
            params: { interventoId: Number(rowdata.id) },
            headers: {
              'Content-Disposition': `attachment;filename=${rowdata.ded_Dis}.pdf`,
              'Content-Type': 'application/octet-stream',
            },
            responseType: 'arraybuffer',
          })
          .then((response: AxiosResponse) => {
            console.log('response', response.data)
            saveAs(new Blob([response.data], { type: 'application/octet-stream' }), `${rowdata.ded_Dis}.pdf`)
          })
          .catch((error: AxiosError) => {
            notify(`Errore esportazione documento. Dettagli : ${error}`, 'error', 2000)
          })
          .finally(() => {
            gridRef?.current?.instance().endCustomLoading()
          })
      })
  }

  const onDeleteClick = (e: ClickEvent) => {
    const rowkey = gridRef.current?.instance().option('focusedRowKey')
    gridRef.current
      ?.instance()
      .byKey(rowkey)
      .then((rowdata: intervento) => {
        const result = confirm(
          `<i>Vuoi davvero eliminare l'intervento <b>${rowdata.ded_Dis}</b>?</i>`,
          'Eliminazione intervento',
        )
        result.then((dialogResult) => {
          if (dialogResult === false) return
          service
            .intervento(rowdata.id)
            .delete()
            .then(() => {
              // Aggiornamento griglia
              gridRef.current?.instance().refresh()

              notify(
                {
                  message: `Intervento '${rowdata['ded_Dis']}' del cliente ${
                    rowdata['sede']?.azienda?.nome ?? 'Non definito'
                  } eliminata con successo`,
                  type: 'success',
                  displayTime: 5000,
                },
                {
                  position: 'bottom center',
                  direction: 'up-push',
                },
              )
            })
            .catch((error: unknown) => {
              console.log('error', error)
              notify(
                {
                  message: `Errore eliminazione intervento: ${error}`,
                  type: 'error',
                  displayTime: 5000,
                },
                {
                  position: 'bottom center',
                  direction: 'up-push',
                },
              )
            })
        })
      })
  }

  const initialFilterConfiguration = useMemo(() => getFiltersConfiguration(), [getFiltersConfiguration])
  const [filterConfiguration, setFilterConfiguration] = useState(initialFilterConfiguration)
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false)
  const getCurrentFilter = useCallback((): any[] => {
    let applied: boolean = false
    const currentFlts = []
    for (const flt of filterConfiguration) {
      const fltValue = flt.composeFilterItem()
      if (fltValue !== null) {
        currentFlts.push(fltValue)
      }
      if (!applied && flt.currentValue !== flt.defaultValue) applied = true
    }
    setFiltersApplied(applied)
    console.log('GENFLT - CURRENT:', currentFlts)
    return currentFlts
  }, [filterConfiguration])

  const [currentFilter, setCurrentFilter] = useState<any[]>(clientId ? [['sede/azienda/id', Number(clientId)]] : [])

  const interventionsDataSource = {
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/intervento`,
      key: 'id',
      keyType: 'Int32',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    expand: [
      'sede($expand=azienda,pacchetti_vendita)',
      'task',
      'stato_fatturazione',
      'impiegati',
      'riferimenti_cliente',
    ],
    filter: currentFilter.length > 0 ? currentFilter : null,
    requireTotalCount: true,
  }

  const getGridHeight = useCallback(() => {
    let h = '79vh'
    if (currentScreenSize.isMedium) {
      h = '75vh'
    } else if (currentScreenSize.isSmall) {
      h = '120vh'
    } else if (currentScreenSize.isXSmall) {
      h = '75vh'
    }
    return h
  }, [currentScreenSize])

  useEffect(() => {
    getCurrentFilter()
  }, [])

  return (
    <>
      <DocumentHead title={title}></DocumentHead>
      {!clientId && <h2 className={'content-block'}>{title}</h2>}
      <div className={clientId ? '' : 'content-block'}>
        {!clientId && (
          <div className="accordion-generic-filter-form">
            <Accordion
              className={filtersApplied ? 'with-filter' : ''}
              collapsible={true}
              onContentReady={(e: ContentReadyEvent<any, any>) => {
                e.component?.collapseItem(0)
              }}
            >
              <AccordionItem icon="filter" title={'Filtri'}>
                <GenericFilterForm
                  FormItemDescriptors={filterConfiguration}
                  resetFilterClicked={function (e: ClickEvent): void {
                    setFilterConfiguration(getFiltersConfiguration())
                  }}
                  filterValueChanged={function (): void {
                    const currentFlts = getCurrentFilter()
                    console.log('GENFLT - VALUE CHNG', currentFlts)
                    if (JSON.stringify(currentFlts) !== JSON.stringify(currentFilter)) {
                      console.log('GENFLT - SETTING FILT', currentFlts)
                      setCurrentFilter(currentFlts)
                    }
                  }}
                />
              </AccordionItem>
            </Accordion>
          </div>
        )}
        <DataGrid
          id={clientId ? 'client-interventions-datagrid' : 'interventions-datagrid'}
          height={getGridHeight}
          dataSource={interventionsDataSource}
          noDataText="Nessun intervento trovato"
          className={'dx-card wide-card'}
          showBorders={false}
          showColumnLines={true}
          wordWrapEnabled={false}
          showRowLines={true}
          focusedRowEnabled={true}
          rowAlternationEnabled={true}
          allowColumnResizing={currentScreenSize.isLarge || currentScreenSize.isMedium}
          allowColumnReordering={currentScreenSize.isLarge || currentScreenSize.isMedium}
          width="100%"
          onExporting={onExporting}
          ref={gridRef}
          columnHidingEnabled={currentScreenSize.isSmall || currentScreenSize.isXSmall}
          onCellPrepared={cellPrepared}
          remoteOperations={{
            filtering: true,
            grouping: false,
            groupPaging: false,
            paging: true,
            sorting: true,
            summary: true,
          }}
          grouping={{
            // Fondamentale per il raggruppamento veloce!!!
            autoExpandAll: true,
          }}
          groupPanel={{
            visible: currentScreenSize.isLarge || currentScreenSize.isMedium,
            emptyPanelText: 'Trascina qui una colonna per raggruppare',
          }}
          onRowDblClick={(e: RowDblClickEvent<any, any>) => {
            if (e.rowType === 'data') navigate(`${e.data.id}`)
          }}
        >
          <Pager
            visible={true}
            allowedPageSizes={'auto'}
            displayMode={'compact'}
            showPageSizeSelector={false}
            showInfo={true}
            showNavigationButtons={false}
            infoText={gridTotalItemsLabel}
          />
          <FilterRow visible={true} />
          <SearchPanel visible={true} width={currentScreenSize.isXSmall ? 200 : 250} />
          <Export enabled={true} />
          <Editing allowAdding={true} />
          <FilterPanel visible={true} />
          <HeaderFilter visible={true} />
          <Selection mode="single" />
          <ColumnChooser enabled={true} title="Scelta colonne" />
          <Scrolling mode="virtual" />
          <Sorting mode="multiple"></Sorting>
          <StateStoring
            enabled={true}
            type="localStorage"
            storageKey={clientId ? 'client-interventions-datagrid' : 'interventions-datagrid'}
            savingTimeout={50}
          />
          <MasterDetail enabled={true} component={InterventionBriefDetail} />,
          <Column
            visible={currentScreenSize.isXSmall || currentScreenSize.isSmall}
            type="buttons"
            width={currentScreenSize.isXSmall || currentScreenSize.isSmall ? '10%' : '2%'}
            alignment="left"
            showInColumnChooser={false}
          >
            <GridButton
              hint="Details"
              icon="search"
              onClick={(e: ColumnButtonClickEvent) => {
                navigate(`${e.row?.data.id}`)
              }}
            ></GridButton>
          </Column>
          <Column
            dataField={'id'}
            width={'5%'}
            caption="ID"
            defaultSortOrder="desc"
            visible={false}
            dataType="number"
            hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 1 : undefined}
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'intervento', 'id', true)}></HeaderFilter>
            )}
          </Column>
          <Column
            dataField={'ded_Dis'}
            cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
              const data: intervento = cellData.row.data
              return isUserRoleAllowed(userInfo?.roles, [Roles.Guest]) ? (
                <>
                  <Link to={`/interventions/${data.id}`}>{data.ded_Dis}</Link>
                </>
              ) : (
                <>{data.ded_Dis}</>
              )
            }}
            caption="CODICE"
            width={currentScreenSize.isLarge ? '5%' : '18%'}
            hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 16 : undefined}
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'intervento', 'ded_Dis', true)}>
                <Search enabled={true} searchExpr={'ded_Dis'} />
              </HeaderFilter>
            )}
          </Column>
          <Column
            dataField={'sede.azienda.nome'}
            cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
              const data: intervento = cellData.row.data
              return isUserRoleAllowed(userInfo?.roles, [Roles.Guest]) ? (
                <>
                  <Link to={`/clients/${data.sede?.azienda?.id}`}>{data.sede?.azienda?.nome}</Link>
                </>
              ) : (
                <>{data.sede?.azienda?.nome}</>
              )
            }}
            caption="CLIENTE"
            visible={clientId === undefined}
            width={'25%'}
            hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 17 : undefined}
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'azienda', 'nome')}>
                <Search enabled={true} searchExpr={'nome'} />
              </HeaderFilter>
            )}
          </Column>
          <Column
            dataField={'sede.nome'}
            caption="SEDE"
            width={'5%'}
            allowHeaderFiltering={false}
            hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 14 : undefined}
          />
          {DateColumn({
            dataField: 'data',
            caption: 'DATA',
            width: 'auto',
            format: 'dd/MM/yyyy',
            hidingPriority: currentScreenSize.isSmall || currentScreenSize.isXSmall ? 15 : undefined,
          })}
          <Column
            dataField={'impiegati'}
            caption="TECNICI"
            width={'20%'}
            calculateCellValue={computeTechniciansValue}
            calculateFilterExpression={calculateTechniciansFilterExpression}
            hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 13 : undefined}
            allowSorting={false}
          >
            <HeaderFilter
              dataSource={clientId ? headerFilterTechnicians : columnSourceFactory(token, 'user', 'fullname')}
            >
              <Search enabled={true} searchExpr={'fullname'} />
            </HeaderFilter>
          </Column>
          <Column
            dataField={'riferimenti_cliente'}
            caption="RIFERIMENTI"
            width={'auto'}
            cellRender={cellRiferimentiRender}
            calculateCellValue={computeCustRifValue}
            calculateFilterExpression={calculateCustRifFilterExpression}
            hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 12 : undefined}
            allowSorting={false}
          >
            <HeaderFilter
              dataSource={clientId ? headerFilterRif : columnSourceFactory(token, 'contatto_aziendale', 'fullname')}
            >
              <Search enabled={true} searchExpr={'fullname'} />
            </HeaderFilter>
          </Column>
          <Column
            dataField={'durata_viaggio'}
            dataType="number"
            caption="VIAGGIO"
            width={'auto'}
            hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 10 : undefined}
          >
            {!clientId && (
              <HeaderFilter
                dataSource={columnSourceFactory(token, 'intervento', 'durata_viaggio', true)}
              ></HeaderFilter>
            )}
          </Column>
          <Column
            dataField={'durata_intervento'}
            dataType="number"
            caption="DURATA"
            width={'auto'}
            hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 11 : undefined}
          >
            {!clientId && (
              <HeaderFilter
                dataSource={columnSourceFactory(token, 'intervento', 'durata_intervento', true)}
              ></HeaderFilter>
            )}
          </Column>
          <Column
            dataField={'mattina_inizio'}
            caption={'MI'}
            width={'auto'}
            dataType={'date'}
            format={'HH:mm'}
            allowHeaderFiltering={false}
            hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 9 : undefined}
            allowSorting={false}
          />
          <Column
            dataField={'mattina_fine'}
            caption={'MF'}
            width={'auto'}
            dataType={'date'}
            format={'HH:mm'}
            allowHeaderFiltering={false}
            hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 8 : undefined}
            allowSorting={false}
          />
          <Column
            dataField={'pomeriggio_inizio'}
            caption={'PI'}
            width={'auto'}
            dataType={'date'}
            format={'HH:mm'}
            allowHeaderFiltering={false}
            hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 7 : undefined}
            allowSorting={false}
          />
          <Column
            dataField={'pomeriggio_fine'}
            caption={'PF'}
            width={'auto'}
            dataType={'date'}
            format={'HH:mm'}
            allowHeaderFiltering={false}
            hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 6 : undefined}
            allowSorting={false}
          />
          <Column
            dataField={'task.ded_Dis'}
            cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
              const data: intervento = cellData.row.data
              return isUserRoleAllowed(userInfo?.roles, [Roles.Guest]) ? (
                <>
                  <Link to={`/tasks/${data.task?.id}`}>{data.task?.ded_Dis}</Link>
                </>
              ) : (
                <>{data.task?.ded_Dis}</>
              )
            }}
            caption="TASK"
            width={'auto'}
            hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 5 : undefined}
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'intervento', 'task.ded_Dis', true)}>
                <Search enabled={true} searchExpr={'task.ded_Dis'} />
              </HeaderFilter>
            )}
          </Column>
          <Column
            dataField={'stato_fatturazione.nome'}
            caption="FATTURAZIONE"
            width={'auto'}
            hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 3 : undefined}
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'intervento', 'stato_fatturazione.nome')}>
                <Search enabled={true} searchExpr={'stato_fatturazione.nome'} />
              </HeaderFilter>
            )}
          </Column>
          <Column dataField={'note'} visible={false} caption="NOTE" width={'auto'} showInColumnChooser={false}>
            <Search enabled={true} searchExpr={'note'} />
          </Column>
          <Column
            name="check_pv"
            caption="PV ATTIVO"
            dataType="boolean"
            width={'7%'}
            allowFiltering={false}
            allowHeaderFiltering={false}
            hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 4 : undefined}
            calculateCellValue={(rowData: intervento) => {
              const actives = rowData.sede?.pacchetti_vendita?.filter((pacchetto) => {
                const dataPacchetto = new Date(pacchetto.creazione ?? '')
                return pacchetto.unita_restanti > 0 && dataPacchetto <= maxDate && dataPacchetto >= minDate
              })
              return actives ? actives?.length > 0 : false
            }}
          ></Column>
          <Column
            dataField={'ignorePackages'}
            caption="IGNORA PCK"
            dataType="boolean"
            width={'7%'}
            hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 4 : undefined}
          ></Column>
          <Toolbar>
            <Item
              location="before"
              locateInMenu="never"
              showText="inMenu"
              widget="dxButton"
              options={{
                hint: 'Refresh',
                text: 'Refresh',
                icon: 'refresh',
                onClick: (e: ClickEvent) => {
                  gridRef.current?.instance().refresh()
                },
                stylingMode: 'text',
              }}
            ></Item>
            <Item name="groupPanel" />
            <Item
              visible={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              name="addRowButton"
              options={{
                onClick: () => {
                  navigate(`new`)
                },
                hint: 'Nuovo intervento',
                text: 'Nuovo intervento',
              }}
            />
            <Item
              visible={true}
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              widget="dxButton"
              options={{
                hint: 'Esporta rapporto .PDF',
                text: 'Esporta rapporto .PDF',
                icon: 'pdffile',
                onClick: handleExportPDFClick,
                stylingMode: 'text',
              }}
            ></Item>
            <Item
              visible={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              widget="dxButton"
              options={{
                hint: 'Elimina intervento',
                text: 'Elimina intervento',
                icon: 'trash',
                onClick: onDeleteClick,
                stylingMode: 'text',
              }}
            ></Item>
            <Item
              name="columnChooserButton"
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              options={{ hint: 'Scelta colonne', text: 'Scelta colonne' }}
            />
            <Item
              name="exportButton"
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              options={{ hint: 'Esporta dati in excel', text: 'Esporta dati in excel' }}
            />
            <Item name="searchPanel" />
          </Toolbar>
        </DataGrid>
      </div>
    </>
  )
}

export default InterventionsPage
