import { DataGridTypes } from 'devextreme-react/cjs/data-grid'
import { Form as DXForm, GroupItem, SimpleItem, Tab, TabbedItem } from 'devextreme-react/form'
import type { intervento } from '@/model/qsadminapi/QsAdminApiModuleModel'

const InterventionBriefDetail = (props: DataGridTypes.MasterDetailTemplateData) => {
  const intervention: intervento = props.data.data

  return (
    <div className={'dx-card responsive-paddings'}>
      <DXForm id={'task_brief_detail_form'} formData={intervention} labelLocation={'top'} readOnly={true}>
        <GroupItem colCount={5} colSpan={5}>
          <GroupItem colCount={1} colSpan={5}>
            <TabbedItem>
              <Tab title="Note">
                <SimpleItem
                  dataField={'note'}
                  editorType={'dxHtmlEditor'}
                  label={{ visible: false }}
                  editorOptions={{
                    stylingMode: 'filled',
                    readOnly: true,
                    valueType: 'html',
                  }}
                ></SimpleItem>
              </Tab>
            </TabbedItem>
          </GroupItem>
        </GroupItem>
      </DXForm>
    </div>
  )
}

export default InterventionBriefDetail
