import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import ODataStore from 'devextreme/data/odata/store'
import notify from 'devextreme/ui/notify'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useLoaderData } from 'react-router-typesafe'
import {
  Column,
  ColumnChooser,
  DataGrid,
  Editing,
  Export,
  FilterRow,
  HeaderFilter,
  Item,
  Scrolling,
  SearchPanel,
  Selection,
  StateStoring,
  Toolbar,
  MasterDetail,
  DataGridRef,
  Sorting,
  Search,
  FilterPanel,
  DataGridTypes,
  Pager,
  Button as GridButton,
} from 'devextreme-react/data-grid'
import { useScreenSize } from '@/themes/media-query'
import dxDataGrid, {
  CellPreparedEvent,
  ColumnButtonClickEvent,
  ExportingEvent,
  RowDblClickEvent,
} from 'devextreme/ui/data_grid'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import saveAs from 'file-saver'
import { ClickEvent } from 'devextreme/ui/button'
import { task, user } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { confirm } from 'devextreme/ui/dialog'
import { EdmLiteral } from 'devextreme/data/odata/utils'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { GridCellColor } from '@/enums'
import { tasksPageRouteLoader } from '@/routes/tasks/TasksPage.route'
import TaskBriefDetail from '@/routes/tasks/TaskBriefDetail'
import { Accordion, Item as AccordionItem } from 'devextreme-react/accordion'
import { ContentReadyEvent } from 'devextreme/ui/accordion'
import {
  createDateFilterItemDescriptor,
  FormFilterItemDescriptorType,
} from '@/components/filter-form/GenericFilterForm.types'
import GenericFilterForm from '@/components/filter-form/GenericFilterForm'
import useTokenRefresh from '@/auth/azure/azureManager'
import OutlookAppointmentSelector from '@/routes/tasks/OutlookAppointmentSelector'
import OutlookAppointmentsCompute from '@/routes/tasks/OutlookAppointmentsCompute'
import DateColumn from '@/components/date-column/DateColumn'
import { columnSourceFactory, isUserRoleAllowed } from '@/routes/utils'
import { Roles } from '@/auth/azure/Roles'
import { gridTotalItemsLabel } from '@/constants'
import { StatoTask } from '@/routes/tasks/tasks.types'
import { PopupRef } from 'devextreme-react/cjs/popup'
import DocumentHead from '@/components/document-head/DocumentHead'
import { AxiosError, AxiosResponse } from 'axios'

const TasksPage = () => {
  const token = useTokenRefresh()
  const { title, aziende, stati, impiegati, userInfo, defaultCRUDAllowedRoles } =
    useLoaderData<typeof tasksPageRouteLoader>()

  const { service, client } = useQsAdminApiManager()
  const { clientId } = useParams()
  const currentScreenSize = useScreenSize()
  const gridRef = useRef<DataGridRef>(null)
  const createOutlookPlaceholdersPopupRef = useRef<PopupRef>(null)
  const createOutlookApponitmentPopupRef = useRef<PopupRef>(null)
  const navigate = useNavigate()
  const [task, setTask] = useState<task>()

  const getFiltersConfiguration = useCallback((): FormFilterItemDescriptorType[] => {
    const fltConf: FormFilterItemDescriptorType[] = [
      {
        fieldName: 'azienda',
        placeHolder: 'Azienda',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        values: aziende.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['sede/azienda/id', this.currentValue]]
        },
      },
      {
        fieldName: 'tecnico',
        placeHolder: 'Tecnico',
        valueKeyName: 'id',
        valueDisplayExpr: 'fullname',
        values: impiegati.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [[`tecnici/any(a: a/id eq ${this.currentValue})`], 'or', ['resp_tecnico/id', this.currentValue]]
        },
      },
      {
        fieldName: 'stato',
        placeHolder: 'Stato',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        defaultValue: StatoTask.APERTO,
        values: stati.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['stato/id', this.currentValue]]
        },
      },
      createDateFilterItemDescriptor('apertura', 'Data Apertura'),
    ]
    console.log('GENFLT - GETCONF :', fltConf)
    return fltConf
  }, [aziende, stati, impiegati])

  const initialFilterConfiguration = useMemo(() => getFiltersConfiguration(), [getFiltersConfiguration])
  const [filterConfiguration, setFilterConfiguration] = useState(initialFilterConfiguration)
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false)
  const getCurrentFilter = useCallback((): any[] => {
    let applied: boolean = false
    const currentFlts = []
    for (const flt of filterConfiguration) {
      const fltValue = flt.composeFilterItem()
      if (fltValue !== null) {
        currentFlts.push(fltValue)
      }
      if (!applied && flt.currentValue !== flt.defaultValue) applied = true
    }
    setFiltersApplied(applied)
    console.log('GENFLT - CURRENT:', currentFlts)
    return currentFlts
  }, [filterConfiguration])

  const [currentFilter, setCurrentFilter] = useState<any[]>(clientId ? [['sede/azienda/id', Number(clientId)]] : [])

  const tasksDataSource = {
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/task`,
      key: 'id',
      keyType: 'Int32',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    expand: [
      'resp_tecnico',
      'ordine($expand=offerta($expand=analisi))',
      'commessa',
      'proprietario',
      'stato',
      'sede($expand=azienda($expand=agente($expand=commerciale_qs),rivenditore))',
      'attivita_lavorative',
      'eventi',
      'interventi',
      'personalizzazioni',
      'pacchetti_venduti',
      'tecnici',
      'punto_fatturazione',
    ],
    filter: currentFilter.length > 0 ? currentFilter : null,
    requireTotalCount: true,
  }

  const getGridHeight = useCallback(() => {
    let h = '79vh'
    if (currentScreenSize.isMedium) {
      h = '75vh'
    } else if (currentScreenSize.isSmall) {
      h = '120vh'
    } else if (currentScreenSize.isXSmall) {
      h = '75vh'
    }
    return h
  }, [currentScreenSize])

  const onCellPrepared = (e: CellPreparedEvent) => {
    if (e.rowType === 'data' && e.column.dataField === 'stato.nome' && e.value) {
      switch (e.value) {
        case 'APERTO': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.YELLOW}`

          break
        }
        case 'COMPLETATO': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.EMERALD}`

          break
        }
      }
    }
  }

  const onExporting = (e: ExportingEvent) => {
    ExportDataGridToExcel(e.component)
  }

  const onDeleteClick = (e: ClickEvent) => {
    const rowkey = gridRef.current?.instance().option('focusedRowKey')
    console.log('id', rowkey)
    gridRef.current
      ?.instance()
      .byKey(rowkey)
      .then((rowdata: task) => {
        const result = confirm(`<i>Vuoi davvero eliminare il task <b>${rowdata.id}</b>?</i>`, 'Eliminazione task')
        result.then((dialogResult) => {
          if (dialogResult === false) return
          service
            .task(rowdata.id)
            .delete()
            .then(() => {
              // Aggiornamento griglia
              gridRef.current?.instance().refresh()

              notify(
                {
                  message: `Task '${rowdata['ded_Dis']}' del cliente ${
                    rowdata['sede']?.azienda?.nome ?? 'Non definito'
                  } eliminato con successo`,
                  type: 'success',
                  displayTime: 5000,
                },
                {
                  position: 'bottom center',
                  direction: 'up-push',
                },
              )
            })
            .catch((error: unknown) => {
              notify(
                {
                  message: `Errore eliminazione task: ${error}`,
                  type: 'error',
                  displayTime: 5000,
                },
                {
                  position: 'bottom center',
                  direction: 'up-push',
                },
              )
            })
        })
      })
  }

  const onAddOutlookAppointmentForTaskClick = (e: ClickEvent) => {
    const rowkey = gridRef.current?.instance().option('focusedRowKey')
    if (rowkey) {
      setTask(gridRef.current?.instance().getSelectedRowsData()[0])
      createOutlookApponitmentPopupRef.current?.instance().show()
    } else {
      notify('Selezionare un task.', 'warning', 2000)
    }
  }

  const onCreateOutlookPlaceholdersClick = (e: ClickEvent) => {
    createOutlookPlaceholdersPopupRef.current?.instance().show()
  }

  const ExportDataGridToExcel = (component: dxDataGrid) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Tasks')
    exportDataGrid({ component, worksheet }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Tasks.xlsx')
      })
    })
  }

  const headerFilterAusiliari: { text: string; value: string }[] = []
  const calculateAusiliariValue = (rowData: task) => {
    let ausiliari: string = ''
    rowData.tecnici?.map((tecnico: user) => {
      ausiliari = `${ausiliari + tecnico.fullname} `
      const filter: { text: string; value: string } = {
        text: tecnico.fullname,
        value: tecnico.fullname,
      }
      const headerFilter = headerFilterAusiliari.find((hf: { text: string; value: string }) => {
        return hf.text === filter.text
      })
      if (!headerFilter) headerFilterAusiliari.push(filter)
    })
    return ausiliari.trim()
  }

  const calculateAusiliariFilterExpression = (
    filterValue: any,
    selectedFilterOperation: string | null,
    target: string,
  ): string | (() => any) | Array<any> => {
    console.log('FLT', filterValue, selectedFilterOperation, target)

    if (target === 'filterBuilder' || target === 'filterRow') {
      switch (selectedFilterOperation) {
        case '=': {
          return [[new EdmLiteral(`tecnici/any(a: a/fullname eq '${filterValue}')`), '=', true]]
        }
        case '<>': {
          return [[new EdmLiteral(`tecnici/any(a: a/fullname eq '${filterValue}')`), '=', false]]
        }
        case 'contains':
        case 'startswith':
        case 'endswith': {
          return [
            [new EdmLiteral(`tecnici/any(a: ${selectedFilterOperation}(a/fullname, '${filterValue}'))`), '=', true],
          ]
        }
        default: {
          // caso notcontains
          return [[new EdmLiteral(`tecnici/any(a: contains(a/fullname, '${filterValue}'))`), '=', false]]
        }
      }
    } else if (target === 'search' || target === 'headerFilter') {
      return [[new EdmLiteral(`tecnici/any(a: contains(a/fullname, '${filterValue}'))`), '=', true]]
    }

    return filterValue
  }

  const cellRenderAusiliari = (cellData: DataGridTypes.ColumnCellTemplateData) => {
    return (
      <>
        {cellData.data.tecnici.map((impiegato: user, index: number) => {
          return (
            <div className={`dx-tag-content custom-tag-padding`} key={index}>
              <span>
                {impiegato.nome} {impiegato.cognome}
              </span>
            </div>
          )
        })}
      </>
    )
  }

  const handleDuplicateTaskClick = (e: ClickEvent) => {
    const rowkey = gridRef.current?.instance().option('focusedRowKey')
    if (rowkey) {
      gridRef?.current?.instance().beginCustomLoading('Duplicazione task in corso....')
      gridRef.current
        ?.instance()
        .byKey(rowkey)
        .then(async (rowdata: task) => {
          console.log('task', rowdata)
          client
            .post(
              '/api/task/duplicatetask',
              { taskId: rowdata.id },
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              },
            )
            .then((response: AxiosResponse) => {
              gridRef.current?.instance().refresh()
            })
            .catch((error: AxiosError) => {
              notify(`Errore duplicazione task. Dettagli : ${error}`, 'error', 2000)
            })
            .finally(() => {
              gridRef.current?.instance().endCustomLoading()
            })
        })
    } else notify('Selezionare un task da duplicare.', 'warning', 2000)
  }

  useEffect(() => {
    getCurrentFilter()
  }, [])

  return (
    <>
      <DocumentHead title={title}></DocumentHead>
      {!clientId && <h2 className={'content-block'}>{title}</h2>}
      <div className={clientId ? '' : 'content-block'}>
        {!clientId && (
          <div className="accordion-generic-filter-form">
            <Accordion
              className={filtersApplied ? 'with-filter' : ''}
              collapsible={true}
              onContentReady={(e: ContentReadyEvent<any, any>) => {
                e.component?.collapseItem(0)
              }}
            >
              <AccordionItem icon="filter" title={'Filtri'}>
                <GenericFilterForm
                  FormItemDescriptors={filterConfiguration}
                  resetFilterClicked={function (e: ClickEvent): void {
                    setFilterConfiguration(getFiltersConfiguration())
                  }}
                  filterValueChanged={function (): void {
                    const currentFlts = getCurrentFilter()
                    console.log('GENFLT - VALUE CHNG', currentFlts)
                    if (JSON.stringify(currentFlts) !== JSON.stringify(currentFilter)) {
                      console.log('GENFLT - SETTING FILT', currentFlts)
                      setCurrentFilter(currentFlts)
                    }
                  }}
                />
              </AccordionItem>
            </Accordion>
          </div>
        )}
        <DataGrid
          id={clientId ? 'client-tasks-datagrid' : 'tasks-datagrid'}
          height={getGridHeight}
          dataSource={tasksDataSource}
          noDataText="Nessun task trovato"
          className={'dx-card wide-card'}
          showBorders={false}
          showColumnLines={true}
          wordWrapEnabled={false}
          showRowLines={true}
          columnHidingEnabled={currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium}
          focusedRowEnabled={true}
          rowAlternationEnabled={true}
          allowColumnResizing={currentScreenSize.isLarge || currentScreenSize.isMedium}
          allowColumnReordering={currentScreenSize.isLarge || currentScreenSize.isMedium}
          width="100%"
          onCellPrepared={onCellPrepared}
          onExporting={onExporting}
          ref={gridRef}
          remoteOperations={{
            filtering: true,
            grouping: false,
            groupPaging: false,
            paging: true,
            sorting: true,
            summary: true,
          }}
          grouping={{
            // Fondamentale per il raggruppamento veloce!!!
            autoExpandAll: true,
          }}
          groupPanel={{
            visible: currentScreenSize.isLarge || currentScreenSize.isMedium,
            emptyPanelText: 'Trascina qui una colonna per raggruppare',
          }}
          onRowDblClick={(e: RowDblClickEvent<any, any>) => {
            if (e.rowType === 'data') navigate(`${e.data.id}`)
          }}
        >
          <Pager
            visible={true}
            allowedPageSizes={'auto'}
            displayMode={'compact'}
            showPageSizeSelector={false}
            showInfo={true}
            showNavigationButtons={false}
            infoText={gridTotalItemsLabel}
          />
          <FilterRow visible={true} />
          <Sorting mode="multiple"></Sorting>
          <SearchPanel visible={true} searchVisibleColumnsOnly={false} width={currentScreenSize.isXSmall ? 200 : 250} />
          <Export enabled={true} />
          <Editing allowAdding={true} />
          <HeaderFilter visible={true} />
          <FilterPanel visible={true} />
          <Selection mode="single" />
          <ColumnChooser enabled={true} />
          <Scrolling mode="virtual" />
          <StateStoring
            enabled={true}
            type="localStorage"
            storageKey={clientId ? 'client-tasks-datagrid' : 'tasks-datagrid'}
            savingTimeout={50}
          />
          <MasterDetail enabled={true} component={TaskBriefDetail} />,
          <Column
            visible={currentScreenSize.isXSmall || currentScreenSize.isSmall}
            type="buttons"
            width={currentScreenSize.isXSmall || currentScreenSize.isSmall ? '10%' : '2%'}
            alignment="left"
            showInColumnChooser={false}
          >
            <GridButton
              hint="Details"
              icon="search"
              onClick={(e: ColumnButtonClickEvent) => {
                navigate(`${e.row?.data.id}`)
              }}
            ></GridButton>
          </Column>
          <Column
            dataField={'id'}
            width={'5%'}
            caption="ID"
            visible={false}
            dataType="number"
            defaultSortOrder="desc"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 1 : undefined
            }
          >
            {!clientId && <HeaderFilter dataSource={columnSourceFactory(token, 'task', 'id', true)}></HeaderFilter>}
          </Column>
          <Column
            dataField="ded_Dis"
            cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
              const data: task = cellData.row.data
              return isUserRoleAllowed(userInfo?.roles, [Roles.Guest]) ? (
                <>
                  <Link to={`/tasks/${data.id}`}>{data.ded_Dis}</Link>
                </>
              ) : (
                <>{data.ded_Dis}</>
              )
            }}
            caption="CODICE"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 14 : undefined
            }
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'task', 'ded_Dis', true)}>
                <Search enabled={true} searchExpr={'ded_Dis'} />
              </HeaderFilter>
            )}
          </Column>
          <Column
            dataField="sede.azienda.nome"
            cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
              const data: task = cellData.row.data
              return isUserRoleAllowed(userInfo?.roles, [Roles.Guest]) ? (
                <>
                  <Link to={`/clients/${data.sede?.azienda?.id}`}>{data.sede?.azienda?.nome}</Link>
                </>
              ) : (
                <>{data.sede?.azienda?.nome}</>
              )
            }}
            caption="AZIENDA"
            visible={clientId === undefined}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 15 : undefined
            }
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'azienda', 'nome')}>
                <Search enabled={true} searchExpr={'nome'} />
              </HeaderFilter>
            )}
          </Column>
          <Column
            dataField="sede.nome"
            caption="SEDE"
            allowHeaderFiltering={false}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 9 : undefined
            }
          />
          <Column
            dataField="sede.azienda.agente.commerciale_qs.fullname"
            caption="AGENTE"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 8 : undefined
            }
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'agente', 'commerciale_qs.fullname')}>
                <Search enabled={true} searchExpr={'commerciale_qs.fullname'} />
              </HeaderFilter>
            )}
          </Column>
          <Column dataField={'sede.azienda.rivenditore.nome'} caption={'RIVENDITORE'} visible={false}>
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'Azienda', 'rivenditore.nome')}>
                <Search enabled={true} searchExpr={'rivenditore.nome'} />
              </HeaderFilter>
            )}
          </Column>
          <Column
            dataField="proprietario.fullname"
            caption="AUTORE"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 7 : undefined
            }
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'task', 'proprietario.fullname')}>
                <Search enabled={true} searchExpr={'proprietario.fullname'} />
              </HeaderFilter>
            )}
          </Column>
          <Column
            dataField="resp_tecnico.fullname"
            caption="RESPONSABILE"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 11 : undefined
            }
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'task', 'resp_tecnico.fullname')}>
                <Search enabled={true} searchExpr={'resp_tecnico.fullname'} />
              </HeaderFilter>
            )}
          </Column>
          <Column
            name={'ausiliari'}
            caption={'AUSILIARI'}
            calculateFilterExpression={calculateAusiliariFilterExpression}
            calculateCellValue={calculateAusiliariValue}
            cellRender={cellRenderAusiliari}
            allowFiltering={true}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 10 : undefined
            }
          >
            <HeaderFilter
              dataSource={clientId ? headerFilterAusiliari : columnSourceFactory(token, 'user', 'fullname')}
            >
              <Search enabled={true} searchExpr={'fullname'} />
            </HeaderFilter>
          </Column>
          {DateColumn({
            dataField: 'apertura',
            caption: 'APERTURA',
            format: 'dd/MM/yyyy',
            width: 'auto',
            hidingPriority:
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 13 : undefined,
          })}
          {DateColumn({
            dataField: 'consegna_prevista',
            caption: 'CONSEGNA',
            format: 'dd/MM/yyyy',
            width: 'auto',
            hidingPriority:
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 6 : undefined,
          })}
          {DateColumn({
            dataField: 'chiusura',
            caption: 'CHIUSURA',
            format: 'dd/MM/yyyy',
            width: 'auto',
            hidingPriority:
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 5 : undefined,
          })}
          <Column
            dataField="stato.nome"
            caption="STATO"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 12 : undefined
            }
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'task', 'stato.nome')}>
                <Search enabled={true} searchExpr={'stato.nome'} />
              </HeaderFilter>
            )}
          </Column>
          <Column
            dataField="ordine.ded_Dis"
            cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
              const data: task = cellData.row.data
              return isUserRoleAllowed(userInfo?.roles, [Roles.Guest]) ? (
                <>
                  <Link to={`/orders/${data.ordine?.id}`}>{data.ordine?.ded_Dis}</Link>
                </>
              ) : (
                <>{data.ordine?.ded_Dis}</>
              )
            }}
            caption="ORDINE"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 4 : undefined
            }
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'task', 'ordine.ded_Dis', true)}>
                <Search enabled={true} searchExpr={'ordine.ded_Dis'} />
              </HeaderFilter>
            )}
          </Column>
          <Column
            dataField="punto_fatturazione.ded_Dis"
            cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
              const data: task = cellData.row.data
              return isUserRoleAllowed(userInfo?.roles, [
                Roles.GlobalAdministrator,
                Roles.Administrator,
                Roles.Supervisor,
              ]) ? (
                <>
                  <Link to={`/invoicingsteps/${data.punto_fatturazione?.id}`}>{data.punto_fatturazione?.ded_Dis}</Link>
                </>
              ) : (
                <>{data.punto_fatturazione?.ded_Dis}</>
              )
            }}
            caption="PUNTO FATTURAZIONE"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 4 : undefined
            }
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'task', 'punto_fatturazione.ded_Dis', true)}>
                <Search enabled={true} searchExpr={'punto_fatturazione.ded_Dis'} />
              </HeaderFilter>
            )}
          </Column>
          <Column
            dataField="ordine.offerta.analisi.ded_Dis"
            cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
              const data: task = cellData.row.data
              return isUserRoleAllowed(userInfo?.roles, [Roles.Guest]) ? (
                <>
                  <Link to={`/analysis/${data.ordine?.offerta?.analisi?.id}`}>
                    {data.ordine?.offerta?.analisi?.ded_Dis}
                  </Link>
                </>
              ) : (
                <>{data.ordine?.offerta?.analisi?.ded_Dis}</>
              )
            }}
            caption="ANALISI"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 3 : undefined
            }
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'task', 'ordine.offerta.analisi.ded_Dis', true)}>
                <Search enabled={true} searchExpr={'ordine.offerta.analisi.ded_Dis'} />
              </HeaderFilter>
            )}
          </Column>
          <Column
            visible={false}
            showInColumnChooser={false}
            dataField={'ordine.note'}
            caption={'NOTE ORDINE'}
            allowFiltering={true}
            allowHeaderFiltering={false}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 1 : undefined
            }
          ></Column>
          <Column
            visible={false}
            showInColumnChooser={false}
            dataField={'note'}
            caption={'NOTE'}
            allowFiltering={true}
            allowHeaderFiltering={false}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 1 : undefined
            }
          ></Column>
          <Toolbar>
            <Item
              location="before"
              locateInMenu="never"
              showText="inMenu"
              widget="dxButton"
              options={{
                hint: 'Refresh',
                text: 'Refresh',
                icon: 'refresh',
                onClick: (e: ClickEvent) => {
                  gridRef.current?.instance().refresh()
                },
                stylingMode: 'text',
              }}
            ></Item>
            <Item name="groupPanel" />
            <Item
              visible={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              name="addRowButton"
              options={{
                onClick: () => {
                  navigate(`new`)
                },
                hint: 'Nuovo task',
                text: 'Nuovo task',
              }}
            />
            <Item
              visible={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              widget="dxButton"
              options={{
                hint: 'Duplica',
                text: 'Duplica',
                icon: 'copy',
                onClick: handleDuplicateTaskClick,
                stylingMode: 'text',
              }}
            ></Item>
            <Item
              visible={isUserRoleAllowed(userInfo?.roles, [
                Roles.GlobalAdministrator,
                Roles.Supervisor,
                Roles.TechDeveloper,
              ])}
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              widget="dxButton"
              options={{
                hint: 'Crea appuntamento outlook per il task selezionato',
                text: 'Crea appuntamento outlook per il task selezionato',
                icon: 'group',
                onClick: onAddOutlookAppointmentForTaskClick,
                stylingMode: 'text',
              }}
            ></Item>
            <Item
              visible={isUserRoleAllowed(userInfo?.roles, [Roles.GlobalAdministrator, Roles.Supervisor])}
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              widget="dxButton"
              options={{
                hint: 'Crea appuntamenti outlook',
                text: 'Crea appuntamenti outlook',
                icon: 'event',
                onClick: onCreateOutlookPlaceholdersClick,
                stylingMode: 'text',
              }}
            ></Item>
            <Item
              visible={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              widget="dxButton"
              options={{
                hint: 'Elimina task',
                text: 'Elimina task',
                icon: 'trash',
                onClick: onDeleteClick,
                stylingMode: 'text',
              }}
            ></Item>
            <Item
              name="columnChooserButton"
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              options={{ hint: 'Scelta colonne', text: 'Scelta colonne' }}
            />
            <Item
              name="exportButton"
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              options={{ hint: 'Esporta dati in excel', text: 'Esporta dati in excel' }}
            />
            <Item name="searchPanel" />
          </Toolbar>
        </DataGrid>
        <OutlookAppointmentSelector task={task} popupRef={createOutlookApponitmentPopupRef} />
        <OutlookAppointmentsCompute popupRef={createOutlookPlaceholdersPopupRef} />
      </div>
    </>
  )
}

export default TasksPage
