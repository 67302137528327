import PersonCreatorForm from '@/routes/contacts/person/PersonCreator.form'
import { personCreatorRouteLoader } from '@/routes/contacts/person/PersonCreator.route'
import { Suspense } from 'react'
import { useLoaderData, Await } from 'react-router-typesafe'
import { LoadPanel } from 'devextreme-react/load-panel'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { useFetcher } from 'react-router-dom'
import { userInfo } from 'node:os'
import DocumentHead from '@/components/document-head/DocumentHead'

const PersonCreator = () => {
  const { title, contact, getRapporti, getAziende, getUserInfo } = useLoaderData<typeof personCreatorRouteLoader>()
  const fetcher = useFetcher()
  return (
    <>
      <DocumentHead title={title}></DocumentHead>
      <Suspense fallback={<LoadingPanel />}>
        <Await resolve={Promise.all([contact, getRapporti, getAziende, getUserInfo])}>
          {([contact, rapporti, aziende, userInfo]) => (
            <>
              <h2>{title}</h2>
              <div id="create-contact-container" className={'dx-card responsive-paddings'}>
                <fetcher.Form>
                  <PersonCreatorForm
                    contact={contact}
                    rapporti={rapporti.data.value}
                    aziende={aziende?.data.value}
                    userInfo={userInfo}
                    fetcher={fetcher}
                  />
                </fetcher.Form>
              </div>
            </>
          )}
        </Await>
      </Suspense>
    </>
  )
}

export default PersonCreator
