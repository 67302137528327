import { Roles } from '@/auth/azure/Roles'
import QuoteEditor from '@/routes/quotes/QuoteEditor'
import { quoteEditorRouteAction, quoteEditorRouteLoader } from '@/routes/quotes/QuoteEditor.route'
import { RoleRouteObject } from '@/types'
import { RouteObject } from 'react-router-dom'

const QuoteCreatorRoute = {
  path: 'new',
  element: <QuoteEditor creating={true} />,
  loader: quoteEditorRouteLoader,
  action: quoteEditorRouteAction,
  allowedRoles: [Roles.Sales, Roles.Administrator, Roles.GlobalAdministrator, Roles.ExternalSales, Roles.Supervisor],
} as RoleRouteObject

export default QuoteCreatorRoute
