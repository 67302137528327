import { RoleRoute, RoleRouteObject, RouteFunctionParams } from '@/types'
import ClientsPage from '@/routes/clients/ClientsPage'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { defer, LoaderFunction } from 'react-router-typesafe'
import { Roles } from '@/auth/azure/Roles'
import { ClientCreatorRoute } from '@/routes/clients/client/ClientCreator.route'
import { ClientMasterViewRoute } from '@/routes/clients/client/ClientMasterView.route'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import RouterErrorHandler from '@/components/RouterErrorHandler'
import { ClientGroupsPageRoute } from '@/routes/clients/client-groups/ClientGroupsPage.route'

export const clientsPageRouteLoader = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const getTipologieRapporto = qsAdminApi.tipologia_rapporto_azienda().query((builder, tipologia_rapporto_azienda) => {
    builder.select('id', 'nome')
    builder.orderBy(tipologia_rapporto_azienda.nome.asc())
  })

  return defer({
    tipologie_rapporto: await getTipologieRapporto,
    userInfo: await getAzureUserInformation(),
    defaultCRUDAllowedRoles: [
      Roles.GlobalAdministrator,
      Roles.Administrator,
      Roles.Supervisor,
      Roles.Sales,
      Roles.ExternalSales,
    ],
  })
}) satisfies LoaderFunction

export const ClientsPageRoute: RoleRoute = {
  path: 'clients',
  children: [
    {
      index: true,
      element: <ClientsPage />,
      loader: clientsPageRouteLoader,
      allowedRoles: [Roles.Guest],
    } as RoleRouteObject,
    ClientMasterViewRoute,
    ClientCreatorRoute,
    ClientGroupsPageRoute,
  ],
  errorElement: <RouterErrorHandler />,
  text: 'Clienti',
  icon: 'globe',
  mainMenuRoute: true,
  allowedRoles: [Roles.Guest],
}
