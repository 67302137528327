import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { RoleRouteObject, RouteFunctionParams } from '@/types'
import { redirect } from 'react-router-dom'
import { defer, ActionFunction, LoaderFunction } from 'react-router-typesafe'
import TicketEditor from '@/routes/tickets/TicketEditor'
import { ODataCollectionResponseV4, ODataModelResponseV4 } from '@odata2ts/odata-core'
import { ODataResponse, HttpResponseModel } from '@odata2ts/http-client-api'
import { argomento_problema, nota_ticket, ticket, azienda } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import { StatoTicket } from '@/routes/tickets/TicketEditor.enums'
import { Roles } from '@/auth/azure/Roles'
import { Reparto } from '@/enums'
import { Qticket } from '@/model/qsadminapi/QQsAdminApiModule'

export const ticketEditorRouteLoader = (async ({ request, params }: RouteFunctionParams<'ticketId' | 'clientId'>) => {
  const ticketId = params.ticketId
  const clientId = params.clientId
  const qsAdminApi = useQsAdminApiManager.getState().service

  let azienda: HttpResponseModel<ODataModelResponseV4<azienda>> | undefined
  if (clientId)
    azienda = await qsAdminApi.azienda(Number(clientId)).query((builder, azienda) => {
      builder.select('id', 'nome')
    })
  let title = azienda ? `${azienda.data.nome} - ` : ``

  let ticket: ticket
  let getProblemArguments: ODataResponse<ODataCollectionResponseV4<argomento_problema>> | undefined

  if (ticketId) {
    //editor
    const getTicket = await qsAdminApi.ticket(Number(ticketId)).query((builder, ticket) => {
      builder.expanding('tipologia_ticket', (tipologiaBuilder, tipologia) => {
        tipologiaBuilder.select('id', 'nome')
      })
      builder.expanding('assegnato', (assegnatoBuilder, assegnato) => {
        assegnatoBuilder.select('id', 'fullname', 'email')
      })
      builder.expanding('tipologia_problema', (tipologiaProblBuilder, tipologiaProbl) => {
        tipologiaProblBuilder.select('id', 'nome')
      })
      builder.expanding('argomento_problema', (argomentoProblBuilder, argomentoProbl) => {
        argomentoProblBuilder.select('id', 'nome')
      })
      builder.expanding('stato', (statoBuilder, stato) => {
        statoBuilder.select('id', 'nome')
      })
      builder.expanding('piattaforma', (piattaformaBuilder, piattaforma) => {
        piattaformaBuilder.select('id', 'nome')
      })
      builder.expanding('applicazione', (applicazioneBuilder, applicazione) => {
        applicazioneBuilder.select('id', 'nome')
      })
      builder.expanding('creatore', (creatoreBuilder, creatore) => {
        creatoreBuilder.select('id', 'fullname')
      })
      builder.expanding('incaricato', (incaricatoBuilder, incaricato) => {
        incaricatoBuilder.select('id', 'fullname', 'email')
      })
      builder.expanding('sede', (sedeBuilder, sede) => {
        sedeBuilder.expanding('azienda', (aziendaSedeBuilder, aziendaSede) => {
          aziendaSedeBuilder.expanding('sedi', (sediAziendaSedeBuilder, sedeAzienda) => {
            sediAziendaSedeBuilder.select('id', 'nome', 'note')
          })
          aziendaSedeBuilder.select('id', 'nome', 'sedi')
        })
        sedeBuilder.select('id', 'nome', 'azienda', 'note')
      })
      builder.expanding('eventi', (eventiBuilder, evento) => {
        eventiBuilder.expanding('tipologia', (tipologiaEventoBuilder, tipologiaEvento) => {
          tipologiaEventoBuilder.select('id', 'nome')
        })
        eventiBuilder.expanding('utente', (utenteEventoBuilder, utenteEvento) => {
          utenteEventoBuilder.select('id', 'fullname')
        })
        eventiBuilder.orderBy(evento.data.desc())
      })
      builder.expanding('note_ticket', (noteTicketBuilder, notaTicket) => {
        noteTicketBuilder.expanding('utente', (utenteNotaTicketBuilder, utenteNota) => {
          utenteNotaTicketBuilder.select('id', 'fullname')
        })
        noteTicketBuilder.expanding('ticket', (ticketNoteTicketBuilder, ticketNotaTicket) => {
          ticketNoteTicketBuilder.expand('assegnato')
          ticketNoteTicketBuilder.expand('stato')
          ticketNoteTicketBuilder.select('id', 'assegnato', 'stato', 'leg_contatto', 'attachmentsFolderUrl')
        })
        noteTicketBuilder.expand('emails')
        noteTicketBuilder.orderBy(notaTicket.data.desc())
      })
    })
    ticket = getTicket.data satisfies ticket
    title += `${ticket.ded_Dis}`
    if (clientId && ticket?.sede?.azienda && ticket?.sede?.azienda.id !== Number(clientId)) {
      throw new Error(
        `Il task ${ticket.ded_Dis} appartiene al cliente ${ticket?.sede?.azienda.nome} e non al cliente corrente`,
      )
    }
    getProblemArguments =
      ticket.applicazione?.nome || ticket.piattaforma?.nome
        ? qsAdminApi.argomento_problema().query((builder, argomentoProbl) => {
            builder.filter(
              ticket.applicazione?.nome
                ? argomentoProbl.qs_applicazione.props.nome.eq(ticket.applicazione?.nome ?? null)
                : argomentoProbl.piattaforma.props.nome.eq(ticket.piattaforma?.nome ?? null),
            )
          })
        : undefined
  } else {
    //creator
    title += 'Nuovo ticket'
    const userInfo = await getAzureUserInformation()
    const getAutore = await qsAdminApi.user().query((builder, impiegato) => {
      builder.filter(impiegato.email.eq(userInfo?.email ?? ''))
      builder.select('id', 'fullname')
    })

    const getStatoAperto = await qsAdminApi.stato_ticket(StatoTicket.APERTO).query((builder, stato) => {
      builder.select('id', 'nome')
    })

    const getTipologiaDefault = await qsAdminApi.tipologia_ticket().query((builder, tipologia) => {
      builder.filter(tipologia.nome.eq('ASSISTENZA TECNICA'))
      builder.select('id', 'nome')
    })

    ticket = {
      id: 0,
      ded_Dis: '',
      ded_RootFam: '',
      ded_Id: 0,
      contattato: false,
      attesa: false,
      urgente: false,
      notifica_chiusura: false,
      rating: 0,
      rating_note: null,
      apertura: new Date().toISOString(),
      chiusura: null,
      descrizione: null,
      soluzione: null,
      leg_tag1: null,
      leg_tag2: null,
      durata: 0,
      leg_contatto: null,
      leg_n_chiamate: 0,
      leg_email_contatto: null,
      leg_phone_contatto: null,
      creatore: getAutore.data.value[0],
      assegnato: null,
      stato: getStatoAperto.data,
      attachmentsFolderUrl: '',
      sede: null,
      tipologia_ticket: getTipologiaDefault.data.value[0],
    }
  }
  const getClients = qsAdminApi.azienda().query((builder, azienda) => {
    builder.filter(azienda.sedi.any())
    builder.filter(clientId ? azienda.id.eq(Number(clientId)) : null)
    builder.orderBy(azienda.nome.asc())
    builder.select('id', 'nome')
  })

  const getStates = qsAdminApi.stato_ticket().query((builder, stato) => {
    builder.select('id', 'nome')
    builder.orderBy(stato.nome.asc())
  })

  const getCategories = qsAdminApi.tipologia_ticket().query((builder, categoria) => {
    builder.select('id', 'nome')
    builder.orderBy(categoria.nome.asc())
  })

  const getPlatforms = qsAdminApi.piattaforma_ticket().query((builder, piattaforma) => {
    builder.select('id', 'nome')
    builder.orderBy(piattaforma.nome.asc())
  })

  const getSupportApplications = qsAdminApi.qs_supporto_applicazione().query((builder, applicazione) => {
    builder.select('id', 'nome')
    builder.orderBy(applicazione.nome.asc())
  })

  const getProblemCategories = qsAdminApi.tipologia_problema().query((builder, tipologiaProbl) => {
    builder.select('id', 'nome', 'note')
    builder.orderBy(tipologiaProbl.nome.asc())
  })

  const getEmployees = qsAdminApi.user().query((builder, impiegato) => {
    builder.filter(
      impiegato.attivo
        .eq(true)
        .and(impiegato.reparto.props.id.eq(Reparto.SVILUPPO).or(impiegato.reparto.props.id.eq(Reparto.TECNICO)))
        .or(ticketId ? impiegato.tickets.any((ticket: Qticket) => ticket.id.eq(Number(ticketId))) : null),
    )
    builder.select('id', 'fullname')
    builder.orderBy(impiegato.fullname.asc())
  })

  return defer({
    title,
    ticket,
    getClients,
    getStates,
    getCategories,
    getPlatforms,
    getSupportApplications,
    getProblemCategories,
    getEmployees,
    getProblemArguments,
    getUserInfo: getAzureUserInformation(),
    defaultCRUDAllowedRoles: [Roles.GlobalAdministrator, Roles.Administrator, Roles.Supervisor, Roles.TechDeveloper],
  })
}) satisfies LoaderFunction

export const ticketEditorRouteAction = (async ({ request }: RouteFunctionParams) => {
  const ticket = (await request.json()) as ticket
  console.log('ticket', ticket)
  const qsAdminApi = useQsAdminApiManager.getState().service

  switch (request.method) {
    case 'POST': {
      const res = await qsAdminApi.ticket().create(ticket)
      return redirect(`../${res.data.id}`)
    }
    case 'PUT':
    case 'PATCH': {
      return await qsAdminApi.ticket(ticket.id).update(ticket)
    }
    default: {
      throw new Response('Method not allowed', {
        status: 405,
        statusText: 'Method not allowed',
      })
    }
  }
}) satisfies ActionFunction

export const cancelAction = (async ({ request, params }: RouteFunctionParams<'quoteId'>) => {
  const ticket = (await request.json()) as ticket
  const qsAdminApi = useQsAdminApiManager.getState().service
  return await qsAdminApi.ticket(ticket.id).Cancel()
}) satisfies ActionFunction

const completeAction = (async ({ request, params }: RouteFunctionParams<'ticketId'>) => {
  const complete = (await request.json()) as { ticket: ticket; closure: string; solution: string }
  const qsAdminApi = useQsAdminApiManager.getState()
  complete.ticket.soluzione = complete.solution
  const response = await qsAdminApi.service.ticket(complete.ticket.id).update(complete.ticket)
  return qsAdminApi.client.post(
    '/api/ticket/complete',
    { ticketId: complete.ticket.ded_Dis, closure: complete.closure, solution: complete.solution },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}) satisfies ActionFunction

const ticketNoteAction = (async ({ request, params }: RouteFunctionParams<'ticketId'>) => {
  const objAction = await request.formData()
  const qsAdminApi = useQsAdminApiManager.getState()
  switch (request.method) {
    case 'POST': {
      return qsAdminApi.client.post('/api/ticket/ticketnote', objAction)
    }
    case 'PUT':
    case 'PATCH': {
      return qsAdminApi.client.put('/api/ticket/ticketnote', objAction)
    }
    default: {
      throw new Response('Method not allowed', {
        status: 405,
        statusText: 'Method not allowed',
      })
    }
  }
}) satisfies ActionFunction

export const setUrgentAction = (async ({ request, params }: RouteFunctionParams<'ticketId'>) => {
  const ticket = (await request.json()) as ticket
  const qsAdminApi = useQsAdminApiManager.getState().service
  return await qsAdminApi.ticket(ticket.id).SetUrgent()
}) satisfies ActionFunction

export const setWaitAction = (async ({ request, params }: RouteFunctionParams<'ticketId'>) => {
  const ticket = (await request.json()) as ticket
  const qsAdminApi = useQsAdminApiManager.getState().service
  return await qsAdminApi.ticket(ticket.id).SetWait()
}) satisfies ActionFunction

export const clearWaitAction = (async ({ request, params }: RouteFunctionParams<'ticketId'>) => {
  const ticket = (await request.json()) as ticket
  const qsAdminApi = useQsAdminApiManager.getState().service
  return await qsAdminApi.ticket(ticket.id).ClearWait()
}) satisfies ActionFunction

export const incrementCallNumberAction = (async ({ request, params }: RouteFunctionParams<'ticketId'>) => {
  const ticket = (await request.json()) as ticket
  const qsAdminApi = useQsAdminApiManager.getState().service
  return await qsAdminApi.ticket(ticket.id).IncrementCallNumber()
}) satisfies ActionFunction

export const removeAssigneeAction = (async ({ request, params }: RouteFunctionParams<'ticketId'>) => {
  const ticket = (await request.json()) as ticket
  const qsAdminApi = useQsAdminApiManager.getState().service
  return await qsAdminApi.ticket(ticket.id).RemoveAssignee()
}) satisfies ActionFunction

export const takeChargeAction = (async ({ request, params }: RouteFunctionParams<'ticketId'>) => {
  const ticket = (await request.json()) as ticket
  const qsAdminApi = useQsAdminApiManager.getState().service
  return await qsAdminApi.ticket(ticket.id).TakeCharge()
}) satisfies ActionFunction

export const releaseAction = (async ({ request, params }: RouteFunctionParams<'ticketId'>) => {
  const ticket = (await request.json()) as ticket
  const qsAdminApi = useQsAdminApiManager.getState().service
  return await qsAdminApi.ticket(ticket.id).Release()
}) satisfies ActionFunction

export const sendClosureNoticeAction = (async ({ request, params }: RouteFunctionParams<'ticketId'>) => {
  const ticket = (await request.json()) as ticket
  const qsAdminApi = useQsAdminApiManager.getState().service
  return await qsAdminApi.ticket(ticket.id).SendClosureNotice()
}) satisfies ActionFunction

export const sendContactNoticeAction = (async ({ request, params }: RouteFunctionParams<'ticketId'>) => {
  const ticket = (await request.json()) as ticket
  const qsAdminApi = useQsAdminApiManager.getState().service
  return await qsAdminApi.ticket(ticket.id).SendContactNotice()
}) satisfies ActionFunction

export const assignAction = (async ({ request, params }: RouteFunctionParams<'ticketId'>) => {
  const requestParams = (await request.json()) as {
    ticketId: string
    userId: number
  }
  const qsAdminApi = useQsAdminApiManager.getState().client
  return await qsAdminApi.post(
    'api/ticket/assign',
    {
      ticketId: requestParams.ticketId,
      userId: requestParams.userId,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}) satisfies ActionFunction

export const sendSupportExpiredNoticeAction = (async ({ request, params }: RouteFunctionParams<'ticketId'>) => {
  const ticket = (await request.json()) as ticket
  const qsAdminApi = useQsAdminApiManager.getState().service
  return await qsAdminApi.ticket(ticket.id).SendSupportExpiredNotice()
}) satisfies ActionFunction

export const sendOtherVendorNoticeAction = (async ({ request, params }: RouteFunctionParams<'ticketId'>) => {
  const ticket = (await request.json()) as ticket
  const qsAdminApi = useQsAdminApiManager.getState().service
  return await qsAdminApi.ticket(ticket.id).SendOtherVendorNotice()
}) satisfies ActionFunction

export const reopenAction = (async ({ request, params }: RouteFunctionParams<'ticketId'>) => {
  const ticket = (await request.json()) as ticket
  const qsAdminApi = useQsAdminApiManager.getState().service
  return await qsAdminApi.ticket(ticket.id).Reopen()
}) satisfies ActionFunction

const TicketEditorRoute = {
  path: ':ticketId',
  element: <TicketEditor creating={false} />,
  children: [
    {
      path: 'complete',
      action: completeAction,
      allowedRoles: [Roles.GlobalAdministrator, Roles.TechDeveloper],
    } as RoleRouteObject,
    {
      path: 'ticketnote',
      action: ticketNoteAction,
      allowedRoles: [Roles.GlobalAdministrator, Roles.TechDeveloper, Roles.Administrator],
    } as RoleRouteObject,
  ],
  loader: ticketEditorRouteLoader,
  action: ticketEditorRouteAction,
  allowedRoles: [Roles.Guest],
} as RoleRouteObject

export default TicketEditorRoute
