import DocumentHead from '@/components/document-head/DocumentHead'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import HourEditorForm from '@/routes/hours/HourEditor.form'
import { hourEditorRouteLoader } from '@/routes/hours/HourEditor.route'
import { HourEditorProps } from '@/routes/hours/HourEditor.types'
import { isUserRoleAllowed } from '@/routes/utils'
import { Suspense } from 'react'
import { useFetcher } from 'react-router-dom'
import { Await, useLoaderData } from 'react-router-typesafe'

const HourEditor = (props: HourEditorProps) => {
  const { creating } = props

  const {
    title,
    hour,
    client,
    tempoCliente,
    tempoTrasferta,
    tempoUfficio,
    getClients,
    getCentriCosto,
    getPiattaforme,
    getTasks,
    getEmployees,
    getUserInfo,
    defaultCRUDAllowedRoles,
  } = useLoaderData<typeof hourEditorRouteLoader>()

  const fetcher = useFetcher()
  return (
    <>
      <DocumentHead title={title}></DocumentHead>
      <Suspense fallback={<LoadingPanel />}>
        <Await resolve={Promise.all([getClients, getCentriCosto, getPiattaforme, getTasks, getEmployees, getUserInfo])}>
          {([clients, centriCosto, piattaforme, tasks, employees, userInfo]) => (
            <>
              <h2 className={'content-block'}>{title}</h2>
              <div id="editor-hour-container" className={'content-block dx-card responsive-paddings'}>
                <fetcher.Form>
                  <HourEditorForm
                    creating={creating}
                    hour={hour}
                    tempoUfficio={tempoUfficio}
                    tempoCliente={tempoCliente}
                    tempoTrasferta={tempoTrasferta}
                    client={client}
                    clients={clients.data.value}
                    centriCosto={centriCosto.data.value}
                    piattaforme={piattaforme.data.value}
                    tasks={tasks.data.value}
                    impiegati={employees.data.value}
                    userInfo={userInfo}
                    isRoleAllowed={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
                    fetcher={fetcher}
                  />
                </fetcher.Form>
              </div>
            </>
          )}
        </Await>
      </Suspense>
    </>
  )
}

export default HourEditor
