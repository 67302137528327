import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import { ArraySourceComposer } from '@/auth/api/connector'
import useTokenRefresh, { AzureUserInfo } from '@/auth/azure/azureManager'
import { Roles } from '@/auth/azure/Roles'
import { GridCellColor } from '@/enums'
import { commessa, ordine } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { isUserRoleAllowed } from '@/routes/utils'
import { useScreenSize } from '@/themes/media-query'
import { DataGrid } from 'devextreme-react'
import {
  Column,
  Paging,
  Button as GridButton,
  StateStoring,
  DataGridTypes,
  DataGridRef,
  Toolbar,
  Item,
} from 'devextreme-react/cjs/data-grid'
import DataSource from 'devextreme/data/data_source'
import ODataStore from 'devextreme/data/odata/store'
import { ClickEvent } from 'devextreme/ui/button'
import { CellPreparedEvent, ColumnButtonClickEvent, RowDblClickEvent } from 'devextreme/ui/data_grid'
import notify from 'devextreme/ui/notify'
import { memo, useRef } from 'react'
import { Link } from 'react-router-dom'

const ProjectEditorFormOrdersGrid = (props: { project: commessa; userInfo: AzureUserInfo | undefined }) => {
  const { project, userInfo } = props
  const currentScreenSize = useScreenSize()
  const gridRef = useRef<DataGridRef>(null)
  const token = useTokenRefresh()
  const onCellPrepared = (e: CellPreparedEvent) => {
    if (e.rowType === 'data' && e.column.dataField === 'stato.nome' && e.value) {
      switch (e.value) {
        case 'EVASO': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.EMERALD}`

          break
        }
        case 'DA EVADERE': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.SALMON}`

          break
        }
        case 'ANNULLATO': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.ORANGE}`
          break
        }
      }
    }
  }

  const ordersDataSource = new DataSource({
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/ordine`,
      key: 'id',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    filter: [['commessa.id', project.id]],
    expand: ['stato'],
  })

  return (
    <>
      <DataGrid
        id={`project-orders-grid`}
        ref={gridRef}
        dataSource={ordersDataSource}
        noDataText="Nessun ordine associato alla commessa"
        keyExpr={'id'}
        showBorders={true}
        showColumnLines={true}
        focusedRowEnabled={true}
        columnHidingEnabled={currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium}
        allowColumnResizing={true}
        rowAlternationEnabled={true}
        wordWrapEnabled={false}
        repaintChangesOnly={true}
        width="100%"
        onCellPrepared={onCellPrepared}
        onRowDblClick={(e: RowDblClickEvent<any, any>) => {
          if (e.rowType === 'data') window.open(`/orders/${e.data.id}`, '_blank')
        }}
      >
        <StateStoring enabled={true} type="localStorage" storageKey={'project-orders-datagrid'} savingTimeout={50} />
        <Paging defaultPageSize={8} />
        <Column
          type="buttons"
          width={currentScreenSize.isXSmall || currentScreenSize.isSmall ? '10%' : '2%'}
          alignment="left"
        >
          <GridButton
            hint="Details"
            icon="search"
            onClick={(e: ColumnButtonClickEvent) => {
              window.open(`/orders/${e.row?.data.id}`, '_blank')
            }}
          />
        </Column>
        <Column
          dataField={'id'}
          caption={'ID'}
          visible={false}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 1 : undefined
          }
        />
        ,
        <Column
          dataField={'ded_Dis'}
          caption={'CODICE'}
          cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
            const data: ordine = cellData.row.data
            return isUserRoleAllowed(userInfo?.roles, [Roles.Guest]) ? (
              <>
                <Link to={`/orders/${data.id}`}>{data.ded_Dis}</Link>
              </>
            ) : (
              <>{data.ded_Dis}</>
            )
          }}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 6 : undefined
          }
        />
        ,
        <Column
          dataField={'stato.nome'}
          caption={'STATO'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 3 : undefined
          }
        />
        <Column
          dataField={'creazione'}
          dataType="date"
          caption={'DATA'}
          format={'dd/MM/yyyy'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 5 : undefined
          }
        />
        ,
        <Column
          dataField={'importo'}
          caption={'IMPORTO'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 4 : undefined
          }
        />
        <Column
          dataField={'evasione'}
          dataType="date"
          caption={'DATA EVASIONE'}
          format={'dd/MM/yyyy'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 2 : undefined
          }
        />
        ,
        <Column
          dataField={'note'}
          caption={'NOTE'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 7 : undefined
          }
        />
        <Toolbar>
          <Item
            location="before"
            locateInMenu="never"
            showText="inMenu"
            widget="dxButton"
            options={{
              hint: 'Refresh',
              text: 'Refresh',
              icon: 'refresh',
              onClick: (e: ClickEvent) => {
                gridRef.current?.instance().refresh()
              },
              stylingMode: 'text',
            }}
          ></Item>
        </Toolbar>
      </DataGrid>
    </>
  )
}

const ProjectEditorFormOrdersGridMemoed = memo(ProjectEditorFormOrdersGrid, (oldProps, newProps) => {
  return oldProps.project === newProps.project
})

export default ProjectEditorFormOrdersGridMemoed
