import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { RoleRouteObject, RouteFunctionParams } from '@/types'
import { FormMode } from '@/enums'
import { LoaderFunction, Params, RouteObject, redirect } from 'react-router-dom'
import type { Editableest_licenza, est_licenza, azienda } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { defer, makeAction } from 'react-router-typesafe'
import { HttpResponseModel } from '@odata2ts/http-client-api'
import { ODataModelResponseV4 } from '@odata2ts/odata-core'
import { SoftwareAltrePiattaformeEditor } from '@/routes/software/altre-piattaforme/SoftwareAltrePiattaformeEditor'
import { Roles } from '@/auth/azure/Roles'
import { getAzureUserInformation } from '@/auth/azure/azureManager'

export const softwareAltrePiattaformeEditorRouteLoader = (async ({
  params,
}: RouteFunctionParams<'clientId' | 'swAltrePiattaformeId'>) => {
  const qsAdminApi = useQsAdminApiManager.getState().service
  const clientId = params.clientId

  let azienda: HttpResponseModel<ODataModelResponseV4<azienda>> | undefined
  if (clientId)
    azienda = await qsAdminApi.azienda(Number(clientId)).query((builder, azienda) => {
      builder.select('id', 'nome')
    })
  let title = azienda ? `${azienda.data.nome} - ` : ``
  title += params.swAltrePiattaformeId ? `Software Altri` : 'Nuova licenza'

  let getSwLicense: Editableest_licenza | Promise<HttpResponseModel<ODataModelResponseV4<est_licenza>>> = {
    altro_fornitore: false,
    rete: false,
    postazioni: 0,
    durata: 0,
  }
  if (params.swAltrePiattaformeId) {
    const currentSwId = +(params.swAltrePiattaformeId as string)
    if (Number.isNaN(currentSwId)) throw new Error('Invalid swAltrePiattaformeId')
    getSwLicense = qsAdminApi.est_licenza(currentSwId).query((builder, qqs_licenza) => {
      builder.expanding('sede', (sedeBuilder) => {
        sedeBuilder.expanding('azienda', (companyBuilder) => {
          companyBuilder.expand('sedi')
        })
      })
      builder.expand('software')
      builder.expand('piano_mantenimento')
      builder.expand('stato_noleggio')
    })

    if (clientId) {
      const swEstResp = await getSwLicense
      const swEst = swEstResp.data satisfies est_licenza
      if (swEst?.sede?.azienda && swEst?.sede?.azienda.id !== Number(clientId)) {
        throw new Error(
          `Il software ${swEst.n_contratto ?? swEst.seriale ?? swEst.id} appartiene al cliente ${swEst?.sede?.azienda.nome} e non al cliente corrente`,
        )
      }
    }
  }

  const getClients = qsAdminApi.azienda().query((builder, azienda) => {
    builder.orderBy(azienda.nome.asc())
    builder.select('id', 'nome')
    builder.filter(azienda.sedi.any())
    builder.filter(clientId ? azienda.id.eq(Number(clientId)) : null)
  })

  const getAltrePiattaformePlatforms = qsAdminApi.est_software().query((builder, software) => {
    builder.orderBy(software.nome.asc())
  })

  const getAltrePiattaformeMaintenancePlans = qsAdminApi.est_piano_mantenimento().query((builder, piano) => {
    builder.orderBy(piano.nome.asc())
  })

  const getAltrePiattaformeRentStatus = qsAdminApi.est_stato_noleggio().query((builder, stato) => {
    builder.orderBy(stato.nome.asc())
  })

  return defer({
    title,
    getSwLicense,
    getClients,
    getAltrePiattaformePlatforms,
    getAltrePiattaformeMaintenancePlans,
    getAltrePiattaformeRentStatus,
    getUserInfo: getAzureUserInformation(),
    defaultCRUDAllowedRoles: [Roles.GlobalAdministrator, Roles.Administrator, Roles.Supervisor],
  })
}) satisfies LoaderFunction

export const SoftwareAltrePiattaformeEditorRoute = {
  path: ':swAltrePiattaformeId',
  element: <SoftwareAltrePiattaformeEditor mode={FormMode.Edit} />,
  loader: softwareAltrePiattaformeEditorRouteLoader,
  action: makeAction(async ({ request, params }) => {
    const swAltrePiattaformeData = (await request.json()) as est_licenza
    const res = await useQsAdminApiManager
      .getState()
      .service.est_licenza(swAltrePiattaformeData.id)
      .patch(swAltrePiattaformeData)
    return null
  }),
  allowedRoles: [Roles.Guest],
}

export const SoftwareAltrePiattaformeCreatorRoute = {
  path: 'new',
  element: <SoftwareAltrePiattaformeEditor mode={FormMode.Create} />,
  loader: softwareAltrePiattaformeEditorRouteLoader,
  index: true,
  action: makeAction(async ({ request, params }: RouteFunctionParams) => {
    const swAltrePiattaformeData = (await request.json()) as est_licenza
    const res = await useQsAdminApiManager.getState().service.est_licenza().create(swAltrePiattaformeData)
    return redirect(`../${res.data.id}`)
  }),
  allowedRoles: [Roles.Guest],
} as RoleRouteObject
