import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { task } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { toolbarHtmlEditor } from '@/routes/utils'
import { useScreenSize } from '@/themes/media-query'
import { Popup } from 'devextreme-react'
import { PopupRef } from 'devextreme-react/cjs/popup'
import {
  ButtonItem,
  ButtonOptions,
  GroupItem,
  SimpleItem,
  Form as DXForm,
  FormRef,
  RequiredRule,
} from 'devextreme-react/form'
import { ClickEvent } from 'devextreme/ui/button'
import React, { useRef } from 'react'
import { FetcherWithComponents } from 'react-router-dom'

const FormPopupClientNote = (props: {
  task: task | null | undefined
  refPopup: React.RefObject<PopupRef>
  fetcher?: FetcherWithComponents<any>
}) => {
  const currentScreenSize = useScreenSize()
  const { task, refPopup, fetcher } = props
  const { client } = useQsAdminApiManager()
  const formRef = useRef<FormRef>(null)

  return (
    <>
      <Popup
        ref={refPopup}
        id="complete_task_popup"
        dragEnabled={false}
        hideOnOutsideClick={false}
        showCloseButton={true}
        showTitle={true}
        title={`Completamento Task`}
        container=".dx-viewport"
        resizeEnabled={true}
        width={currentScreenSize.isLarge || currentScreenSize.isMedium ? 800 : currentScreenSize.isXSmall ? 350 : 700}
        height={currentScreenSize.isSmall ? 300 : 550}
      >
        <DXForm
          id={'complete_task_form'}
          formData={task}
          ref={formRef}
          labelLocation={'top'}
          colCount={1}
          showValidationSummary={true}
          validationGroup="CompleteTaskValidationGroup"
        >
          <GroupItem colCount={1}>
            <SimpleItem
              colSpan={1}
              dataField="note_completamento"
              label={{ text: 'Note Completamento' }}
              editorType={'dxHtmlEditor'}
              editorOptions={{
                stylingMode: 'filled',
                valueType: 'html',
                toolbar: toolbarHtmlEditor(currentScreenSize),
                mediaResizing: {
                  enabled: true,
                },
                imageUpload: {
                  tabs: ['file', 'url'],
                  fileUploadMode: 'base64',
                },
                height: '300',
              }}
            >
              <RequiredRule message="Note completamento obbligatorie"></RequiredRule>
            </SimpleItem>
          </GroupItem>
          <GroupItem visible={true} cssClass="last-group">
            <GroupItem cssClass="buttons-group" colCount={1}>
              <ButtonItem name="btn-save">
                <ButtonOptions
                  icon="check"
                  text="Completa"
                  width="150px"
                  onClick={async (e: ClickEvent) => {
                    const validationResult = formRef.current?.instance().validate()
                    if (!validationResult?.isValid) return
                    fetcher?.submit(JSON.stringify({ taskId: task?.ded_Dis, note: task?.note_completamento }), {
                      method: 'POST',
                      action: `/tasks/${task?.id}/complete`,
                      encType: 'application/json',
                    })
                    refPopup.current?.instance().hide()
                  }}
                />
              </ButtonItem>
            </GroupItem>
          </GroupItem>
        </DXForm>
      </Popup>
    </>
  )
}

export default FormPopupClientNote
