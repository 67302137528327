import './TestComponent.scss'
import Rating from '@/components/Rating/Rating'
import { PhoneNumber } from '@/components/PhoneNumber/PhoneNumber'

const TestComponent = () => {
  console.log(import.meta.env)
  return (
    <>
      <Rating value={2} />
      <PhoneNumber phoneNumber={'1234567890'} showCountryCode={true} countryCode={'+1'} />
    </>
  )
}

export default TestComponent
