import { ArraySourceComposer } from '@/auth/api/connector'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { azienda, sede } from '@/model/qsadminapi/QsAdminApiModuleModel'
import {
  ButtonItem,
  ButtonOptions,
  Form as DXForm,
  EmptyItem,
  GroupItem,
  RequiredRule,
  SimpleItem,
  Tab,
  TabbedItem,
  TabPanelOptions,
  FormRef,
} from 'devextreme-react/form'
import DataSource from 'devextreme/data/data_source'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useRevalidator } from 'react-router-dom'
import { FieldDataChangedEvent } from 'devextreme/ui/form'
import { ProjectEditorFormProps } from '@/routes/projects/ProjectEditor.types'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import SiteSimpleItem from '@/components/site-simple-item/SiteSimpleItem'
import notify from 'devextreme/ui/notify'
import { PopupRef } from 'devextreme-react/cjs/popup'
import FormPopupClientNote from '@/components/form-popup-client-note/FormPopupClientNote'
import { customButton, isUserRoleAllowed, toolbarHtmlEditor } from '@/routes/utils'
import { Roles } from '@/auth/azure/Roles'
import { ValueChangedEvent as ValueChangedEventSelectBox } from 'devextreme/ui/select_box'
import { useScreenSize } from '@/themes/media-query'
import { ClickEvent } from 'devextreme/ui/button'
import { DropDownButton } from 'devextreme-react'
import ProjectEditorFormActivitiesGridMemoed from '@/routes/projects/ProjectEditor.form.activities'
import ProjectEditorFormAnalysisGridMemoed from '@/routes/projects/ProjectEditor.form.analysis'
import ProjectEditorFormOffersGridMemoed from '@/routes/projects/ProjectEditor.form.offers'
import ProjectEditorFormQuotesGridMemoed from '@/routes/projects/ProjectEditor.form.quotes'
import ProjectEditorFormOrdersGridMemoed from '@/routes/projects/ProjectEditor.form.orders'
import ProjectEditorFormTasksGridMemoed from '@/routes/projects/ProjectEditor.form.tasks'
import { StatoCommessa } from '@/routes/projects/ProjectEditor.enums'

const ProjectEditorForm = (props: ProjectEditorFormProps) => {
  const { creating, project, stati, aziende, impiegati, isRoleAllowed, userInfo, fetcher } = props

  const { clientId } = useParams()
  const navigate = useNavigate()
  const qsAdminApi = useQsAdminApiManager.getState().service
  const formRef = useRef<FormRef>(null)
  const revalidator = useRevalidator()
  const [currentSite, setCurrentSite] = useState<sede | null | undefined>()
  const popupNoteClienteRef = useRef<PopupRef>(null)
  const currentScreenSize = useScreenSize()

  const [currentCompany, setCurrentCompany] = useState<azienda | null | undefined>(
    clientId ? (aziende ? aziende[0] : undefined) : project && 'sede' in project ? project?.sede?.azienda : undefined,
  )
  const [currentCompanyChanged, setCurrentCompanyChanged] = useState<boolean>(clientId && creating ? true : false)
  const [sediDataSource, setSediDataSource] = useState<DataSource>(
    ArraySourceComposer('id', project && 'sede' in project ? project?.sede?.azienda?.sedi : undefined),
  )

  const [loadPanelVisible, setLoadPanelVisible] = useState(false)

  const getCompanySites = useCallback(
    async (companyId: number) => {
      const sitesData = await qsAdminApi.sede().query((builder, sede) => {
        builder.filter(sede.azienda.props.id.eq(companyId))
        builder.orderBy(sede.principale.desc())
        builder.expanding('azienda', (aziendaSedeBuilder, azienda) => {
          aziendaSedeBuilder.expanding('sedi', (sediAziendaSedeBuilder, sedeAzienda) => {
            sediAziendaSedeBuilder.select('id', 'nome', 'note')
            sediAziendaSedeBuilder.orderBy(sedeAzienda.principale.desc())
          })
          aziendaSedeBuilder.select('id', 'nome', 'sedi')
        })
        builder.select('id', 'nome', 'azienda', 'note')
      })
      return sitesData.data.value
    },
    [qsAdminApi],
  )

  useEffect(() => {
    if (!currentCompanyChanged) return
    console.log('COMPANY EFFECT', currentCompany)
    if (currentCompany) {
      getCompanySites(currentCompany?.id).then((sites: sede[]) => {
        console.log('SITES', sites)
        setSediDataSource(ArraySourceComposer('id', sites))
        formRef.current?.instance().updateData('sede', sites[0])
      })
      setCurrentCompanyChanged(false)
    }
  }, [currentCompanyChanged, currentCompany, getCompanySites])

  return (
    <>
      <LoadingPanel visible={!(fetcher?.state === 'idle' && revalidator.state === 'idle' && !loadPanelVisible)} />
      <DXForm
        id={'project_form'}
        formData={project}
        labelLocation={'top'}
        colCount={1}
        showValidationSummary={true}
        onFieldDataChanged={(e: FieldDataChangedEvent) => {
          if (
            formRef.current?.instance().option().isDirty !== null &&
            formRef.current?.instance().option().isDirty !== undefined
          ) {
            console.log('is dirty', formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-save')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-cancel')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
          }
        }}
        validationGroup="projectValidation"
        ref={formRef}
        readOnly={!isRoleAllowed}
      >
        <GroupItem colCount={5}>
          <GroupItem colCount={2} colSpan={2}>
            <SimpleItem
              label={{ text: 'Azienda' }}
              editorType="dxSelectBox"
              editorOptions={{
                buttons: [
                  customButton('open', (e: ClickEvent) => {
                    if (currentCompany) window.open(`/clients/${currentCompany.id}`, '_blank')
                    else notify(`Deve essere selezionata un' azienda per poterla aprire.`, 'warning', 3000)
                  }),
                  { name: 'dropDown', location: 'after' },
                ],
                value: currentCompany,
                dataSource: ArraySourceComposer('id', aziende),
                placeholder: "Seleziona l'azienda...",
                displayExpr: 'nome',
                searchEnabled: true,
                readOnly: !isRoleAllowed || (clientId ? true : false),
                dropDownOptions: {
                  showTitle: true,
                  title: "Selezionare l'azienda",
                  hideOnOutsideClick: true,
                },
                onValueChanged: (e: ValueChangedEventSelectBox) => {
                  if (e.previousValue?.id === e.value?.id) return
                  console.log('COMPANY EVENT CHANGED', e.previousValue, e.value)
                  setCurrentCompany(e.value)
                  setCurrentCompanyChanged(true)
                },
              }}
            >
              <RequiredRule message="Azienda obbligatoria"></RequiredRule>
            </SimpleItem>
            {SiteSimpleItem({
              dataSource: sediDataSource,
              rules: [
                {
                  type: 'required',
                  message: `Sede obbligatoria`,
                },
              ],
              readOnly: !isRoleAllowed,
              onInfoClick: () => {
                if (project.sede) {
                  setCurrentSite(project.sede)
                  popupNoteClienteRef.current?.instance().show()
                } else notify('Deve essere selezionata una sede per aprire le note.', 'warning', 3000)
              },
            })}
            <SimpleItem
              dataField="autore"
              label={{ text: 'Autore' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', impiegati),
                placeholder: 'Seleziona autore...',
                displayExpr: 'fullname',
                searchEnabled: true,
                dropDownCentered: true,
                readOnly: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare autore',
                  hideOnOutsideClick: true,
                },
              }}
            >
              <RequiredRule message="Autore obbligatorio" />
            </SimpleItem>
            <SimpleItem
              dataField="creazione"
              editorOptions={{
                displayFormat: 'dd/MM/yyyy',
                readOnly: true,
              }}
              editorType="dxDateBox"
              label={{ text: 'Data Creazione' }}
            >
              <RequiredRule message="Data creazione obbligatoria" />
            </SimpleItem>
            <SimpleItem
              dataField="inizio"
              editorOptions={{
                displayFormat: 'dd/MM/yyyy',
                pickerType: 'rollers',
              }}
              editorType="dxDateBox"
              label={{ text: 'Data inizio' }}
            >
              <RequiredRule message="Data inizio obbligatoria"></RequiredRule>
            </SimpleItem>
            <SimpleItem
              dataField="fine"
              editorOptions={{
                displayFormat: 'dd/MM/yyyy',
                pickerType: 'rollers',
                showClearButton: true,
              }}
              editorType="dxDateBox"
              label={{ text: 'Data fine' }}
            ></SimpleItem>
            <SimpleItem
              dataField="stato"
              label={{ text: 'Stato' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', stati),
                placeholder: 'Seleziona lo stato...',
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare lo stato',
                  hideOnOutsideClick: true,
                },
                readOnly: !isRoleAllowed || creating,
              }}
            >
              <RequiredRule message="Stato obbligatorio"></RequiredRule>
            </SimpleItem>
            <EmptyItem></EmptyItem>
            <SimpleItem colSpan={2} dataField={'titolo'} editorType={'dxTextArea'}>
              <RequiredRule message="Titolo obbligatorio" />
            </SimpleItem>
          </GroupItem>
          <GroupItem colSpan={3}>
            <TabbedItem>
              <TabPanelOptions
                animationEnabled={true}
                swipeEnabled={false}
                deferRendering={false}
                scrollingEnabled={true}
              />
              <Tab title="Note">
                <SimpleItem
                  dataField={'note'}
                  editorType={'dxHtmlEditor'}
                  label={{ visible: false }}
                  editorOptions={{
                    stylingMode: 'filled',
                    valueType: 'html',
                    toolbar: toolbarHtmlEditor(currentScreenSize),
                    mediaResizing: {
                      enabled: true,
                    },
                    imageUpload: {
                      tabs: ['file', 'url'],
                      fileUploadMode: 'base64',
                    },
                    height: '325',
                  }}
                >
                  <RequiredRule message="Note obbligatorie"></RequiredRule>
                </SimpleItem>
              </Tab>
              {isUserRoleAllowed(userInfo?.roles, [
                Roles.GlobalAdministrator,
                Roles.Administrator,
                Roles.Sales,
                Roles.ExternalSales,
                Roles.Marketing,
                Roles.Supervisor,
              ]) && (
                <Tab title="Attività Commerciali">
                  <ProjectEditorFormActivitiesGridMemoed
                    project={project}
                    userInfo={userInfo}
                  ></ProjectEditorFormActivitiesGridMemoed>
                </Tab>
              )}
              {isUserRoleAllowed(userInfo?.roles, [
                Roles.GlobalAdministrator,
                Roles.Administrator,
                Roles.Sales,
                Roles.ExternalSales,
                Roles.Marketing,
                Roles.TechDeveloper,
                Roles.Supervisor,
              ]) && (
                <Tab title="Analisi">
                  <ProjectEditorFormAnalysisGridMemoed
                    project={project}
                    userInfo={userInfo}
                  ></ProjectEditorFormAnalysisGridMemoed>
                </Tab>
              )}
              {isUserRoleAllowed(userInfo?.roles, [
                Roles.GlobalAdministrator,
                Roles.Administrator,
                Roles.Sales,
                Roles.ExternalSales,
                Roles.Marketing,
                Roles.Supervisor,
              ]) && (
                <Tab title="Offerte">
                  <ProjectEditorFormOffersGridMemoed
                    project={project}
                    userInfo={userInfo}
                  ></ProjectEditorFormOffersGridMemoed>
                </Tab>
              )}
              {isUserRoleAllowed(userInfo?.roles, [
                Roles.GlobalAdministrator,
                Roles.Administrator,
                Roles.Sales,
                Roles.ExternalSales,
                Roles.Marketing,
                Roles.Supervisor,
              ]) && (
                <Tab title="Quote">
                  <ProjectEditorFormQuotesGridMemoed
                    project={project}
                    userInfo={userInfo}
                  ></ProjectEditorFormQuotesGridMemoed>
                </Tab>
              )}
              {isUserRoleAllowed(userInfo?.roles, [Roles.Guest]) && (
                <Tab title="Ordini">
                  <ProjectEditorFormOrdersGridMemoed
                    project={project}
                    userInfo={userInfo}
                  ></ProjectEditorFormOrdersGridMemoed>
                </Tab>
              )}
              {isUserRoleAllowed(userInfo?.roles, [Roles.Guest]) && (
                <Tab title="Task">
                  <ProjectEditorFormTasksGridMemoed
                    project={project}
                    userInfo={userInfo}
                  ></ProjectEditorFormTasksGridMemoed>
                </Tab>
              )}
            </TabbedItem>
          </GroupItem>
        </GroupItem>
        <GroupItem visible={isRoleAllowed} cssClass="last-group">
          <GroupItem cssClass="buttons-group" colCount={creating ? 2 : 3}>
            <SimpleItem>
              <DropDownButton
                id="ddbAzioni-project"
                text="Crea"
                useItemTextAsTitle={false}
                items={[
                  {
                    icon: 'bookmark',
                    text: 'Attività commerciale',
                    onClick: async (e: ClickEvent) => {
                      if (project?.stato?.id === StatoCommessa.APERTA)
                        if (clientId)
                          navigate({
                            pathname: `/clients/${clientId}/activities/new`,
                            search: `?projectId=${project.id}`,
                          })
                        else
                          navigate({
                            pathname: '/activities/new',
                            search: `?projectId=${project.id}`,
                          })
                      else
                        notify(
                          `La commessa deve essere nello stato 'APERTA'. Stato: '${project.stato?.nome}'`,
                          'warning',
                          3000,
                        )
                    },
                  },
                  {
                    icon: 'money',
                    text: 'Offerta',
                    onClick: async (e: ClickEvent) => {
                      if (project?.stato?.id === StatoCommessa.APERTA)
                        if (clientId)
                          navigate({
                            pathname: `/clients/${clientId}/offers/new`,
                            search: `?projectId=${project.id}`,
                          })
                        else
                          navigate({
                            pathname: `/offers/new`,
                            search: `?projectId=${project.id}`,
                          })
                      else
                        notify(
                          `La commessa deve essere nello stato 'APERTA'. Stato: '${project.stato?.nome}'`,
                          'warning',
                          3000,
                        )
                    },
                  },
                  {
                    icon: 'money',
                    text: 'Quota',
                    onClick: async (e: ClickEvent) => {
                      if (project?.stato?.id === StatoCommessa.APERTA)
                        if (clientId)
                          navigate({
                            pathname: `/clients/${clientId}/quotes/new`,
                            search: `?projectId=${project.id}`,
                          })
                        else
                          navigate({
                            pathname: '/quotes/new',
                            search: `?projectId=${project.id}`,
                          })
                      else
                        notify(
                          `La commessa deve essere nello stato 'APERTA'. Stato: '${project.stato?.nome}'`,
                          'warning',
                          3000,
                        )
                    },
                  },
                ]}
                dropDownOptions={{ width: '250' }}
                width={'150px'}
              />
            </SimpleItem>
            <ButtonItem name="btn-save">
              <ButtonOptions
                disabled={!formRef.current?.instance().option().isDirty}
                icon="save"
                text="Salva"
                width="150px"
                onClick={async () => {
                  const validationResult = formRef.current?.instance().validate()
                  if (!validationResult?.isValid) return
                  console.log('validation ok')
                  console.log('project', project)
                  if (project) {
                    fetcher?.submit(JSON.stringify(project), {
                      method: creating ? 'POST' : 'PUT',
                      encType: 'application/json',
                    })
                  } else console.error('no data tu submit')
                }}
              />
            </ButtonItem>
            {!creating && (
              <ButtonItem name="btn-cancel">
                <ButtonOptions
                  disabled={!formRef.current?.instance().option().isDirty}
                  width="150px"
                  text="Annulla"
                  icon="undo"
                  onClick={() => {
                    revalidator.revalidate()
                  }}
                />
              </ButtonItem>
            )}
          </GroupItem>
        </GroupItem>
      </DXForm>
      <FormPopupClientNote
        data={currentSite}
        refPopup={popupNoteClienteRef}
        refreshDataSourceSites={() => {
          if (currentCompany) {
            getCompanySites(currentCompany?.id).then((sites: sede[]) => {
              console.log('SITES', sites)
              setSediDataSource(ArraySourceComposer('id', sites))
              formRef.current?.instance().updateData(
                'sede',
                sites.find((site: sede) => {
                  return site.id === currentSite?.id
                }),
              )
            })
          }
        }}
        isRoleAllowed={isUserRoleAllowed(userInfo?.roles, [
          Roles.TechDeveloper,
          Roles.Sales,
          Roles.GlobalAdministrator,
          Roles.ExternalSales,
          Roles.Administrator,
        ])}
      ></FormPopupClientNote>
    </>
  )
}

export default ProjectEditorForm
