import { ClientSite } from './ClientSite'
import { useLoaderData, Await } from 'react-router-typesafe'
import './ClientSitesEditor.scss'
import { Editablesede, sede } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { Button } from 'devextreme-react/button'
import { useState, Suspense, useEffect, useCallback, useRef } from 'react'
import { clientSiteEditorRouteLoader } from '@/routes/clients/client/sites/ClientSitesEditor.route'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { ResponsiveBox, Toolbar } from 'devextreme-react'
import { Item as ToolbarItem } from 'devextreme-react/toolbar'
import { Row, Col, Item as ResponsiveItem, Location } from 'devextreme-react/responsive-box'
import { Outlet, useFetcher } from 'react-router-dom'
import { ValidationResult } from 'devextreme/ui/validation_group'
import notify from 'devextreme/ui/notify'
import { isUserRoleAllowed } from '@/routes/utils'
import { Role, Roles } from '@/auth/azure/Roles'
import DocumentHead from '@/components/document-head/DocumentHead'

export type IClientEditorItemRef = {
  validate: () => ValidationResult | undefined
  submit: () => boolean
}

const ClientSitesEditor = () => {
  const { azienda, getSedi, userInfo, defaultCRUDAllowedRoles } = useLoaderData<typeof clientSiteEditorRouteLoader>()
  const [sitesConfigurationDirty, setSitesConfigurationDirty] = useState(false)
  const fetcher = useFetcher()
  const [sites, setSites] = useState<(sede | Editablesede | undefined)[] | undefined>(getSedi.data.value)
  const rowsCount = Math.ceil(((sites ? sites.length : 0) + 1) / 2)
  const rows = Array.from({ length: rowsCount }, (_, i) => <Row key={i} />)
  const cols = [<Col key={0} />, <Col key={1} />]
  const sitesEditorRef = useRef<{ [key: number]: IClientEditorItemRef }>({})
  const title = `${azienda.data.nome} - Sedi`

  const reloadSites = useCallback(() => {
    try {
      console.log('REFETCH')
      fetcher.load(location.pathname)
    } catch (error) {
      console.error('Failed to reload sites:', error)
    }
  }, [fetcher])

  // UseEffect to update sites state when fetcher.data changes
  useEffect(() => {
    console.log('FETCHER EFFECT', fetcher.data)
    if (fetcher.data) {
      const fetchedSites: sede[] = fetcher.data.getSedi.data.value
      if (fetchedSites.length >= (sites?.length ?? 0)) {
        console.log('FETCHER DATA CHANGGED', fetcher.data.getSedi.data.value)
        setSites(fetcher.data.getSedi.data.value)
      }
    }
  }, [fetcher.data, sites?.length])

  return (
    <>
      <DocumentHead title={title}></DocumentHead>
      <div>
        <Toolbar visible={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)} className={'siteToolbar'}>
          <ToolbarItem location="after" locateInMenu="never">
            <Button
              id={'saveButton'}
              text={'Salva Configurazione'}
              type={'default'}
              icon={'save'}
              disabled={!sitesConfigurationDirty}
              onClick={() => {
                let validationResult: ValidationResult | undefined
                sites?.map((site, index) => {
                  validationResult = sitesEditorRef.current[index].validate()
                  console.log('VR', site, validationResult)
                  if (!validationResult?.isValid) return
                })
                if (!validationResult?.isValid) return
                let success = true
                sites?.map((site, index) => {
                  success = success && sitesEditorRef.current[index].submit()
                  console.log('SUBMIT', site)
                })
                if (success) {
                  notify('Salvataggio configurazione sedi completato con successo', 'success', 3000)
                  reloadSites()
                }
                setSitesConfigurationDirty(false)
              }}
            />
          </ToolbarItem>
        </Toolbar>
        <ResponsiveBox singleColumnScreen="xs sm md" className="cards-layout">
          {rows}
          {cols}
          {sites?.map((site, idx) => {
            console.log('SITE MAPPED', site)
            // Creo una chiave unica, altrimenti nel passaggio da editableSede a sede viene renderizzato male
            let objKey = `${idx}-tempSite`
            if (site && 'id' in site) {
              objKey = `${site.id}-${site.nome}`
            }
            return (
              <ResponsiveItem key={objKey}>
                <Location row={Math.floor(idx / 2)} col={idx % 2} />
                <ClientSite
                  key={objKey}
                  removeSelf={() => {
                    setSites((prev) => prev?.filter((s) => s !== site))
                  }}
                  onDirty={() => {
                    console.log('FETCH DIRTY')
                    setSitesConfigurationDirty(true)
                  }}
                  ref={(clientEditorItemRef: IClientEditorItemRef) => {
                    sitesEditorRef.current[idx] = clientEditorItemRef
                  }}
                  site={site}
                  className={'card'}
                  userInfo={userInfo}
                  readOnly={!isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
                  writeInfo={isUserRoleAllowed(userInfo?.roles, [Roles.TechDeveloper])}
                />
              </ResponsiveItem>
            )
          })}
          <ResponsiveItem key={'sitebtn'}>
            <Location row={Math.floor((sites ? sites.length : 0) / 2)} col={(sites ? sites.length : 0) % 2} />
            <div className={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles) ? 'card' : ''}>
              <Button
                visible={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
                id={'createButton'}
                type={'default'}
                icon={'plus'}
                stylingMode={'text'}
                onClick={() => {
                  setSites((prev) =>
                    prev?.concat({
                      principale: prev.length === 0,
                      azienda: azienda.data,
                    }),
                  )
                  setSitesConfigurationDirty(true)
                }}
              />
            </div>
          </ResponsiveItem>
        </ResponsiveBox>
      </div>
      <Outlet />
    </>
  )
}

export default ClientSitesEditor
