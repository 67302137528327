import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { Button, DropDownButton } from 'devextreme-react'
import { DropDownButtonTypes } from 'devextreme-react/cjs/drop-down-button'
import { Suspense, useCallback, useEffect, useRef, useState } from 'react'
import { Await, useLoaderData } from 'react-router-typesafe'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { QuoteRequest, QuoteEditorProps } from '@/routes/quotes/QuoteEditor.types'
import {
  ButtonItem,
  ButtonOptions,
  CustomRule,
  Form as DXForm,
  FormRef,
  GroupItem,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form'
import { useFetcher, useParams } from 'react-router-dom'
import {
  ad_csn,
  ad_product_catalog,
  ad_promotion,
  ad_quote,
  commessa,
  contatto_aziendale,
  entita_aziendale,
  sede,
  user,
} from '@/model/qsadminapi/QsAdminApiModuleModel'
import DataSource from 'devextreme/data/data_source'
import { ArraySourceComposer } from '@/auth/api/connector'
import { ValueChangedEvent } from 'devextreme/ui/lookup'
import { ILineItemRef, LineItemModel } from '@/routes/quotes/line-items/LineItem.types'
import { quoteEditorRouteLoader } from '@/routes/quotes/QuoteEditor.route'
import { ValidationResult } from 'devextreme/ui/validation_group'
import notify from 'devextreme/ui/notify'
import { ClickEvent } from 'devextreme/ui/button'
import { confirm } from 'devextreme/ui/dialog'
import { CreateItemModel, CreateQuoteRequest, TransformGetQuoteDetails } from '@/routes/quotes/utils'
import LineItemNew from '@/routes/quotes/line-items/LineItemNew'
import LineItemRenewal from '@/routes/quotes/line-items/LineItemRenewal'
import LineItemSwitch from '@/routes/quotes/line-items/LineItemSwitch'
import LineItemExtension from '@/routes/quotes/line-items/LineItemExtension'
import LineItemTrueup from '@/routes/quotes/line-items/LineItemTrueup'
import LineItemCoterm from '@/routes/quotes/line-items/LineItemCoterm'
import FormPopupProject from '@/components/form-popup-project/FormPopupProject'
import { Pricing } from '@/routes/quotes/pws/GetQuoteDetails.type'
import { AxiosError, AxiosResponse, isAxiosError } from 'axios'
import ProjectSimpleItem from '@/components/project-simple-item/ProjectSimpleItem'
import { StatoCommessa } from '@/routes/projects/ProjectEditor.enums'
import Popup, { PopupRef } from 'devextreme-react/cjs/popup'
import ContactEditorForm from '@/routes/contacts/ContactEditor.form'
import { isIPerson, onEmailsCreate } from '@/routes/contacts/utils'
import { customButton, isUserRoleAllowed } from '@/routes/utils'
import { ValueChangedEvent as ValueChangedEventSelectBox } from 'devextreme/ui/select_box'
import { useScreenSize } from '@/themes/media-query'
import { ValidationCallbackData } from 'devextreme/common'
import LineItemSwitchTerm from '@/routes/quotes/line-items/LineItemSwitchTerm'
import DocumentHead from '@/components/document-head/DocumentHead'

const QuoteEditor = (props: QuoteEditorProps) => {
  const { creating } = props
  const { clientId } = useParams()
  const {
    title,
    actions,
    quote,
    contacts,
    csns,
    commesse,
    quoteDetailsObjectWebAdmin,
    clients,
    productsCatalog,
    promotions,
    getRapporti,
    getImpiegati,
    userInfo,
    defaultCRUDAllowedRoles,
  } = useLoaderData<typeof quoteEditorRouteLoader>()

  const fetcher = useFetcher()
  const currentScreenSize = useScreenSize()
  const { service, client } = useQsAdminApiManager()

  const quoteFormRef = useRef<FormRef>(null)
  const lineItemsRef = useRef<{ [key: number]: ILineItemRef }>({})

  const [lineItems, setLineItems] = useState<LineItemModel[]>(
    quoteDetailsObjectWebAdmin ? quoteDetailsObjectWebAdmin.lineItemsModel : [],
  )
  const [quoteFormData, setQuoteFormData] = useState<ad_quote>(quote)
  const [csnsList, setCsnsList] = useState<ad_csn[]>(csns ? csns.data.value : [])
  const [contattiDataSource, setContattiDataSource] = useState<DataSource>(
    ArraySourceComposer('id', contacts ? contacts.data.value : []),
  )

  const [commesseDataSource, setCommesseDataSource] = useState<DataSource>(
    ArraySourceComposer('ded_Dis', commesse ? commesse.data.value : []),
  )
  const [sedi, setSedi] = useState<sede[] | undefined>(!creating || clientId ? quote.azienda?.sedi : [])
  const [indexItems, setIndexItems] = useState<number>(
    creating ? 0 : Math.max(...lineItems.map((o) => o.lineNumber + 1)),
  )

  const [pricing, setPricing] = useState<Pricing | null>(
    quoteDetailsObjectWebAdmin ? quoteDetailsObjectWebAdmin.pricing : null,
  )

  const [number, setNumber] = useState<string | null>(quote.number ?? quote.transactionId)

  const [status, setStatus] = useState<string>(quote.status)

  const [author, setAuthor] = useState<user | null | undefined>(quote.author)

  const [failedError, setFailedError] = useState<string | null>(null)

  const [loadPanelVisible, setLoadPanelVisible] = useState(false)

  const [products, setProducts] = useState<ad_product_catalog[]>([
    ...new Map(
      productsCatalog.data.value.map((product: ad_product_catalog) => [product['offeringCode'], product]),
    ).values(),
  ])
  const [terms, setTerms] = useState<ad_product_catalog[]>(
    [
      ...new Map(
        productsCatalog.data.value.map((product: ad_product_catalog) => [product['termCode'], product]),
      ).values(),
    ].sort((productA: ad_product_catalog, productB: ad_product_catalog) => {
      return productA.termCode && productB.termCode ? productA.termCode.localeCompare(productB.termCode) : 1
    }),
  )
  const [promos, setPromos] = useState<ad_promotion[]>([
    ...new Map(promotions.data.value.map((promotion: ad_promotion) => [promotion['code'], promotion])).values(),
  ])

  const refNuovoContattoPopup = useRef<PopupRef>(null)

  const contact: contatto_aziendale = {
    id: 0,
    fullname: null,
    ad_recipient: false,
    nome: null,
    cognome: null,
    ruolo: null,
    note: null,
    titolo: null,
    telefono: null,
    attivo: true,
    sede: null,
    emails: [
      {
        id: 0,
        email: null,
        note: null,
        rif_ad: false,
      },
    ],
  }

  const getCsns = useCallback(
    async (companyId: number) => {
      const csnsData = await service.ad_csn().query((builder, csn) => {
        builder.expand('admin')
        builder.expand('quoteContact')
        builder.filter(csn.azienda.props.id.eq(companyId))
        builder.orderBy(csn.csn.asc())
      })
      return csnsData.data.value
    },
    [service],
  )
  const getContactsCompany = useCallback(
    async (companyId: number) => {
      const contactsData = await service.contatto_aziendale().query((builder, contatto) => {
        builder.filter(contatto.sede.props.azienda.props.id.eq(companyId))
        builder.filter(contatto.emails.any())
        builder.filter(contatto.attivo.eq(true))
        builder.expanding('emails', (emailsBuilder, email) => {
          emailsBuilder.select('id', 'email')
        })
        builder.select('id', 'fullname', 'ad_recipient', 'emails')
        builder.orderBy(contatto.fullname.asc())
      })
      return contactsData.data.value
    },
    [service],
  )
  const getCommesse = useCallback(
    async (companyId: number | undefined) => {
      const commesseData = await service.commessa().query((builder, commessa) => {
        builder
          .filter(commessa.sede.props.azienda.props.id.eq(Number(companyId)))
          .filter(commessa.stato.props.id.eq(StatoCommessa.APERTA))
        builder.select('id', 'ded_Dis', 'titolo')
        builder.orderBy(commessa.ded_Dis.desc())
      })
      return commesseData.data.value
    },
    [service],
  )
  const getCompanySites = useCallback(
    async (companyId: number) => {
      const sitesData = await service.sede().query((builder, sede) => {
        builder.filter(sede.azienda.props.id.eq(companyId))
        builder.orderBy(sede.principale.desc())
        builder.select('id', 'nome', 'note', 'stato')
      })
      return sitesData.data.value
    },
    [service],
  )

  //commessa form popup
  const popupRefProject = useRef<PopupRef>(null)

  const onContactCreate = async (newdata: contatto_aziendale | entita_aziendale): Promise<boolean> => {
    console.log('SAVING NEWDATA', newdata)
    if (isIPerson(newdata)) {
      await onEmailsCreate(newdata)
      console.log('CONTACT UPDATE BEFORE', newdata)

      await service
        .contatto_aziendale()
        .create(newdata)
        .then(async (result) => {
          notify(`Contatto creato con successo`, 'success', 2000)
          refNuovoContattoPopup.current?.instance().hide()
        })
        .catch((error) => {
          notify(error, 'error', 2000)
        })

      getContactsCompany(Number(quoteFormData.azienda?.id)).then((contacts: contatto_aziendale[]) => {
        console.log('contacts', contacts)
        setContattiDataSource(ArraySourceComposer('id', contacts))
      })
    }
    return true
  }

  const onContactReset = async (contactdata: contatto_aziendale | entita_aziendale): Promise<boolean> => {
    return true
  }

  useEffect(() => {
    //nel fetcher.data viene memorizzato il risultato dell'ultima azione eseguita (si puo usare anche useActionData con useSubmit)
    if (fetcher.data && fetcher.state === 'idle') {
      console.log('data action', fetcher.data)
      if (isAxiosError(fetcher.data)) {
        console.log('axios error')
        let errMsg = fetcher.data.message
        if (fetcher.data.response?.data) {
          if (fetcher.data.response.data.error) {
            errMsg = fetcher.data.response.data.error.message
          } else if (fetcher.data.response.data.errors) {
            errMsg =
              typeof fetcher.data.response.data.errors === 'object'
                ? fetcher.data.response.data.errors[0].message
                : fetcher.data.response.data.errors
          }
        }
        notify(errMsg, 'error', 10_000)
      } else {
        console.log('qui', quoteDetailsObjectWebAdmin)
        setLineItems(quoteDetailsObjectWebAdmin ? quoteDetailsObjectWebAdmin.lineItemsModel : [])
        setPricing(quoteDetailsObjectWebAdmin ? quoteDetailsObjectWebAdmin.pricing : null)
      }
    }
  }, [fetcher.data, fetcher.state, quoteDetailsObjectWebAdmin])

  useEffect(() => {
    setNumber(quote.number ?? quote.transactionId)
    setStatus(quote.status)
    setAuthor(quote.author)
  }, [quote])

  return (
    <>
      <DocumentHead title={creating ? `Nuova quota` : `${number}`}></DocumentHead>
      <Suspense fallback={<LoadingPanel />}>
        <Await resolve={Promise.all([getRapporti, getImpiegati])}>
          {([relationships, impiegati]) => (
            <>
              <h2 className={clientId ? '' : 'content-block'}>
                {title} {status}
              </h2>
              <div
                id="header-quote-container"
                className={clientId ? 'dx-card responsive-paddings' : 'content-block dx-card responsive-paddings'}
              >
                <fetcher.Form>
                  <DXForm
                    id="quote_form"
                    ref={quoteFormRef}
                    formData={quoteFormData}
                    validationGroup="quoteValidation"
                    showValidationSummary={true}
                    readOnly={
                      (!creating && status !== 'DRAFT') || !isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)
                    }
                  >
                    <GroupItem colCount={3} colSpan={1}>
                      <SimpleItem
                        dataField="azienda"
                        label={{ text: 'Azienda' }}
                        editorType="dxSelectBox"
                        editorOptions={{
                          buttons: [
                            customButton('open', (e: ClickEvent) => {
                              if (quote.azienda) window.open(`/clients/${quote.azienda.id}`, '_blank')
                              else notify(`Deve essere selezionata un' azienda per poterla aprire.`, 'warning', 3000)
                            }),
                            { name: 'dropDown', location: 'after' },
                          ],
                          dataSource: ArraySourceComposer('id', clients.data.value),
                          placeholder: 'Selezionare azienda...',
                          displayExpr: 'nome',
                          searchEnabled: true,
                          dropDownCentered: true,
                          dropDownOptions: {
                            hideOnOutsideClick: true,
                          },
                          readOnly:
                            !creating || clientId || !isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles),
                          onValueChanged: (e: ValueChangedEventSelectBox) => {
                            if (e.previousValue?.id === e.value?.id) return
                            console.log('COMPANY EVENT CHANGED', e.previousValue, e.value)
                            if (e.value) {
                              getCsns(e.value.id).then((res: ad_csn[]) => {
                                setCsnsList(res)
                                quoteFormRef.current?.instance().updateData('csn', null)
                              })
                              getContactsCompany(e.value.id).then((res: contatto_aziendale[]) => {
                                setContattiDataSource(ArraySourceComposer('id', res))
                                quoteFormRef.current?.instance().updateData('contact', null)
                                quoteFormRef.current?.instance().updateData('admin', null)
                                const additionals = res.filter((contatto) => contatto.ad_recipient === true)
                                quoteFormRef.current?.instance().updateData('contatti_addizionali', additionals)
                              })
                              getCommesse(e.value.id).then((res: commessa[]) => {
                                setCommesseDataSource(ArraySourceComposer('id', res))
                                quoteFormRef.current?.instance().updateData('commessa', null)
                              })
                              getCompanySites(e.value.id).then((sedi: sede[]) => {
                                setSedi(sedi)
                                if (sedi[0].stato?.toUpperCase() === 'ITALIA')
                                  quoteFormRef.current?.instance().updateData('language', 'it')
                                else quoteFormRef.current?.instance().updateData('language', 'en')
                              })
                            }
                          },
                        }}
                      >
                        <RequiredRule message="Azienda obbligatorio"></RequiredRule>
                      </SimpleItem>
                      <SimpleItem
                        dataField="csn"
                        label={{ text: 'Csn' }}
                        editorType="dxLookup"
                        editorOptions={{
                          dataSource: ArraySourceComposer('id', csnsList),
                          placeholder: 'Selezionare csn...',
                          displayExpr: 'csn',
                          searchEnabled: true,
                          dropDownCentered: true,
                          dropDownOptions: {
                            hideOnOutsideClick: true,
                          },
                          clearButtonText: 'Clear',
                          showClearButton: true,
                          readOnly: !creating || !isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles),
                          onValueChanged: (e: ValueChangedEvent) => {
                            if (e.previousValue?.id === e.value?.id) return
                            console.log('CSN EVENT CHANGED', e.previousValue, e.value)
                            if (e.value) {
                              quoteFormRef.current?.instance().updateData('contact', e.value.quoteContact)
                              quoteFormRef.current?.instance().updateData('admin', e.value.admin)
                            } else {
                              quoteFormRef.current?.instance().updateData('contact', null)
                              quoteFormRef.current?.instance().updateData('admin', null)
                            }
                          },
                        }}
                      >
                        <CustomRule
                          message="Csn obbligatorio"
                          reevaluate={true}
                          validationCallback={({ value }: ValidationCallbackData) => {
                            return !(!value && csnsList.length > 0)
                          }}
                        ></CustomRule>
                      </SimpleItem>
                      {ProjectSimpleItem({
                        onAddClick: async () => {
                          if (quoteFormData.azienda) {
                            popupRefProject.current?.instance().show()
                          } else
                            notify(
                              `Deve essere selezionata un' azienda per poter creare una commessa.`,
                              'warning',
                              3000,
                            )
                        },
                        onFolderClick: () => {
                          if (quoteFormData.commessa) window.open(`/projects/${quoteFormData.commessa.id}`, '_blank')
                          else notify(`Deve essere selezionata una commessa per poterla aprire.`, 'warning', 3000)
                        },
                        dataSource: commesseDataSource,
                        required: !quoteFormData.auto,
                        readOnly:
                          (status !== 'DRAFT' && status !== '') ||
                          !isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles),
                      })}
                    </GroupItem>
                    <GroupItem colCount={3} colSpan={1}>
                      <SimpleItem
                        dataField="contact"
                        label={{ text: 'Contatto' }}
                        editorType="dxSelectBox"
                        editorOptions={{
                          buttons: [
                            customButton(
                              'add',
                              (e: ClickEvent) => {
                                if (quote.azienda) {
                                  refNuovoContattoPopup.current?.instance().show()
                                } else
                                  notify(
                                    `Deve essere selezionata una sede per poter inserire un contatto.`,
                                    'warning',
                                    3000,
                                  )
                              },
                              !isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles),
                            ),
                            { name: 'clear', location: 'after' },
                            { name: 'dropDown', location: 'after' },
                          ],
                          dataSource: contattiDataSource,
                          placeholder: 'Seleziona il contatto...',
                          displayExpr: (item: contatto_aziendale) => {
                            let displayExpreValue: string = ''
                            if (item) {
                              displayExpreValue += item.fullname
                              if (item.emails && item.emails.length > 0)
                                displayExpreValue += ` - ${item.emails[0].email}`
                            }
                            return displayExpreValue
                          },
                          searchEnabled: true,
                          showDropDownButton: true,
                          dropDownButtonTemplate: 'dropDownButton',
                          dropDownOptions: {
                            showTitle: true,
                            title: 'Selezionare il contatto',
                            hideOnOutsideClick: true,
                          },
                        }}
                      >
                        <RequiredRule message="Contatto obbligatorio"></RequiredRule>
                      </SimpleItem>
                      <SimpleItem
                        dataField="admin"
                        label={{ text: 'Admin' }}
                        editorType="dxSelectBox"
                        editorOptions={{
                          buttons: [
                            customButton(
                              'add',
                              (e: ClickEvent) => {
                                if (quote.azienda) {
                                  refNuovoContattoPopup.current?.instance().show()
                                } else
                                  notify(
                                    `Deve essere selezionata una sede per poter inserire un contatto.`,
                                    'warning',
                                    3000,
                                  )
                              },
                              !isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles),
                            ),
                            { name: 'clear', location: 'after' },
                            { name: 'dropDown', location: 'after' },
                          ],
                          dataSource: contattiDataSource,
                          placeholder: 'Selezionare admin...',
                          displayExpr: (item: contatto_aziendale) => {
                            let displayExpreValue: string = ''
                            if (item) {
                              displayExpreValue += item.fullname
                              if (item.emails && item.emails.length > 0)
                                displayExpreValue += ` - ${item.emails[0].email}`
                            }
                            return displayExpreValue
                          },
                          searchEnabled: true,
                          dropDownOptions: {
                            hideOnOutsideClick: true,
                          },
                          showClearButton: true,
                          dropDownButtonTemplate: 'dropDownButton',
                          readOnly:
                            (status !== 'DRAFT' && status !== '') ||
                            !isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles),
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        dataField="contatti_addizionali"
                        colSpan={2}
                        editorType="dxTagBox"
                        label={{ text: 'Contatti addizionali' }}
                        editorOptions={{
                          buttons: [
                            customButton(
                              'add',
                              (e: ClickEvent) => {
                                if (quote.azienda) {
                                  refNuovoContattoPopup.current?.instance().show()
                                } else
                                  notify(
                                    `Deve essere selezionata una sede per poter inserire un contatto.`,
                                    'warning',
                                    3000,
                                  )
                              },
                              !isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles),
                            ),
                          ],
                          dataSource: contattiDataSource,
                          displayExpr: (item: contatto_aziendale) => {
                            let displayExpreValue: string = ''
                            if (item) {
                              displayExpreValue += item.fullname
                              if (item.emails && item.emails.length > 0)
                                displayExpreValue += ` - ${item.emails[0].email}`
                            }
                            return displayExpreValue
                          },
                          placeholder: 'Seleziona contatti addizionali...',
                          applyValueMode: 'instantly',
                          showSelectionControls: true,
                        }}
                      ></SimpleItem>
                      <GroupItem
                        colCount={quoteFormData.ordini && quoteFormData.ordini.length === 1 ? 6 : 5}
                        colSpan={3}
                      >
                        <SimpleItem
                          dataField="attivitaTecnicaRichiesta"
                          label={{ text: 'Attività Tecnica Richiesta' }}
                          editorType="dxSelectBox"
                          editorOptions={{
                            dataSource: [true, false],
                            displayExpr: (item: any) => {
                              console.log('displayExpr', item)
                              if (item === true) return 'SI'
                              else if (item === false) return 'NO'
                              else item === null || item === undefined
                              return null
                            },
                            placeholder: 'Seleziona flag...',
                            searchEnabled: true,
                            dropDownOptions: {
                              showTitle: true,
                              title: 'Selezionare falg',
                              hideOnOutsideClick: true,
                            },
                          }}
                        >
                          {!quoteFormData.auto && (
                            <CustomRule
                              type="custom"
                              message="Attivita tecnica richiesta obbligatoria"
                              validationCallback={(options: {
                                column: Record<string, any>
                                data: Record<string, any>
                                formItem: Record<string, any>
                                rule: Record<string, any>
                                validator: Record<string, any>
                                value: string | number
                              }) => {
                                const value = options.value
                                try {
                                  return value.toString() === 'true' || value.toString() === 'false' ? true : false
                                } catch {
                                  //se entro nel catch è perche non si riesce ad applicare il toString() su un valore null
                                  //quindi il campo non è sicuramente valorizzato e ritorno false sulla validazione
                                  return false
                                }
                              }}
                            ></CustomRule>
                          )}
                        </SimpleItem>
                        {quoteFormData.ordini && quoteFormData.ordini.length === 1 && (
                          <SimpleItem
                            label={{ text: 'Ordine' }}
                            editorType="dxSelectBox"
                            editorOptions={{
                              buttons: [
                                customButton(
                                  'open',
                                  (e: ClickEvent) => {
                                    if (quoteFormData.ordini)
                                      window.open(`/orders/${quoteFormData.ordini[0].id}`, '_blank')
                                  },
                                  !isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles),
                                ),
                              ],
                              dataSource: ArraySourceComposer('id', quoteFormData.ordini),
                              value: quoteFormData.ordini ? quoteFormData.ordini[0] : null,
                              placeholder: 'Seleziona ordine...',
                              displayExpr: 'ded_Dis',
                              searchEnabled: true,
                              dropDownCentered: true,
                              dropDownOptions: {
                                showTitle: true,
                                title: 'Selezionare ordine',
                                hideOnOutsideClick: true,
                              },
                              readOnly: true,
                            }}
                          ></SimpleItem>
                        )}
                        <SimpleItem
                          dataField="author"
                          label={{ text: 'Autore' }}
                          editorType="dxLookup"
                          editorOptions={{
                            value: author,
                            dataSource: ArraySourceComposer('id', impiegati.data.value),
                            placeholder: 'Seleziona autore...',
                            displayExpr: 'fullname',
                            searchEnabled: true,
                            dropDownCentered: true,
                            readOnly: true,
                            dropDownOptions: {
                              showTitle: true,
                              title: 'Selezionare autore',
                              hideOnOutsideClick: true,
                            },
                          }}
                        ></SimpleItem>
                        <SimpleItem
                          dataField="creationDate"
                          editorOptions={{
                            displayFormat: 'dd/MM/yyyy',
                            readOnly: true,
                          }}
                          editorType="dxDateBox"
                          label={{ text: 'Data Creazione' }}
                        ></SimpleItem>
                        <SimpleItem
                          dataField="expiringDate"
                          editorOptions={{
                            displayFormat: 'dd/MM/yyyy',
                            readOnly: true,
                          }}
                          editorType="dxDateBox"
                          label={{ text: 'Data Scadenza' }}
                        ></SimpleItem>
                        <SimpleItem
                          dataField="language"
                          label={{ text: 'Lingua' }}
                          editorType="dxLookup"
                          editorOptions={{
                            dataSource: ['it', 'en'],
                            displayExpr: (item: any) => {
                              console.log('displayExpr', item)
                              if (item === 'it') return 'ITALIANO'
                              else if (item === 'en') return 'INGLESE'
                              else item === null || item === undefined
                              return null
                            },
                            placeholder: 'Seleziona lingua...',
                            searchEnabled: true,
                            dropDownOptions: {
                              showTitle: true,
                              title: 'Selezionare lingua',
                              hideOnOutsideClick: true,
                            },
                            onValueChanged: (e: ValueChangedEventSelectBox) => {
                              if (e.previousValue === e.value) return
                              console.log('LANGUAGE EVENT CHANGED', e.previousValue, e.value)
                              quoteFormRef.current?.instance().getButton('btn-save')?.option('disabled', false)
                              quoteFormRef.current?.instance().getButton('btn-cancel')?.option('disabled', false)
                            },
                          }}
                        >
                          <RequiredRule message="Lingua obbligatoia"></RequiredRule>
                        </SimpleItem>
                      </GroupItem>
                    </GroupItem>
                  </DXForm>
                  <h3>Azioni</h3>
                  <div className="action-button">
                    <DropDownButton
                      id="ddbAzioni-quote"
                      text="Azioni"
                      useItemTextAsTitle={false}
                      items={actions}
                      disabled={
                        (!creating && status !== 'DRAFT') ||
                        !isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)
                      }
                      dropDownOptions={{ width: 230 }}
                      onItemClick={(e: DropDownButtonTypes.ItemClickEvent) => {
                        if (!creating && e.itemData === 'TRUE-UP') {
                          notify('Action TRUE-UP possibile solo in creazione quota', 'warning', 5000)
                          return
                        }
                        const lineItemModel: LineItemModel = CreateItemModel(e.itemData, creating, indexItems)
                        setIndexItems(indexItems + 1)
                        setLineItems((prev) => prev?.concat(lineItemModel))
                      }}
                    />
                  </div>
                  <h5>Numero linee: {lineItems.length}</h5>
                  {lineItems.length > 0 ? (
                    <div>
                      {lineItems.map((item: LineItemModel) => {
                        switch (item.action) {
                          case 'New': {
                            return (
                              <>
                                <LineItemNew
                                  key={`lineItem_${item.quoteLineNumber}`}
                                  item={item}
                                  products={products}
                                  promotions={promos}
                                  creating={creating}
                                  ref={(lineItemRef: ILineItemRef) => {
                                    lineItemsRef.current[item.lineNumber] = lineItemRef
                                  }}
                                  removeSelf={() => {
                                    if (creating) {
                                      setLineItems((prev) => prev?.filter((i) => i !== item))
                                    } else {
                                      if (item.operation === 'Insert') {
                                        setLineItems((prev) => prev?.filter((i) => i !== item))
                                      } else item.operation = item.operation === '' ? 'Delete' : ''
                                    }
                                  }}
                                  refreshProductsCatalog={async () => {
                                    try {
                                      const response: AxiosResponse<ad_product_catalog[], ad_product_catalog[]> =
                                        await client.put(
                                          '/api/autodeskpws/catalog',
                                          {},
                                          {
                                            headers: {
                                              'Content-Type': 'application/json',
                                            },
                                          },
                                        )
                                      console.log('response', response)
                                      setProducts(
                                        [
                                          ...new Map(
                                            response.data.map((product: ad_product_catalog) => [
                                              product['offeringCode'],
                                              product,
                                            ]),
                                          ).values(),
                                        ].sort((productA: ad_product_catalog, productB: ad_product_catalog) => {
                                          return productA.offeringName && productB.offeringName
                                            ? productA.offeringName.localeCompare(productB.offeringName)
                                            : 1
                                        }),
                                      )
                                      setTerms(
                                        [
                                          ...new Map(
                                            response.data.map((product: ad_product_catalog) => [
                                              product['termCode'],
                                              product,
                                            ]),
                                          ).values(),
                                        ].sort((productA: ad_product_catalog, productB: ad_product_catalog) => {
                                          return productA.termCode && productB.termCode
                                            ? productA.termCode.localeCompare(productB.termCode)
                                            : 1
                                        }),
                                      )
                                    } catch (error: unknown) {
                                      notify(
                                        `Errore aggiornamento elenco prodotti autodesk. Dettagli : ${error}`,
                                        'error',
                                        2000,
                                      )
                                    }
                                  }}
                                  refreshPromotions={async () => {
                                    try {
                                      const response: AxiosResponse<ad_promotion[], ad_promotion[]> = await client.put(
                                        '/api/autodeskpws/promotions',
                                        {},
                                        {
                                          headers: {
                                            'Content-Type': 'application/json',
                                          },
                                        },
                                      )
                                      console.log('response', response)
                                      setPromos(
                                        [
                                          ...new Map(
                                            response.data.map((promotion: ad_promotion) => [
                                              promotion['code'],
                                              promotion,
                                            ]),
                                          ).values(),
                                        ].sort((promoA: ad_promotion, promoB: ad_promotion) => {
                                          return promoA.code && promoB.code ? promoA.code.localeCompare(promoB.code) : 1
                                        }),
                                      )
                                    } catch (error: unknown) {
                                      notify(
                                        `Errore aggiornamento elenco promozioni autodesk. Dettagli : ${error}`,
                                        'error',
                                        2000,
                                      )
                                    }
                                  }}
                                  readOnly={
                                    (!creating && status !== 'DRAFT') ||
                                    !isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)
                                  }
                                />
                              </>
                            )
                          }
                          case 'Renewal': {
                            return (
                              <>
                                <LineItemRenewal
                                  key={`lineItem_${item.quoteLineNumber}`}
                                  item={item}
                                  promotions={promos}
                                  csn={quoteFormData.csn}
                                  creating={creating}
                                  ref={(lineItemRef: ILineItemRef) => {
                                    lineItemsRef.current[item.lineNumber] = lineItemRef
                                  }}
                                  removeSelf={() => {
                                    if (creating) {
                                      setLineItems((prev) => prev?.filter((i) => i !== item))
                                    } else {
                                      if (item.operation === 'Insert') {
                                        setLineItems((prev) => prev?.filter((i) => i !== item))
                                      } else item.operation = item.operation === '' ? 'Delete' : ''
                                    }
                                  }}
                                  readOnly={
                                    (!creating && status !== 'DRAFT') ||
                                    !isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)
                                  }
                                  refreshPromotions={async () => {
                                    try {
                                      const response: AxiosResponse<ad_promotion[], ad_promotion[]> = await client.put(
                                        '/api/autodeskpws/promotions',
                                        {},
                                        {
                                          headers: {
                                            'Content-Type': 'application/json',
                                          },
                                        },
                                      )
                                      console.log('response', response)
                                      setPromos(
                                        [
                                          ...new Map(
                                            response.data.map((promotion: ad_promotion) => [
                                              promotion['code'],
                                              promotion,
                                            ]),
                                          ).values(),
                                        ].sort((promoA: ad_promotion, promoB: ad_promotion) => {
                                          return promoA.code && promoB.code ? promoA.code.localeCompare(promoB.code) : 1
                                        }),
                                      )
                                    } catch (error: unknown) {
                                      notify(
                                        `Errore aggiornamento elenco promozioni autodesk. Dettagli : ${error}`,
                                        'error',
                                        2000,
                                      )
                                    }
                                  }}
                                />
                              </>
                            )
                          }
                          case 'Switch': {
                            return 'subscription' in item ? (
                              <>
                                <LineItemSwitchTerm
                                  key={`lineItem_${item.quoteLineNumber}`}
                                  item={item}
                                  products={products}
                                  promotions={promos}
                                  terms={terms}
                                  csn={quoteFormData.csn}
                                  creating={creating}
                                  ref={(lineItemRef: ILineItemRef) => {
                                    lineItemsRef.current[item.lineNumber] = lineItemRef
                                  }}
                                  removeSelf={() => {
                                    if (creating) {
                                      setLineItems((prev) => prev?.filter((i) => i !== item))
                                    } else {
                                      if (item.operation === 'Insert') {
                                        setLineItems((prev) => prev?.filter((i) => i !== item))
                                      } else item.operation = item.operation === '' ? 'Delete' : ''
                                    }
                                  }}
                                  readOnly={
                                    (!creating && status !== 'DRAFT') ||
                                    !isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)
                                  }
                                  refreshProductsCatalog={async () => {
                                    try {
                                      const response: AxiosResponse<ad_product_catalog[], ad_product_catalog[]> =
                                        await client.put(
                                          '/api/autodeskpws/catalog',
                                          {},
                                          {
                                            headers: {
                                              'Content-Type': 'application/json',
                                            },
                                          },
                                        )
                                      console.log('response', response)
                                      setProducts(
                                        [
                                          ...new Map(
                                            response.data.map((product: ad_product_catalog) => [
                                              product['offeringCode'],
                                              product,
                                            ]),
                                          ).values(),
                                        ].sort((productA: ad_product_catalog, productB: ad_product_catalog) => {
                                          return productA.offeringName && productB.offeringName
                                            ? productA.offeringName.localeCompare(productB.offeringName)
                                            : 1
                                        }),
                                      )
                                      setTerms(
                                        [
                                          ...new Map(
                                            response.data.map((product: ad_product_catalog) => [
                                              product['termCode'],
                                              product,
                                            ]),
                                          ).values(),
                                        ].sort((productA: ad_product_catalog, productB: ad_product_catalog) => {
                                          return productA.termCode && productB.termCode
                                            ? productA.termCode.localeCompare(productB.termCode)
                                            : 1
                                        }),
                                      )
                                    } catch (error: unknown) {
                                      notify(
                                        `Errore aggiornamento elenco prodotti autodesk. Dettagli : ${error}`,
                                        'error',
                                        2000,
                                      )
                                    }
                                  }}
                                  refreshPromotions={async () => {
                                    try {
                                      const response: AxiosResponse<ad_promotion[], ad_promotion[]> = await client.put(
                                        '/api/autodeskpws/promotions',
                                        {},
                                        {
                                          headers: {
                                            'Content-Type': 'application/json',
                                          },
                                        },
                                      )
                                      console.log('response', response)
                                      setPromos(
                                        [
                                          ...new Map(
                                            response.data.map((promotion: ad_promotion) => [
                                              promotion['code'],
                                              promotion,
                                            ]),
                                          ).values(),
                                        ].sort((promoA: ad_promotion, promoB: ad_promotion) => {
                                          return promoA.code && promoB.code ? promoA.code.localeCompare(promoB.code) : 1
                                        }),
                                      )
                                    } catch (error: unknown) {
                                      notify(
                                        `Errore aggiornamento elenco promozioni autodesk. Dettagli : ${error}`,
                                        'error',
                                        2000,
                                      )
                                    }
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                <LineItemSwitch
                                  key={`lineItem_${item.quoteLineNumber}`}
                                  item={item}
                                  products={products}
                                  promotions={promos}
                                  terms={terms}
                                  csn={quoteFormData.csn}
                                  creating={creating}
                                  ref={(lineItemRef: ILineItemRef) => {
                                    lineItemsRef.current[item.lineNumber] = lineItemRef
                                  }}
                                  removeSelf={() => {
                                    if (creating) {
                                      setLineItems((prev) => prev?.filter((i) => i !== item))
                                    } else {
                                      if (item.operation === 'Insert') {
                                        setLineItems((prev) => prev?.filter((i) => i !== item))
                                      } else item.operation = item.operation === '' ? 'Delete' : ''
                                    }
                                  }}
                                  readOnly={
                                    (!creating && status !== 'DRAFT') ||
                                    !isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)
                                  }
                                  refreshProductsCatalog={async () => {
                                    try {
                                      const response: AxiosResponse<ad_product_catalog[], ad_product_catalog[]> =
                                        await client.put(
                                          '/api/autodeskpws/catalog',
                                          {},
                                          {
                                            headers: {
                                              'Content-Type': 'application/json',
                                            },
                                          },
                                        )
                                      console.log('response', response)
                                      setProducts(
                                        [
                                          ...new Map(
                                            response.data.map((product: ad_product_catalog) => [
                                              product['offeringCode'],
                                              product,
                                            ]),
                                          ).values(),
                                        ].sort((productA: ad_product_catalog, productB: ad_product_catalog) => {
                                          return productA.offeringName && productB.offeringName
                                            ? productA.offeringName.localeCompare(productB.offeringName)
                                            : 1
                                        }),
                                      )
                                      setTerms(
                                        [
                                          ...new Map(
                                            response.data.map((product: ad_product_catalog) => [
                                              product['termCode'],
                                              product,
                                            ]),
                                          ).values(),
                                        ].sort((productA: ad_product_catalog, productB: ad_product_catalog) => {
                                          return productA.termCode && productB.termCode
                                            ? productA.termCode.localeCompare(productB.termCode)
                                            : 1
                                        }),
                                      )
                                    } catch (error: unknown) {
                                      notify(
                                        `Errore aggiornamento elenco prodotti autodesk. Dettagli : ${error}`,
                                        'error',
                                        2000,
                                      )
                                    }
                                  }}
                                  refreshPromotions={async () => {
                                    try {
                                      const response: AxiosResponse<ad_promotion[], ad_promotion[]> = await client.put(
                                        '/api/autodeskpws/promotions',
                                        {},
                                        {
                                          headers: {
                                            'Content-Type': 'application/json',
                                          },
                                        },
                                      )
                                      console.log('response', response)
                                      setPromos(
                                        [
                                          ...new Map(
                                            response.data.map((promotion: ad_promotion) => [
                                              promotion['code'],
                                              promotion,
                                            ]),
                                          ).values(),
                                        ].sort((promoA: ad_promotion, promoB: ad_promotion) => {
                                          return promoA.code && promoB.code ? promoA.code.localeCompare(promoB.code) : 1
                                        }),
                                      )
                                    } catch (error: unknown) {
                                      notify(
                                        `Errore aggiornamento elenco promozioni autodesk. Dettagli : ${error}`,
                                        'error',
                                        2000,
                                      )
                                    }
                                  }}
                                />
                              </>
                            )
                          }
                          case 'Extension': {
                            return (
                              <>
                                <LineItemExtension
                                  key={`lineItem_${item.quoteLineNumber}`}
                                  item={item}
                                  csn={quoteFormData.csn}
                                  creating={creating}
                                  ref={(lineItemRef: ILineItemRef) => {
                                    lineItemsRef.current[item.lineNumber] = lineItemRef
                                  }}
                                  removeSelf={() => {
                                    if (creating) {
                                      setLineItems((prev) => prev?.filter((i) => i !== item))
                                    } else {
                                      if (item.operation === 'Insert') {
                                        setLineItems((prev) => prev?.filter((i) => i !== item))
                                      } else item.operation = item.operation === '' ? 'Delete' : ''
                                    }
                                  }}
                                  readOnly={
                                    (!creating && status !== 'DRAFT') ||
                                    !isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)
                                  }
                                />
                              </>
                            )
                          }
                          case 'True-Up': {
                            return (
                              <>
                                <LineItemTrueup
                                  key={`lineItem_${item.quoteLineNumber}`}
                                  item={item}
                                  products={products}
                                  creating={creating}
                                  ref={(lineItemRef: ILineItemRef) => {
                                    lineItemsRef.current[item.lineNumber] = lineItemRef
                                  }}
                                  removeSelf={() => {
                                    if (creating) {
                                      setLineItems((prev) => prev?.filter((i) => i !== item))
                                    } else {
                                      if (item.operation === 'Insert') {
                                        setLineItems((prev) => prev?.filter((i) => i !== item))
                                      } else item.operation = item.operation === '' ? 'Delete' : ''
                                    }
                                  }}
                                  readOnly={
                                    (!creating && status !== 'DRAFT') ||
                                    !isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)
                                  }
                                  refreshProductsCatalog={async () => {
                                    try {
                                      const response: AxiosResponse<ad_product_catalog[], ad_product_catalog[]> =
                                        await client.put(
                                          '/api/autodeskpws/catalog',
                                          {},
                                          {
                                            headers: {
                                              'Content-Type': 'application/json',
                                            },
                                          },
                                        )
                                      console.log('response', response)
                                      setProducts(
                                        [
                                          ...new Map(
                                            response.data.map((product: ad_product_catalog) => [
                                              product['offeringCode'],
                                              product,
                                            ]),
                                          ).values(),
                                        ].sort((productA: ad_product_catalog, productB: ad_product_catalog) => {
                                          return productA.offeringName && productB.offeringName
                                            ? productA.offeringName.localeCompare(productB.offeringName)
                                            : 1
                                        }),
                                      )
                                      setTerms(
                                        [
                                          ...new Map(
                                            response.data.map((product: ad_product_catalog) => [
                                              product['termCode'],
                                              product,
                                            ]),
                                          ).values(),
                                        ].sort((productA: ad_product_catalog, productB: ad_product_catalog) => {
                                          return productA.termCode && productB.termCode
                                            ? productA.termCode.localeCompare(productB.termCode)
                                            : 1
                                        }),
                                      )
                                    } catch (error: unknown) {
                                      notify(
                                        `Errore aggiornamento elenco prodotti autodesk. Dettagli : ${error}`,
                                        'error',
                                        2000,
                                      )
                                    }
                                  }}
                                />
                              </>
                            )
                          }
                          case 'Co-term': {
                            return (
                              <>
                                <LineItemCoterm
                                  key={`lineItem_${item.quoteLineNumber}`}
                                  item={item}
                                  products={products}
                                  promotions={promos}
                                  csn={quoteFormData.csn}
                                  creating={creating}
                                  ref={(lineItemRef: ILineItemRef) => {
                                    lineItemsRef.current[item.lineNumber] = lineItemRef
                                  }}
                                  removeSelf={() => {
                                    if (creating) {
                                      setLineItems((prev) => prev?.filter((i) => i !== item))
                                    } else {
                                      if (item.operation === 'Insert') {
                                        setLineItems((prev) => prev?.filter((i) => i !== item))
                                      } else item.operation = item.operation === '' ? 'Delete' : ''
                                    }
                                  }}
                                  readOnly={
                                    (!creating && status !== 'DRAFT') ||
                                    !isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)
                                  }
                                  refreshProductsCatalog={async () => {
                                    try {
                                      const response: AxiosResponse<ad_product_catalog[], ad_product_catalog[]> =
                                        await client.put(
                                          '/api/autodeskpws/catalog',
                                          {},
                                          {
                                            headers: {
                                              'Content-Type': 'application/json',
                                            },
                                          },
                                        )
                                      console.log('response', response)
                                      setProducts(
                                        [
                                          ...new Map(
                                            response.data.map((product: ad_product_catalog) => [
                                              product['offeringCode'],
                                              product,
                                            ]),
                                          ).values(),
                                        ].sort((productA: ad_product_catalog, productB: ad_product_catalog) => {
                                          return productA.offeringName && productB.offeringName
                                            ? productA.offeringName.localeCompare(productB.offeringName)
                                            : 1
                                        }),
                                      )
                                      setTerms(
                                        [
                                          ...new Map(
                                            response.data.map((product: ad_product_catalog) => [
                                              product['termCode'],
                                              product,
                                            ]),
                                          ).values(),
                                        ].sort((productA: ad_product_catalog, productB: ad_product_catalog) => {
                                          return productA.termCode && productB.termCode
                                            ? productA.termCode.localeCompare(productB.termCode)
                                            : 1
                                        }),
                                      )
                                    } catch (error: unknown) {
                                      notify(
                                        `Errore aggiornamento elenco prodotti autodesk. Dettagli : ${error}`,
                                        'error',
                                        2000,
                                      )
                                    }
                                  }}
                                  refreshPromotions={async () => {
                                    try {
                                      const response: AxiosResponse<ad_promotion[], ad_promotion[]> = await client.put(
                                        '/api/autodeskpws/promotions',
                                        {},
                                        {
                                          headers: {
                                            'Content-Type': 'application/json',
                                          },
                                        },
                                      )
                                      console.log('response', response)
                                      setPromos(
                                        [
                                          ...new Map(
                                            response.data.map((promotion: ad_promotion) => [
                                              promotion['code'],
                                              promotion,
                                            ]),
                                          ).values(),
                                        ].sort((promoA: ad_promotion, promoB: ad_promotion) => {
                                          return promoA.code && promoB.code ? promoA.code.localeCompare(promoB.code) : 1
                                        }),
                                      )
                                    } catch (error: unknown) {
                                      notify(
                                        `Errore aggiornamento elenco promozioni autodesk. Dettagli : ${error}`,
                                        'error',
                                        2000,
                                      )
                                    }
                                  }}
                                />
                              </>
                            )
                          }
                        }
                      })}
                    </div>
                  ) : creating ? (
                    <div></div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%',
                        fontSize: '2em',
                      }}
                    >
                      <h3>
                        {status === 'FAILED'
                          ? failedError
                            ? `${failedError}`
                            : quoteFormData.ad_quote_histories
                              ? quoteFormData.ad_quote_histories[0].message
                              : ''
                          : status === 'RECEIVED'
                            ? 'Dettagli quota non ancora disponibili'
                            : 'Nessun dettaglio quota'}
                      </h3>
                    </div>
                  )}
                  {!creating && status !== 'RECEIVED' && status !== 'FAILED' && lineItems.length > 0 && (
                    <>
                      <h3>Totali</h3>
                      <hr />
                      <div style={{ float: 'right' }}>
                        <div
                        // style={{
                        //   display: 'flex',
                        //   alignItems: 'center',
                        //   justifyContent: 'center',
                        // }}
                        >
                          <p style={{ marginTop: '10px', marginBottom: '10px' }}>
                            Totale Prodotti: € {pricing?.totalListAmount}
                          </p>
                          <p style={{ marginTop: '10px', marginBottom: '10px' }}>
                            Totale Prodotti con Sconto: € {pricing?.totalNetAmount}
                          </p>
                          <p style={{ marginTop: '10px', marginBottom: '10px' }}>IVA: € {pricing?.estimatedTax}</p>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <h6 style={{ marginTop: '10px', marginBottom: '10px' }}>
                              Totale: € {pricing?.totalAmount}
                            </h6>
                            <Button
                              icon="copy"
                              stylingMode={'text'}
                              onClick={() => {
                                navigator.clipboard
                                  .writeText(pricing?.totalAmount ? pricing?.totalAmount.toString() : '')
                                  .then(
                                    () => {
                                      notify(`Totale quota copiato con successo`, 'success', 2000)
                                    },
                                    (error: any) => {
                                      console.log('Errore copia totale quota', error)
                                      notify(`Errore copia totale quota`, 'error', 2000)
                                    },
                                  )
                              }}
                            ></Button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div>
                    <DXForm>
                      <GroupItem
                        visible={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
                        cssClass="last-group"
                      >
                        <GroupItem cssClass="buttons-group" colCount={4}>
                          <ButtonItem name="btn-statusupdate">
                            <ButtonOptions
                              width="150px"
                              text="Aggiorna"
                              icon="pulldown"
                              disabled={
                                creating ||
                                status === 'FAILED' ||
                                status === 'CANCELLED' ||
                                !isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)
                              }
                              onClick={async (e: ClickEvent) => {
                                setLoadPanelVisible(true)
                                await client
                                  .put(
                                    '/api/autodeskpws/quotes/status',
                                    { quoteNumber: number, transactionId: quote.transactionId },
                                    {
                                      headers: {
                                        'Content-Type': 'application/json',
                                      },
                                    },
                                  )
                                  .then(async (res: AxiosResponse<any, any>) => {
                                    console.log('res', res)
                                    if (res.status === 204)
                                      notify(
                                        'Nessun aggiornamento disponibile per la quota in editazione.',
                                        'warning',
                                        5000,
                                      )
                                    else if ('quoteStatus' in res.data && res.data.quoteStatus === 'FAILED') {
                                      setStatus(res.data.quoteStatus)
                                      setNumber(res.data.quoteNumber)
                                      setFailedError(`Quota nello stato 'FAILED': ${res.data.errors[0].message}`)
                                      notify(`Quota nello stato 'FAILED': ${res.data.errors[0].message}`, 'error', 5000)
                                    } else {
                                      const objectWebAdmin = await TransformGetQuoteDetails(res.data)
                                      console.log('objectWebAdmin', objectWebAdmin)
                                      setLineItems(objectWebAdmin ? objectWebAdmin.lineItemsModel : [])
                                      setPricing(objectWebAdmin ? objectWebAdmin.pricing : null)
                                      setStatus(res.data[0].quoteStatus.toUpperCase())
                                      setNumber(res.data[0].quoteNumber)
                                      if (res.data[0].quoteCreatedTime)
                                        quoteFormRef.current
                                          ?.instance()
                                          .updateData('creationDate', res.data[0].quoteCreatedTime)
                                      if (res.data[0].quoteExpirationDate)
                                        quoteFormRef.current
                                          ?.instance()
                                          .updateData('expiringDate', res.data[0].quoteExpirationDate)
                                    }
                                  })
                                  .catch((error: AxiosError<any, any>) => {
                                    notify(`Errore aggiornamento stato: ${error}`, 'error', 5000)
                                  })
                                  .finally(() => {
                                    setLoadPanelVisible(false)
                                  })
                              }}
                            ></ButtonOptions>
                          </ButtonItem>
                          <ButtonItem name="btn-finalize">
                            <ButtonOptions
                              width="150px"
                              text="Finalizza"
                              icon="check"
                              disabled={
                                status !== 'DRAFT' || !isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)
                              }
                              onClick={(e: ClickEvent) => {
                                const result = confirm(
                                  `<i>Vuoi davvero finalizzare la quota <b>${number}</b>?</i>`,
                                  'Finalizzazione Quota',
                                )
                                result.then(async (dialogResult) => {
                                  if (dialogResult === false) return
                                  setLoadPanelVisible(true)
                                  await client
                                    .put(
                                      '/api/autodeskpws/quotes/finalize',
                                      { quoteNumber: number },
                                      {
                                        headers: {
                                          'Content-Type': 'application/json',
                                        },
                                      },
                                    )
                                    .then((res: AxiosResponse<any, any>) => {
                                      console.log('res', res)
                                      setStatus('FINALIZING')
                                    })
                                    .catch((error: AxiosError<any, any>) => {
                                      notify(`Errore finalizzazione quota: ${error}`, 'error', 5000)
                                    })
                                    .finally(() => {
                                      setLoadPanelVisible(false)
                                    })
                                })
                              }}
                            ></ButtonOptions>
                          </ButtonItem>
                          <ButtonItem name="btn-cancel">
                            <ButtonOptions
                              width="150px"
                              text="Annulla"
                              icon="remove"
                              disabled={
                                (status !== 'DRAFT' && status !== 'QUOTED') ||
                                !isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)
                              }
                              onClick={(e: ClickEvent) => {
                                const result = confirm(
                                  `<i>Vuoi davvero annullare la quota <b>${number}</b>?</i>`,
                                  'Annulamento Quota',
                                )
                                result.then(async (dialogResult) => {
                                  if (dialogResult === false) return
                                  setLoadPanelVisible(true)
                                  await client
                                    .put(
                                      '/api/autodeskpws/quotes/cancel',
                                      { quoteNumber: number },
                                      {
                                        headers: {
                                          'Content-Type': 'application/json',
                                        },
                                      },
                                    )
                                    .then((res: AxiosResponse<any, any>) => {
                                      console.log('res', res)
                                      setStatus('CANCELLING')
                                    })
                                    .catch((error: AxiosError<any, any>) => {
                                      notify(`Errore cancellazione quota: ${error}`, 'error', 5000)
                                    })
                                    .finally(() => {
                                      setLoadPanelVisible(false)
                                    })
                                })
                              }}
                            ></ButtonOptions>
                          </ButtonItem>
                          <ButtonItem name="btn-save">
                            <ButtonOptions
                              width="150px"
                              text="Salva"
                              icon="save"
                              disabled={
                                lineItems.length === 0 ||
                                (!creating && status !== 'DRAFT') ||
                                !isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)
                              }
                              onClick={() => {
                                let validationResult: ValidationResult | undefined
                                validationResult = quoteFormRef.current?.instance().validate()
                                if (!validationResult?.isValid) return
                                lineItems.map((item, index) => {
                                  validationResult = lineItemsRef.current[item.lineNumber].validate()
                                  console.log('VR', validationResult)
                                  if (!validationResult?.isValid) return
                                })
                                if (!validationResult?.isValid) return
                                quoteFormData.number = number ?? ''
                                quoteFormData.status = status ?? ''
                                quoteFormData.id = quote.id
                                console.log('quoteFormData', quoteFormData)
                                console.log('lineItems', lineItems)
                                const quoteRequest: QuoteRequest = CreateQuoteRequest(
                                  creating,
                                  quoteFormData,
                                  lineItems,
                                )
                                console.log('quoteRequest', quoteRequest)
                                if (quoteRequest.lineItems.length > 0 && validationResult && validationResult.isValid) {
                                  fetcher.submit(JSON.stringify(quoteRequest), {
                                    method: creating ? 'POST' : 'PUT',
                                    encType: 'application/json',
                                  })
                                }
                              }}
                            ></ButtonOptions>
                          </ButtonItem>
                        </GroupItem>
                      </GroupItem>
                    </DXForm>
                  </div>
                </fetcher.Form>
              </div>
              <LoadingPanel visible={fetcher.state !== 'idle' || loadPanelVisible} />
              <FormPopupProject
                popupRef={popupRefProject}
                azienda={quoteFormData.azienda}
                sediList={sedi}
                refreshCommessa={async () => {
                  getCommesse(quoteFormData.azienda?.id).then((res: commessa[]) => {
                    setCommesseDataSource(ArraySourceComposer('id', res))
                    quoteFormRef.current?.instance().updateData('commessa', res[0])
                    popupRefProject.current?.instance().hide()
                  })
                }}
              ></FormPopupProject>
              <Popup
                ref={refNuovoContattoPopup}
                id="nuovo_contatto_popup"
                dragEnabled={false}
                hideOnOutsideClick={false}
                showCloseButton={true}
                showTitle={true}
                title={`Nuovo contatto`}
                container=".dx-viewport"
                resizeEnabled={true}
                width={
                  currentScreenSize.isLarge || currentScreenSize.isMedium ? 900 : currentScreenSize.isXSmall ? 350 : 700
                }
                height={currentScreenSize.isSmall ? 300 : 700}
              >
                <ContactEditorForm
                  contact={contact}
                  rapporti={relationships.data.value}
                  aziende={quoteFormData.azienda ? [quoteFormData.azienda] : []}
                  sedi={sedi}
                  creating={true}
                  onDataReset={onContactReset}
                  onDataSave={onContactCreate}
                ></ContactEditorForm>
              </Popup>
            </>
          )}
        </Await>
      </Suspense>
    </>
  )
}

export default QuoteEditor
