import { offerEditorRouteLoader } from '@/routes/offers/OfferEditor.route'
import { OfferEditorProps } from '@/routes/offers/OfferEditor.types'
import { useLoaderData, Await } from 'react-router-typesafe'
import { Suspense, useRef } from 'react'
import OfferEditorForm from '@/routes/offers/OfferEditor.form'
import { Form as DXForm, Tab, TabbedItem } from 'devextreme-react/form'
import AttachmentsManager from '@/components/file-manager/AttachmentsManager'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import DocumentCreator from '@/components/document/DocumentCreator'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { useFetcher, useParams } from 'react-router-dom'
import { FileManagerRef } from 'devextreme-react/file-manager'
import { isUserRoleAllowed } from '@/routes/utils'
import { DocumentPermissions } from '@/enums'
import { Roles } from '@/auth/azure/Roles'
import DocumentHead from '@/components/document-head/DocumentHead'

const OfferEditor = (props: OfferEditorProps) => {
  const { creating } = props
  const {
    title,
    offer,
    getAziende,
    getStati,
    getQsImpiegati,
    getPersone,
    getEntita,
    getAnalisi,
    getCommesse,
    getTemplates,
    userInfo,
    defaultCRUDAllowedRoles,
  } = useLoaderData<typeof offerEditorRouteLoader>()

  const { clientId } = useParams()

  const { service, client } = useQsAdminApiManager()
  const documentoGetRef = useRef<any>(null)
  const fetcher = useFetcher()

  const fileManagerRef = useRef<FileManagerRef>(null)
  return (
    <>
      <DocumentHead title={title}></DocumentHead>
      <Suspense fallback={<LoadingPanel />}>
        <Await
          resolve={Promise.all([
            getStati,
            getAziende,
            getQsImpiegati,
            getAnalisi,
            getCommesse,
            getPersone,
            getEntita,
            getTemplates,
          ])}
        >
          {([stati, aziende, impiegati, analisi, commesse, persone, entita, templates]) => (
            <>
              <h2 className={clientId ? '' : 'content-block'}>{title}</h2>
              <div
                id="editor-offer-container"
                className={clientId ? 'dx-card responsive-paddings' : 'content-block dx-card responsive-paddings'}
              >
                <fetcher.Form>
                  <OfferEditorForm
                    creating={creating}
                    offer={offer}
                    stati={stati.data.value}
                    aziende={aziende.data.value}
                    impiegati={impiegati.data.value}
                    templates={templates.data.value}
                    analisi={analisi?.data.value}
                    commesse={commesse?.data.value}
                    persone={persone?.data.value}
                    entita={entita?.data.value}
                    isRoleAllowed={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
                    userInfo={userInfo}
                    documentoGetRef={documentoGetRef}
                    fetcher={fetcher}
                  />
                </fetcher.Form>
              </div>
              {!creating && (
                <div
                  id="allegati-offer-container"
                  className={clientId ? 'dx-card responsive-paddings' : 'content-block dx-card responsive-paddings'}
                >
                  <DXForm id="form-allegati">
                    <TabbedItem>
                      <Tab title="Documento">
                        <DocumentCreator
                          title="Documento"
                          documentUrl={offer.mainDocumentUrl}
                          documentGetRef={documentoGetRef}
                          action={async () => {
                            await service.offerta(offer.id).CreateDocument()
                          }}
                          preliminarPermissionsAction={async () => {
                            try {
                              const response = await client.post(
                                '/api/offerta/drivepermissions',
                                {
                                  objectId: offer.id,
                                  path: offer.mainDocumentUrl,
                                },
                                {
                                  headers: {
                                    'Content-Type': 'application/json',
                                  },
                                },
                              )
                              return response.status === 200
                                ? (response.data as DocumentPermissions)
                                : DocumentPermissions.Deny
                            } catch (error) {
                              console.log('Errore in settaggio permessi sul documento offerta', error)
                              return DocumentPermissions.Deny
                            }
                          }}
                        />
                      </Tab>
                      <Tab title="Allegati">
                        <AttachmentsManager
                          fileManagerRef={fileManagerRef}
                          folderUrl={offer?.attachmentsFolderUrl}
                          preliminarPermissionsAction={async () => {
                            try {
                              const response = await client.post(
                                '/api/offerta/drivepermissions',
                                {
                                  objectId: offer.id,
                                  path: offer.attachmentsFolderUrl,
                                },
                                {
                                  headers: {
                                    'Content-Type': 'application/json',
                                  },
                                },
                              )
                              return response.status === 200
                                ? (response.data as DocumentPermissions)
                                : DocumentPermissions.Deny
                            } catch (error) {
                              console.log('Errore in settaggio permessi sulla cartella allegati offerta', error)
                              return DocumentPermissions.Deny
                            }
                          }}
                          createDirectoryAction={async (
                            rootFolderPath: string,
                            name: string,
                            conflictBehavior: string,
                          ) => {
                            return await client.post(
                              '/api/offerta/createfolder',
                              {
                                rootFolder: rootFolderPath,
                                newFolderName: name,
                                conflictBehavior,
                                objectId: offer.id,
                              },
                              {
                                headers: {
                                  'Content-Type': 'application/json',
                                },
                              },
                            )
                          }}
                        />
                      </Tab>
                    </TabbedItem>
                  </DXForm>
                </div>
              )}
            </>
          )}
        </Await>
      </Suspense>
    </>
  )
}

export default OfferEditor
