import { Suspense } from 'react'
import './DashboardPage.scss'
import { Agenda, Get, MgtTemplateProps, Person, FileList, Todo } from '@microsoft/mgt-react'
import { Item } from 'devextreme-react/tab-panel'
import { LoadIndicator, ResponsiveBox, TabPanel } from 'devextreme-react'
import { Row, Col, Item as ResponsiveItem, Location } from 'devextreme-react/responsive-box'
import { CostCentersPie } from '@/routes/costcenters/CostCentersPie'
import { dashboardPageRouterLoader } from '@/routes/home/DashboardPage.route'
import { Await, useLoaderData } from 'react-router-typesafe'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { Accordion, Item as AccordionItem } from 'devextreme-react/accordion'
import DocumentHead from '@/components/document-head/DocumentHead'

function DashboardPage() {
  const { getCurrentEmployee } = useLoaderData<typeof dashboardPageRouterLoader>()
  const title = 'Dashboard'
  const now = new Date()
  const lastWeekDay = new Date(now)
  lastWeekDay.setDate(now.getDate() - 7)
  lastWeekDay.setHours(0, 0, 0, 0)

  function EmailsComponent(props: MgtTemplateProps) {
    const email = props.dataContext
    return (
      <div>
        <div className="email">
          <div className="header">
            <div>
              <Person
                personQuery={email.sender.emailAddress.address}
                view={'oneline'}
                personCardInteraction={'hover'}
              />
            </div>
          </div>
          <div className="title">
            <a href={email.webLink} target="_blank" rel="noreferrer">
              <h3>{email.subject}</h3>
            </a>
            <span className="date">{new Date(email.receivedDateTime).toLocaleDateString()}</span>
          </div>
          {/* {email.bodyPreview ?? <div className="preview">{email.bodyPreview}</div>}
          {!email.bodyPreview ?? <div className="preview empty-body">...</div>} */}
        </div>
      </div>
    )
  }

  function LoadingComponent(props: MgtTemplateProps) {
    return <LoadIndicator id="small-indicator" height={20} width={20} />
  }

  return (
    <>
      <DocumentHead title={title}></DocumentHead>
      <div className={'content-block box-container'}>
        <ResponsiveBox>
          <Row ratio={1} />
          <Row ratio={1} />
          <Row ratio={1} screen="xs sm" />
          <Row ratio={1} screen="xs sm" />
          <Col ratio={1} />
          <Col ratio={1} screen="md lg" />
          <ResponsiveItem>
            <Location screen="md lg" row={0} col={0} />
            <Location screen="xs sm" row={0} col={0} />
            <div className={'dx-card responsive-paddings card'}>
              <div className={'card-content'}>
                <Person personQuery="me" view={'fourlines'} personCardInteraction={'click'} showPresence={true} />
                <h4>Prossimi impegni</h4>
                <Agenda groupByDay={true} showMax={4} />
              </div>
            </div>
          </ResponsiveItem>
          <ResponsiveItem>
            <Location screen="md lg" row={0} col={1} />
            <Location screen="xs sm" row={1} col={0} />
            <div className={'dx-card responsive-paddings card'}>
              <div className={'card-content'}>
                <h4 className={'card-title'}>Promemoria</h4>
                <div className={'responsive-item-content'}>
                  <Accordion
                    onSelectionChanged={(e) => {
                      e.component?.repaint()
                    }}
                  >
                    <AccordionItem title={'ToDo'} visible={true} icon="checklist">
                      <Todo
                        taskFilter={(task) => {
                          return task.status !== 'completed'
                        }}
                      />
                    </AccordionItem>
                    <AccordionItem title={'Completati'} icon={'check'}>
                      <Todo
                        taskFilter={(task) => {
                          return task.status === 'completed'
                        }}
                      />
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
            </div>
          </ResponsiveItem>
          <ResponsiveItem>
            <Location screen="md lg" row={1} col={0} />
            <Location screen="xs sm" row={2} col={0} />
            <div className={'dx-card responsive-paddings card'}>
              <div className={'card-content'}>
                <h4 className={'card-title'}>Dal tuo account</h4>
                <TabPanel deferRendering={true} swipeEnabled={false} animationEnabled={true}>
                  <Item title={'Email'}>
                    <div id="mgt-email-container" className="mgt-get-email">
                      <Get resource="/me/mailFolders/Inbox/messages?$top=6" maxPages={1}>
                        <EmailsComponent template="value"></EmailsComponent>
                        <LoadingComponent template="loading"></LoadingComponent>
                      </Get>
                    </div>
                  </Item>
                  <Item title={'File Personali'}>
                    <FileList pageSize={8} />
                  </Item>
                </TabPanel>
              </div>
            </div>
          </ResponsiveItem>
          <ResponsiveItem>
            <Location screen="md lg" row={1} col={1} />
            <Location screen="xs sm" row={3} col={0} />
            <div className={'dx-card responsive-paddings card'} id="costCenterPieContainer">
              <div className={'card-content'}>
                <h4 className={'card-title'}>Riepilogo attività ultima settimana</h4>
                <Suspense fallback={<LoadingPanel position={{ of: '#costCenterPieContainer' }} shading={false} />}>
                  <Await resolve={Promise.resolve(getCurrentEmployee)}>
                    {(currentEmployee) => (
                      <>
                        {currentEmployee?.data ? (
                          <CostCentersPie
                            filter={[
                              ['impiegato/id', currentEmployee.data.value[0].id],
                              ['data', '>=', lastWeekDay],
                              ['data', '<=', now],
                            ]}
                          />
                        ) : (
                          <div>Impiegato non trovato</div>
                        )}
                      </>
                    )}
                  </Await>
                </Suspense>
              </div>
            </div>
          </ResponsiveItem>
        </ResponsiveBox>
      </div>
    </>
  )
}

export default DashboardPage
