import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { Roles } from '@/auth/azure/Roles'
import PlatformsEditor from '@/routes/costcenters/platforms/PlatformsEditor'
import { RoleRouteObject } from '@/types'
import { defer, LoaderFunction } from 'react-router-typesafe'

export const platformsEditorRouteLoader = (async () => {
  return defer({ title: 'Editazione piattaforme' })
}) satisfies LoaderFunction

const PlatformsEditorRoute = {
  path: 'platforms',
  element: <PlatformsEditor />,
  allowedRoles: [Roles.Guest],
  loader: platformsEditorRouteLoader,
} as RoleRouteObject

export default PlatformsEditorRoute
