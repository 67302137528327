import { RoleRoute, RoleRouteObject, RouteFunctionParams } from '@/types'
import TasksPage from './TasksPage'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import { defer, LoaderFunction } from 'react-router-typesafe'
import TaskEditorRoute from '@/routes/tasks/TaskEditor.route'
import { TaskCreatorRoute } from '@/routes/tasks/TaskCreator.route'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { Roles } from '@/auth/azure/Roles'
import RouterErrorHandler from '@/components/RouterErrorHandler'
import { Reparto } from '@/enums'
import { azienda } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { ODataModelResponseV4 } from '@odata2ts/odata-core'
import { HttpResponseModel } from '@odata2ts/http-client-api'

export const tasksPageRouteLoader = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const clientId = params.clientId
  let azienda: HttpResponseModel<ODataModelResponseV4<azienda>> | undefined
  if (clientId)
    azienda = await qsAdminApi.azienda(Number(clientId)).query((builder, azienda) => {
      builder.select('id', 'nome')
    })

  const title = azienda ? `${azienda.data.nome} - Task` : 'Task'

  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.select('id', 'nome')
    builder.orderBy(azienda.nome.asc())
  })
  const getStati = qsAdminApi.stato_task().query((builder, stato) => {
    builder.select('id', 'nome')
    builder.orderBy(stato.nome.asc())
  })
  const getImpiegati = qsAdminApi.user().query((builder, impiegato) => {
    builder.filter(impiegato.reparto.props.id.eq(Reparto.SVILUPPO).or(impiegato.reparto.props.id.eq(Reparto.TECNICO)))
    builder.select('id', 'fullname', 'email')
    builder.orderBy(impiegato.fullname.asc())
  })

  return defer({
    title,
    aziende: await getAziende,
    stati: await getStati,
    impiegati: await getImpiegati,
    userInfo: await getAzureUserInformation(),
    defaultCRUDAllowedRoles: [Roles.GlobalAdministrator, Roles.Administrator, Roles.Supervisor, Roles.TechDeveloper],
  })
}) satisfies LoaderFunction

export const TasksPageRoute: RoleRoute = {
  path: 'tasks',
  children: [
    {
      index: true,
      element: <TasksPage />,
      loader: tasksPageRouteLoader,
      allowedRoles: [Roles.Guest],
    } as RoleRouteObject,
    TaskCreatorRoute,
    TaskEditorRoute,
  ],
  errorElement: <RouterErrorHandler />,
  text: 'Task',
  icon: 'taskhelpneeded',
  mainMenuRoute: true,
  allowedRoles: [Roles.Guest],
}
