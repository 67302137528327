import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { Suspense } from 'react'
import { useFetcher } from 'react-router-dom'
import { Await, useLoaderData } from 'react-router-typesafe'
import { PackageSaleEditorProps } from '@/routes/packages/sale/PackageSaleEditor.types'
import PackageSaleEditorForm from '@/routes/packages/sale/PackageSaleEditor.form'
import { packageSaleEditorRouteLoader } from '@/routes/packages/sale/PackageSaleEditor.route'
import { isUserRoleAllowed } from '@/routes/utils'
import DocumentHead from '@/components/document-head/DocumentHead'

const PackageSaleEditor = (props: PackageSaleEditorProps) => {
  const { creating } = props
  const { title, packageSale, getAziende, getTipologieDurata, getCommesse, getUserInfo, defaultCRUDAllowedRoles } =
    useLoaderData<typeof packageSaleEditorRouteLoader>()

  const fetcher = useFetcher()

  return (
    <>
      <DocumentHead title={title}></DocumentHead>
      <h2>{title}</h2>
      <Suspense fallback={<LoadingPanel />}>
        <Await resolve={Promise.all([getAziende, getTipologieDurata, getCommesse, getUserInfo])}>
          {([aziende, tipologieDurata, commesse, userInfo]) => (
            <>
              <div id="edit-adsk-license-container" className={`dx-card responsive-paddings`}>
                <fetcher.Form>
                  <PackageSaleEditorForm
                    pacchetto={packageSale}
                    aziende={aziende.data.value}
                    tipologieDurata={tipologieDurata.data.value}
                    creating={creating}
                    commesse={commesse?.data.value}
                    userInfo={userInfo}
                    isRoleAllowed={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
                    fetcher={fetcher}
                  ></PackageSaleEditorForm>
                </fetcher.Form>
              </div>
            </>
          )}
        </Await>
      </Suspense>
    </>
  )
}

export default PackageSaleEditor
